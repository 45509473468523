import { colors } from '../colors';
import { TInputs } from './TInputs';

export const lightThemeInputs: TInputs = {
  variants: {
    default: {
      enabled: {
        // enabled
        background: colors.secondary,
        border: `1px solid ${colors.gray20}`,
        hintFontColor: colors.gray80,
        labelFontColor: colors.secondaryDarkBlue,
        placeholderFontColor: colors.gray80,
        valueFontColor: colors.primaryActive,
        // hover
        backgroundHover: colors.secondary,
        borderHover: `1px solid ${colors.secondaryLightBlue}`,
        hintFontColorHover: colors.gray80,
        labelFontColorHover: colors.secondaryDarkBlue,
        placeholderFontColorHover: colors.gray80,
        valueFontColorHover: colors.primaryActive,
        // active
        backgroundActive: colors.secondary,
        borderActive: `1px solid ${colors.secondaryLightBlue}`,
        hintFontColorActive: colors.gray80,
        labelFontColorActive: colors.secondaryDarkBlue,
        placeholderFontColorActive: colors.gray80,
        valueFontColorActive: colors.primaryActive,
      },
      disabled: {
        background: colors.gray10,
        border: `1px solid ${colors.gray20}`,
        hintFontColor: 'transparent',
        labelFontColor: colors.gray90,
        placeholderFontColor: colors.gray50,
        valueFontColor: colors.gray50,
        // hover
        backgroundHover: colors.gray10,
        borderHover: `1px solid ${colors.gray20}`,
        hintFontColorHover: 'transparent',
        labelFontColorHover: colors.gray90,
        placeholderFontColorHover: colors.gray80,
        valueFontColorHover: colors.gray50,
        // active
        backgroundActive: colors.gray10,
        borderActive: `1px solid ${colors.gray20}`,
        hintFontColorActive: 'transparent',
        labelFontColorActive: colors.gray90,
        placeholderFontColorActive: colors.gray80,
        valueFontColorActive: colors.gray50,
      },
      error: {
        background: colors.secondary,
        border: `1px solid ${colors.permanentPrimary}`,
        hintFontColor: colors.permanentPrimary,
        labelFontColor: colors.secondaryDarkBlue,
        placeholderFontColor: colors.gray80,
        valueFontColor: colors.primaryActive,
        // hover
        backgroundHover: colors.secondary,
        borderHover: `1px solid ${colors.permanentPrimary}`,
        hintFontColorHover: colors.permanentPrimary,
        labelFontColorHover: colors.secondaryDarkBlue,
        placeholderFontColorHover: colors.gray80,
        valueFontColorHover: colors.primaryActive,
        // active
        backgroundActive: colors.secondary,
        borderActive: `1px solid ${colors.permanentPrimary}`,
        hintFontColorActive: colors.permanentPrimary,
        labelFontColorActive: colors.secondaryDarkBlue,
        placeholderFontColorActive: colors.gray80,
        valueFontColorActive: colors.primaryActive,
      },
      name: 'default',
      borderRadius: '4px',
      hasLabel: false,
    },
  },
};

export default lightThemeInputs;
