import React, { useState } from 'react';
import { Button } from 'legacy/components/common/design-system';
import { Column, Message } from 'legacy/components/common/myeverbright';
import { MessageType, UserType } from 'legacy/types';
import { AxiosError } from 'axios';
import { isHomeowner } from 'legacy/utils';
import { inviteAdmin, inviteHomeowner } from 'api/accounts';
import { USER_ROLES } from '../../constants';
import UserForm, { UserFormType } from './UserForm';

interface AddUserSubmitHandler {
  (newUser: UserType): void;
}

const blankUser: UserFormType = {
  email: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  role: USER_ROLES.ADMIN,
};

export default function AddUserForm({ submitHandler }: { submitHandler: AddUserSubmitHandler }) {
  const [newUser, setNewUser] = useState<UserFormType>(blankUser);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);

    const { address, ...userWithoutAddress } = newUser;

    try {
      const response = isHomeowner(newUser.role)
        ? await inviteHomeowner({ ...userWithoutAddress, address })
        : await inviteAdmin(userWithoutAddress);

      setNewUser(blankUser);
      setMessage({ message: 'User successfully created', type: 'success' });
      submitHandler(response.data);
    } catch (error) {
      const { response, message: axiosMessage } = error as AxiosError;

      const errorMessage: string | undefined = Array.isArray(response?.data)
        ? (response?.data[0] as unknown as string)
        : (response?.data as unknown as string);

      setMessage({ message: errorMessage || axiosMessage, type: 'error' });
    }
  };

  return (
    <form>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <Column $padding="0 14px">
        <UserForm user={newUser} setUser={setNewUser} />

        <Button label="Submit" margin="0 0 0 auto" onClick={handleSubmit} />
      </Column>
    </form>
  );
}
