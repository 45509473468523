import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AxiosError } from 'axios';
import pages from 'pages';
import NoMatch from 'legacy/views/noMatch/NoMatch';
import useUser from 'hooks/useUser';
import { useGlobalState } from 'hooks/useGlobalState';
import { getFAQs } from 'api/faqs';
import ProtectedRoute from 'legacy/ProtectedRoute';
import { getDashboardRedirectPath } from 'legacy/utils';

import Agreement from './Agreement';
import System from './System/System';
import Billing from './Billing';
import Overview from './Overview';
import { DashboardLayout } from './DashboardLayout';

function Dashboard() {
  const { setFaqs } = useGlobalState();
  const { userResult, selectedAccount, refetch } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const faqsResponse = await getFAQs();
        setFaqs(faqsResponse.data);
      } catch (error) {
        if ((error as AxiosError).response && (error as AxiosError).response?.status === 401) {
          refetch();
        }
      }
    };
    fetchData();
  }, [setFaqs, refetch]);

  const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
    userResult?.user || undefined,
    selectedAccount,
  );

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={hasDashboardAccess}
            redirectPath={redirectPath}
            hasSession
            isLoading={userResult?.loading}
          />
        }
      >
        <Route element={<DashboardLayout />}>
          <Route index element={<Overview />} />
          <Route path={pages.OVERVIEW} element={<Overview />} />
          <Route path={pages.SYSTEM} element={<System />} />
          <Route path={pages.BILLING} element={<Billing />} />
          <Route path={pages.AGREEMENT} element={<Agreement />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Dashboard;
