import React, { useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { CalendarIcon } from 'legacy/components/common/design-system/Icons';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { DatePickerWrapper, DateHeading } from './DatePickerStyles';
import { YearPicker } from './YearPicker';
import { MonthPicker } from './MonthPicker';
import { WeekPicker } from './WeekPicker';
import { DayPicker } from './DayPicker';

type Props = {
  timeFrame: 'day' | 'week' | 'month' | 'year';
  currentDate: Date;
  onDateChange: MonthChangeEventHandler;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dateRange: { oldest: Date; newest: Date };
};

// TODO: Once design has a DatePicker in a design system we'll need refactor and move this to common components
export function DatePicker({ timeFrame, currentDate, onDateChange, dateRange }: Props) {
  const [active, setActive] = useState(false);

  const getHeadingText = () => {
    if (timeFrame === 'year') {
      return format(currentDate, 'yyyy');
    }
    if (timeFrame === 'month') {
      return format(currentDate, 'MMMM');
    }
    if (timeFrame === 'week') {
      return `${format(startOfWeek(currentDate), 'M/d/yyyy')} - ${format(
        endOfWeek(currentDate),
        'M/d/yyyy',
      )}`;
    }
    return format(currentDate, 'M/d/yyyy');
  };

  const handleClick = () => {
    setActive((a) => !a);
  };

  const handleCloseModal = () => {
    setActive(false);
  };

  const modalRef = useOutsideClick(handleCloseModal);

  return (
    <div ref={modalRef}>
      <DateHeading onClick={handleClick}>
        <CalendarIcon />
        <p>{getHeadingText()}</p>
      </DateHeading>
      <DatePickerWrapper active={active}>
        {timeFrame === 'year' && (
          <YearPicker
            onDateChange={onDateChange}
            currentDate={currentDate}
            onCloseModal={handleCloseModal}
            dateRange={dateRange}
          />
        )}
        {timeFrame === 'month' && (
          <MonthPicker
            onDateChange={onDateChange}
            currentDate={currentDate}
            onCloseModal={handleCloseModal}
            dateRange={dateRange}
          />
        )}
        {timeFrame === 'week' && (
          <WeekPicker
            currentDate={currentDate}
            onDateChange={onDateChange}
            onCloseModal={handleCloseModal}
            dateRange={dateRange}
          />
        )}
        {timeFrame === 'day' && (
          <DayPicker
            currentDate={currentDate}
            onDateChange={onDateChange}
            onCloseModal={handleCloseModal}
            dateRange={dateRange}
          />
        )}
      </DatePickerWrapper>
    </div>
  );
}

export default DatePicker;
