import styled from 'styled-components';
import colors from 'theme/colors';
import img from '../../static/bg.webp';
import { MOBILE_MAX_WIDTH } from '../constants';

export const Wrapper = styled.div<{ $accountView?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #22345b;
  align-items: stretch;
  height: 100%;
  ${({ $accountView }) =>
    $accountView
      ? `
  background: ${colors.gray40} url(${img}) no-repeat;
  background-size: cover;
  `
      : `
  background: ${colors.gray05};
  `}
`;

export const HeaderStyled = styled.header`
  align-self: start;
  height: fit-content;
  width: 100%;
`;

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: ${colors.white};
  padding: 24px 0;
  align-self: end;
  width: 100%;
  border-top: 1px solid ${colors.gray10};

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
    padding: 32px 0;
  }
`;
