import React from 'react';

import BillingIcon from 'static/billing-icon.svg';
import { EVERBRIGHT_ONLINE_ACCOUNT_LINK } from '../../constants';
import { Button } from '../common/design-system';
import { ArrowUpRightFromSquare } from '../common/design-system/Icons';
import { billingCopy } from '../../copy';
import { Card } from './Card';
import { Content, Footer, Icon, Text } from './CardStyles';

export function BillingCard() {
  const handleClick = () => {
    window.open(EVERBRIGHT_ONLINE_ACCOUNT_LINK, '_blank');
  };
  return (
    <Card title="Billing & Payment" color="blue" fullWidth fullHeight>
      <>
        <Icon alt="billing and payment" src={BillingIcon} />

        <Content>
          <Text>{billingCopy}</Text>
        </Content>

        <Footer>
          <Button
            label="Payment Dashboard"
            Icon={ArrowUpRightFromSquare}
            iconRight
            onClick={handleClick}
            isFullWidth
          />
        </Footer>
      </>
    </Card>
  );
}

export default BillingCard;
