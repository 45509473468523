import React, { useEffect, useReducer, useState } from 'react';

import { AxiosError } from 'axios';
import { updateUser } from 'api/user';
import { useUser } from 'hooks/useUser';
import { sendAdminClaimAccountEmail, sendHomeownerClaimAccountEmail } from 'api/accounts';
import { Button, Select, Table } from 'legacy/components/common/design-system';
import { Card, Message } from 'legacy/components/common/myeverbright';
import { UserType, MessageType, RolesKeyAdmin } from 'legacy/types';
import AddUserForm from 'legacy/components/forms/AddUserForm';
import { formatDateShort, tableDataReducer, isAdmin, isHomeowner } from '../../utils';
import { USER_ROLES, USER_ROLES_INTERNAL } from '../../constants';
import Checkbox from '../../../static/checkbox.svg';

function InternalUserTable({
  internalUsers,
  updateInternalUsers,
}: {
  internalUsers: ReadonlyArray<UserType>;
  updateInternalUsers: React.Dispatch<any>;
}) {
  const [message, setMessage] = useState<MessageType | null>(null);
  const [adding, setAdding] = useState(false);

  const [state, dispatch] = useReducer(tableDataReducer, {
    tableData: [],
  });
  const { tableData } = state;
  const { userResult } = useUser();
  const currentUser = userResult?.user;

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', tableData: internalUsers });
  }, [internalUsers]);

  const submitHandler = (newUser: UserType) => {
    updateInternalUsers([...internalUsers, newUser]);
  };

  const onResendClaimAccountEmail = async (user: UserType) => {
    try {
      if (isHomeowner(user.role)) {
        await sendHomeownerClaimAccountEmail(user.email, true);
      } else {
        await sendAdminClaimAccountEmail(user.id);
      }

      setMessage({ message: `Claim Account Email sent to ${user.email}`, type: 'success' });
    } catch (error: any) {
      setMessage({ message: (error as AxiosError).message, type: 'error' });
    }
  };

  const handleSelectOption = async (role: RolesKeyAdmin, user: UserType) => {
    try {
      await updateUser({ user_id: user.id, body: { role } });

      const newAdmins = tableData.map((admin: UserType) =>
        admin.id === user.id ? { ...admin, role } : admin,
      );
      dispatch({ type: 'LOAD_DATA', tableData: newAdmins });
      setMessage({
        message: `Successfully updated role for ${user.email} from ${user.role} to ${role}.`,
        type: 'success',
      });
    } catch (error: any) {
      setMessage({
        message: `Only Super Admins can change a user's role to ${role}.`,
        type: 'error',
      });
    }
  };

  const selectOptions = Object.keys(USER_ROLES_INTERNAL).map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    value: key,
  }));

  const columns = [
    {
      title: 'Name',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'last_name' }),
    },
    {
      title: 'Email',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'email' }),
    },
    {
      title: 'Role',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'role' }),
    },
    {
      title: 'Claimed',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'claimed_account' }),
    },
    {
      title: 'Date Invited',
      handleSort: () => dispatch({ type: 'CHANGE_SORT', column: 'date_joined' }),
    },
  ];

  const rows = tableData.map((user: UserType) => ({
    key: `table-row${user.id}`,
    cells: [
      `${user.first_name} ${user.last_name}`,
      user.email,
      currentUser && isAdmin(currentUser.role) ? (
        <Select
          value={user.role}
          options={selectOptions}
          onSelectOption={(value: string) =>
            handleSelectOption(USER_ROLES[value as RolesKeyAdmin], user)
          }
        />
      ) : (
        user.role
      ),
      user.claimed_account ? (
        <img alt="check" src={Checkbox} width={16} />
      ) : (
        <Button
          label="Resend Email"
          onClick={() => onResendClaimAccountEmail(user)}
          styleVariant="tertiary"
        />
      ),
      <time dateTime={user.date_joined}>{formatDateShort(user.date_joined)}</time>,
    ],
  }));

  return (
    <>
      {message && <Message severity={message.type}>{message.message}</Message>}
      {adding ? (
        <Card $fullWidth>
          <h3>
            Add User
            <div
              className="pull-right edit"
              role="button"
              tabIndex={0}
              onClick={() => setAdding(!adding)}
              onKeyDown={() => setAdding(!adding)}
            >
              Close
            </div>
          </h3>
          <AddUserForm submitHandler={submitHandler} />
        </Card>
      ) : (
        <Button onClick={() => setAdding(!adding)} label="+ Add User" maxWidth="150px" />
      )}
      <Table columns={columns} rows={rows} />
    </>
  );
}

export default InternalUserTable;
