import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyText } from '../ProjectQualification/ProjectQualification.styles';

export function ContractSigning() {
  const { t } = useTranslation();

  return <BodyText as="p">{t('contractSigned.yourContractSigned')}</BodyText>;
}

export default ContractSigning;
