import styled from 'styled-components';
import { MOBILE_MAX_WIDTH, breakpoints } from '../../constants';

export const Container = styled.div`
  width: 933px;
  margin: 0 auto;
  max-width: 100vw;
  @media (min-width: ${breakpoints.large}) {
    width: 1127px;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    width: auto;
    padding: 0 16px;
  }
`;

export const BannerSpacing = styled.div`
  margin-top: 12;
`;
