import styled from 'styled-components';

export const AutopayModalContent = styled.div`
  ${({ theme }) => `
        display: flex-column;
        align-items: center;
        width: 416px;
        height: fit-content;

        svg {
            display: block;
            margin: 20px auto 26px;
        }

        h2 {
            font-weight: 500;
            font-size: 24px;
            text-align: center;
            margin-bottom: 20px;
        }

        p {
            color: ${theme.colors.textSecondaryGrey90};
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
            strong {
                font-weight: 700;
            }
        }
        
        a {
            text-decoration: none;
            font-size: 16px;
            color: ${theme.colors.textLinkLinkSecondary2100};
            font-weight: 700;
        }

        button {
            margin: 36px 0 0 auto;
        }

        @media screen and (max-width: ${theme.breakpoints.small}) {
            width: 100%;
        }
    `}
`;

export default AutopayModalContent;
