import {
  getDropdownStyleVariantValues,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';
import styled from 'styled-components';

export type TDropdownPosition = {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

export type TDropdownContainerProps = {
  $isOpen: boolean;
  $isFullWidth: boolean;
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
  $position: TDropdownPosition;
};

export const DropdownContainer = styled.ul<TDropdownContainerProps>`
  ${({
    $isOpen = false,
    $isFullWidth,
    $styleVariant,
    $state,
    $position: { top, right, bottom, left },
    theme,
  }) => {
    const { background, border, borderRadius, padding } = getDropdownStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    return `
      position: absolute;
      z-index: 100;
      display: ${$isOpen ? 'block' : 'none'};
      width: ${$isFullWidth ? '100%' : 'fit-content'};
      max-height: 400px;
      overflow: auto;
      background: ${background};
      border: ${border};
      border-radius: ${borderRadius};
      padding: ${padding};
      margin: 0;
      top: ${top};
      right: ${right};
      bottom: ${bottom};
      left: ${left};
      box-shadow: 0px 4px 8px -2px rgba(34, 52, 91, 0.10), 0px 2px 4px -2px rgba(34, 52, 91, 0.06);
    `;
  }}
`;

export const DropdownButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;
