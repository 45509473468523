import styled from 'styled-components';
import { TTableStyleVariantsKey } from 'theme/table';

type TTableStyleProps = {
  $isFullWidth: boolean;
  $styleVariant: TTableStyleVariantsKey;
};

export const TableStyled = styled.table<TTableStyleProps>`
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'fit-content')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 15px 30px 0px rgba(112, 144, 176, 0.1);
`;

export default TableStyled;
