import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function CircleXIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4722_404)">
        <path
          d="M5.64687 5.64687C5.84062 5.45 6.15938 5.45 6.35313 5.64687L8 7.29375L9.64688 5.64687C9.84063 5.45 10.1594 5.45 10.3531 5.64687C10.55 5.84062 10.55 6.15938 10.3531 6.35313L8.70625 8L10.3531 9.64688C10.55 9.84063 10.55 10.1594 10.3531 10.3531C10.1594 10.55 9.84063 10.55 9.64688 10.3531L8 8.70625L6.35313 10.3531C6.15938 10.55 5.84062 10.55 5.64687 10.3531C5.45 10.1594 5.45 9.84063 5.64687 9.64688L7.29375 8L5.64687 6.35313C5.45 6.15938 5.45 5.84062 5.64687 5.64687ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z"
          fill={color || theme.colors.iconIconSecondarySubtleGrey80}
        />
      </g>
      <defs>
        <clipPath id="clip0_4722_404">
          <rect width="16" height="16" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CircleXIcon;
