import React, { useState } from 'react';
import { useUser } from 'hooks/useUser';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import { Button } from '../common/design-system';
import { Card } from '../common/myeverbright/Card/Card';
import { EditButtonWrapper, FormWrapper } from './SecurityStyles';

function Security() {
  const { userResult } = useUser();
  const user = userResult?.user;

  const [editingPassword, setEditingPassword] = useState(false);

  const handleEditingPassword = () => setEditingPassword(!editingPassword);

  return (
    <Card $fullWidth>
      <h2>Login Details</h2>
      <div>
        <div className="label">Email</div>
        <p>{user?.email}</p>
        <EditButtonWrapper>
          <div className="label">Password</div>
          {!userResult?.impersonator && (
            <Button
              label={editingPassword ? 'Close' : 'Edit'}
              styleVariant="tertiary"
              onClick={handleEditingPassword}
            />
          )}
        </EditButtonWrapper>
        {editingPassword ? (
          <FormWrapper>
            <ChangePasswordForm />
          </FormWrapper>
        ) : (
          <p>********</p>
        )}
      </div>
    </Card>
  );
}

export default Security;
