import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MessageType } from 'legacy/types';
import { BodyBold, BodyRegular } from 'legacy/components/common/design-system/Typography';
import { Checkbox } from '../common/myeverbright';
import { OrderedList } from './AuthCopyAckStyles';

type Props = {
  confirmed: boolean;
  message: MessageType | null;
  onChangeConfirm: () => void;
};
function AuthCopyAck({ confirmed, message, onChangeConfirm }: Props) {
  const { t } = useTranslation();

  const checkboxRef = useRef<HTMLInputElement | null>(null);

  const body4List: string[] = t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body4List', {
    returnObjects: true,
  });

  useEffect(() => {
    if (checkboxRef && message?.type === 'error') {
      checkboxRef.current?.scrollIntoView();
    }
  }, [message]);

  return (
    <>
      <BodyRegular $margin="24px 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body1')}
      </BodyRegular>

      <BodyRegular $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body2')}
      </BodyRegular>

      <BodyBold>{t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body3Title')}</BodyBold>
      <BodyRegular $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body3')}
      </BodyRegular>

      <BodyBold>{t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body4Title')}</BodyBold>

      <OrderedList>
        {Array.isArray(body4List) &&
          body4List?.map((item: string) => (
            <li key={item}>
              <BodyRegular>{item}</BodyRegular>
            </li>
          ))}
      </OrderedList>

      <BodyBold>{t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body5Title')}</BodyBold>
      <BodyRegular $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body5')}
      </BodyRegular>

      <Checkbox
        forwardRef={checkboxRef}
        isChecked={confirmed}
        isRequired
        onChange={onChangeConfirm}
        text={t('acknowledgeAuthoritativeCopy.acknowledgeCopy.checkbox')}
        margin="24px 0"
        hint={message?.message}
        error={message?.type === 'error'}
      />

      <BodyRegular $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body6')}
      </BodyRegular>

      <BodyRegular $margin="0 0 16px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeCopy.body7')}
      </BodyRegular>

      <BodyRegular>
        <Trans i18nKey="acknowledgeAuthoritativeCopy.acknowledgeCopy.body8">
          Stay sunny,
          <br />
          EverBright
        </Trans>
      </BodyRegular>
    </>
  );
}

export default AuthCopyAck;
