import React from 'react';
import { Color } from 'theme/colors';
import { StatusIndicatorStyled } from './StatusIndicatorStyles';
import { Subtitle2 } from '../Typography';

type StatusIndicatorProps = {
  bgColor?: Color;
  text: string;
};

/**
 * @param {string} [bgColor] - background color of indicator (defaults to green20)
 * @param {string} [text] - text displayed
 */
export function StatusIndicator({ bgColor, text }: StatusIndicatorProps) {
  return (
    <StatusIndicatorStyled $bgColor={bgColor}>
      <Subtitle2>{text}</Subtitle2>
    </StatusIndicatorStyled>
  );
}

export default StatusIndicator;
