import React from 'react';
import { AuthBox, AuthWrapper, BodyText } from 'modules/auth/Auth.styles';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { pages } from 'pages';
import { sendResetPasswordLink } from 'api/accounts';
import { EmailField } from 'shared/components/EmailField/EmailField';
import { Button } from 'shared/components/Button/Button';
import { BackButton } from 'modules/auth/components/BackButton';
import { resolvePath } from 'react-router-dom';
import { Header } from 'modules/auth/components/Header/Header';
import { LoginFormInput } from '../../components/LoginForm/LoginForm';

export function ForgotPassword() {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<Omit<LoginFormInput, 'password'>>({
    mode: 'onSubmit',
    defaultValues: {
      email: location.state?.email || '',
    },
  });

  const { mutate } = useMutation({
    mutationFn: sendResetPasswordLink,
  });

  const onSubmit: SubmitHandler<Omit<LoginFormInput, 'password'>> = ({ email }) => {
    mutate(email);
    navigate(resolvePath(pages.CHECK_EMAIL, '/'), { state: { email } });
  };

  return (
    <AuthWrapper>
      <BackButton />
      <AuthBox as="form" onSubmit={handleSubmit(onSubmit)}>
        <Header
          title={t('forgotPasswordScreen.sendScreenHeaderOne')}
          titleBold={t('forgotPasswordScreen.sendScreenHeaderTwo')}
          showLogo
        />

        <BodyText as="p" $margin="0 0 20px">
          {t('forgotPasswordScreen.sendScreenBodyOne')}
        </BodyText>
        <BodyText as="p" $margin="0 0 20px">
          {t('forgotPasswordScreen.sendScreenBodyTwo')}
        </BodyText>

        <EmailField
          register={register}
          onClearInput={resetField}
          hasError={Boolean(errors.email)}
          errorMessage={errors.email?.message}
          margin="24px 0 0"
        />
        <Button
          type="submit"
          label={t('forgotPasswordScreen.resetPassword')}
          isFullWidth
          margin={`${screenSize === 'sm' ? '16px' : '32px'} 0 0`}
        />
      </AuthBox>
    </AuthWrapper>
  );
}

export default ForgotPassword;
