import React from 'react';
import { Button } from 'legacy/components/common/design-system';
import { ArrowUpRightFromSquare } from 'legacy/components/common/design-system/Icons';
import { Card } from 'legacy/components/OverviewCards/Card';
import { Content } from 'legacy/components/OverviewCards/CardStyles';
import { noSystemCopy, systemPerformanceCopy } from 'legacy/copy';
import SystemCard from 'legacy/components/SystemCard';
import { SupportCard } from 'legacy/components/OverviewCards/SupportCard';
import { Column, Row } from 'legacy/components/common/myeverbright';
import { useUser } from 'hooks/useUser';
import { hasMonitoring } from 'legacy/utils';
import { EVERBRIGHT_OMNIDIAN_LOGIN } from '../../../constants';
import MonitoringSystem from './MonitoringSystem';

const handleClick = () => {
  window.open(EVERBRIGHT_OMNIDIAN_LOGIN, '_blank');
};

function System() {
  const { selectedAccount } = useUser();

  return (
    <Column>
      <Row>
        <h1>System</h1>
      </Row>

      {selectedAccount?.agreements && hasMonitoring(selectedAccount.agreements) ? (
        <MonitoringSystem agreements={selectedAccount?.agreements} />
      ) : (
        <Row $collapsing>
          <Column $flex="2">
            {selectedAccount?.agreements?.some((agreement) => !agreement.is_ric) && (
              <Card title="System Performance" fullWidth color="green" fullHeight>
                <>
                  <Content>
                    <p>{systemPerformanceCopy}</p>
                  </Content>
                  <Button
                    label="Performance Dashboard"
                    Icon={ArrowUpRightFromSquare}
                    iconRight
                    onClick={handleClick}
                    margin="0 auto 0 0"
                  />
                </>
              </Card>
            )}
            {selectedAccount?.agreements?.map((agreement) =>
              agreement.is_beam ? (
                <p key={agreement.id}>{noSystemCopy}</p>
              ) : (
                <SystemCard agreement={agreement} key={agreement.id} />
              ),
            )}
          </Column>
          <Row $flex="1" $height="fit-content">
            <SupportCard fullHeight />
          </Row>
        </Row>
      )}
    </Column>
  );
}

export default System;
