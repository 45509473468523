import styled, { css } from 'styled-components';

export const PinOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PinWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Input = styled.input<{ $hasValue: boolean }>`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 45px;
  height: 64px;
  padding: 12px 9px;
  border-radius: 6px;
  font-family: Avenir;
  font-size: 34px;
  line-height: 44px;
  font-weight: 800;
  outline: none;

  ${({ theme, $hasValue }) => css`
    border: 1px solid;
    background: ${theme.colors.cardElevationMidWhite};

    ${$hasValue
      ? theme.colors.textSystemInfoSecondary2100
      : theme.colors.strokeAccentGreyStrongerGrey50};
    color: ${theme.colors.textSystemInfoSecondary2100};
    text-align: ${$hasValue ? 'center' : 'left'};
    &:focus,
    &:focus-visible {
      border: 1px solid ${theme.colors.textSystemInfoSecondary2100};
    }
  `}
`;
