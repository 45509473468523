import styled from 'styled-components';
import { Card } from 'legacy/components/common/myeverbright';
// import { Grid } from 'components/common/myeverbright/Layout/Layout';

export const StatusDetailsCardStyled = styled(Card)`
  display: flex;
  flex: 1;
  padding: 32px;
  max-width: 359px;
  height: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
`;

export const Content = styled.div`
  flex-basis: calc(50% - 6px);
`;

export const LogoImage = styled.img`
  height: 48px;
  margin: auto auto 0 0;
`;
