export const globalStyles = {
  ebSmallDropShadow: '10px 10px 10px rgba(112, 144, 176, 0.05)',
  ebStandardDropShadow: '0px 15px 30px 0px rgba(112, 144, 176, 0.1)',
};

export type GlobalStylesKey = keyof typeof globalStyles;

export type GlobalStyle = (typeof globalStyles)[GlobalStylesKey];

export default globalStyles;
