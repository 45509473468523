import React from 'react';
import { Button } from 'legacy/components/common/design-system';
import { Link } from 'react-router-dom';

import { ArrowUpRightFromSquare } from 'legacy/components/common/design-system/Icons';
import { SupportCard } from '../../components/OverviewCards/SupportCard';
import { billingCopy } from '../../copy';
import { EVERBRIGHT_ONLINE_ACCOUNT_LINK } from '../../constants';

import { ButtonGroup } from './BillingStyles';
import { Box, Column, Row } from '../../components/common/myeverbright';
import { Card } from '../../components/OverviewCards/Card';
import { useUser } from '../../../hooks/useUser';

function Billing() {
  const { selectedAccount } = useUser();

  const handleClick = () => {
    window.open(EVERBRIGHT_ONLINE_ACCOUNT_LINK, '_blank');
  };

  return (
    <Column>
      <h1>Billing & Payment</h1>

      <Row $collapsing>
        <Column $flex="2">
          {selectedAccount?.agreements?.map((agreement) => (
            <Box key={agreement.id}>
              <Card
                title={`${agreement.product_display_name} #${agreement.homeowner_contract_id}`}
                color="blue"
                fullWidth
                fullHeight
                key={agreement.id}
              >
                <>
                  <p className="copy">{billingCopy}</p>
                  <ButtonGroup>
                    <Button
                      label="Payment Dashboard"
                      Icon={ArrowUpRightFromSquare}
                      iconRight
                      onClick={handleClick}
                      margin="16px 16px 0 0"
                    />
                    <Link to={`../agreement/${agreement.id}`} className="agreements-details-link">
                      View Agreement Details
                    </Link>
                  </ButtonGroup>
                </>
              </Card>
            </Box>
          ))}
        </Column>
        <Column $flex="1">
          <Box>
            <SupportCard />
          </Box>
        </Column>
      </Row>
    </Column>
  );
}

export default Billing;
