import React, { useMemo } from 'react';
import { Select, TextInput } from 'legacy/components/common/design-system';
import { TDropdownDataItem } from 'legacy/components/common/design-system/Dropdown/TDropdown';
import { STATES } from '../../../constants';
import { Grid } from './AddressFormStyles';

export interface AddressFormType {
  street_1: string | null;
  street_2: string | null;
  city: string | null;
  state: string | undefined;
  zip_code: string | null;
}

type Props = {
  address: AddressFormType;
  onAddressChange: (address: AddressFormType) => void;
};

export function AddressForm({ address, onAddressChange }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = { ...address, [e.target.name]: e.target.value };
    onAddressChange(newAddress);
  };

  const handleSelectChange = (value: string) => {
    const newAddress = { ...address, state: value };
    onAddressChange(newAddress);
  };

  const stateOptions: TDropdownDataItem[] = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  return (
    <>
      <Grid $columns={2}>
        <TextInput
          ariaLabel="Street Address 1"
          label="Street Address 1"
          name="street_1"
          isRequired
          defaultValue={address.street_1 || ''}
          onChange={handleChange}
        />

        <TextInput
          ariaLabel="Street Address 2"
          label="Street Address 2"
          name="street_2"
          isRequired
          defaultValue={address.street_2 || ''}
          onChange={handleChange}
        />
      </Grid>
      <Grid>
        <TextInput
          ariaLabel="City"
          label="City"
          name="city"
          isRequired
          defaultValue={address.city || ''}
          onChange={handleChange}
        />

        <Select
          label="State"
          placeholder="State"
          value={address.state}
          options={stateOptions}
          onSelectOption={handleSelectChange}
        />

        <TextInput
          ariaLabel="Zip"
          label="Zip"
          name="zip_code"
          isRequired
          defaultValue={address.zip_code || ''}
          onChange={handleChange}
        />
      </Grid>
    </>
  );
}

export default AddressForm;
