import { darkThemeColors as colors } from '../colors/themeColors';
import { TInputs } from './TInputs';

export const darkThemeInputs: TInputs = {
  variants: {
    default: {
      enabled: {
        // enabled
        background: colors.backgroundPrimaryWhite,
        border: `1px solid ${colors.strokePrimaryGrey20}`,
        hintFontColor: colors.textSecondarySubtleGrey80,
        labelFontColor: colors.textPrimarySecondary1100,
        placeholderFontColor: colors.textSecondarySubtleGrey80,
        valueFontColor: colors.textPrimarySecondary1100,
        // hover
        backgroundHover: colors.backgroundPrimaryWhite,
        borderHover: `1px solid ${colors.strokeAccentGreyStrongGrey40}`,
        hintFontColorHover: colors.textSecondarySubtleGrey80,
        labelFontColorHover: colors.textPrimarySecondary1100,
        placeholderFontColorHover: colors.textSecondarySubtleGrey80,
        valueFontColorHover: colors.textPrimarySecondary1100,
        // active
        backgroundActive: colors.backgroundPrimaryWhite,
        borderActive: `1px solid ${colors.strokeInteractionActiveStrongFunction4100}`,
        hintFontColorActive: colors.textSecondarySubtleGrey80,
        labelFontColorActive: colors.textPrimarySecondary1100,
        placeholderFontColorActive: colors.textSecondarySubtleGrey80,
        valueFontColorActive: colors.textPrimarySecondary1100,
      },
      disabled: {
        background: colors.backgroundSystemDisabledGrey10,
        border: `1px solid ${colors.strokePrimaryGrey20}`,
        hintFontColor: 'transparent',
        labelFontColor: colors.textSystemDisabledGrey50,
        placeholderFontColor: colors.textSystemDisabledGrey50,
        valueFontColor: colors.textSystemDisabledGrey50,
        // hover
        backgroundHover: colors.backgroundSystemDisabledGrey10,
        borderHover: `1px solid ${colors.strokePrimaryGrey20}`,
        hintFontColorHover: 'transparent',
        labelFontColorHover: colors.textSystemDisabledGrey50,
        placeholderFontColorHover: colors.textSystemDisabledGrey50,
        valueFontColorHover: colors.textSystemDisabledGrey50,
        // active
        backgroundActive: colors.backgroundSystemDisabledGrey10,
        borderActive: `1px solid ${colors.strokePrimaryGrey20}`,
        hintFontColorActive: 'transparent',
        labelFontColorActive: colors.textSystemDisabledGrey50,
        placeholderFontColorActive: colors.textSystemDisabledGrey50,
        valueFontColorActive: colors.textSystemDisabledGrey50,
      },
      error: {
        background: colors.backgroundPrimaryWhite,
        border: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        hintFontColor: colors.textSystemErrorFunction1100,
        labelFontColor: colors.textPrimarySecondary1100,
        placeholderFontColor: colors.textSecondarySubtleGrey80,
        valueFontColor: colors.textPrimarySecondary1100,
        // hover
        backgroundHover: colors.backgroundPrimaryWhite,
        borderHover: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        hintFontColorHover: colors.textSystemErrorFunction1100,
        labelFontColorHover: colors.textPrimarySecondary1100,
        placeholderFontColorHover: colors.textSecondarySubtleGrey80,
        valueFontColorHover: colors.textPrimarySecondary1100,
        // active
        backgroundActive: colors.backgroundPrimaryWhite,
        borderActive: `1px solid ${colors.strokeSystemErrorFunction1100}`,
        hintFontColorActive: colors.textSystemErrorFunction1100,
        labelFontColorActive: colors.textPrimarySecondary1100,
        placeholderFontColorActive: colors.textSecondarySubtleGrey80,
        valueFontColorActive: colors.textPrimarySecondary1100,
      },
      name: 'default',
      borderRadius: '4px',
      hasLabel: false,
    },
  },
};

export default darkThemeInputs;
