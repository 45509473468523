import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'shared/components/Text/Text';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';
import { ClaimAccountLoadingContainer, LogoWrapper } from './ClaimAccountLoading.styles';

export function ClaimAccountLoading() {
  const { t } = useTranslation();
  return (
    <ClaimAccountLoadingContainer>
      <Text as="body">{t('claimAccount.settingUpYourAccount')}</Text>
      <LogoWrapper>
        <LoadingLogo />
      </LogoWrapper>
    </ClaimAccountLoadingContainer>
  );
}

export default ClaimAccountLoading;
