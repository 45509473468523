import axios, { AxiosPromise } from 'axios';
import routes from 'routes';
import { DateRange } from './utils/createDateRange';
import { getRequestParams } from './utils/getRequestParams';
import { EnergyUnit, PowerFlowDataResponse, PowerUnit, SiteTimezoneResponse } from './types';
import { EnergySummaryType, RawMonitoringDatumType } from './monitoringTypes';

export type GetMonitoringRequestParams = {
  systemId: string;
  dateRange?: DateRange | null;
};

export function patchSystem(id: string, body: {}) {
  return axios.patch(routes.system(id), body);
}

export function backfillWorkflows(workflow_ids: string[]) {
  return axios.post(routes.backfillWorkflows, { workflow_ids });
}

export function getPowerFlow(systemId: string): AxiosPromise<PowerFlowDataResponse> {
  return axios.get(routes.powerFlow(systemId));
}

export function getSiteTimezone(systemId: string): AxiosPromise<SiteTimezoneResponse> {
  return axios.get(routes.siteTimezone(systemId));
}

export function getSolarPower({
  systemId,
  dateRange,
}: GetMonitoringRequestParams): AxiosPromise<RawMonitoringDatumType<PowerUnit>[]> {
  return axios.get(routes.solarPower(systemId), {
    params: getRequestParams(dateRange),
  });
}

export function getSolarEnergy({
  systemId,
  dateRange,
}: GetMonitoringRequestParams): AxiosPromise<RawMonitoringDatumType<EnergyUnit>[]> {
  return axios.get(routes.solarEnergy(systemId), {
    params: getRequestParams(dateRange),
  });
}

export function getEnergySummary(id: string): AxiosPromise<EnergySummaryType> {
  return axios.get(routes.energySummary(id));
}
