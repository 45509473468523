import React from 'react';
import { FlyoutProps } from 'victory';
import { DateRange } from 'api/system/utils/createDateRange';
import { format } from 'date-fns';
import { ToolTipTextContainer, Line, chartStyles, ToolTipkW, TooltipTime } from '../ChartStyles';
import { TooltipIcon } from './TooltipIcon';

export const valueFormatted = (value: number, unit: string) => `${value.toFixed(2)} ${unit}`;

export const timeFormatted = (time: Date, dateFormat: string = 'hh:mm a') =>
  format(time, dateFormat);

interface Props extends FlyoutProps {
  datum?: FlyoutProps['datum'] & {
    unit: string;
    value: number;
    time: Date;
    customTooltip?: string;
  };
  dateRange?: DateRange;
  tooltipDateFormatter?: (date: Date, dateRange: DateRange) => string;
}

export function Tooltip({ x, y, datum, dateRange, tooltipDateFormatter }: Props) {
  const { time, unit, value, customTooltip } = datum!;
  const tooltipWidth = 150;
  const tooltipOffset = 8;

  const tooltipTextPosition = {
    x: x ? x + tooltipOffset : x,
    y: y ? y - 44 : y,
  };

  const tooltipIconPosition = {
    x: x ? x - 15 : x,
    y: y ? y - 15 : y,
  };

  if (tooltipTextPosition.x && tooltipTextPosition.x > 590) {
    tooltipTextPosition.x -= tooltipWidth + tooltipOffset * 2;
  }

  return (
    <g style={{ top: 0, position: 'relative' }}>
      <Line x1={x} x2={x} y1={chartStyles.tooltip.lineHeight} y2={chartStyles.tooltip.y2} />

      <foreignObject
        x={tooltipTextPosition.x}
        y={tooltipTextPosition.y}
        width={tooltipWidth}
        height="44"
        style={{ overflow: 'visible' }}
      >
        <ToolTipTextContainer>
          {customTooltip ? (
            <TooltipTime>{customTooltip}</TooltipTime>
          ) : (
            <>
              <ToolTipkW>{valueFormatted(value, unit)}</ToolTipkW>
              {dateRange && tooltipDateFormatter && (
                <TooltipTime>{tooltipDateFormatter(time, dateRange)}</TooltipTime>
              )}
            </>
          )}
        </ToolTipTextContainer>
      </foreignObject>
      <foreignObject
        x={tooltipIconPosition.x}
        y={tooltipIconPosition.y}
        style={{ overflow: 'visible' }}
      >
        <TooltipIcon />
      </foreignObject>
    </g>
  );
}

export default Tooltip;
