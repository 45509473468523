import colors from 'theme/colors';

// dimensions come from figma and are based on the svg viewBox coordinate system
export const dimensions = {
  width: 749,
  height: 68,
  dividerLength: 71.203,
  textCircleRadius: 12,
  get circleHeight() {
    return this.height - 43;
  },
  get circleTextHeight() {
    return this.height - 38;
  },
  get textHeight() {
    return this.height - 15;
  },
  get radius() {
    return this.height / 2;
  },
  get dividerRun() {
    return Math.sqrt(this.dividerLength ** 2 - this.height ** 2);
  },
};

export const mobileDimensions = {
  width: 343,
  height: 42,
  dividerLength: 47,
  get radius() {
    return this.height / 2;
  },
  get dividerRun() {
    return Math.sqrt(this.dividerLength ** 2 - this.height ** 2);
  },
};

export const numberTextStyles = {
  fontFamily: 'AvenirLTPro',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
};

export const textStyles = {
  ...numberTextStyles,
  fontWeight: '400',
  lineHeight: '16px',
};

const getSegmentDimensions = (
  stages: string[],
  segmentIdx: number,
  isSmallScreen: boolean = false,
) => {
  const barWidth = isSmallScreen ? mobileDimensions.width : dimensions.width;
  const barHeight = isSmallScreen ? mobileDimensions.height : dimensions.height;
  const segmentWidth = barWidth / stages.length;
  const run = isSmallScreen ? mobileDimensions.dividerRun : dimensions.dividerRun;
  const endSegmentRadius = isSmallScreen ? mobileDimensions.radius : dimensions.radius;

  // xy coordinates for left segment
  if (segmentIdx === 0) {
    const x1 = endSegmentRadius;
    const y1 = 0;
    const x2 = segmentWidth + run / 2;
    const y2 = 0;
    const x3 = segmentWidth - run / 2;
    const y3 = barHeight;
    const x4 = endSegmentRadius;
    const y4 = barHeight;
    const centerX = segmentWidth / 2;

    return {
      points: `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`,
      centerX,
    };
  }

  // xy coordinates for right segment
  if (segmentIdx === stages.length - 1) {
    const x1 = barWidth - segmentWidth + run / 2;
    const y1 = 0;
    const x2 = barWidth - endSegmentRadius;
    const y2 = 0;
    const x3 = barWidth - endSegmentRadius;
    const y3 = barHeight;
    const x4 = barWidth - segmentWidth - run / 2;
    const y4 = barHeight;
    const centerX = (barWidth - endSegmentRadius + (barWidth - segmentWidth + run)) / 2;

    return {
      points: `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`,
      centerX,
    };
  }

  // xy coordinates for middle segments
  const x1 = segmentWidth * segmentIdx + run / 2;
  const y1 = 0;
  const x2 = segmentWidth * segmentIdx + segmentWidth + run / 2;
  const y2 = 0;
  const x3 = segmentWidth * segmentIdx + segmentWidth - run / 2;
  const y3 = barHeight;
  const x4 = segmentWidth * segmentIdx - run / 2;
  const y4 = barHeight;
  const centerX = (x1 + x2 - run) / 2;

  return {
    points: `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`,
    centerX,
  };
};

export const getSegmentStyles = (
  stages: string[],
  currentStage: string,
  segmentIdx: number,
  isSmallScreen: boolean = false,
) => {
  const currentStageIdx = stages.indexOf(currentStage);
  const segmentDimensions = getSegmentDimensions(stages, segmentIdx, isSmallScreen);

  if (segmentIdx <= currentStageIdx) {
    return {
      bgColor: 'transparent',
      textColor: colors.white,
      numberColor: colors.secondaryDarkBlue,
      numberBgColor: colors.white,
      ...segmentDimensions,
    };
  }

  if (segmentIdx === currentStageIdx + 1) {
    return {
      bgColor: colors.gray20,
      textColor: colors.secondaryDarkBlue,
      numberColor: colors.secondaryDarkBlue,
      numberBgColor: colors.white,
      ...segmentDimensions,
    };
  }

  return {
    bgColor: colors.gray20,
    textColor: colors.gray40,
    numberColor: colors.gray40,
    numberBgColor: colors.gray05,
    ...segmentDimensions,
  };
};
