import { Card } from 'shared/components/Card/Card';
import { CardActionText, CardTitle } from 'shared/components/Card/Card.styles';
import { FONT_FAMILY } from 'shared/components/Text/Text';
import styled from 'styled-components';
import { breakpointValues } from 'shared/design-system/theme/breakpoints';
import {
  MonitoringSnapshotWrapper,
  StatisticLabel,
  StatisticValue,
  StatisticWrapper,
} from '../../components/MonitoringSnapshot/MonitoringSnapshot.styles';
import { DateHeading } from '../../components/DateSelector/DateSelector.styles';
import { SegmentControl } from '../../components/TimescaleControl/TimescaleControl.styles';

/** Represents the minimum screen space occupied by the nav bar–it's being used
 * here to trigger a slightly earlier than usual jump to medium-screen styling to
 * avoid crowding the system screen
 */
const NAV_BAR_WIDTH = 214;

export const SystemChartHeader = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (${theme.breakpoints.small} < width <= ${theme.breakpoints.medium}) {
        margin-bottom: 18px;
        ${DateHeading} {
          height: 36px;
        }
    }

    @media (width <= ${theme.breakpoints.small}) {
      display: flex;
      flex-direction: column-reverse;
      ${DateHeading} {
        margin: 18px 0 20px 0;
        height: 36px;
      }
      ${SegmentControl} {
        width: 68px;
      }
    }
      
  `}
`;

export const SystemCard = styled(Card)`
  margin: 0;
  max-width: 245px;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const SideCardLabel = styled.h4``;

export const SideCardInfo = styled.span``;

export const SystemSideCards = styled.div<{ $isChartPresent?: boolean }>`
  ${({ theme, $isChartPresent }) => `
        font-size: 16px;
        background: ${theme.colors.backgroundPrimaryWhite};
        line-height: 1.5;
        margin-left: 32px;
        display: flex;
        height: fit-content;

        ${
          $isChartPresent
            ? `
          flex-direction: column;
        `
            : `
          flex-direction: row;
          > div {
            margin-right: 24px;
          }
        `
        }

        ${SystemCard} > p:not(:last-of-type) {
            color: ${theme.colors.textSecondarySubtleGrey80};
          }
          h2 {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            color: ${theme.colors.textPrimarySecondary1100};
            }
          h4 { 
            color: ${theme.colors.textSecondarySubtleGrey80};
            font-weight: 300;
            margin: 0;
          }
          span {
            font-weight: 700;
            margin-bottom: 12px;
            color: ${theme.colors.textPrimarySecondary1100};
            &:last-of-type {
              margin: 0;
            }
        }
        ${SystemCard}:first-of-type {
            margin-bottom: 32px;
        }
        p {
          margin: 0;
          &:last-of-type {
            margin-top: 8px;
          }
        }
    `}
`;

export const SystemPageBodyWrapper = styled.div`
  display: flex;
  min-width: 100%;
`;

export const SystemPageWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    font-family: ${FONT_FAMILY};
    background: ${theme.colors.backgroundPrimaryWhite};
    h1 {
      color: ${theme.colors.textPrimarySecondary1100};
    }
    
    @media (${theme.breakpoints.small} < width <= ${breakpointValues.medium + NAV_BAR_WIDTH}px) {
      h1 {
        font-size: 24px;
        margin-bottom: 18px;
      }
      ${StatisticWrapper} {
        ${StatisticLabel} {
          font-size: 16px;
        }
        ${StatisticValue} {
          font-size: 16px;
          span:first-of-type {
            font-weight: 700;
          }
        }
      }
      ${SystemPageBodyWrapper} {
        flex-direction: column;
        ${SystemSideCards} {
          padding-top: 24px;
          margin: 0;
          display: flex;
          flex-direction: row;
          ${SystemCard} {
            min-width: 308px;
            height: 284px;
            ${CardTitle} {
              margin-bottom: 8px;
            }
            span {
              margin-bottom: 8px;
            }
            &:first-of-type {
                margin-right: 24px;
                margin-bottom: 0;
            }
            ${CardActionText} {
              margin-top: auto;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    @media (width <= ${theme.breakpoints.small}) {
      h1 {
        font-size: 24px;
        margin-bottom: 18px;
      }
      ${MonitoringSnapshotWrapper} {
        width: 100%;
        row-gap: 12px;
        margin-bottom: 22px;
        ${StatisticLabel} {
          font-size: 14px;
        }
        ${StatisticValue} {
          font-size: 16px;
          span:first-of-type {
            font-weight: 700;
          }
        }
      }
      ${SystemPageBodyWrapper} {
        flex-direction: column;
        ${SystemSideCards} {
          display: flex;
          flex-direction: column;
          margin: 0;
          ${SystemCard} {
            margin: 22px auto 0 auto;
            max-width: 288px;
          }
        }
      }
    }
  `}
`;
