import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function CircleCheckIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35313 10.3531C7.15938 10.55 6.84062 10.55 6.64687 10.3531L4.64687 8.35312C4.45 8.15937 4.45 7.84062 4.64687 7.64687C4.84062 7.45 5.15938 7.45 5.35313 7.64687L7 9.29375L10.6469 5.64687C10.8406 5.45 11.1594 5.45 11.3531 5.64687C11.55 5.84062 11.55 6.15938 11.3531 6.35313L7.35313 10.3531ZM16 8C16 12.4187 12.4187 16 8 16C3.58125 16 0 12.4187 0 8C0 3.58125 3.58125 0 8 0C12.4187 0 16 3.58125 16 8ZM8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default {};
