import {
  DISABLED_DROPDOWN_STATE,
  ENABLED_DROPDOWN_STATE,
  TDropdowns,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from './TDropdown';

// STATE VALUE
export const getDropdownState = (isDisabled: boolean) => {
  if (isDisabled) {
    return DISABLED_DROPDOWN_STATE;
  }

  return ENABLED_DROPDOWN_STATE;
};

// RETURNS ALL STYLES RELATED TO DROPDOWN STYLE VARIANT
export const getDropdownStyleVariantValues = (
  theme: TDropdowns,
  variant: TDropdownStyleVariantsKey,
  state: TDropdownStatesKey,
) => ({
  ...theme.variants[variant][state],
  ...theme.variants[variant],
});

export default getDropdownStyleVariantValues;
