import { RolesKey, UserRoles } from 'modules/types/util';

export function isHomeowner(role?: RolesKey): boolean {
  return role === UserRoles.HOMEOWNER;
}

export function isSupport(role?: RolesKey): boolean {
  return role === UserRoles.SUPPORT || role === UserRoles.ADMIN;
}

export function isAdmin(role?: RolesKey): boolean {
  return role === UserRoles.ADMIN || role === UserRoles.SUPER_ADMIN;
}

export function isSuperAdmin(role?: RolesKey): boolean {
  return role === UserRoles.SUPER_ADMIN;
}

export function isMarketing(role?: RolesKey): boolean {
  return role === UserRoles.MARKETING || role === UserRoles.ADMIN;
}
