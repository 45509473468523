import React from 'react';
import { Accordion } from 'legacy/components/common/design-system';
import { BatteryType } from 'legacy/types';
import InfoCard from './InfoCard';
import { AdminAccordionWrapper } from './UserDetailStyles';

type Props = {
  battery: BatteryType;
};

export default function BatterySection({ battery }: Props) {
  const items = [
    { label: 'Manufacturer', value: battery.manufacturer, id: 'bat1' },
    { label: 'Model', value: battery.model, id: 'bat2' },
    { label: 'Capacity', value: battery.capacity.toString(), id: 'bat3' },
    { label: 'Power Rating', value: battery.power_rating.toString(), id: 'bat4' },
    { label: 'Count', value: battery.count.toString(), id: 'bat5' },
  ];

  return (
    <AdminAccordionWrapper>
      <Accordion
        id="admin-accordion"
        styleVariant="secondary"
        items={[
          {
            label: 'Battery',
            content: <InfoCard items={items} enableArchive={false} archived={battery.archived} />,
          },
        ]}
      />
    </AdminAccordionWrapper>
  );
}
