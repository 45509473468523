import React, { useRef } from 'react';
import { TDropdownDataItem } from 'legacy/components/common/design-system/Dropdown/TDropdown';
import {
  DISABLED_DROPDOWN_STATE,
  ENABLED_DROPDOWN_STATE,
  TDropdownStyleVariantsKey,
  getDropdownStyleVariantValues,
  lightThemeDropdowns,
} from 'theme/dropdowns';

import {
  DropdownItemButtonWrapper,
  DropdownItemStyled,
  DropdownTitle,
  IconContainer,
} from './DropdownItemStyles';
import { H5 } from '../../Typography';

export type TDropdownItemProps = {
  id?: string;
  ariaRole?: 'listitem' | 'option';
  dropdownItemData: TDropdownDataItem;
  styleVariant?: TDropdownStyleVariantsKey;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLLIElement>) => void;
};

/**
 * @param {string} [id] - id for the dropdown item  (optional)
 * @param {'listitem' | 'option'} [ariaRole] - role for item (optional)
 * @param {TDropdownDataItem} [dropdownItemData] - item data
 * @param {TDropdownStyleVariantsKey} [styleVariant] -  defaults to primary style (optional)
 * @param {function} [onClick] - onClick handler
 * @param {function} [onKeyDown] - onKeyDown handler
 * @param {function} [onMouseMove] - onMouseMove handler
 * @param {function} [onClick] - onClick handler
 */

export function DropdownItem({
  id,
  dropdownItemData,
  onClick,
  onKeyDown,
  onMouseMove,
  onMouseEnter,
  styleVariant = 'default',
  ariaRole = 'listitem',
}: TDropdownItemProps) {
  const { title, Icon, isDisabled } = dropdownItemData;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonState = isDisabled ? DISABLED_DROPDOWN_STATE : ENABLED_DROPDOWN_STATE;
  const { fontColor: color } = getDropdownStyleVariantValues(
    lightThemeDropdowns,
    styleVariant,
    buttonState,
  );

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };

  return (
    <DropdownItemStyled
      id={id}
      role={ariaRole}
      $styleVariant={styleVariant}
      $state={buttonState}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
    >
      <DropdownItemButtonWrapper
        ref={buttonRef}
        type="button"
        role="button"
        aria-label={title}
        $styleVariant={styleVariant}
        $state={buttonState}
        tabIndex={isDisabled ? -1 : undefined}
      >
        {Icon && (
          <IconContainer>
            <Icon color={color} />
          </IconContainer>
        )}
        <DropdownTitle>
          <H5 color={color}>{title}</H5>
        </DropdownTitle>
      </DropdownItemButtonWrapper>
    </DropdownItemStyled>
  );
}
