import {
  getDropdownStyleVariantValues,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';
import styled, { css } from 'styled-components';

type DropdownItemStyledProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
  $hasIcon: boolean;
};
export const DropdownItemStyled = styled.li<DropdownItemStyledProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { fontColor } = getDropdownStyleVariantValues(theme, $styleVariant, $state);

    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      :hover {
        background: ${theme.colors.cardInteractionHoverGrey05};
      }

      :active {
        background: ${theme.colors.cardInteractionActiveSecondary420};
      }

      h5 {
        color: ${fontColor};
      }

      svg {
        path {
          fill: ${theme.colors.iconIconPrimaryPressedSecondary2100};
        }
      }
    `;
  }}
`;

export const DropdownTitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  text-wrap: wrap;
`;

type TDropdownItemButtonWrapper = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const DropdownItemButtonWrapper = styled.button<TDropdownItemButtonWrapper>`
  ${({ $styleVariant, $state, theme }) => {
    const { dropdownItemPadding } = getDropdownStyleVariantValues(theme, $styleVariant, $state);
    return css`
      cursor: ${$state === 'disabled' ? 'default' : 'pointer'};
      align-items: center;
      width: 100%;
      display: flex;
      border: 0;
      background-color: transparent;
      outline: none;
      white-space: nowrap;
      padding: ${dropdownItemPadding};
      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow:
          0px 0px 0px 1px #fff,
          0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
        z-index: 1;
      }
    `;
  }}
`;

export const IconContainer = styled.div`
  margin-right: 8px;
`;
