export type Sizes = 'xl' | 'lg' | 'md' | 'sm';

type Size = {
  [key in Sizes]: number;
};

type Grid = {
  gridColumns: Size;
  col: { gutterSize: Size };
  container: { margin: Size };
};

export const grid: Grid = {
  gridColumns: {
    xl: 12,
    lg: 12,
    md: 12,
    sm: 4,
  },
  col: {
    gutterSize: {
      xl: 48,
      lg: 32,
      md: 24,
      sm: 16,
    },
  },
  container: {
    margin: {
      xl: 48,
      lg: 32,
      md: 24,
      sm: 16,
    },
  },
};

export default grid;
