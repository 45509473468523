import React from 'react';
import { CustomPickerGridWrapper, CustomPickerWrapper } from './DatePickerStyles';
import { CustomCaption } from './CustomCaption';
import { CustomFooter } from './CustomFooter';

interface Props {
  onCloseModal: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onOkClick: () => void;
  header: string | number;
  items: React.ReactNode[];
}

export function CustomPicker({
  onCloseModal,
  header,
  items,
  onNextClick,
  onPrevClick,
  onOkClick,
}: Props) {
  return (
    <CustomPickerWrapper>
      <CustomCaption
        header={header.toString()}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
      <CustomPickerGridWrapper>{items}</CustomPickerGridWrapper>
      <CustomFooter onCancelClick={onCloseModal} onOkClick={onOkClick} />
    </CustomPickerWrapper>
  );
}

export default CustomPicker;
