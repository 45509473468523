import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from 'legacy/components/common/design-system/Icons';
import { colors } from 'theme/colors';
import {
  PaginationButton,
  PaginationButtonWrapper,
  PaginationHeader,
  PaginationWrapper,
} from './DatePickerStyles';

export interface Props {
  header: string;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

export function CustomCaption({ header, onPrevClick, onNextClick }: Props) {
  return (
    <PaginationWrapper>
      <PaginationButtonWrapper>
        <PaginationButton disabled={!onPrevClick} onClick={onPrevClick}>
          <ArrowLeftIcon color={onPrevClick ? undefined : colors.gray50} />
        </PaginationButton>
        <PaginationHeader>{header}</PaginationHeader>
        <PaginationButton disabled={!onNextClick} onClick={onNextClick}>
          <ArrowRightIcon color={onNextClick ? undefined : colors.gray50} />
        </PaginationButton>
      </PaginationButtonWrapper>
    </PaginationWrapper>
  );
}

export default CustomCaption;
