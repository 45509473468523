import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const RegistrationEmailFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BodyText = styled(Text)`
  font-family: Avenir;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
  strong {
    font-family: Avenir;
    font-weight: 800;
  }
`;
