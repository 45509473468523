export const getNumber = (value: string | number | null): Number | null => {
  if (value === '' || value === null) {
    return null;
  }

  const parsedValue = Number(value);

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(parsedValue)) {
    return parsedValue;
  }

  return null;
};

export default getNumber;
