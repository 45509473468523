import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyRegular } from 'legacy/components/common/design-system/Typography';

function AuthCopyAckConfirmed() {
  const { t } = useTranslation();

  return (
    <>
      <BodyRegular $margin="24px 0 8px">
        {t('acknowledgeAuthoritativeCopy.acknowledgeConfirmed.body1')}
      </BodyRegular>
      <BodyRegular $margin="8px 0">
        {t('acknowledgeAuthoritativeCopy.acknowledgeConfirmed.body2')}
      </BodyRegular>
    </>
  );
}

export default AuthCopyAckConfirmed;
