import React from 'react';
import { IcoProps } from './types';

export function MinusSign({ color = 'white', size = 'large' }: IcoProps) {
  return (
    <svg
      width={size === 'large' ? '24' : '13'}
      height="2"
      viewBox="0 0 24 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5942 1C24.5942 1.414 24.25 1.75 23.8256 1.75H0.768568C0.344223 1.75 0 1.4125 0 1C0 0.585906 0.344223 0.25 0.768568 0.25H23.8256C24.2483 0.25 24.5942 0.5875 24.5942 1Z"
        fill={color}
      />
    </svg>
  );
}

export default MinusSign;
