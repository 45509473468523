import styled from 'styled-components';
import colors from 'theme/colors';

type TOuterContainerProps = {
  $margin: string;
};

export const OuterContainer = styled.div<TOuterContainerProps>`
  display: flex;
  flex-direction: column;
  margin: ${({ $margin }) => $margin};
`;

export const Label = styled.label`
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ span {
    border: 1px solid ${colors.primaryHover};
  }
`;

export const Input = styled.input`
  display: block;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
  &:checked ~ span {
    border: 1px solid ${colors.primaryActive};
    svg {
      display: block;
    }
  }
  &:checked ~ span:after {
    display: block;
  }
`;

export const Box = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid ${colors.secondaryDarkBlue};
  border-radius: 4px;
  svg {
    display: none;
  }
`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 0 28px;
  color: ${colors.secondaryDarkBlue};
  font-family: AvenirLTPro;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const HintText = styled.p<{ hasError?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ hasError }) => (hasError ? colors.red : colors.secondaryDarkBlue)};
  font-style: normal;
  font-weight: 400;
  font-family: AvenirLTPro;
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0 0 36px;
`;
