import { TAccordions, TAccordionStyleVariantsKey } from './TAccordions';

// RETURNS ALL STYLES RELATED TO ACCORDION STYLE VARIANT
export const getAccordionStyleVariantValues = (
  theme: TAccordions,
  variant: TAccordionStyleVariantsKey,
) => ({
  ...theme.variants[variant],
});

export default getAccordionStyleVariantValues;
