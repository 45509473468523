import { format, startOfWeek, endOfWeek, startOfToday } from 'date-fns';

export const formatWeekdayName = (date: Date) => format(date, 'EEEEEE');

/** Boolean check that gets applied to each date rendered by the react-day-picker component */
export const isDateDisabled = (
  date: Date,
  isWeekSelector: boolean = false,
  inServiceDate?: Date,
): boolean => {
  const today = startOfToday();
  const finalEnabledDay = isWeekSelector ? endOfWeek(today) : today;
  const firstEnabledDay =
    isWeekSelector && inServiceDate ? startOfWeek(inServiceDate) : inServiceDate;
  const isBeforeFirstDay = firstEnabledDay ? date < firstEnabledDay : false;
  return date > finalEnabledDay || isBeforeFirstDay;
};

export const getHeadingText = (timescaleLevel: number, currentDate: Date): string => {
  if (timescaleLevel === 3) {
    return format(currentDate, 'yyyy');
  }
  if (timescaleLevel === 2) {
    return format(currentDate, 'MMMM');
  }
  if (timescaleLevel === 1) {
    return `${format(startOfWeek(currentDate), 'MMM d')} – ${format(
      endOfWeek(currentDate),
      'd, yyyy',
    )}`;
  }
  return format(currentDate, 'PP');
};
