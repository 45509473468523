import React, { useState } from 'react';
import { Button } from 'legacy/components/common/design-system';
import { updateAccount } from 'api/accounts';
import { AccountType } from 'legacy/types';
import { HandleMessage } from 'legacy/views/dashboard/AccountSettings';
import { useUser } from 'hooks/useUser';
import { AddressForm, AddressFormType } from './AddressForm';

interface Props {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAccount: AccountType;
  handleMessage: HandleMessage;
}

export default function EditAccountSettingsForm({
  selectedAccount,
  setEditing,
  handleMessage,
}: Props) {
  const [address, setAddress] = useState<AddressFormType>(selectedAccount.address);
  const { setSelectedAccount } = useUser();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleMessage(null);

    updateAccount(selectedAccount.id, { address })
      .then((response) => {
        setSelectedAccount(response.data);
        handleMessage(
          { message: 'Your billing address was successfully updated.', type: 'success' },
          4000,
        );
      })
      .catch(() => {
        handleMessage({
          message: 'Failed to update billing address. Please contact support for assistance.',
          type: 'error',
        });
      })
      .finally(() => {
        setEditing(false);
      });
  };

  const handleAddressChange = (value: AddressFormType) => {
    setAddress(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <AddressForm address={address} onAddressChange={handleAddressChange} />
      <div className="account-settings-submit">
        <Button label="Submit" type="submit" />
      </div>
    </form>
  );
}
