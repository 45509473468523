export type ChartDataType = {
  x: number;
  y: number;
  unit: string;
  value: number;
  time: Date;
};

export type AxisType = {
  domain?: [number, number];
  tickValues?: unknown[];
  tickFormat?: (tick: any, index: number, ticks: any) => string | number;
  style?: any;
};

export type Tuple<T> = [T, T];

export declare type PaddingType = number | Tuple<number>;

export enum TabType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
