export const STATES: { text: string; key: string; value: string }[] = [
  { text: 'Alabama', key: 'AL', value: 'AL' },
  { text: 'Alaska', key: 'AK', value: 'AK' },
  { text: 'American Samoa', key: 'AS', value: 'AS' },
  { text: 'Arizona', key: 'AZ', value: 'AZ' },
  { text: 'Arkansas', key: 'AR', value: 'AR' },
  { text: 'California', key: 'CA', value: 'CA' },
  { text: 'Colorado', key: 'CO', value: 'CO' },
  { text: 'Connecticut', key: 'CT', value: 'CT' },
  { text: 'Delaware', key: 'DE', value: 'DE' },
  { text: 'District Of Columbia', key: 'DC', value: 'DC' },
  { text: 'Federated States Of Micronesia', key: 'FM', value: 'FM' },
  { text: 'Florida', key: 'FL', value: 'FL' },
  { text: 'Georgia', key: 'GA', value: 'GA' },
  { text: 'Guam', key: 'GU', value: 'GU' },
  { text: 'Hawaii', key: 'HI', value: 'HI' },
  { text: 'Idaho', key: 'ID', value: 'ID' },
  { text: 'Illinois', key: 'IL', value: 'IL' },
  { text: 'Indiana', key: 'IN', value: 'IN' },
  { text: 'Iowa', key: 'IA', value: 'IA' },
  { text: 'Kansas', key: 'KS', value: 'KS' },
  { text: 'Kentucky', key: 'KY', value: 'KY' },
  { text: 'Louisiana', key: 'LA', value: 'LA' },
  { text: 'Maine', key: 'ME', value: 'ME' },
  { text: 'Marshall Islands', key: 'MH', value: 'MH' },
  { text: 'Maryland', key: 'MD', value: 'MD' },
  { text: 'Massachusetts', key: 'MA', value: 'MA' },
  { text: 'Michigan', key: 'MI', value: 'MI' },
  { text: 'Minnesota', key: 'MN', value: 'MN' },
  { text: 'Mississippi', key: 'MS', value: 'MS' },
  { text: 'Missouri', key: 'MO', value: 'MO' },
  { text: 'Montana', key: 'MT', value: 'MT' },
  { text: 'Nebraska', key: 'NE', value: 'NE' },
  { text: 'Nevada', key: 'NV', value: 'NV' },
  { text: 'New Hampshire', key: 'NH', value: 'NH' },
  { text: 'New Jersey', key: 'NJ', value: 'NJ' },
  { text: 'New Mexico', key: 'NM', value: 'NM' },
  { text: 'New York', key: 'NY', value: 'NY' },
  { text: 'North Carolina', key: 'NC', value: 'NC' },
  { text: 'North Dakota', key: 'ND', value: 'ND' },
  { text: 'Northern Mariana Islands', key: 'MP', value: 'MP' },
  { text: 'Ohio', key: 'OH', value: 'OH' },
  { text: 'Oklahoma', key: 'OK', value: 'OK' },
  { text: 'Oregon', key: 'OR', value: 'OR' },
  { text: 'Palau', key: 'PW', value: 'PW' },
  { text: 'Pennsylvania', key: 'PA', value: 'PA' },
  { text: 'Puerto Rico', key: 'PR', value: 'PR' },
  { text: 'Rhode Island', key: 'RI', value: 'RI' },
  { text: 'South Carolina', key: 'SC', value: 'SC' },
  { text: 'South Dakota', key: 'SD', value: 'SD' },
  { text: 'Tennessee', key: 'TN', value: 'TN' },
  { text: 'Texas', key: 'TX', value: 'TX' },
  { text: 'Utah', key: 'UT', value: 'UT' },
  { text: 'Vermont', key: 'VT', value: 'VT' },
  { text: 'Virgin Islands', key: 'VI', value: 'VI' },
  { text: 'Virginia', key: 'VA', value: 'VA' },
  { text: 'Washington', key: 'WA', value: 'WA' },
  { text: 'West Virginia', key: 'WV', value: 'WV' },
  { text: 'Wisconsin', key: 'WI', value: 'WI' },
  { text: 'Wyoming', key: 'WY', value: 'WY' },
];

export default STATES;
