import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function CloseIcon({ color = colors.secondaryDarkBlue, size = 'small' }: IcoProps) {
  const svgBreadth = size === 'small' ? '12' : '24';
  return (
    <svg
      width={svgBreadth}
      height={svgBreadth}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8228 11.8228C11.5884 12.0573 11.209 12.0573 10.9743 11.8228L5.9999 6.84728L1.02435 11.8228C0.789893 12.0573 0.410558 12.0573 0.175841 11.8228C-0.0586137 11.5884 -0.0586137 11.209 0.175841 10.9743L5.15252 5.9999L0.176591 1.02435C-0.0578638 0.789893 -0.0578638 0.410558 0.176591 0.175841C0.411046 -0.0586137 0.79038 -0.0586137 1.0251 0.175841L5.9999 5.15252L10.9755 0.176966C11.2099 -0.0574889 11.5892 -0.0574889 11.824 0.176966C12.0584 0.411421 12.0584 0.790755 11.824 1.02547L6.84728 5.9999L11.8228 10.9755C12.0591 11.2079 12.0591 11.5904 11.8228 11.8228Z"
        fill={color}
      />
    </svg>
  );
}

export default CloseIcon;
