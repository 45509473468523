import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useLocation, useNavigate } from 'react-router';
import { BackButton } from 'modules/auth/components/BackButton';
import { Button } from 'shared/components/Button/Button';
import { AuthBox, AuthWrapper, BodyText } from 'modules/auth/Auth.styles';
import { Header } from 'modules/auth/components/Header/Header';
import { useMutation } from 'react-query';
import { sendResetPasswordLink } from 'api/accounts';
import { pages } from 'pages';
import handleFocusedKeyPress from 'shared/utils/handleFocusedKeypress';

export function CheckEmail({ email: emailProp }: { email?: string }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const email = emailProp || location.state.email;

  const { mutate } = useMutation({
    mutationFn: sendResetPasswordLink,
  });

  const handleResendEmail = useCallback(() => mutate(email || ''), [mutate, email]);

  const goToResetPassword = useCallback(() => {
    navigate(resolvePath(pages.FORGOT_PASSWORD, '/'), { state: { email } });
  }, [email, navigate]);

  const handleKeyUpGoToReset = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      handleFocusedKeyPress(e, goToResetPassword);
    },
    [goToResetPassword],
  );

  const handleKeyUpResendEmail = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      handleFocusedKeyPress(e, handleResendEmail);
    },
    [handleResendEmail],
  );

  return (
    <AuthWrapper>
      <BackButton />
      <AuthBox>
        <Header
          title={t('forgotPasswordScreen.checkYour')}
          titleBold={t('forgotPasswordScreen.email')}
          showLogo
        />

        <BodyText as="p" $margin="0 0 24px">
          {t('forgotPasswordScreen.sentScreenBodyOne')}
        </BodyText>

        <BodyText as="p" $margin="0 0 24px">
          {t('forgotPasswordScreen.sentScreenBodyTwo')}
        </BodyText>
        <Button
          label={t('forgotPasswordScreen.resendEmail')}
          isFullWidth
          onClick={handleResendEmail}
          onKeyUp={handleKeyUpResendEmail}
        />
        <Button
          label={t('forgotPasswordScreen.reEnterEmailAddress')}
          styleVariant="tertiary"
          onClick={goToResetPassword}
          onKeyUp={handleKeyUpGoToReset}
          margin="0 auto"
        />
      </AuthBox>
    </AuthWrapper>
  );
}

export default CheckEmail;
