import React from 'react';
import { AddressType } from 'legacy/types';

export function Address({ address }: { address: AddressType }) {
  return (
    <div>
      <div>{address.street_1}</div>
      {address.street_2 ? <div>{address.street_2}</div> : null}
      <div>
        {address.city}, {address.state} {address.zip_code}
      </div>
    </div>
  );
}

export default Address;
