import { Card } from 'legacy/components/common/myeverbright';
import styled from 'styled-components';
import { breakpoints } from '../../constants';

export const Container = styled(Card)`
  margin: 24px 24px 0;
`;

export const InnerContainer = styled.div`
  min-width: 260px;
  max-width: 295px;
  margin: 0 64px;
  text-align: center;

  @media (max-width: ${breakpoints.small}) {
    margin: 0;
  }
`;

export const LoginContainer = styled(InnerContainer)`
  margin: 0 auto;
`;

export const LinkContainer = styled.div`
  margin-top: 16px;
`;
