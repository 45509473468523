import { TFunction } from 'i18next';

export const getScoreWords = (t: TFunction) => [
  t('passwordStrength.tooWeak'),
  t('passwordStrength.veryWeak'),
  t('passwordStrength.weak'),
  t('passwordStrength.strong'),
  t('passwordStrength.veryStrong'),
];

export default {};
