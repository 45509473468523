import React, { useMemo } from 'react';
import { Divider } from 'legacy/components/common/design-system';
import { EnergySummaryType, PowerFlowDataResponse } from 'legacy/types';
import { Box } from '../common/myeverbright';
import MonitoringStatistic from '../MonitoringStatistic';
import { SnapshotHeader } from './MonitoringSnapshotStyles';

type Props = {
  energySummary: EnergySummaryType | null;
  powerFlow: PowerFlowDataResponse | null;
};

export default function MonitoringSnapshot({ energySummary, powerFlow }: Props) {
  const [energyToday, energyThisMonth, energyAllTime] = useMemo(() => {
    if (energySummary) {
      const { day_kwh: dayKwh, month_kwh: monthKwh, all_time_kwh: allTimeKwh } = energySummary;
      return [dayKwh, monthKwh, allTimeKwh];
    }
    return [null, null, null];
  }, [energySummary]);

  return (
    <>
      <SnapshotHeader>Your Solar Production</SnapshotHeader>
      <Box $columnGap="32px" $flexWrap="wrap">
        <MonitoringStatistic
          label="Current Power"
          value={powerFlow?.system?.power_kw || null}
          unit="kW"
        />
        <MonitoringStatistic label="Today" value={energyToday} unit="kWh" />
        <MonitoringStatistic label="This Month" value={energyThisMonth} unit="kWh" />
        <MonitoringStatistic label="All Time" value={energyAllTime} unit="kWh" />
      </Box>
      <Divider $lineColor="rgba(0, 0, 0, 0.1)" $margin="10px 0px 24px 0px" />
    </>
  );
}
