import styled from 'styled-components';
import colors from 'theme/colors';

const labelStyles = {
  fontFamily: 'AvenirLTPro',
  fill: colors.secondaryDarkBlue,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
};

export const chartStyles = {
  height: 430,
  width: 730,
  spinnerHeight: 250,
  domainPadding: { x: 30, y: 64 },
  padding: { top: 10, right: 30, left: 50, bottom: 30, domainY: 64 }, // 64 comes from height of tooltip (44) + 20px from top of chart
  tooltip: { lineHeight: 335, y2: 0 },
  label: {
    x: 59,
    y: 10,
    dx: 0,
    dy: 0,
    textAnchor: 'end',
    style: labelStyles,
  },
  tickLabels: labelStyles,
};

export const Container = styled.div`
  position: relative;
  max-width: 730px;
  margin: 0 auto;
`;

export const SpinnerContainer = styled.div`
  height: ${chartStyles.spinnerHeight}px;
  display: flex;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #0096db;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #22345b;
  margin-left: 9px;
  font-family: 'AvenirLTPro';
`;

export const ToolTipContainer = styled.div`
  position: relative;
  height: 45px;
`;

export const ToolTipTextContainer = styled.div`
  padding: 6px 8px;
  width: fit-content;
  height: fit-content;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #daebf6;
  border-radius: 8px;
`;

export const ToolTipkW = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  white-space: pre-wrap;
  font-family: 'AvenirLTPro';
  color: #0257a4;
`;

export const TooltipTime = styled(ToolTipkW)`
  font-weight: 400;
  color: #6a6a6a;
`;

export const Line = styled.line`
  stroke: #22345b;
  stroke-dasharray: 8 8;
  stroke-linecap: round;
`;
