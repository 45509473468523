import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AgreementType } from 'api/types';
import { MilestoneType } from 'modules/types';
import { getStatus } from 'modules/status/utils/getStatus';
import { generateRandomString } from 'shared/utils';
import { Accordion, AccordionItemType } from 'shared/components/Accordion/Accordion';
import { AccordionItem } from 'shared/components/Accordion/AccordionItem';
import { AccordionWrapper, AccordionItemWrapper, Status } from './StatusAccordion.styles';
import { AccordionStepIndicator } from './AccordionStepIndicator';
import { ContractSigning } from '../ContractSigning/ContractSigning';
import { ProjectQualification } from '../ProjectQualification/ProjectQualification';
import { SystemInstallation } from '../SystemInstallation/SystemInstallationContent';
import { InService } from '../InService/InService';

export type StatusAccordionItemType = AccordionItemType & {
  status?: Status;
  isOpen: boolean;
};
type Props = {
  currentMilestone: MilestoneType;
  agreement?: AgreementType;
  isFetchingWelcomeChecklist: boolean;
  onOpenWelcomeChecklist: () => void;
};

export function StatusAccordion({
  currentMilestone,
  agreement,
  isFetchingWelcomeChecklist,
  onOpenWelcomeChecklist,
}: Props) {
  const { t } = useTranslation();

  const items = useMemo(() => {
    const milestoneStatus = getStatus(currentMilestone);

    return [
      {
        label: t('contractSigned.contractSigned'),
        content: <ContractSigning />,
        status: milestoneStatus[MilestoneType.CONTRACT_SIGNED],
        id: generateRandomString(),
        isOpen: milestoneStatus[MilestoneType.CONTRACT_SIGNED] === Status.IN_PROGRESS,
      },
      {
        label: t('projectQualification.projectQualification'),
        content: (
          <ProjectQualification
            isLoadingWelcomeChecklistQuestions={isFetchingWelcomeChecklist}
            agreement={agreement}
            onOpenWelcomeChecklist={onOpenWelcomeChecklist}
            isCurrentMilestone={
              milestoneStatus[MilestoneType.PROJECT_QUALIFICATION_COMPLETED] === Status.IN_PROGRESS
            }
          />
        ),
        status: milestoneStatus[MilestoneType.PROJECT_QUALIFICATION_COMPLETED],
        id: generateRandomString(),
        isOpen:
          milestoneStatus[MilestoneType.PROJECT_QUALIFICATION_COMPLETED] === Status.IN_PROGRESS,
      },
      {
        label: t('systemInstallation.systemInstallation'),
        content: <SystemInstallation />,
        status: milestoneStatus[MilestoneType.INSTALLATION_COMPLETED],
        id: generateRandomString(),
        isOpen: milestoneStatus[MilestoneType.INSTALLATION_COMPLETED] === Status.IN_PROGRESS,
      },
      {
        label: t('systemOperational.systemOperational'),
        content: <InService />,
        status: milestoneStatus[MilestoneType.IN_SERVICE],
        id: generateRandomString(),
        isOpen:
          milestoneStatus[MilestoneType.IN_SERVICE] === Status.IN_PROGRESS ||
          milestoneStatus[MilestoneType.IN_SERVICE] === Status.COMPLETED,
      },
    ];
  }, [agreement, currentMilestone, isFetchingWelcomeChecklist, onOpenWelcomeChecklist, t]);

  return (
    <AccordionWrapper>
      <Accordion id="status-accordion" items={items}>
        {items.map((item: StatusAccordionItemType, i: number) => (
          <AccordionItemWrapper key={`item-${item.id}`}>
            <AccordionStepIndicator
              step={i + 1}
              showLine={i !== items.length - 1}
              status={item.status}
            />
            <AccordionItem
              id={`item-${item.id}`}
              styleVariant="primary"
              label={item.label}
              isOpen={item.isOpen}
              size="large"
            >
              {item.content}
            </AccordionItem>
          </AccordionItemWrapper>
        ))}
      </Accordion>
    </AccordionWrapper>
  );
}

export default StatusAccordion;
