import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getBanners } from 'api/messaging';
import { useUser } from 'hooks/useUser';
import { BillingCard } from 'legacy/components/OverviewCards/BillingCard';
import { SystemCard } from 'legacy/components/OverviewCards/system';
import { SupportCard } from 'legacy/components/OverviewCards/SupportCard';
import { Banner } from 'legacy/components/common/design-system/Banner/Banner';
import { H2 } from 'legacy/components/common/design-system/Typography';
import { AuthCopyAckModal } from 'legacy/components/AuthCopyAckModal/AuthCopyAckModal';
import colors from 'theme/colors';
import { Box, Column, Row, Grid } from '../../components/common/myeverbright';
import AgreementCard from '../../components/AgreementCard';
import FaqRow from '../../components/Faqs/FaqRow';
import {
  achBannerLinkText,
  achBannerText,
  achPDFLink,
  perSessionFetchBannersKey,
} from '../../constants';
import { InfoIcon } from '../../components/common/design-system/Icons';
import { BannerSpacing } from './DashboardStyles';

function Overview() {
  const { userResult, selectedAccount } = useUser();

  const { data: bannersData, isError } = useQuery(
    'banners',
    () => getBanners().then((response) => response.data),
    {
      enabled: localStorage.getItem(perSessionFetchBannersKey) === null,
      onSuccess: () => {
        localStorage.setItem(perSessionFetchBannersKey, 'true');
      },
    },
  );

  const banners = useMemo(() => {
    if (!isError && !bannersData) return [];

    if (isError) {
      return (
        <Banner
          bannerKey="achBanner"
          bannerText={achBannerText}
          boldText="Reminder:"
          link={{ text: achBannerLinkText, url: achPDFLink }}
          bgColor={colors.gray10}
          icon={<InfoIcon />}
        />
      );
    }
    const activeBanners = bannersData.banners.filter((b) => b.display_banner);
    return activeBanners.map((banner, idx) => (
      <>
        {idx ? <BannerSpacing /> : null}
        <Banner
          key={banner.banner_key}
          bannerKey={banner.banner_key}
          boldText={banner.title}
          bannerText={banner.message}
          link={{ text: banner.link.text, url: banner.link.url }}
          bgColor={colors.gray10}
          icon={<InfoIcon />}
        />
      </>
    ));
  }, [bannersData, isError]);

  return (
    <>
      <Column>
        <Column $gap="0px">
          {banners}
          <h1>Hello, {userResult?.user?.first_name}</h1>
          <p>Welcome back, what can we help you with?</p>
        </Column>

        <Grid $columns={3}>
          <BillingCard />
          <SystemCard agreements={selectedAccount?.agreements} />
          <SupportCard fullHeight />
        </Grid>
        <H2>
          {selectedAccount && selectedAccount.agreements.length > 1 ? 'Agreements' : 'Agreement'}
        </H2>
        <Row $collapsing $flex="1">
          {selectedAccount?.agreements.slice(0, 2).map((agreement) => (
            <Box key={agreement.id}>
              <AgreementCard agreement={agreement} />
            </Box>
          ))}
          {selectedAccount && selectedAccount.agreements.length < 2 && <Box $flex="1" />}
        </Row>
        <FaqRow />
      </Column>
      <AuthCopyAckModal modalKey="authCopyAckModal" />
    </>
  );
}

export default Overview;
