import React from 'react';
import { AgreementType } from 'api/types';
import { hasMonitoring } from 'legacy/utils';
import { SystemCapacityCard } from './SystemCapacityCard';
import { SystemPerformanceCard } from './SystemPerformanceCard';
import { SystemProductionCard } from './SystemProductionCard';
import { NoSystemCard } from './NoSystemCard';

export enum SystemType {
  CAPACITY = 'capacity',
  PERFORMANCE = 'performance',
  PRODUCTION = 'production',
  NO_SYSTEM = 'no system',
}

type Props = {
  agreements?: AgreementType[];
};

export function SystemCard({ agreements }: Props) {
  if (agreements) {
    let type = SystemType.PERFORMANCE;

    if (agreements.every((agreement) => agreement.is_beam)) {
      type = SystemType.NO_SYSTEM;
    } else if (agreements.every((agreement) => agreement.is_ric) && !hasMonitoring(agreements)) {
      type = SystemType.CAPACITY;
    } else if (hasMonitoring(agreements)) {
      type = SystemType.PRODUCTION;
    }

    if (type === SystemType.CAPACITY && agreements) {
      return <SystemCapacityCard agreements={agreements} />;
    }

    if (type === SystemType.PRODUCTION) return <SystemProductionCard />;

    if (type === SystemType.PERFORMANCE) return <SystemPerformanceCard />;
  }
  return <NoSystemCard />;
}

export default SystemCard;
