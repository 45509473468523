import React from 'react';
import { resolvePath, useNavigate } from 'react-router-dom';
import { Button } from 'legacy/components/common/design-system';
import pages from 'pages';

import { systemCapacityCopy } from 'legacy/copy';
import { AgreementType } from 'legacy/types';
import { formatCapacity } from 'legacy/utils';
import { Content, Footer, Text } from '../CardStyles';
import { Card } from '../Card';
import { SystemCapacityText } from './SystemStyles';

export function SystemCapacityCard({ agreements }: { agreements: AgreementType[] }) {
  const navigate = useNavigate();

  const totalSystemCapacity = agreements.reduce((runningTotal, agreement) => {
    const { capacity } = agreement.system;
    return capacity ? runningTotal + Number(capacity) : runningTotal;
  }, 0.0);

  const handleClick = () => {
    const path = resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname;
    navigate(path);
  };

  return (
    <Card title="System Capacity" color="green" fullWidth fullHeight>
      <>
        <SystemCapacityText>
          <p>{formatCapacity(totalSystemCapacity)}</p>
          <p>kW</p>
        </SystemCapacityText>

        <Content>
          <Text>{systemCapacityCopy}</Text>
        </Content>

        <Footer>
          <Button
            styleVariant="secondary"
            label="View System Details"
            onClick={handleClick}
            isFullWidth
          />
        </Footer>
      </>
    </Card>
  );
}

export default SystemCapacityCard;
