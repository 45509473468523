import React from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_VALIDATION_REGEX } from 'constants/auth';
import { CustomFieldProps, FormField } from '../FormField/FormField';

interface EmailFieldProps extends CustomFieldProps {}

export function EmailField({
  register,
  onClearInput,
  hasError,
  errorMessage,
  margin,
  fieldName = 'email',
}: EmailFieldProps) {
  const { t } = useTranslation();

  return (
    <FormField
      label={t('loginScreen.email')}
      // making this an 'email' type enables auto-validation we don't want
      type="text"
      placeholder={t('loginScreen.email')}
      hasError={hasError}
      errorMessage={errorMessage}
      onClearInput={() => onClearInput(fieldName)}
      margin={margin}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(fieldName, {
        required: t('loginScreen.errors.EMAIL_MISSING'),
        pattern: { value: EMAIL_VALIDATION_REGEX, message: t('loginScreen.errors.EMAIL_INVALID') },
      })}
    />
  );
}

export default EmailField;
