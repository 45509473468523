import styled from 'styled-components';
import colors from 'theme/colors';

export const TableHeaderStyled = styled.thead`
  background-color: ${colors.gray10};
  height: 48px;
`;

export const HeaderRow = styled.tr`
  th:first-child {
    padding-left: 24px;
  }

  th:last-child {
    padding-right: 24px;
  }
`;

type HeaderCellInnerProps = {
  $hasCursor: boolean;
};

export const HeaderCell = styled.th<HeaderCellInnerProps>`
  padding-left: 32px;
  color: ${colors.secondaryDarkBlue};
  font-family: AvenirLTPro;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  cursor: ${({ $hasCursor }) => ($hasCursor ? 'pointer' : 'initial')};
`;

export const HeaderCellInner = styled.div`
  display: flex;
  align-items: center;
  min-width: 72px;

  & > svg {
    margin-left: 4px;
  }
`;
