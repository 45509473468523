import React, { useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_DROPDOWN_STYLE_VARIANT,
  TDropdownStyleVariantsKey,
  getDropdownState,
  getDropdownStyleVariantValues,
  lightThemeDropdowns,
} from 'theme/dropdowns';
import { generateRandomString } from 'legacy/utils';
import { CustomSelectContainer, SelectSelected, NativeSelect } from './SelectStyles';
import { DropdownItem } from '../Dropdown/DropdownItem';
import { Dropdown } from '../Dropdown';
import { ArrowDownIcon } from '../Icons';
import { TDropdownDataItem } from '../Dropdown/TDropdown';
import { H5 } from '../Typography';
import { InputLabel } from '../TextInput/TextInputStyles';

type TSelectProps = {
  id?: string;
  ariaLabelledBy?: string;
  role?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  isDisabled?: boolean;
  options: TDropdownDataItem[];
  styleVariant?: TDropdownStyleVariantsKey;
  onSelectOption?: (value: string) => void;
};

/**
 * @param {string} [id] - id (optional)
 * @param {string} [ariaLabelledBy] - aria-labelledby (optional)
 * @param {string} [role] - role of dropdown container. defaults to listbox (optional)
 * @param {string} [label] - label of dropdown container. (optional)
 * @param {string} [placeholder] - placeholder when no item is selected (optional)
 * @param {string} [value] - value for select (optional)
 * @param {boolean} [isDisabled] - is the select disabled (optional)
 * @param {TDropdownDataItem} [options] - select options
 * @param {TDropdownStyleVariantsKey} [styleVariant] - (optional)
 * @param {function} [onSelectOption] - click handler (optional)
 */

export function Select({
  id,
  ariaLabelledBy,
  role = 'listbox',
  label,
  placeholder = 'Select an option',
  value,
  isDisabled = false,
  options,
  styleVariant = DEFAULT_DROPDOWN_STYLE_VARIANT,
  onSelectOption,
}: TSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TDropdownDataItem>();

  const inputId = useMemo(() => id || generateRandomString(), [id]);

  const dropdownState = getDropdownState(isDisabled);

  const { fontColor } = getDropdownStyleVariantValues(
    lightThemeDropdowns,
    styleVariant,
    dropdownState,
  );

  useEffect(() => {
    const newOption = options.find((option) => option.value === value);
    setSelectedOption(newOption);
  }, [options, value]);

  const handleOpenDropdown = () => {
    setIsOpen(true);
  };
  const handleOptionClick = (option: TDropdownDataItem) => {
    onSelectOption?.(option.value || '');
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <CustomSelectContainer id={id} aria-labelledby={ariaLabelledBy} role={role}>
      {label && (
        <InputLabel
          id={`${inputId}-select-title`}
          htmlFor={`${inputId}-TextInput`}
          $styleVariant="default"
          $inputState={dropdownState}
        >
          {label}
        </InputLabel>
      )}

      <NativeSelect id={`${inputId}-TextInput`} value={selectedOption?.value} />
      <SelectSelected
        $styleVariant={styleVariant}
        $state={dropdownState}
        onClick={handleOpenDropdown}
      >
        <H5 color={fontColor} $margin="0 12px 0 0">
          {selectedOption?.title || placeholder}
        </H5>

        <ArrowDownIcon color={fontColor} />
      </SelectSelected>

      <Dropdown
        styleVariant={styleVariant}
        isOpen={isOpen}
        isFullWidth
        onClickOutside={handleClickOutside}
      >
        {options.map((option) => (
          <DropdownItem
            styleVariant={styleVariant}
            key={option.title}
            onClick={() => handleOptionClick(option)}
            dropdownItemData={option}
          />
        ))}
      </Dropdown>
    </CustomSelectContainer>
  );
}

export default Select;
