import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 24px 0;
  padding: 32px;
`;

export const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
