import { WelcomeChecklistStatusType } from 'modules/types';

export const isWelcomeChecklistPassed = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.PASSED;

export const isWelcomeChecklistFailed = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.FAILED;

export const isWelcomeChecklistRequired = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.REQUIRED;
export const isWelcomeChecklistNotRequired = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.NOT_REQUIRED;

export const isWelcomeChecklistCompleted = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  isWelcomeChecklistFailed(welcomeChecklistStatus) ||
  isWelcomeChecklistPassed(welcomeChecklistStatus);
