import React from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  DEFAULT_DROPDOWN_STYLE_VARIANT,
  ENABLED_DROPDOWN_STATE,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';
import { DropdownContainer } from './Dropdown.styles';

export type TDropdownProps = {
  id?: string;
  ariaLabelledBy?: string;
  role?: string;
  isOpen?: boolean;
  isFullWidth?: boolean;
  position?: { top?: string; right?: string; bottom?: string; left?: string };
  styleVariant?: TDropdownStyleVariantsKey;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLUListElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLUListElement>) => void;
  onClickOutside?: () => void;
};

/**
 * @param {string} [id] - id (optional)
 * @param {boolean} [isOpen] - is dropdown open (optional)
 * @param {string} [ariaLabelledBy] - aria-labelledby (optional)
 * @param {string} [role] - role of dropdown container. defaults to listbox (optional)
 * @param {boolean} [isFullWidth] -  is dropdown full width of container. defaults to false (optional)
 * @param {TDropdownStyleVariantsKey} [styleVariant] - (optional)
 * @param {React.ReactNode} [children] - children
 * @param {function} [onClick] - click handler (optional)
 * @param {function} [onBlur] - blur handler (optional)
 * @param {function} [onClickOutside] - handler for clicking outside of the dropdown
 */

export function Dropdown({
  id,
  ariaLabelledBy,
  isOpen = false,
  isFullWidth = false,
  role = 'listbox',
  position = {},
  styleVariant = DEFAULT_DROPDOWN_STYLE_VARIANT,
  children,
  onClick,
  onBlur,
  onClickOutside,
}: TDropdownProps) {
  const ref = useOutsideClick(onClickOutside, isOpen);

  return (
    <div ref={ref}>
      <DropdownContainer
        id={id}
        aria-labelledby={ariaLabelledBy}
        role={role}
        $isOpen={isOpen}
        $isFullWidth={isFullWidth}
        $styleVariant={styleVariant}
        $state={ENABLED_DROPDOWN_STATE}
        $position={position}
        onClick={onClick}
        onBlur={onBlur}
      >
        {isOpen ? children : null}
      </DropdownContainer>
    </div>
  );
}

export default Dropdown;
