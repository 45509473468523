import React, { useContext } from 'react';
import { AdminContext } from 'contexts/AdminContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgreementType } from 'legacy/types';
import { Address } from '../common/myeverbright';
import { Table } from '../common/design-system';
import UserButton from './AgreementTableStyles';

function AgreementTable() {
  const { agreementTableState, setAgreementTableState } = useContext(AdminContext);
  const { sortBy, direction, agreements } = agreementTableState;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(`${pathname.replace('agreements', 'users')}/${id}`);
  };

  const updateSort = (column: string) => {
    const toggledDirection = direction === 'ascending' ? 'descending' : 'ascending';
    setAgreementTableState({
      ...agreementTableState,
      direction: sortBy === column ? toggledDirection : direction,
      sortBy: column,
    });
  };

  const welcomeChecklistResult = (agreement: AgreementType) => {
    const welcomeChecklists = agreement.welcome_checklists;
    if (welcomeChecklists.length === 0) return null;

    // Get the last welcome checklist result
    return welcomeChecklists[welcomeChecklists.length - 1].result;
  };

  const columns = [
    { title: 'Homeowner Contract Id', handleSort: () => updateSort('homeowner_contract_id') },
    { title: 'Workflow Id', handleSort: () => updateSort('platform_uuid') },
    { title: 'Associated Users', handleSort: () => updateSort('account_users_summaries') },
    { title: 'Address', handleSort: () => updateSort('system__address') },
    { title: 'Current Milestone', handleSort: () => updateSort('current_milestone') },
    { title: 'Site ID', handleSort: () => updateSort('system__site_id') },
    { title: 'Organization Name', handleSort: () => updateSort('organization__name') },
    { title: 'Welcome Checklist Result' },
  ];

  const rows = agreements.map((agreement) => ({
    key: `table-${agreement.id}`,
    cells: [
      agreement.homeowner_contract_id,
      `${agreement.platform_uuid}${agreement.archived ? ' [ARCHIVED]' : ''}`,
      <>
        {agreement.account_users_summaries.map((data) => (
          <UserButton key={data.id} onClick={() => handleClick(data.id)}>
            <h4>
              {`${data.name} `}
              {data.claimed ? (
                <span style={{ color: 'limegreen' }}>{'\u2713'}</span>
              ) : (
                <span style={{ color: 'red' }}>{'\u2717'}</span>
              )}
            </h4>
            <p>{data.email}</p>
          </UserButton>
        ))}
      </>,
      <Address address={agreement.system?.address || 'N/A'} />,
      agreement.current_milestone || 'N/A',
      agreement.system?.site_id || 'N/A',
      agreement.organization?.name || 'N/A',
      <>{welcomeChecklistResult(agreement)}</>,
    ],
  }));

  return <Table columns={columns} rows={rows} />;
}

export default AgreementTable;
