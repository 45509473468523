import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'shared/components/Card/Card';
import { CardBody, TextWrapper, Title, Value } from './AgreementCard.styles';

type Props = {
  title: string;
  number: string;
  term: string;
  onClick: () => void;
};

export function AgreementCard({ title, number, term, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <Card title={title} onClick={onClick}>
      <CardBody>
        <TextWrapper>
          <Title as="p">{t('overview.number')}</Title>
          <Value as="p">{number}</Value>
        </TextWrapper>
        <TextWrapper>
          <Title as="p">{t('agreementDetails.term')}</Title>
          <Value as="p">{term}</Value>
        </TextWrapper>
      </CardBody>
    </Card>
  );
}

export default AgreementCard;
