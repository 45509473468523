import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from 'hooks/useGlobalState';
import { FaqType } from 'legacy/types';
import { Button } from 'legacy/components/common/design-system';
import { Card } from 'legacy/components/common/myeverbright';
import pages from 'pages';
import Support from './Support';
import { DashboardLayout } from './DashboardLayout';

export default function Faq() {
  const { faqs } = useGlobalState();
  const { faqId } = useParams();
  const [faq, setFaq] = useState<FaqType | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (!faq) {
      setFaq(faqs?.find(({ id }) => id === faqId));
    }
  }, [faq, faqId, faqs]);

  const handleGoToFaqs = () => {
    navigate(`/${pages.SUPPORT}`);
  };

  const handleGoBack = () => {
    navigate(`/${pages.DASHBOARD}`);
  };

  return (
    <DashboardLayout>
      <Support>
        <div className="faq-button-row">
          <Button styleVariant="tertiary" label="Back to Dashboard" onClick={handleGoBack} />
          <Button styleVariant="tertiary" label="Go to FAQs" onClick={handleGoToFaqs} />
        </div>
        {faq && (
          <Card $fullWidth>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </Card>
        )}
      </Support>
    </DashboardLayout>
  );
}
