import React from 'react';
import { IconProps } from './types';

export function Plus({ color = 'white', size = 'large' }: IconProps) {
  const dimensions = size === 'large' ? '16' : '13';

  return (
    <svg
      width={dimensions}
      height={dimensions}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_348_3087)">
        <path
          d="M8.92308 0.923077C8.92308 0.411538 8.51154 0 8 0C7.48846 0 7.07692 0.411538 7.07692 0.923077V7.07692H0.923077C0.411538 7.07692 0 7.48846 0 8C0 8.51154 0.411538 8.92308 0.923077 8.92308H7.07692V15.0769C7.07692 15.5885 7.48846 16 8 16C8.51154 16 8.92308 15.5885 8.92308 15.0769V8.92308H15.0769C15.5885 8.92308 16 8.51154 16 8C16 7.48846 15.5885 7.07692 15.0769 7.07692H8.92308V0.923077Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_348_3087">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Plus;
