import React, { useContext, useEffect, useState } from 'react';
import { addTag, removeTag } from 'api/faqs';
import FaqTitle from 'legacy/components/Faqs/FaqTitle';
import { Card, IconButton, ManageFAQCard } from 'legacy/components/common/myeverbright';
import { PlusSign, XIcon } from 'legacy/components/common/design-system/Icons';
import { Select } from 'legacy/components/common/design-system';
import { TDropdownDataItem } from 'legacy/components/common/design-system/Dropdown/TDropdown';
import colors from 'theme/colors';
import AddFaqForm from 'legacy/components/forms/AddFaqForm';
import { AdminContext } from 'contexts/AdminContext';
import EditFaqForm from 'legacy/components/forms/EditFaqForm';
import { FaqType, TagType } from 'legacy/types';

function FaqTag({ faq, tag, setTags }: { faq: FaqType; tag: TagType; setTags: Function }) {
  const handleRemoveTag = async () => {
    const { data } = await removeTag({ faqId: faq.id, tagId: tag.id });
    setTags(data);
  };

  return (
    <div className="tag">
      {tag.tag}
      <IconButton
        role="button"
        $margin="auto 0 auto 10px"
        tabIndex={0}
        onClick={handleRemoveTag}
        onKeyDown={handleRemoveTag}
      >
        <XIcon color={colors.red} />
      </IconButton>
    </div>
  );
}

function AddFaqTag({ faq, setTags }: { faq: FaqType; setTags: Function }) {
  const { tags } = useContext(AdminContext);

  const [selecting, setSelecting] = useState(false);
  const [tagOptions, setTagOptions] = useState<TDropdownDataItem[]>([]);

  useEffect(() => {
    const unusedTags = tags.filter((el) => faq.tags.filter((e) => el.id === e.id).length < 1);
    const options = unusedTags.map((unusedTag) => ({
      value: unusedTag.id,
      title: unusedTag.tag,
    }));

    setTagOptions(options);
  }, [tags, faq.tags]);

  const handleSelectOption = async (tagId: string) => {
    const { data } = await addTag({ faqId: faq.id, tagId });
    setTags(data);
    setSelecting(false);
  };

  return (
    <span className="tag">
      {selecting ? (
        <>
          <Select placeholder="Add Tag" options={tagOptions} onSelectOption={handleSelectOption} />
          <IconButton
            role="button"
            $margin="auto 0 auto 10px"
            tabIndex={0}
            onClick={() => setSelecting(false)}
            onKeyDown={() => setSelecting(false)}
          >
            <XIcon color={colors.red} />
          </IconButton>
        </>
      ) : (
        <IconButton
          role="button"
          tabIndex={0}
          onClick={() => setSelecting(true)}
          onKeyDown={() => setSelecting(true)}
        >
          <PlusSign color="green" size="small" />
        </IconButton>
      )}
    </span>
  );
}

function Faq({ faq, setFaq }: { faq: FaqType; setFaq: Function }) {
  const [editing, setEditing] = useState(false);

  const setTags = (tags: TagType[]) => setFaq({ ...faq, tags });

  return (
    <ManageFAQCard $fullWidth className={`manage-faq-card ${faq.hidden ? 'hidden' : ''}`}>
      {editing ? (
        <>
          <div className={`order ${faq.hidden ? 'hidden' : ''}`}>
            {faq.order}
            <div
              className="pull-right edit"
              role="button"
              tabIndex={0}
              onClick={() => setEditing(false)}
              onKeyDown={() => setEditing(false)}
            >
              Close
            </div>
          </div>
          <EditFaqForm faq={faq} onSubmit={() => setEditing(false)} />
        </>
      ) : (
        <>
          <div className={`order ${faq.hidden ? 'hidden' : ''}`}>
            {`${faq.order} ${faq.hidden ? '(Hidden)' : ''}`}
            <div
              className="pull-right edit"
              role="button"
              tabIndex={0}
              onClick={() => setEditing(true)}
              onKeyDown={() => setEditing(true)}
            >
              Edit
            </div>
          </div>
          <h4>{faq.hidden ? faq.question : <FaqTitle faq={faq} />}</h4>
          <p>{faq.answer}</p>
          <div className="tags">
            {faq.tags.map((tag) => (
              <FaqTag tag={tag} faq={faq} key={tag.id} setTags={setTags} />
            ))}
            <AddFaqTag faq={faq} setTags={setTags} />
          </div>
        </>
      )}
    </ManageFAQCard>
  );
}

function FaqsView() {
  const { faqs, setFaqs } = useContext(AdminContext);

  const [adding, setAdding] = useState(false);

  const setFaq = (idx: number, faq: FaqType) => {
    const newArray = [...faqs];
    newArray[idx] = faq;
    return setFaqs(newArray);
  };

  return (
    <>
      {faqs
        .sort((a, b) => a.order - b.order)
        .map((faq, idx) => (
          <Faq faq={faq} key={faq.id} setFaq={(f: FaqType) => setFaq(idx, f)} />
        ))}
      {adding ? (
        <Card $fullWidth>
          <h3>
            Add FAQ
            <div
              className="pull-right edit"
              role="button"
              tabIndex={0}
              onClick={() => setAdding(!adding)}
              onKeyDown={() => setAdding(!adding)}
            >
              Close
            </div>
          </h3>
          <AddFaqForm onSubmit={() => setAdding(false)} />
        </Card>
      ) : (
        <h3>
          <div
            className="edit"
            role="button"
            tabIndex={0}
            onClick={() => setAdding(!adding)}
            onKeyDown={() => setAdding(!adding)}
          >
            + Add FAQ
          </div>
        </h3>
      )}
    </>
  );
}

export default FaqsView;
