import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function ArrowRightIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <path
          id="Vector"
          d="M16.2509 12.4459L9.50045 19.9459C9.2236 20.2535 8.74904 20.2784 8.44154 20.0016C8.11726 19.7076 8.12359 19.2333 8.38585 18.9413L14.6853 11.9443L8.38576 4.94589C8.11013 4.63651 8.13451 4.16495 8.44107 3.88651C8.74904 3.60995 9.22342 3.63479 9.50045 3.94229L16.2504 11.4001C16.5087 11.7287 16.5087 12.1599 16.2509 12.4459Z"
          fill={color || theme.colors.textPrimarySecondary1100}
        />
      </g>
    </svg>
  );
}

export default ArrowRightIcon;
