import React, { useState } from 'react';
import { patchSystem } from 'api/system';
import { Button, TextInput } from 'legacy/components/common/design-system';
import { Card, Column, Row, Message } from 'legacy/components/common/myeverbright';
import { AgreementType, MessageType } from 'legacy/types';
import { formatInverterManufacturer, invalidMonitoringID } from 'legacy/utils';

export default function FixMonitoringId({
  agreements,
  setAgreements,
}: {
  agreements: AgreementType[];
  setAgreements: Function;
}) {
  const [newMonitoringId, setNewMonitoringId] = useState<string>('');
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);

    const invalidId = invalidMonitoringID({
      inverter_manufacturer_id: agreements[0].system.inverter_manufacturer_id,
      site_id: newMonitoringId,
    });

    if (invalidId && !newMonitoringId.length) {
      setMessage({ message: 'Not 7 characters', type: 'error' });
    } else {
      patchSystem(agreements[0].system.id, {
        site_id: newMonitoringId,
      })
        .then(() => {
          setMessage({ message: 'Successfully updated Site ID', type: 'success' });
          setNewMonitoringId('');
          const tempAgreements = [...agreements];
          tempAgreements.shift();
          setAgreements(tempAgreements);
        })
        .catch(() => {
          setMessage({ message: 'Failed to update Site ID', type: 'error' });
        });
    }
  };

  const handleChangeMonitoringId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMonitoringId(e.target.value);
  };

  const next = () => {
    const tempAgreements = [...agreements];
    const tempAgreement = tempAgreements.shift();
    if (tempAgreement) {
      setAgreements([...tempAgreements, tempAgreement]);
    }
  };

  return (
    <Card $fullWidth>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <Column>
        <Row $justifyContent="space-between">
          <Column>
            {Boolean(agreements[0]) && (
              <div>
                <h3>Agreement {agreements[0].homeowner_contract_id}</h3>
                <div className="label">Manufacturer</div>
                <p>{formatInverterManufacturer(agreements[0].system.inverter_manufacturer_id)}</p>
                <div className="label">Site ID</div>
                <p>{agreements[0].system.site_id}</p>
              </div>
            )}
            {agreements.length > 1 && <Button label="Next" onClick={next} />}
          </Column>
          <Column $width="50%">
            <TextInput
              ariaLabel="Update Site ID"
              label="Update Site ID"
              onChange={handleChangeMonitoringId}
            />
            <Button label="Submit" onClick={handleSubmit} margin="12px 0 12px auto" />
          </Column>
        </Row>
      </Column>
    </Card>
  );
}
