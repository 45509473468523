import React from 'react';

const gradientFillColors = {
  start: '#3F9BDE',
  middle: '#42B5DA',
  end: '#BCC2FA',
};

export function ChartLinearGradient() {
  return (
    <defs>
      <linearGradient
        id="myGradient"
        x1="128.655"
        y1="27.996"
        x2="132.324"
        y2="207.743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={gradientFillColors.start} stopOpacity="0.9" />
        <stop offset="0.52" stopColor={gradientFillColors.middle} stopOpacity="0.5" />
        <stop offset="1" stopColor={gradientFillColors.end} stopOpacity="0.25" />
      </linearGradient>
    </defs>
  );
}

export default ChartLinearGradient;
