import React from 'react';
import { colors } from 'theme/colors';

import { ArrowDownIcon, ArrowUpIcon } from 'legacy/components/common/design-system/Icons';
import {
  Accordion,
  AccordionItemType,
  AccordionItem,
} from 'legacy/components/common/design-system';
import { AccordionWrapper, AccordionItemWrapper, Status } from './StatusAccordionStyles';
import { AccordionStepIndicator } from './AccordionStepIndicator';

export type StatusAccordionItemType = AccordionItemType & {
  status?: Status;
  isOpen: boolean;
};
type Props = {
  items: StatusAccordionItemType[];
};

export function StatusAccordion({ items }: Props) {
  return (
    <AccordionWrapper>
      <Accordion id="status-accordion" items={items}>
        {items.map((item: StatusAccordionItemType, i: number) => (
          <AccordionItemWrapper key={`item-${item.id}`}>
            <AccordionStepIndicator
              step={i + 1}
              showLine={i !== items.length - 1}
              status={item.status}
            />
            <AccordionItem
              id={`item-${item.id}`}
              styleVariant="tertiary"
              contentBgColor={colors.white}
              label={item.label}
              isOpen={item.isOpen}
              size="large"
              icons={{ expanded: <ArrowUpIcon />, minimized: <ArrowDownIcon /> }}
            >
              {item.content}
            </AccordionItem>
          </AccordionItemWrapper>
        ))}
      </Accordion>
    </AccordionWrapper>
  );
}

export default StatusAccordion;
