import styled from 'styled-components';

type IconButtonProps = {
  $margin?: string;
};
export const IconButton = styled.button<IconButtonProps>`
  cursor: pointer;
  border: none;
  padding: 0;
  margin: ${({ $margin = 0 }) => $margin};
`;

export default IconButton;
