/* eslint-disable class-methods-use-this */
import { addDays, differenceInCalendarDays, format } from 'date-fns';
import { capitalize } from 'lodash';
import { toZonedTime } from 'date-fns-tz';

import { MonitoringDatum, AxisType, Tuple, PaddingType } from 'legacy/types';
import { DateRange } from 'api/system/utils/createDateRange';
import { ChartDataProvider } from './ChartDataProvider';
import { AnyChartDataProvider } from './AnyChartDataProvider';

export class WeekChartDataProvider extends ChartDataProvider implements AnyChartDataProvider {
  static numberOfTicks = 7;

  static placeholderMaximumValue = 16;

  barWidth = 20;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    const dailyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      largestProductionValue = Math.max(largestProductionValue, value);
      if (largestProductionValue === value) {
        largestProductionUnit = unit;
      }
      const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
      dailyProduction.set(dateNumber, { time, value, unit });
    });
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, WeekChartDataProvider.numberOfTicks - 1],
    y: [0, maximumValue || WeekChartDataProvider.placeholderMaximumValue],
  });

  tickXFormat = (day: number, range: DateRange): string => {
    const date = addDays(range.startDate, day);
    const zoned = toZonedTime(date, range.systemTimezone);
    return capitalize(format(zoned, 'eee'));
  };

  tickYFormat = <T>(value: number, _: T | null): string => `${value.toFixed(1)}`;

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(WeekChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  yTickLabelOffset = {
    x: -64,
    y: 15,
  };

  getTickValuesYAxis = (maximumValue: number): number[] => {
    const numberOfTicks = 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return tickValues;
  };

  domainPaddingX: PaddingType = [75, 10];

  getYAxis = <EnergyUnit>(maximumValue: number, unit: EnergyUnit | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = (date: Date, dateRange: DateRange): string => {
    const zoned = toZonedTime(date, dateRange.systemTimezone);
    return capitalize(format(zoned, 'M/d').toLowerCase());
  };
}

export default WeekChartDataProvider;
