import React from 'react';
import ExpiringSessionModal from 'legacy/components/ExpiringSessionModal';
import { Page } from 'pages';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import FullPageLoader from 'legacy/components/common/design-system/Loader/FullPageLoader';

type Props = {
  isAllowed: boolean;
  redirectPath: Page | `/${Page}`;
  hasSession?: boolean;
  isLoading?: boolean;
  children?: JSX.Element;
};
export default function ProtectedRoute({
  isAllowed,
  redirectPath,
  children,
  hasSession,
  isLoading = false,
}: Props) {
  const location = useLocation();
  if (isLoading) return <FullPageLoader />;

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ path: location.pathname }} />;
  }

  return (
    <>
      {hasSession ? <ExpiringSessionModal /> : null}
      {children || <Outlet />}
    </>
  );
}
