import styled from 'styled-components';
import colors from 'theme/colors';

type TableRowStyledProps = {
  $hasCursor: boolean;
};
export const TableRowStyled = styled.tr<TableRowStyledProps>`
  cursor: ${({ $hasCursor }) => ($hasCursor ? 'pointer' : 'initial')};
  border-bottom: solid 0.5px ${colors.gray20};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${colors.secondaryLightBlue};
  }
  td:first-child {
    padding-left: 24px;
  }
`;

export const TableCell = styled.td`
  padding: 16px 0 16px 32px;
  vertical-align: top;
  overflow-wrap: break-word;
`;
