import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, LoadingSpinner } from 'legacy/components/common/design-system';
import { Card, Column, Message } from 'legacy/components/common/myeverbright';
import { MessageType } from 'legacy/types';
import routes from 'routes';
import { ButtonGroup } from './AuthorizeStyles';
import { Wrapper } from '../LayoutStyles';

function Authorize() {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  params.append('allow', 'Authorize');
  const [message, setMessage] = useState<MessageType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectUri, setRedirectUri] = useState<string>('');

  const authorizeSubmit = useCallback(() => {
    axios
      .post(routes.authorize, Object.fromEntries(params))
      .then((response) => {
        window.location.replace(response.data.success_url);
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  }, [params]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(routes.checkAuthorizeParams, { params })
      .then((response) => {
        if (response.data.grant_exists) {
          authorizeSubmit();
        }
        setRedirectUri(response.data.redirect_uri);
        setLoading(false);
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  }, [authorizeSubmit, params]);

  const cancelSubmit = () => {
    window.location.replace(`${redirectUri}?error=access_denied`);
  };

  return (
    <Wrapper $accountView>
      <main>
        <Column $alignItems="center" $justifyContent="center">
          <Card $bgColor="white" $textColor="white">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {message && <Message severity={message.type}>{message.message}</Message>}
                <h3>MyEverBright wants to access the account services portal</h3>

                <ButtonGroup>
                  <Button label="Cancel" styleVariant="secondary" onClick={cancelSubmit} />
                  <Button label="Authorize" onClick={authorizeSubmit} />
                </ButtonGroup>
              </>
            )}
          </Card>
        </Column>
      </main>
    </Wrapper>
  );
}

export default Authorize;
