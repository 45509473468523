import { addDays, addHours, addMonths, addSeconds, addWeeks, addYears, set } from 'date-fns';
import timezones from 'countries-and-timezones';
import { getTimezoneOffset } from 'date-fns-tz';
import { DateRangeZoomLevel } from 'legacy/types';

/*
getMidnightInTimezoneFromUTC
We receive, from the native datepicker, a timestamp in UTC
We receive, from the user, the name of a timestamp
We need to send to the server a date, in the customer's system's timezone,
for the date that the user selected in the native datepicker
This method accepts that timestamp from the datepicker, gets the offset
for the certain date (important, because offset changes with DST)
... and returns a new date, at midnight, offset appropriately.
*/
export const getMidnightInTimezoneFromUTC = (timestamp: Date, timezone: string): Date => {
  const timezoneOffsetOnDate = getTimezoneOffset(timezone, timestamp) / 1000 / 60 / 60;
  return addHours(
    new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(), 0, 0, 0),
    -timezoneOffsetOnDate - timestamp.getTimezoneOffset() / 60,
  );
};

const upperBound = (date: Date, fn: (date: Date, amount: number) => Date, timezone: string) => {
  const previousDay = addDays(fn(date, 1), -1);
  const midnightPreviousDay = getMidnightInTimezoneFromUTC(previousDay, timezone);
  return addSeconds(midnightPreviousDay, 1);
};

export class DateRange {
  startDate: Date;

  endDate: Date;

  zoomLevel: DateRangeZoomLevel;

  systemTimezone: string;

  constructor(startDate: Date, zoomLevel: DateRangeZoomLevel, systemTimezone: string) {
    this.startDate = startDate;
    this.zoomLevel = zoomLevel;
    this.systemTimezone = systemTimezone;

    switch (zoomLevel) {
      case DateRangeZoomLevel.DAY:
        this.endDate = addDays(startDate, 1);
        break;
      case DateRangeZoomLevel.WEEK:
        // we need to add an hour, because the endpoints are not inclusive at the upper bound
        /*
        1. The range should not be inclusive of the first day of the next week, so adding 6 days
        2. We need to add a second, because the endpoints are not inclusive at the upper bound
        */
        this.endDate = upperBound(startDate, addWeeks, systemTimezone);
        break;
      case DateRangeZoomLevel.MONTH:
        /*
        1. If the user selected February 10, the range should go to March 9. This is because our
        normal range is showing the entire month, rather than inclusive of the first day of the
        next month. 
        2. We need to add a second, because the endpoints are not inclusive at the upper bound
        */
        this.endDate = upperBound(startDate, addMonths, systemTimezone);
        break;
      case DateRangeZoomLevel.YEAR:
        // we need to add an hour, because the endpoints are not inclusive at the upper bound
        // this.endDate = addHours(addDays(addYears(startDate, 1), -1), 1);
        this.endDate = upperBound(startDate, addYears, systemTimezone);
        break;
      default:
        throw new Error(`Unsupported zoom level: ${zoomLevel}`);
    }
  }
}

export const startOfWeek = (currentDate: Date): Date => addDays(currentDate, -currentDate.getDay());

export const startOfMonth = (currentDate: Date): Date =>
  addDays(currentDate, -currentDate.getDate() + 1);

export const startOfYear = (currentDate: Date): Date => set(currentDate, { month: 0, date: 1 });

export enum DateRangeStartDateBehavior {
  FROM_DATE,
  FROM_CALENDAR_UNIT,
}

/*
We allow zoom levels of day, week, month and year
Our UI (native ios or android) only allows selecting a _date_ (month/day/year)
We also aggregate data, so that if you are in year mode (for example), we bucket
by month.

However, we also aggregate based on the _start_ of the month, rather than a random day
_within_ the month. If a user selects a 12-month range starting on Februrary 5, no data
will be returned for February. However, if we request starting on February 1, data _will_
show for February.

Below, only in the case that a zoom level is for year, we therefore pick the start of the
month that they are requesting.
*/
export const startDateForZoomLevel = (startDate: Date, zoomLevel: DateRangeZoomLevel) => {
  switch (zoomLevel) {
    case DateRangeZoomLevel.YEAR:
      return startOfMonth(startDate);
    default:
      return startDate;
  }
};

export const createDateRange = (
  selectedStartDate: Date,
  zoomLevel: DateRangeZoomLevel,
  systemTimezone: string | undefined,
  behavior: DateRangeStartDateBehavior,
): DateRange | null => {
  if (!systemTimezone) {
    return null;
  }

  const timezone = timezones.getTimezone(systemTimezone);
  if (!timezone) {
    throw new Error(`Timezone not found for ${systemTimezone}`);
  }

  const midnightToday = getMidnightInTimezoneFromUTC(selectedStartDate, systemTimezone);
  let startDate = startDateForZoomLevel(midnightToday, zoomLevel);
  if (behavior === DateRangeStartDateBehavior.FROM_CALENDAR_UNIT) {
    switch (zoomLevel) {
      case DateRangeZoomLevel.DAY:
        startDate = midnightToday;
        /*
        below for testing, since I can't see anything
        till later in the day, since we pick our date
        range based on calendar day, rather than a
        trailing average. this leads to scenarios most
        of the time where the graph is empty.
        design call, not mine.
        */
        // startDate = addDays(midnightToday, -1);
        break;
      case DateRangeZoomLevel.WEEK:
        startDate = getMidnightInTimezoneFromUTC(startOfWeek(selectedStartDate), systemTimezone);
        break;
      case DateRangeZoomLevel.MONTH:
        startDate = getMidnightInTimezoneFromUTC(startOfMonth(selectedStartDate), systemTimezone);
        break;
      case DateRangeZoomLevel.YEAR:
        startDate = getMidnightInTimezoneFromUTC(startOfYear(selectedStartDate), systemTimezone);
        break;
      default:
        throw new Error(`Unsupported zoom level: ${zoomLevel}`);
    }
  }
  return new DateRange(startDate, zoomLevel, systemTimezone);
};
