import React from 'react';

import { themeButtons, getButtonStyleVariantValues } from 'shared/design-system/theme/buttons';
import { TButtonStyleVariantsKey } from 'shared/design-system/theme/buttons/TButtons';
import { Color } from 'theme/colors';
import { useTheme } from 'styled-components';
import { ButtonStyled, IconWrapper } from './Button.styles';
import { TIcon } from '../icons/TIcon';
import { LoadingSpinner } from '../LoadingSpinner';

export type TButtonProps = {
  ariaLabel?: string;
  id?: string;
  dataTestId?: string;
  isDisabled?: boolean;
  label?: string;
  styleVariant?: TButtonStyleVariantsKey;
  margin?: string;
  isFullWidth?: boolean;
  isLoading?: boolean;
  maxWidth?: string;
  Icon?: TIcon;
  iconRight?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tabIndex?: number;
  type?: 'button' | 'submit' | 'reset';
};

/**
 * @param {string} [ariaLabel] - aria Label for accesibility (optional)
 * @param {string} [id] - id value for the button (optional)
 * @param {string} [dataTestId] - id value used for testing (optional)
 * @param {boolean} [isDisabled] - is button disabled (optional)
 * @param {string} [label] - button text contents (optional)
 * @param {TButtonStyleVariantsKey} [styleVariant] - defaults to primary style (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {boolean} [isFullWidth] - defaults to false (width = auto) (optional)
 * @param {string} [maxWidth] - defaults to false (max-width = none) (optional)
 * @param {function} [Icon] - icon to use that renders inside the button (optional)
 * @param {string} [iconRight] - places icon to the right. Defaults to left (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {function} [onBlur] - onBlur handler (optional)
 * @param {function} [onClick] - onClick handler (optional)
 * @param {function} [onKeyDown] - onKeyDown handler (optional)
 * @param {function} [onKeyUp] - onKeyUp handler (optional)
 * @param {function} [onFocus] - onFocus handler (optional)
 * @param {function} [onMouseEnter] - onMouseEnter handler (optional)
 * @param {function} [onMouseDown] - onMouseDown handler (optional)
 * @param {function} [onMouseLeave] - onMouseLeave handler (optional)
 * @param {function} [onMouseUp] - onMouseUp handler (optional)
 * @param {number} [tabIndex] - tab index value (optional)
 * @param {string} [type] - HTML button type (optional)
 */

export function Button({
  ariaLabel,
  id,
  dataTestId,
  isDisabled = false,
  margin = '0',
  styleVariant = 'primary',
  label = undefined,
  isFullWidth = false,
  maxWidth,
  isLoading = false,
  Icon,
  iconRight,
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
  tabIndex = 0,
  type = 'button',
}: TButtonProps) {
  const theme = useTheme();
  const { fontColor: color, background } = getButtonStyleVariantValues(
    theme,
    themeButtons,
    styleVariant,
  );

  return (
    <ButtonStyled
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      id={id}
      data-testid={dataTestId}
      disabled={isDisabled || isLoading}
      $styleVariant={styleVariant}
      $margin={margin}
      $isFullWidth={isFullWidth}
      $maxWidth={maxWidth}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      type={type}
    >
      {isLoading ? (
        <LoadingSpinner
          spinnerColor={color as Color}
          spinnerBackgroundColor={background as Color}
          size="small"
        />
      ) : (
        <>
          {Icon && (
            <IconWrapper $iconRight={iconRight}>
              <Icon color={color} />
            </IconWrapper>
          )}
          <span>{label}</span>
        </>
      )}
    </ButtonStyled>
  );
}

export default Button;
