export const perSessionBannersKey = 'perSessionBanners';

export const perSessionFetchBannersKey = 'perSessionFetchBanners';

export const perSessionModalKey = 'perSessionModalKey';

export const perSessionAchDiscountModalM0 = 'achDiscountModalM0';

export const perSessionAchDiscountModalM2 = 'achDiscountModalM2';

export const PER_SESSION_STORAGE_KEYS = [
  perSessionBannersKey,
  perSessionFetchBannersKey,
  perSessionModalKey,
  perSessionAchDiscountModalM0,
  perSessionAchDiscountModalM2,
];

export const authenticatedKey = 'authenticated';
