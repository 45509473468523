import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { XIcon } from 'legacy/components/common/design-system/Icons';
import colors from 'theme/colors';
import { Table } from 'legacy/components/common/design-system';
import { TTableRow } from 'legacy/components/common/design-system/Table/TTable';
import { Address, Column, Pagination, Row } from 'legacy/components/common/myeverbright';
import { AdminContext } from 'contexts/AdminContext';
import { AgreementType } from 'legacy/types';
import { getAgreements } from 'api/agreement';

import {
  formatDateObjShort,
  formatInverterManufacturer,
  hasSystemMonitoring,
  parseDateOnly,
} from 'legacy/utils';
import pages from 'pages';
import FixMonitoringId from './FixMonitoringId';

export default function Monitoring() {
  const { monitoringTableState, setMonitoringTableState } = useContext(AdminContext);
  const { page, pageSize, goodAgreements, badAgreements } = monitoringTableState;

  const navigate = useNavigate();

  const navigateToMonitoringDetail = (id: string) => {
    const path = pages.MONITORING_DETAIL.replace(':systemId', id);
    navigate(`../${path}`);
  };

  useEffect(() => {
    getAgreements({
      page,
      page_size: pageSize,
      has_monitoring: true,
    }).then((response) => {
      const agreementsWithMonitoring: AgreementType[] = response.data.results;
      const agreementsWithBadMonitoring = agreementsWithMonitoring.filter(
        (agreement) => !hasSystemMonitoring(agreement.system),
      );
      const agreementsWithGoodMonitoring = agreementsWithMonitoring.filter((agreement) =>
        hasSystemMonitoring(agreement.system),
      );
      const totalNumAgreements = response.data.count;
      setMonitoringTableState({
        goodAgreements: agreementsWithGoodMonitoring,
        badAgreements: agreementsWithBadMonitoring,
        totalPages: Math.ceil(totalNumAgreements / pageSize) || 1,
        page,
        pageSize,
        count: response.data.count,
      });
    });
  }, [page, pageSize, setMonitoringTableState]);

  const columns = [
    { title: 'Contract' },
    { title: 'Homeowner Contract ID' },
    { title: 'Product Type' },
    { title: 'Location' },
    { title: 'Manufacturer' },
    { title: 'Site ID' },
  ];

  const badMonitoringAgreementsRow = badAgreements.map((agreement) => ({
    key: `table-${agreement.id}`,
    cells: [
      formatDateObjShort(parseDateOnly(agreement.contract_start_date)),
      agreement.homeowner_contract_id,
      agreement.product_type,
      <Address address={agreement.system.address} />,
      formatInverterManufacturer(agreement.system.inverter_manufacturer_id),
      agreement.system.site_id,
      <XIcon color={colors.red} />,
    ],
  }));

  const goodMonitoringAgreementsRow = goodAgreements
    .sort((a, b) => {
      if (a.system.site_id && b.system.site_id) {
        return Number(a.system.site_id) - Number(b.system.site_id);
      }
      if (a.system.site_id) {
        return -1;
      }
      if (b.system.site_id) {
        return 1;
      }
      return (
        parseDateOnly(a.contract_start_date).getTime() -
        parseDateOnly(b.contract_start_date).getTime()
      );
    })
    .map((agreement) => ({
      key: `table-${agreement.id}`,
      handleClick: () => navigateToMonitoringDetail(agreement.system.id),
      cells: [
        formatDateObjShort(parseDateOnly(agreement.contract_start_date)),
        agreement.homeowner_contract_id,
        agreement.product_type,
        <Address address={agreement.system.address} />,

        formatInverterManufacturer(agreement.system.inverter_manufacturer_id),

        agreement.system.site_id,
      ],
    }));

  const handlePageChange = (activePage: number) => {
    setMonitoringTableState({
      ...monitoringTableState,
      page: activePage,
    });
  };

  const rows: TTableRow[] = [...badMonitoringAgreementsRow, ...goodMonitoringAgreementsRow];

  return (
    <>
      {badAgreements.length ? (
        <FixMonitoringId
          agreements={badAgreements}
          setAgreements={(agreements: AgreementType[]) =>
            setMonitoringTableState({ ...monitoringTableState, badAgreements: agreements })
          }
        />
      ) : null}
      <Row>
        <Column $flex="1">
          <div className="user-statistic">
            <div className="value">{badAgreements.length}</div>
            <div className="label">Bad Site ID</div>
          </div>
        </Column>
        <Column $flex="1">
          <div className="user-statistic">
            <div className="value">{goodAgreements.length}</div>
            <div className="label">Good Site ID</div>
          </div>
        </Column>
      </Row>

      <Table columns={columns} rows={rows} />

      <Pagination
        totalPages={monitoringTableState.totalPages}
        activePage={monitoringTableState.page}
        pageSize={monitoringTableState.pageSize}
        onPageChange={handlePageChange}
      />
    </>
  );
}
