import styled from 'styled-components';
import colors from 'theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 6px;
`;

type ButtonStyledProps = {
  active: boolean;
};

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button<ButtonStyledProps>`
  background: none;
  padding: 0px;
  border: none;
  cursor: pointer;
  margin-right: 60px;
  :last-child {
    margin: 0;
  }
`;

export const ActiveUnderline = styled.div`
  padding: 1px;
  background-color: ${colors.blue};
  border-radius: 5px;
  width: 50px;
`;

export const Underline = styled.div`
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: -2px;
  z-index: -1px;
  width: 100%;
`;

export const Text = styled.p`
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
`;
