import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function LinkIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0291 8.15696C16.3236 6.86246 16.3236 4.76537 15.0291 3.47087C13.7346 2.17638 11.6375 2.17638 10.343 3.47087L9.75793 4.05599C9.59741 4.2165 9.59741 4.48058 9.75793 4.6411C9.91845 4.80162 10.1825 4.80162 10.343 4.6411L10.9282 4.05599C11.899 3.08511 13.4731 3.08511 14.444 4.05599C15.4149 5.02686 15.4149 6.60097 14.444 7.57184L11.2181 10.7951C10.2498 11.7634 8.67832 11.7634 7.70744 10.7951C6.78576 9.87346 6.73398 8.39774 7.58576 7.41392L7.72298 7.25599C7.87314 7.08252 7.85502 6.82104 7.68155 6.67087C7.50809 6.52071 7.2466 6.53884 7.09644 6.7123L6.95922 6.87023C5.82265 8.18285 5.89256 10.1505 7.11974 11.3777C8.41165 12.6696 10.5087 12.6696 11.8006 11.3777L15.0291 8.15696ZM0.970874 8.15696C-0.323625 9.45146 -0.323625 11.5485 0.970874 12.843C2.26537 14.1375 4.36246 14.1375 5.65696 12.843L6.24207 12.2579C6.40259 12.0974 6.40259 11.8333 6.24207 11.6728C6.08155 11.5123 5.81748 11.5123 5.65696 11.6728L5.07184 12.2579C4.10097 13.2288 2.52686 13.2288 1.55599 12.2579C0.585113 11.2871 0.585113 9.71295 1.55599 8.74207L4.78188 5.51877C5.75016 4.55049 7.32168 4.55049 8.29256 5.51877C9.21424 6.44045 9.26602 7.91618 8.41424 8.9L8.27702 9.05793C8.12686 9.23139 8.14498 9.49288 8.31845 9.64304C8.49191 9.7932 8.7534 9.77508 8.90356 9.60162L9.04078 9.44369C10.1773 8.13107 10.1074 6.16343 8.88026 4.93625C7.58317 3.63916 5.48867 3.63916 4.19417 4.93107L0.970874 8.15696Z"
        fill={color}
      />
    </svg>
  );
}

export default LinkIcon;
