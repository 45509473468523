import React from 'react';
import { Circle, StatusWrapper, VerticalLine, Status } from './StatusAccordionStyles';

type AccordionStepIndicatorProps = {
  step: number;
  showLine?: boolean;
  status?: Status;
};

/**
 * @param {number} [step] - indicates order of accordion
 * @param {boolean} showLine - vertical line behind. defaults to false (optional)
 * @param {Status} [status] - status of the accordion (optional)
 */

export function AccordionStepIndicator({
  step,
  showLine = false,
  status = Status.INCOMPLETE,
}: AccordionStepIndicatorProps) {
  return (
    <StatusWrapper>
      <VerticalLine $showLine={showLine} $status={status} />
      <Circle $status={status}>
        <span>{step}</span>
      </Circle>
    </StatusWrapper>
  );
}

export default AccordionStepIndicator;
