import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function PhoneIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7417 8.60939C11.2293 8.39068 10.6357 8.5344 10.2826 8.96558L9.24527 10.2341C7.80802 9.39988 6.60823 8.20008 5.774 6.76283L7.03941 5.72864C7.47058 5.37557 7.61743 4.78193 7.39559 4.26952L5.89585 0.770122C5.66152 0.220218 5.071 -0.0859792 4.48672 0.0389991L0.987329 0.788869C0.412429 0.910723 0 1.42001 0 2.01053C0 9.40613 5.73338 15.4613 12.9977 15.9737C13.1383 15.9831 13.2821 15.9925 13.4258 15.9987C13.4258 15.9987 13.4258 15.9987 13.4289 15.9987C13.6195 16.005 13.807 16.0112 14.0007 16.0112C14.5912 16.0112 15.1005 15.5988 15.2224 15.0239L15.9722 11.5245C16.0972 10.9402 15.791 10.3497 15.2411 10.1154L11.7417 8.61564V8.60939ZM13.9882 15.0083C6.81444 15.002 0.999827 9.18741 0.999827 2.01053C0.999827 1.8918 1.08106 1.79182 1.19667 1.76682L4.69606 1.01695C4.81167 0.991959 4.9304 1.05445 4.97726 1.1638L6.477 4.6632C6.52075 4.7663 6.49262 4.88503 6.40514 4.95377L5.13661 5.99109C4.75855 6.30041 4.65857 6.84095 4.9054 7.26587C5.82712 8.85622 7.15189 10.181 8.73911 11.0996C9.16404 11.3464 9.70457 11.2464 10.0139 10.8684L11.0512 9.59984C11.1231 9.51236 11.2418 9.48424 11.3418 9.52798L14.8412 11.0277C14.9505 11.0746 15.013 11.1933 14.988 11.3089L14.2382 14.8083C14.2132 14.9239 14.1101 15.0052 13.9944 15.0052C13.9913 15.0052 13.9882 15.0052 13.9851 15.0052L13.9882 15.0083Z"
        fill={color}
      />
    </svg>
  );
}

export default PhoneIcon;
