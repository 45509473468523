import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'theme/colors';
import { CheckIcon, XIcon } from 'legacy/components/common/design-system/Icons';
import { Checkbox } from 'legacy/components/common/myeverbright';
import { LanguageCodeKey, WelcomeChecklistItemType } from 'legacy/types';
import { useUser } from 'hooks/useUser';
import { BodyRegular } from 'legacy/components/common/design-system/Typography';
import {
  IconWrapper,
  ReviewContainer,
  ReviewItem,
  ReviewPageSubTitle,
} from './WelcomeChecklistStyles';

type Props = {
  checklist: WelcomeChecklistItemType[];
  onChangeConfirmed: () => void;
  confirmed: boolean;
  showAckRequired: boolean;
  message: React.ReactNode;
  primaryLanguage: LanguageCodeKey;
};

export function ReviewAnswers({
  checklist,
  confirmed,
  showAckRequired,
  onChangeConfirmed,
  message,
  primaryLanguage,
}: Props) {
  const { userResult } = useUser();
  const { t } = useTranslation();

  const name = `${userResult?.user?.first_name} ${userResult?.user?.last_name}`;

  return (
    <ReviewContainer>
      {message ? (
        <span>{message}</span>
      ) : (
        <>
          <ReviewPageSubTitle>{t('welcomeChecklist.reviewPageSubTitle')}</ReviewPageSubTitle>
          {checklist.map((item) => {
            const primaryLanguageQuestion = item[`question_${primaryLanguage}`];

            return (
              <ReviewItem boldText={item.answer === t('welcomeChecklist.no')}>
                <IconWrapper>
                  {item.answer === t('welcomeChecklist.yes') ? (
                    <CheckIcon color={colors.green} size="large" />
                  ) : (
                    <XIcon color={colors.red} />
                  )}
                </IconWrapper>
                <BodyRegular>{primaryLanguageQuestion}</BodyRegular>
              </ReviewItem>
            );
          })}
          <Checkbox
            isChecked={confirmed}
            isRequired={showAckRequired}
            error={showAckRequired}
            hint={showAckRequired ? '*Required' : ''}
            onChange={onChangeConfirmed}
            text={t('welcomeChecklist.acknowledgeStatement', { name })}
            margin="16px 0 0"
          />
        </>
      )}
    </ReviewContainer>
  );
}

export default ReviewAnswers;
