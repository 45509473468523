import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const CardBody = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  :first-child {
    margin-right: 56px;
  }
`;

export const Title = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textSecondarySubtleGrey80};
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    `}
`;

export const Value = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textPrimarySecondary1100};
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 800;
    `}
`;
