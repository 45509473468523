import React from 'react';

export const systemPerformanceCopy = (
  <span>
    Review your system’s performance data and access maintenance information with EverBright’s
    monitoring partner, <b>Omnidian</b>.
  </span>
);

export const noSystemCopy = <span>We&apos;re gathering your system data. Check back soon!</span>;

export const systemCapacityCopy = (
  <span>
    View your system details. Plus, access guidance on how to get the most out of your system
    production.
  </span>
);

export const billingCopy = (
  <span>
    Make payments, view billing history and manage payment preferences by accessing our account
    services website with the button below.
  </span>
);

export const customerSupportCopy = (
  <span>For immediate help, chat with an EverBright Support agent using the chat bubble.</span>
);

export const systemProductionCopy = (
  <span>View your system&apos;s production and equipment details to track your impact.</span>
);

export const systemServiceCopy = (
  <span>
    Access maintenance and servicing information with EverBright&apos;s monitoring partner,{' '}
    <b>Omnidian</b>.
  </span>
);

export const contractSignedCopy = (
  <span>
    We are verifying all relevant information in order to proceed to the installation process.
  </span>
);

export const projectQualificationCompletedCopy = (
  <span>
    Your solar install partner has begun the installation process. They will be in touch if there
    are next steps.
  </span>
);

export const installationCompletedCopy = (
  <span>
    Your system has completed installation! Your solar install partner is now working with your
    utility company to approve your system for activation.
  </span>
);

export const inServiceCopy = <span>Your system is in service.</span>;

export const statusBannerText =
  'Welcome to MyEverBright! Here, you can effortlessly access and manage your account details, track the progress of your solar installation, and explore valuable resources to maximize the benefits of your solar investment.';

export const welcomeChecklistErrorMessage = (
  <span>
    <p>
      <b>Oops! Something went wrong on our end.</b>
    </p>
    <br />
    <p>{`Please click 'Try Again' to re-submit the form.`}</p>
  </span>
);
