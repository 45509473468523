import React, { ReactElement, useEffect, useState } from 'react';
import { Color } from 'theme/colors';
import { BannerButton, BannerContent, BannerStyled, BannerText, IconWrapper } from './BannerStyles';
import { CloseIcon } from '../Icons';
import { perSessionBannersKey } from '../../../../constants';
import { initializeBannerVisibility } from './utils/initializeBannerVisibility';

interface BannerProps {
  bannerText: string;
  boldText?: string;
  bgColor: Color;
  link?: { text?: string; url?: string };
  bannerKey?: string;
  icon: ReactElement;
  dismissable?: boolean;
}

/**
 * @param {string} [bannerText] - Main body text of banner
 * @param {string} [boldText] - Bold text preceeding the main bannerText (optional)
 * @param {Color} [bgColor] - Background color of banner
 * @param {{text: string; url: string}} [link] - Clickable link (optional)
 * @param {string} [bannerKey] - Optional banner key used for handling hiding/showing on a per session basis. Should be unique across all <Banner /> components used in app. Omit to handle hiding/showing with just react state. (optional)
 * @param {ReactElement} [icon] - Icon preceeding text content
 * @param {boolean} [dismissable] - Can the banner be dismissed (optional)
 */
export function Banner({
  bannerText,
  boldText,
  link,
  bannerKey,
  bgColor,
  icon,
  dismissable = true,
}: BannerProps) {
  const [bannerVisible, setBannerVisible] = useState(true);

  useEffect(() => {
    const visibility = initializeBannerVisibility(bannerKey);
    setBannerVisible(visibility);
  }, [bannerKey]);

  const handleClose = () => {
    const perSessionBannersRaw = localStorage.getItem(perSessionBannersKey);
    if (perSessionBannersRaw != null && bannerKey) {
      const bannersObj = JSON.parse(perSessionBannersRaw);
      bannersObj[bannerKey] = false;
      localStorage.setItem(perSessionBannersKey, JSON.stringify(bannersObj));
    }
    setBannerVisible(false);
  };

  return bannerVisible ? (
    <BannerStyled $bgColor={bgColor}>
      <IconWrapper>{icon}</IconWrapper>
      <BannerContent>
        <BannerText $margin={link ? '0 12px 0 0' : '0'}>
          {boldText && <b>{boldText} </b>}
          {bannerText}
        </BannerText>

        {link && (
          <a href={link.url} target="_blank" rel="noreferrer">
            {link.text}
          </a>
        )}
      </BannerContent>
      {dismissable && (
        <BannerButton onClick={handleClose}>
          <CloseIcon />
        </BannerButton>
      )}
    </BannerStyled>
  ) : null;
}

export default Banner;
