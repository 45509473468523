import React, { useEffect, useState } from 'react';
import colors from 'theme/colors';
import {
  PRIMARY_ACCORDION_STYLE_VARIANT,
  TAccordionStyleVariantsKey,
  TERTIARY_ACCORDION_STYLE_VARIANT,
} from 'theme/accordions/TAccordions';
import { getAccordionStyleVariantValues, lightThemeAccordions } from 'theme/accordions';

import { AccordionItemButton, AccordionItemContent, AccordionItemStyled } from './AccordionStyles';
import { PlusSign, MinusSign } from '../Icons';
import { H3, H4, H5 } from '../Typography';

export type AccordionItemProps = {
  id: string;
  label: string;
  styleVariant: TAccordionStyleVariantsKey;
  contentBgColor?: string;
  size?: 'small' | 'large';
  isOpen?: boolean;
  children: React.ReactNode;
  icons?: { expanded: JSX.Element; minimized: JSX.Element };
};

/**
 * @param {string} [id] - id used for assigning accessibility control roles
 * @param {string} [label] - label for the button which toggles the accordion
 * @param {TAccordionStyleVariantsKey} [styleVariant] - defaults to primary (optional)
 * @param {string} [contentBgColor] - background color for content
 * @param {'small' | 'large'} [size] - defaults to large (optional)
 * @param {boolean} isOpen -  defaults to false (optional)
 * @param {JSX.Element} [children] - shape of each accordion item
 * @param {{expanded: JSX.Element; minimized: JSX.Element}} [icons] - custom icons. not a part of the dsign system (optional)
 */

export function AccordionItem({
  id,
  label,
  styleVariant = PRIMARY_ACCORDION_STYLE_VARIANT,
  contentBgColor,
  isOpen = false,
  children,
  size = 'large',
  icons,
}: AccordionItemProps) {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const { labelColor } = getAccordionStyleVariantValues(lightThemeAccordions, styleVariant);

  const iconColor = styleVariant === 'primary' ? colors.white : colors.secondaryDarkBlue;

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  let icon = isExpanded ? icons?.expanded : <MinusSign color={iconColor} size={size} />;
  if (!isExpanded) icon = icons?.minimized || <PlusSign color={iconColor} size={size} />;

  let Label = H3;
  if (size === 'small') Label = H5;
  if (styleVariant === TERTIARY_ACCORDION_STYLE_VARIANT) Label = H4;

  return (
    <AccordionItemStyled
      id={id}
      className="accordion-item"
      $styleVariant={styleVariant}
      $size={size}
      $isExpanded={isExpanded}
    >
      <AccordionItemButton
        id={`${id}-button`}
        aria-controls={`${id}-content`}
        aria-expanded={isExpanded ? 'true' : false}
        $isExpanded={isExpanded}
        $styleVariant={styleVariant}
        onClick={() => setIsExpanded(!isExpanded)}
        $size={size}
      >
        <Label color={labelColor}>{label}</Label>
        {icon}
      </AccordionItemButton>
      <AccordionItemContent
        id={`${id}-content`}
        aria-labelledby={`${id}-button`}
        aria-hidden={isExpanded ? 'false' : 'true'}
        $isExpanded={isExpanded}
        $bgColor={contentBgColor}
        $size={size}
        $styleVariant={styleVariant}
      >
        {children}
      </AccordionItemContent>
    </AccordionItemStyled>
  );
}

export default AccordionItem;
