import styled, { css } from 'styled-components';

export const MonitoringSnapshotWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    max-width: 727px;
    justify-content: space-between;

    margin-bottom: 24px;
    > div {
      padding: 0 12px;
      border-left: 1px solid ${theme.colors.strokeSecondaryGrey10};
    }
  `}
`;

export const StatisticWrapper = styled.div``;

export const StatisticLabel = styled.div`
  ${({ theme }) => `
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.colors.textSecondarySubtleGrey80};
  `}
`;

export const StatisticValue = styled.div`
  ${({ theme }) => `
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: ${theme.colors.textSecondaryGrey90};
  `}
`;

export default MonitoringSnapshotWrapper;
