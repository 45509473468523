import { StateContext } from 'contexts/StateContext';
import { useContext } from 'react';

export const useGlobalState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a StateContextProvider');
  }
  return context;
};

export default useGlobalState;
