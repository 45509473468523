import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from 'api/user';
import { MessageType, UserType } from 'legacy/types';
import AccountSection from 'legacy/components/admin/user/AccountSection';
import UserSection from 'legacy/components/admin/user/UserSection';

export default function UserDetailView() {
  const { userId } = useParams();

  const [user, setUser] = useState<UserType | null>(null);
  const [message, setMessage] = useState<MessageType | null>(null);

  const refreshUser = () => {
    if (userId) {
      getUser(userId).then((response) => {
        setUser(response.data);
      });
    }
  };

  useEffect(refreshUser, [userId]);

  const handleMessageUpdate = (newMessage: MessageType | null) => {
    setMessage(newMessage);
  };

  return user ? (
    <>
      <UserSection
        user={user}
        refreshUser={refreshUser}
        message={message}
        onMessageUpdate={handleMessageUpdate}
      />
      <h3>Accounts</h3>
      {user.accounts.map((account) => (
        <AccountSection key={account.id} account={account} refreshUser={refreshUser} />
      ))}
    </>
  ) : null;
}
