export const guessEnvironment = (): 'local' | 'app-dev' | 'dev' | 'qa' | 'prod' | 'unknown' => {
  if (import.meta.env.DEV) return 'local';
  if (import.meta.env.PROD) {
    const subdomain = window.location.host.split('.')[0];
    if (subdomain === 'app-dev') return 'app-dev';
    if (subdomain === 'dev') return 'dev';
    if (subdomain === 'qa') return 'qa';
    if (subdomain === 'myeverbright') return 'prod';
  }
  // eslint-disable-next-line no-console
  console.log(
    `error: could not infer deployment environment for ${import.meta.env.MODE} and ${
      window.location.host
    }`,
  );
  return 'unknown';
};

export default guessEnvironment;
