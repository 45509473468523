import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { getFAQs } from 'api/faqs';
import useUser from 'hooks/useUser';
import {
  MonitoringQueryKey,
  prefetchMonitoringQueries,
} from 'modules/system/utils/prefetchQueries';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { OverviewHeader } from '../components/OverviewHeader/OverviewHeader';
import { FAQsSection } from '../components/FAQsSection/FAQsSection';
import { AgreementsSection } from '../components/AgreementsSection/AgreementsSection';
import { OverviewCards } from '../components/OverviewCards/OverviewCards';
import { Container } from './Overview.styles';

export function Overview() {
  const { userResult, selectedAccount, setSelectedAccount } = useUser();
  const user = userResult?.user;
  const queryClient = useQueryClient();

  const { data: faqData } = useQuery('faqs', () => getFAQs().then((response) => response.data), {
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff: 1s, 2s, 4s, etc.
  });

  const agreements = selectedAccount?.agreements || [];
  // get system with monitoring enabled
  const { system } = getMonitoringSystemInfo(selectedAccount);

  useEffect(() => {
    async function prefetchQueries() {
      if (system) await prefetchMonitoringQueries({ queryClient, systemId: system.id });
    }

    // run the monitoring query prefetch group if there's no timezone req already in the query cache
    if (!queryClient.getQueryData([MonitoringQueryKey.TIMEZONE, system?.id])) {
      prefetchQueries();
    }
  }, [queryClient, system]);

  const handleAccountChange = (accountId: string) => {
    if (accountId !== selectedAccount?.id) {
      const newAccount = user?.accounts.find((account) => account.id === accountId);
      setSelectedAccount(newAccount);
    }
  };

  return (
    <Container>
      {user && selectedAccount && (
        <OverviewHeader
          selectedAccount={selectedAccount}
          accounts={user.accounts}
          onAccountChange={handleAccountChange}
        />
      )}

      <OverviewCards />

      {Boolean(agreements?.length) && <AgreementsSection agreements={agreements} />}

      {faqData && <FAQsSection faqs={faqData} />}
    </Container>
  );
}

export default Overview;
