import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyRegular } from 'legacy/components/common/design-system/Typography';

export default function InServiceContent() {
  const { t } = useTranslation();

  return (
    <div>
      <BodyRegular $margin="0 0 16px">{t('statusAccordionCopy.inService.copy.body1')}</BodyRegular>
      <BodyRegular $margin="0 0 16px">{t('statusAccordionCopy.inService.copy.body2')}</BodyRegular>
      <BodyRegular $margin="0 0 16px">{t('statusAccordionCopy.inService.copy.body3')}</BodyRegular>
    </div>
  );
}
