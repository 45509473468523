import { AgreementType, SystemType } from 'api/types';
import { hasSystemMonitoring } from 'legacy/utils';

export const getSystemWithMonitoring = (agreements: AgreementType[]): SystemType | null => {
  const systemsWithMonitoring = agreements
    ?.map((agreement) => agreement.system)
    ?.find((system) => hasSystemMonitoring(system));
  return systemsWithMonitoring || null;
};

export default getSystemWithMonitoring;
