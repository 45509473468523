import React from 'react';
import { Outlet } from 'react-router';

import { Navigation, MenuOptions } from 'shared/components/Navigation/Navigation';
import { LayoutContent, LayoutWrapper, MenuBar } from './Layout.styles';
import { Footer } from '../Footer/Footer';

type Props = {
  isAuthScreen?: boolean;
  menuOptions?: MenuOptions[];
  hasNav?: boolean;
  hasFooter?: boolean;
  children?: JSX.Element;
};

export function Layout({ menuOptions, hasNav, hasFooter = true, isAuthScreen, children }: Props) {
  return (
    <LayoutWrapper>
      {hasNav && (
        <MenuBar>
          <Navigation menuOptions={menuOptions || []} />
        </MenuBar>
      )}
      <LayoutContent $isAuthScreen={isAuthScreen}>
        {children || <Outlet />}
        {hasFooter && <Footer />}
      </LayoutContent>
    </LayoutWrapper>
  );
}

export default Layout;
