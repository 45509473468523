import styled from 'styled-components';
import { MOBILE_MAX_WIDTH } from '../../../constants';

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const ButtonFooter = styled.div`
  display: flex;
  justify-content: end;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
  }
`;
