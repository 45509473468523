import React from 'react';
import { CustomPickerGridWrapper, CustomPickerWrapper } from './DateSelector.styles';
import { CustomCaption } from './CustomCaption';
import { CustomFooter } from './CustomFooter';

interface Props {
  onCloseModal: () => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onOkClick: () => void;
  header: string | number;
  items: React.ReactNode[];
  size: 'small' | 'large';
  timeScale?: 'month' | 'year';
}

export function CustomPicker({
  onCloseModal,
  header,
  items,
  onNextClick,
  onPrevClick,
  onOkClick,
  size = 'large',
  timeScale,
}: Props) {
  return (
    <CustomPickerWrapper>
      <CustomCaption
        header={header.toString()}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        size={size}
        hasHeaderSpacer={!timeScale}
      />
      <CustomPickerGridWrapper $timeScale={timeScale}>{items}</CustomPickerGridWrapper>
      <CustomFooter onCancelClick={onCloseModal} onOkClick={onOkClick} />
    </CustomPickerWrapper>
  );
}

export default CustomPicker;
