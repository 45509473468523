import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubHeader, SubHeadingRight, SubHeadingLeft } from './WelcomeChecklistStyles';

type Props = {
  hideHeader: boolean;
};
export function WelcomeChecklistHeader({ hideHeader }: Props) {
  const { t } = useTranslation();
  if (hideHeader) return null;

  return (
    <SubHeader>
      <SubHeadingLeft>{t('welcomeChecklist.checklistSubTitle')}</SubHeadingLeft>
      <SubHeadingRight>
        <span>{t('welcomeChecklist.yes')}</span>
        <span>{t('welcomeChecklist.no')}</span>
      </SubHeadingRight>
    </SubHeader>
  );
}

export default WelcomeChecklistHeader;
