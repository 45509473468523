import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useLocation } from 'react-router-dom';

import { SystemType } from 'api/types';
import { pages } from 'pages';
import { Header } from 'shared/components/Header/Header';
import { BatteryDetailsWrapper, Label, SectionDetails, Title, Value } from './HardwareInfo.styles';

interface Props {
  /** Should never need to be used outside testing–system information should be passed by navigation props */
  systemProp?: SystemType;
}

export function HardwareInfo({ systemProp }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const system: SystemType = systemProp || location.state.system;

  const systemDetails = [
    { label: t('hardwareInformation.panels'), value: system.panels || '' },
    { label: t('hardwareInformation.inverters'), value: system.inverters || '' },
    {
      label: t('hardwareInformation.capacity'),
      value: t('hardwareInformation.capacity_format', {
        value: parseFloat(system.capacity?.toString() || '0'),
      }),
    },
  ];

  return (
    <div>
      <Header
        pageName="HARDWARE_INFO"
        title={t('hardwareInformation.hardwareInformation')}
        routeBreadCrumbs={[
          {
            label: 'System',
            link: resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`),
          },
          { label: 'Hardware Information' },
        ]}
      />

      <Title as="h2" $margin="0 0 18px">
        {t('hardwareInformation.overview')}
      </Title>

      <SectionDetails>
        {systemDetails.map((item) => (
          <div key={item.label}>
            <Label as="p">{item.label}</Label>
            <Value as="p">{item.value}</Value>
          </div>
        ))}
      </SectionDetails>

      <BatteryDetailsWrapper>
        {system?.batteries.map((battery) => (
          <div key={battery.id}>
            <Title as="h2" $margin="0 0 24px">
              {t('hardwareInformation.battery')}
            </Title>

            <SectionDetails>
              <div>
                <Label as="p">{t('hardwareInformation.storageCapacity')}</Label>
                <Value as="p">
                  {t('hardwareInformation.capacity_format', {
                    value: parseFloat(battery.capacity?.toString() || '0'),
                  })}
                </Value>
              </div>

              <div>
                <Label as="p">{t('hardwareInformation.bateryPowerRating')}</Label>
                <Value as="p">{`${battery.power_rating} kW`}</Value>
              </div>

              <div>
                <Label as="p">{t('hardwareInformation.batteryCount')}</Label>
                <Value as="p">{battery.count}</Value>
              </div>

              <div>
                <Label as="p">{t('hardwareInformation.batteryManufacturer')}</Label>
                <Value as="p">{battery.manufacturer || ''}</Value>
              </div>
            </SectionDetails>
          </div>
        ))}
      </BatteryDetailsWrapper>
    </div>
  );
}

export default HardwareInfo;
