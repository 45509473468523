import { Sizes } from 'shared/design-system/theme/grid';
import { TooltipPosition } from '../components/Charts/Tooltip/ToolTip';

interface TooltipPositionProps {
  screenSize: Sizes;
  dataPosition: TooltipPosition;
  chartType: 'area' | 'bar';
}

type CoordinateModifiers = Record<Sizes, TooltipPosition>;

export const TOOLTIP_STANDARD_CHARS = 8;

export const getTooltipPosition = ({
  screenSize,
  dataPosition,
  chartType,
}: TooltipPositionProps): TooltipPosition & { digitOffset: number } => {
  const { x: currentX, y: currentY } = dataPosition;

  let additionalDigitOffset = 9;

  /** Determine default tooltip positioning */
  const defaultPosition = chartType === 'area' ? { x: -108, y: -30 } : { x: -116, y: -30 };

  /**
   * Tooltip is repositioned depending on how close it gets to a particular
   * edge of the graph (x = 0, y = 0 is the top left corner)
   */
  const maxCoordinates: CoordinateModifiers =
    chartType === 'area'
      ? {
          xl: { x: 808, y: 374 },
          lg: { x: 808, y: 374 },
          md: { x: 685, y: 320 },
          sm: { x: 285, y: 215 },
        }
      : {
          xl: { x: 808, y: 374 },
          lg: { x: 808, y: 374 },
          md: { x: 690, y: 350 },
          sm: { x: 326, y: 224 },
        };

  /** Determine how far to nudge the tooltip box when it approaches a chart boundary */
  const boundaryOffsetValues: CoordinateModifiers =
    chartType === 'area'
      ? {
          xl: { x: 128, y: 20 },
          lg: { x: 128, y: 20 },
          md: { x: 128, y: 15 },
          sm: { x: 128, y: 15 },
        }
      : {
          xl: { x: 134, y: 20 },
          lg: { x: 134, y: 20 },
          md: { x: 134, y: 15 },
          sm: { x: 134, y: 15 },
        };

  const tooltipPosition = {
    x: currentX ? currentX + defaultPosition.x : currentX,
    y: currentY ? currentY + defaultPosition.y : currentY,
  };

  // tooltip moves to other side if too close to left chart boundary
  const xLowThreshold = chartType === 'area' ? 32 : 20;
  if (tooltipPosition.x && tooltipPosition.x < xLowThreshold) {
    tooltipPosition.x += boundaryOffsetValues[screenSize].x;
    // don't need to account for additional digits here since the tooltip box expands to the right
    additionalDigitOffset = 0;
  }

  // tooltip moves above or below normal position if too close to y-axis chart boundary
  if (tooltipPosition.y) {
    const yLowThreshold = chartType === 'area' ? 30 : 50;

    if (tooltipPosition.y < yLowThreshold) {
      tooltipPosition.y += boundaryOffsetValues[screenSize].y;
    } else if (tooltipPosition.y > maxCoordinates[screenSize].y - 50) {
      tooltipPosition.y -= boundaryOffsetValues[screenSize].y;
    }
  }

  return { ...tooltipPosition, digitOffset: additionalDigitOffset };
};

export default getTooltipPosition;
