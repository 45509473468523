import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const NavigationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 42px 0 32px;

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      width: calc(100% + 10px);
      background: ${theme.colors.backgroundSecondaryGrey05};
    }

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      padding-top: 12px;
      height: 74px;
      width: 100%;
      background: ${theme.colors.cardElevationMidWhite};
      box-shadow: ${theme.elevations.midInverse};
    }

    @media screen and (max-width: ${theme.breakpoints.small}) {
      height: 66px;
      padding-top: 8px;
    }
  `}
`;

export const LogoLink = styled(Link)`
  width: 156px;
  height: auto;

  margin-top: 52px;
  margin-bottom: 88px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 44px;
    margin-bottom: 80px;
  }
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;

  button {
    justify-content: flex-start;
    margin-bottom: 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    button {
      flex-direction: column;
      height: fit-content;
      margin-bottom: 0;
      div {
        margin: 0;
      }
    }
  }
`;
