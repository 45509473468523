import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyBold, BodyRegular } from 'legacy/components/common/design-system/Typography';

export function ModalBodyError() {
  const { t } = useTranslation();

  const textArray: string[] = t('modal.error', {
    returnObjects: true,
  });

  return (
    <>
      {textArray.map((item, idx) => {
        if (idx === 0) return <BodyBold $margin="16px 0">{item}</BodyBold>;
        return <BodyRegular>{item}</BodyRegular>;
      })}
    </>
  );
}

export default ModalBodyError;
