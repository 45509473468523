import styled from 'styled-components';
import { MOBILE_MAX_WIDTH } from '../../../constants';

export type GridProps = {
  $columns?: number;
  $gap?: string;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ $columns = 2 }) => `repeat(${$columns}, 1fr)`};
  gap: ${({ $gap = '16px' }) => $gap};
  margin-bottom: 16px;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
