import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserDetailView from 'legacy/views/admin/UserDetailView';
import NoMatch from 'legacy/views/noMatch/NoMatch';
import pages from 'pages';
import ProtectedRoute from 'legacy/ProtectedRoute';
import { useUser } from 'hooks/useUser';
import { getFAQs, getTags } from 'api/faqs';
import { AxiosError } from 'axios';
import BackfillWorkflows from 'legacy/components/admin/BackfillWorkflows';
import FaqsView from 'legacy/views/admin/FaqsView';
import TagsView from 'legacy/views/admin/TagsView';
import AdminTableView from 'legacy/views/admin/AdminTableView';
import MonitoringDetailView from 'legacy/views/admin/MonitoringDetailView';
import StatisticsView from 'legacy/views/admin/StatisticsView';
import { AdminLayout } from 'legacy/views/admin/AdminLayout';
import AuthCopyAckView from 'legacy/views/admin/AuthCopyAckView';
import AgreementTableView from 'legacy/views/admin/AgreementTableView';
import Monitoring from 'legacy/components/admin/monitoring/Monitoring';
import { AdminContext } from 'contexts/AdminContext';
import { USER_ROLES } from 'legacy/constants';
import { isHomeowner, isSupport } from 'legacy/utils';

export function Admin() {
  const { setTags, setFaqs } = useContext(AdminContext);
  const { userResult, refetch } = useUser();

  const user = userResult?.user;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const faqsResponse = await getFAQs();
        setFaqs(faqsResponse.data);

        if (
          userResult?.user?.role === USER_ROLES.ADMIN ||
          userResult?.user?.role === USER_ROLES.MARKETING ||
          userResult?.user?.role === USER_ROLES.SUPER_ADMIN
        ) {
          const tagsResponse = await getTags();
          setTags(tagsResponse.data);
        }
      } catch (error) {
        if ((error as AxiosError).response && (error as AxiosError).response?.status === 401) {
          refetch();
        }
      }
    };

    fetchData();
  }, [refetch, setFaqs, setTags, userResult]);

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={!isHomeowner(user?.role)}
            redirectPath={`/${pages.DASHBOARD}`}
            hasSession
            isLoading={userResult?.loading}
          />
        }
      >
        <Route element={<AdminLayout user={user} />}>
          <Route
            element={
              <ProtectedRoute isAllowed={isSupport(user?.role)} redirectPath={pages.MANAGE_FAQS} />
            }
          >
            <Route path={pages.USER} element={<UserDetailView />} />
          </Route>
          <Route
            index
            element={<Navigate to={isSupport(user?.role) ? pages.AGREEMENTS : pages.MANAGE_FAQS} />}
          />

          <Route path={pages.STATISTICS} element={<StatisticsView />} />
          <Route path={pages.ADMINS} element={<AdminTableView />} />
          <Route path={pages.MANAGE_FAQS} element={<FaqsView />} />
          <Route path={pages.TAGS} element={<TagsView />} />
          <Route path={pages.MONITORING_DETAIL} element={<MonitoringDetailView />} />
          <Route path={pages.MONITORING} element={<Monitoring />} />
          <Route path={pages.AUTH_COPY_ACK} element={<AuthCopyAckView />} />
          <Route path={pages.AGREEMENTS} element={<AgreementTableView />} />
          <Route path={pages.BACKFILL} element={<BackfillWorkflows />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Admin;
