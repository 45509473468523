import React, { useState } from 'react';
import axios from 'axios';
import { Accordion } from 'legacy/components/common/design-system';
import { Address } from 'legacy/components/common/myeverbright';
import { AccountType, MessageType } from 'legacy/types';
import routes from 'routes';
import AgreementSection from './AgreementSection';
import AddAgreement from '../AddAgreement';

import InfoCard from './InfoCard';
import { AdminAccordionWrapper } from './UserDetailStyles';

type Props = {
  account: AccountType;
  refreshUser: () => void;
};

export default function AccountSection({ account, refreshUser }: Props) {
  const [message, setMessage] = useState<MessageType | null>(null);

  const infoList = [
    { label: 'Home Owner Account ID ', value: account.homeowner_account_id, id: 'as1' },
    {
      label: 'Address',
      value: <Address address={account.address} />,
      id: 'as2',
    },
    { label: 'Archived', value: account.archived.toString(), id: 'as6' },
  ];

  const toggleArchived = (e: React.SyntheticEvent) => {
    e.preventDefault();
    axios
      .patch(routes.account(account.id), { archived: !account.archived })
      .then(() => {
        refreshUser();
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  };

  const content = (
    <InfoCard
      items={infoList}
      archived={account.archived}
      enableArchive
      message={message}
      onChangeArchived={toggleArchived}
    >
      <>
        {account.agreements.map((agreement) => (
          <AgreementSection key={agreement.id} agreement={agreement} refreshUser={refreshUser} />
        ))}
        <AddAgreement accountId={account.id} addAgreement={refreshUser} />
      </>
    </InfoCard>
  );

  return (
    <AdminAccordionWrapper>
      <Accordion
        id="admin-accordion"
        styleVariant="secondary"
        contentBgColor={account.archived ? '#dbdbdb' : 'inherit'}
        isOpen
        items={[
          {
            label: `Account #${account.homeowner_account_id} ${
              account.archived ? '(archived)' : ''
            }`,
            content,
          },
        ]}
      />
    </AdminAccordionWrapper>
  );
}
