import React from 'react';
import colors from 'theme/colors';
import { IconProps } from './types';

export function DownloadIcon({ color = colors.secondaryBlue }: IconProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 16H4.5C2.84312 16 1.5 14.6569 1.5 13V10.75C1.5 10.3344 1.83594 10 2.25 10C2.66438 10 3 10.3359 3 10.75V13C3 13.825 3.675 14.5 4.5 14.5H12.5C13.325 14.5 14 13.825 14 13V10.75C14 10.3359 14.3359 10 14.75 10C15.1644 10 15.5 10.3359 15.5 10.75V13C15.5 14.6562 14.1562 16 12.5 16ZM9.0125 10.7969L13.2625 6.79688C13.5652 6.51269 13.5787 6.03813 13.2947 5.73719C13.0115 5.43544 12.5369 5.42094 12.235 5.70497L9.25 8.5125V0.75C9.25 0.335938 8.91563 0 8.5 0C8.08437 0 7.75 0.335938 7.75 0.75V8.5125L4.7625 5.70312C4.46187 5.41875 3.98719 5.43438 3.70406 5.7375C3.5675 5.88125 3.5 6.06563 3.5 6.25C3.5 6.44922 3.57909 6.64844 3.73631 6.79594L7.98631 10.7959C8.275 11.0688 8.725 11.0688 9.0125 10.7969Z"
        fill={color}
      />
    </svg>
  );
}

export default DownloadIcon;
