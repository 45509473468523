import React from 'react';
import { Outlet } from 'react-router-dom';
import pages from 'pages';
import { UserType } from 'legacy/types';
import { isAdmin, isMarketing, isSupport } from 'legacy/utils';
import { Column } from 'legacy/components/common/myeverbright';
import { Container, MenuItem, Menu } from './AdminStyles';

type Props = {
  user?: UserType | null;
};

export function AdminLayout({ user }: Props) {
  return (
    <Container>
      <Column>
        <h1>Admin Dashboard</h1>
        {user && (
          <Column>
            <Menu>
              {isSupport(user.role) && <MenuItem to={pages.AGREEMENTS}>Agreements</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.ADMINS}>Admins</MenuItem>}
              <MenuItem to={pages.STATISTICS}>Statistics</MenuItem>
              {isMarketing(user.role) && <MenuItem to={pages.MANAGE_FAQS}>FAQs</MenuItem>}
              {isMarketing(user.role) && <MenuItem to={pages.TAGS}>Tags</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.MONITORING}>Monitoring</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.AUTH_COPY_ACK}>Auth Copy Ack</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.BACKFILL}>Backfill</MenuItem>}
            </Menu>
            <Outlet />
          </Column>
        )}
      </Column>
    </Container>
  );
}

export default AdminLayout;
