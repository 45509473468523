import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadContract } from 'api/agreement';
import { AgreementType, MilestoneType, WelcomeChecklistStatusType } from 'legacy/types';
import { Button } from 'legacy/components/common/design-system';
import { DownloadIcon } from 'legacy/components/common/design-system/Icons';
import { BodyBold, BodyRegular } from 'legacy/components/common/design-system/Typography';
import { ButtonFooter } from './styles';
import { ErrorText } from '../WelcomeChecklist/WelcomeChecklistStyles';

function ProjectQualificationCopy({
  welcomeChecklistStatus,
  showContent,
}: {
  welcomeChecklistStatus: WelcomeChecklistStatusType;
  showContent: boolean;
}) {
  const { t } = useTranslation();

  const getCopy = (selection: string) =>
    `statusAccordionCopy.projectQualification.copy.${selection}`;

  const intro = (
    <>
      {welcomeChecklistStatus === WelcomeChecklistStatusType.REQUIRED ? (
        <strong>{t(getCopy('intro2'))}</strong>
      ) : (
        <span>{t(getCopy('intro2'))}</span>
      )}
      <span>{t(getCopy('intro3'))}</span>
    </>
  );

  const body = (
    <>
      <BodyRegular>{t(getCopy('body'))}</BodyRegular>
      <ul>
        {(t(getCopy('bodyList'), { returnObjects: true }) as string[]).map((item: string) => (
          <li key={item}>
            <BodyRegular>{item}</BodyRegular>
          </li>
        ))}
      </ul>
      <BodyRegular>{t(getCopy('congrats'))}</BodyRegular>
    </>
  );

  let conditionalContent: React.ReactNode | null = null;

  if (welcomeChecklistStatus === WelcomeChecklistStatusType.PASSED) {
    conditionalContent = <BodyBold>{t(getCopy('completeChecklist'))}</BodyBold>;
  }

  if (welcomeChecklistStatus === WelcomeChecklistStatusType.FAILED) {
    if (showContent)
      conditionalContent = (
        <BodyRegular>
          <strong>{t(getCopy('contactSupport1'))}</strong>
          <span>{t(getCopy('contactSupport2'))}</span>
        </BodyRegular>
      );
  }

  return (
    <div>
      <BodyRegular>
        <span>{t(getCopy('intro1'))}</span>
        {showContent && !conditionalContent && intro}
      </BodyRegular>
      {conditionalContent}
      {body}
    </div>
  );
}

export type ProjectQualificationContentProps = {
  isLoadingWelcomeChecklistQuestions: boolean;
  agreement?: AgreementType;
  onOpenWelcomeChecklist: () => void;
};

export default function ProjectQualificationContent({
  isLoadingWelcomeChecklistQuestions,
  agreement,
  onOpenWelcomeChecklist,
}: ProjectQualificationContentProps) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleDownload = async () => {
    setMessage('');

    if (agreement) {
      setLoading(true);
      downloadContract(agreement.id)
        .then((response) => {
          const { presigned_url: presignedUrl } = response.data;
          const link = document.createElement('a');
          link.href = presignedUrl;
          link.download = 'signed_everbright_contract.pdf';
          link.click();
          link.remove();
        })
        .catch(() => {
          setMessage('Something went wrong. Please try again later, or contact support.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMessage('Something went wrong. Please try again later, or contact support.');
    }
  };

  const shouldShowContent: boolean = useMemo(
    () =>
      !!agreement &&
      agreement.current_milestone === MilestoneType.CONTRACT_SIGNED &&
      agreement.welcome_checklist_status !== WelcomeChecklistStatusType.NOT_REQUIRED,
    [agreement],
  );

  const handleOpenWelcomeChecklist = () => {
    setMessage('');
    onOpenWelcomeChecklist();
  };

  return agreement ? (
    <div>
      <ProjectQualificationCopy
        welcomeChecklistStatus={agreement.welcome_checklist_status}
        showContent={shouldShowContent}
      />
      {shouldShowContent &&
        agreement.welcome_checklist_status === WelcomeChecklistStatusType.REQUIRED && (
          <>
            <ButtonFooter>
              <Button
                onClick={handleDownload}
                label="Contract"
                iconRight
                Icon={DownloadIcon}
                styleVariant="tertiary"
                margin="0 0 0 auto"
                isLoading={loading}
              />

              <Button
                label="Complete Checklist"
                onClick={handleOpenWelcomeChecklist}
                margin="0 0 0 20px"
                isLoading={isLoadingWelcomeChecklistQuestions}
              />
            </ButtonFooter>
            {message && <ErrorText>{message}</ErrorText>}
          </>
        )}
    </div>
  ) : (
    <div />
  );
}
