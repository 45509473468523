import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { AccountType } from 'api/types';

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  const { userResult, setUserResult } = context;
  const selectedAccount = userResult?.selectedAccount;
  const setSelectedAccount = (account: AccountType | undefined) =>
    setUserResult({
      user: userResult ? userResult.user : null,
      impersonator: userResult?.impersonator,
      error: userResult?.error ? userResult.error : '',
      loading: userResult?.loading,
      selectedAccount: account,
    });

  return {
    selectedAccount,
    setSelectedAccount,
    ...context,
  };
};

export default useUser;
