/** Compares local timezone offset to offset from the provided timezone to return
 * a value that can be used to reconcile local/system times
 */
export const getSystemTimezoneConversion = (systemTimezone: string) => {
  const date = new Date();
  const { locale } = Intl.DateTimeFormat().resolvedOptions();
  const utcDate = new Date(date.toLocaleString(locale, { timeZone: 'UTC' }));
  const systemDate = new Date(date.toLocaleString(locale, { timeZone: systemTimezone }));
  const systemTimezoneOffset = (utcDate.getTime() - systemDate.getTime()) / 6e4;
  return date.getTimezoneOffset() - systemTimezoneOffset;
};

export default getSystemTimezoneConversion;
