import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function AccountIcon({ color: colorProp, filled }: IIconProps & { filled: boolean }) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9C9.19347 9 10.3381 8.52589 11.182 7.68198C12.0259 6.83807 12.5 5.69347 12.5 4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5C3.5 5.69347 3.97411 6.83807 4.81802 7.68198C5.66193 8.52589 6.80653 9 8 9ZM6.39336 10.6875C2.93047 10.6875 0.125 13.493 0.125 16.9559C0.125 17.5324 0.592578 18 1.16914 18H14.8309C15.4074 18 15.875 17.5324 15.875 16.9559C15.875 13.493 13.0695 10.6875 9.60664 10.6875H6.39336Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.02783 9C11.5134 9 13.5278 6.9852 13.5278 4.5C13.5278 2.0148 11.5134 0 9.02783 0C6.54229 0 4.52783 2.0148 4.52783 4.5C4.52783 6.9852 6.54229 9 9.02783 9ZM9.02783 1.125C10.889 1.125 12.4028 2.63883 12.4028 4.5C12.4028 6.36082 10.889 7.875 9.02783 7.875C7.16666 7.875 5.65283 6.35977 5.65283 4.5C5.65283 2.63883 7.16807 1.125 9.02783 1.125ZM10.8103 10.6875H7.24541C3.88131 10.6875 1.15283 13.4156 1.15283 16.7801C1.15283 17.453 1.69846 17.9989 2.37135 17.9989H15.685C16.3579 18 16.9028 17.4551 16.9028 16.7801C16.9028 13.4156 14.1747 10.6875 10.8103 10.6875ZM15.6829 16.875H2.37135C2.32002 16.875 2.27783 16.8328 2.27783 16.7801C2.27783 14.0414 4.50674 11.8125 7.24541 11.8125H10.8067C13.5489 11.8125 15.7778 14.0414 15.7778 16.7801C15.7778 16.8328 15.7356 16.875 15.6829 16.875Z"
        fill={color}
      />
    </svg>
  );
}

export default AccountIcon;
