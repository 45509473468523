import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, XIcon } from 'shared/components/icons';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { useUser } from 'hooks/useUser';
import { generateRandomString } from 'shared/utils/generateRandomString';
import { LanguageCodeKey, WelcomeChecklistItemType } from 'modules/types';
import { Text } from 'shared/components/Text/Text';
import { useTheme } from 'styled-components';
import {
  IconWrapper,
  MessageWrapper,
  ReviewContainer,
  ReviewItem,
  ReviewPageSubTitle,
} from './WelcomeChecklist.styles';

type Props = {
  checklist: WelcomeChecklistItemType[];
  onChangeConfirmed: () => void;
  confirmed: boolean;
  showAckRequired: boolean;
  message: React.ReactNode;
  primaryLanguage: LanguageCodeKey;
};

export function ReviewAnswers({
  checklist,
  confirmed,
  showAckRequired,
  onChangeConfirmed,
  message,
  primaryLanguage,
}: Props) {
  const { userResult } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();

  const name = `${userResult?.user?.first_name} ${userResult?.user?.last_name}`;

  return (
    <ReviewContainer>
      {message ? (
        <MessageWrapper>{message}</MessageWrapper>
      ) : (
        <>
          <ReviewPageSubTitle as="body">
            {t('welcomeChecklist.reviewPageSubTitle')}
          </ReviewPageSubTitle>
          {checklist.map((item) => {
            const primaryLanguageQuestion = item[`question_${primaryLanguage}`];
            const [answeredYes, answeredNo] = [
              item.answer === t('welcomeChecklist.yes'),
              item.answer === t('welcomeChecklist.no'),
            ];

            return (
              <ReviewItem $boldText={answeredNo} key={generateRandomString()}>
                <IconWrapper $isXIcon={!answeredYes}>
                  {answeredYes ? (
                    <CheckIcon color={theme.colors.iconSystemIconSuccessPrimary2100} size="large" />
                  ) : (
                    <XIcon color={theme.colors.strokeSystemErrorFunction1100} />
                  )}
                </IconWrapper>
                <Text as="body" color={theme.colors.textPrimarySecondary1100}>
                  {primaryLanguageQuestion}
                </Text>
              </ReviewItem>
            );
          })}
          <Checkbox
            isChecked={confirmed}
            isRequired={showAckRequired}
            error={showAckRequired}
            hint={showAckRequired ? '*Required' : ''}
            onChange={onChangeConfirmed}
            text={t('welcomeChecklist.acknowledgeStatement', { name })}
            margin="16px 0 8px"
          />
        </>
      )}
    </ReviewContainer>
  );
}

export default ReviewAnswers;
