import React, { useState } from 'react';
import { Button, TextInput } from 'legacy/components/common/design-system';
import { Column, Message } from 'legacy/components/common/myeverbright';

import axios from 'axios';
import { routes } from '../../../routes';
import { Container, InnerContainer } from './AuthStyles';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [, setEmailValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    axios
      .post(routes.forgotPassword, { email })
      .then(() => {
        setSubmitted(true);
      })
      .catch(() => {
        setErrorMessage('Our system did not respond. Please try again.');
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailValid(e.target.checkValidity());
  };

  return (
    <Container>
      <InnerContainer>
        <form onSubmit={handleSubmit}>
          {submitted ? (
            <Column $alignItems="center">
              <Column>
                <h2>Congrats!</h2>
                <p>If there is an account on file, you will receive an email in your inbox.</p>
                <p>
                  If you don&apos;t receive an email, please contact customer support using the chat
                  below.
                </p>
              </Column>

              <Button type="submit" label="Resend Email" />

              <div
                role="button"
                className="link-like"
                tabIndex={0}
                onClick={() => setSubmitted(false)}
                onKeyDown={() => setSubmitted(false)}
              >
                Re-enter your email address
              </div>
            </Column>
          ) : (
            <>
              <h3 data-testid="forgot-pswd-header">Reset Your Password</h3>
              <p>
                Enter the email address associated with your account to receive an email with a link
                to reset your password.
              </p>
              <p>This may take up to 5 minutes.</p>
              {errorMessage && <Message severity="error">{errorMessage}</Message>}
              <TextInput
                ariaLabel="reset email"
                label="Email Address"
                type="email"
                onChange={handleChange}
              />
              <Button type="submit" label="Send Email" margin="24px auto 0" />
            </>
          )}
        </form>
      </InnerContainer>
    </Container>
  );
}

export default ForgotPassword;
