import styled from 'styled-components';
import colors, { ColorsKey } from 'theme/colors';

type CardProps = {
  $bgColor?: ColorsKey;
  $hideShadow?: boolean;
  $textColor?: ColorsKey;
  $fullWidth?: boolean;
  $fullHeight?: boolean;
};

export const Card = styled.div<CardProps>`
  ${({
    $bgColor = 'white',
    $hideShadow,
    $textColor = 'black',
    $fullWidth,
    $fullHeight = false,
  }) => `
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px 32px;
  position: relative;
  height: ${$fullHeight ? '100%' : 'fit-content'};
  max-width: ${$fullWidth ? 'unset' : '487px'};
  background:${colors[$bgColor]};
  box-shadow: ${$hideShadow ? 'unset' : '0 15px 30px rgba(112, 144, 176, 0.1)'};
  color: ${colors[$textColor]}
`}
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const ManageFAQCard = styled(Card)`
  // TODO: remove classnames once less is removed
  padding-top: 0;

  &.hidden {
    background: ${colors.gray10};
    color: ${colors.gray80};
    p,
    h4 {
      color: ${colors.gray80};
    }

    .tags {
      .tag {
        border-color: ${colors.gray40};
      }
    }
  }

  .order {
    padding: 12px;
    background: ${colors.gray10};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: -32px;
    margin-right: -32px;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;

    &.hidden {
      background: ${colors.gray40};
      color: ${colors.gray10};

      .edit {
        color: ${colors.gray10};
      }
    }
  }
`;
