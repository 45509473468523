import React from 'react';
import { colors } from 'theme/colors';
import { IcoProps } from './types';

export function CalendarIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.52476 0C3.79976 0 4.02476 0.223875 4.02476 0.5V2H10.0248V0.5C10.0248 0.223875 10.2498 0 10.5248 0C10.7998 0 11.0248 0.223875 11.0248 0.5V2H12.0248C13.1279 2 14.0248 2.89531 14.0248 4V14C14.0248 15.1031 13.1279 16 12.0248 16H2.02476C0.92007 16 0.0247579 15.1031 0.0247579 14V4C0.0247579 2.89531 0.92007 2 2.02476 2H3.02476V0.5C3.02476 0.223875 3.24976 0 3.52476 0ZM13.0248 6H1.02476V14C1.02476 14.5531 1.47257 15 2.02476 15H12.0248C12.5779 15 13.0248 14.5531 13.0248 14V6ZM12.0248 3H2.02476C1.47257 3 1.02476 3.44688 1.02476 4V5H13.0248V4C13.0248 3.44688 12.5779 3 12.0248 3Z"
        fill={color}
      />
    </svg>
  );
}

export default CalendarIcon;
