import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LINKS } from 'shared/links';
import { pages } from 'pages';
import { Header } from 'modules/auth/components/Header/Header';
import { AuthBox, AuthWrapper, BodyText, LinkStyled, LoginFooter } from '../../Auth.styles';
import { LoginForm } from '../../components/LoginForm/LoginForm';

export function Login() {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('loginScreen.headerOne')}
          titleBold={t('loginScreen.headerTwo')}
          showLogo
          headerAlwaysCentered
        />

        <BodyText as="p" $margin="0 0 20px">
          <Trans
            i18nKey="loginScreen.firstTimeLoggingIn"
            components={{
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              a: <LinkStyled to={`/${pages.REGISTRATION}`} />,
            }}
          />
        </BodyText>
        <LoginForm />
      </AuthBox>
      <LoginFooter>
        {t('loginScreen.currentPartner')}{' '}
        <a href={LINKS.MYEVERBRIGHT_PARTNER_LOGIN}>{t('loginScreen.logInHere')}</a>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default Login;
