import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Card } from 'legacy/components/common/myeverbright';
import routes from 'routes';
import { TagType } from 'legacy/types';
import TagForm from 'legacy/components/forms/TagForm';
import { AdminContext } from 'contexts/AdminContext';

function Tag({
  tag,
  updateTag,
}: {
  tag: TagType;
  updateTag: (tag: TagType, tagValue: string) => Promise<void>;
}) {
  const [editing, setEditing] = useState(false);

  return (
    <Card $fullWidth>
      <h4>
        {editing ? '' : tag.tag}
        <div
          className="pull-right edit"
          role="button"
          tabIndex={0}
          onClick={() => setEditing(!editing)}
          onKeyDown={() => setEditing(!editing)}
        >
          {editing ? 'Close' : 'Edit'}
        </div>
      </h4>
      {editing && (
        <TagForm
          tag={tag}
          onSubmit={(tagValue: string) => updateTag(tag, tagValue).then(() => setEditing(false))}
        />
      )}
    </Card>
  );
}

function TagsView() {
  const { tags, setTags } = useContext(AdminContext);

  const [adding, setAdding] = useState(false);

  const createTag = async (tagValue: string) =>
    axios.post(routes.tags, { tag: tagValue }).then((response) => {
      if (tags) {
        setTags([...tags, response.data]);
      } else {
        setTags([response.data]);
      }
      setAdding(false);
    });

  const updateTag = async (tag: TagType, tagValue: string) =>
    axios.patch(`${routes.tags + tag.id}/`, { tag: tagValue }).then((response) => {
      if (tags && tag) {
        const tempTags = [...tags];
        const idx = tags.indexOf(tag);
        tempTags[idx] = response.data;
        setTags(tempTags);
      }
    });

  return (
    <>
      {tags.map((tag) => (
        <Tag tag={tag} key={tag.id} updateTag={updateTag} />
      ))}

      {adding ? (
        <Card $fullWidth>
          <h3>
            Add Tag
            <div
              className="pull-right edit"
              role="button"
              tabIndex={0}
              onClick={() => setAdding(!adding)}
              onKeyDown={() => setAdding(!adding)}
            >
              Close
            </div>
          </h3>
          <TagForm tag={null} onSubmit={createTag} />
        </Card>
      ) : (
        <h3>
          <div
            className="edit"
            role="button"
            tabIndex={0}
            onClick={() => setAdding(!adding)}
            onKeyDown={() => setAdding(!adding)}
          >
            + Add Tag
          </div>
        </h3>
      )}
    </>
  );
}

export default TagsView;
