import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function CircleExclamationIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM8 15C4.14062 15 1 11.8594 1 8C1 4.14062 4.14062 1 8 1C11.8594 1 15 4.14062 15 8C15 11.8594 11.8594 15 8 15ZM8 9.5C8.27637 9.5 8.5 9.27637 8.5 9V4C8.5 3.72362 8.27637 3.5 8 3.5C7.72363 3.5 7.5 3.725 7.5 4V9C7.5 9.275 7.725 9.5 8 9.5ZM8 10.75C7.58594 10.75 7.25 11.0859 7.25 11.5C7.25 11.9141 7.58594 12.25 8 12.25C8.41406 12.25 8.75 11.9141 8.75 11.5C8.75 11.0859 8.41562 10.75 8 10.75Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default {};
