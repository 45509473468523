import React from 'react';
import { Button } from 'legacy/components/common/design-system';
import pages from 'pages';
import { Link, useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';
import SVG404 from '../../../static/404.svg';

import {
  BodyText,
  BodyWrapper,
  Container,
  LinkWrapper,
  PrimaryText,
  SVGImage,
} from './NoMatchStyles';

function NoMatch() {
  const navigate = useNavigate();
  const { userResult } = useUser();

  const backToText = userResult?.user ? 'Back to Dashboard' : 'Back to Login';
  const backToLink = userResult?.user ? `/${pages.DASHBOARD}` : `/${pages.LOGIN}`;

  const handleClick = () => {
    navigate(backToLink, { replace: true });
  };

  return (
    <Container>
      <LinkWrapper>
        <Link to={backToLink}>&lt; {backToText}</Link>
      </LinkWrapper>
      <SVGImage alt="404 image" src={SVG404} />
      <BodyWrapper>
        <PrimaryText>Sorry, we couldn&rsquo;t find that page.</PrimaryText>
        <BodyText>
          The page you are looking for has been removed, renamed or is temporarily unavailable.
          Please navigate back to your Dashboard.
        </BodyText>
        <Button label={backToText} margin="24px 0" onClick={handleClick} />
      </BodyWrapper>
    </Container>
  );
}

export default NoMatch;
