import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function CircleQuestionIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 12C23 9.21523 21.8938 6.54451 19.9246 4.57538C17.9555 2.60625 15.2848 1.5 12.5 1.5C9.71523 1.5 7.04451 2.60625 5.07538 4.57538C3.10625 6.54451 2 9.21523 2 12C2 14.7848 3.10625 17.4555 5.07538 19.4246C7.04451 21.3938 9.71523 22.5 12.5 22.5C15.2848 22.5 17.9555 21.3938 19.9246 19.4246C21.8938 17.4555 23 14.7848 23 12ZM0.5 12C0.5 8.8174 1.76428 5.76516 4.01472 3.51472C6.26516 1.26428 9.3174 0 12.5 0C15.6826 0 18.7348 1.26428 20.9853 3.51472C23.2357 5.76516 24.5 8.8174 24.5 12C24.5 15.1826 23.2357 18.2348 20.9853 20.4853C18.7348 22.7357 15.6826 24 12.5 24C9.3174 24 6.26516 22.7357 4.01472 20.4853C1.76428 18.2348 0.5 15.1826 0.5 12ZM8.40781 7.95938C8.7125 6.80625 9.75312 6 10.9484 6H13.625C15.2891 6 16.625 7.35938 16.625 9.01406C16.625 10.1391 15.9969 11.1797 14.9891 11.6953L13.25 12.5766V13.5C13.25 13.9125 12.9125 14.25 12.5 14.25C12.0875 14.25 11.75 13.9125 11.75 13.5V12.1172C11.75 11.8359 11.9094 11.5781 12.1578 11.4469L14.3047 10.35C14.8062 10.0969 15.125 9.57188 15.125 9.00469C15.125 8.17031 14.45 7.49063 13.625 7.49063H10.9484C10.4375 7.49063 9.9875 7.8375 9.86094 8.32969L9.85156 8.3625C9.74844 8.76094 9.33594 9.00469 8.9375 8.89688C8.53906 8.78906 8.29531 8.38125 8.40313 7.98281L8.4125 7.95L8.40781 7.95938ZM11.375 16.5C11.375 16.2016 11.4935 15.9155 11.7045 15.7045C11.9155 15.4935 12.2016 15.375 12.5 15.375C12.7984 15.375 13.0845 15.4935 13.2955 15.7045C13.5065 15.9155 13.625 16.2016 13.625 16.5C13.625 16.7984 13.5065 17.0845 13.2955 17.2955C13.0845 17.5065 12.7984 17.625 12.5 17.625C12.2016 17.625 11.9155 17.5065 11.7045 17.2955C11.4935 17.0845 11.375 16.7984 11.375 16.5Z"
        fill={color}
      />
    </svg>
  );
}

export default CircleQuestionIcon;
