export const handleChatDisplay = (display: string) => {
  /* Targets the outer ID for the NICE/CXone Support Chat */
  const chat = document.getElementById('be-chat-container');

  if (chat) {
    chat.style.display = display;
  }
};

export default handleChatDisplay;
