import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function ArrowDownIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.251 8.80761L11.7934 15.5574C11.5063 15.8137 11.0758 15.8137 10.7885 15.5574L3.24795 8.80761C2.94046 8.48887 2.91562 8.05763 3.19217 7.74827C3.48653 7.42484 3.96089 7.42953 4.25291 7.69202L11.2497 13.9918L18.2479 7.69202C18.557 7.41655 19.0302 7.44073 19.3082 7.74771C19.5838 8.05763 19.5604 8.48887 19.251 8.80761Z"
        fill={color || theme.colors.textPrimarySecondary1100}
      />
    </svg>
  );
}

export default ArrowDownIcon;
