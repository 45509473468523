import React, { useMemo } from 'react';

import { generateRandomString } from 'shared/utils/generateRandomString';
import { Input, Label, OuterContainer, Box, HintText, Text } from './CheckboxStyles';
import { CheckIcon } from '../icons';

export type TCheckboxProps = {
  dataTestId?: string;
  id?: string;
  isRequired?: boolean;
  forwardRef?: React.MutableRefObject<HTMLInputElement | null>;
  isChecked: boolean;
  margin?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string | React.ReactNode;
  hint?: string;
  error?: boolean;
};

/**
 * @param {string} [dataTestId] - id value for the checkbox used for testing purposes (optional)
 * @param {string} [id] - id value for the checkbox. Used for accessibility purposes. This value should not change. Default provided if none passed as a prop (optional)
 * @param {boolean} [isRequired] - is this input required? Defaults to false (optional)
 * @param {React.MutableRefObject<HTMLInputElement | null>} [forwardRef] - ref object to pass to the component (optional)
 * @param {boolean} isChecked - is the checkbox checked? Defaults to false (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {function} [onChange] - onChange handler (optional)
 * @param {string} [text] - text regarding what checkbox is referring too (optional)
 * @param {string} [hint] - text that appears when user needs a hint to check checkbox (optional)
 * @param {boolean} [error] - did an error occur? defaults to false (optional)
 */

export function Checkbox({
  dataTestId,
  id: idProp,
  isRequired = false,
  isChecked = false,
  forwardRef,
  margin = '0',
  onChange,
  text,
  hint,
  error,
}: TCheckboxProps) {
  const checkboxId = useMemo(() => idProp || generateRandomString(), [idProp]);

  return (
    <OuterContainer $margin={margin}>
      <Label
        aria-describedby={`${checkboxId}-label`}
        id={checkboxId}
        data-testid={dataTestId}
        htmlFor={`${checkboxId}-input`}
      >
        <Input
          required={isRequired}
          aria-required={isRequired}
          ref={forwardRef}
          id={`${checkboxId}-input`}
          aria-checked={isChecked}
          checked={isChecked}
          type="checkbox"
          onChange={onChange}
        />
        <Box>
          <CheckIcon />
        </Box>
      </Label>
      <Text>{text}</Text>
      {hint && <HintText hasError={error}>{hint}</HintText>}
    </OuterContainer>
  );
}

export default Checkbox;
