import { FONT_FAMILY } from 'shared/components/Text/Text';
import styled from 'styled-components';

export const ChartWrapper = styled.div`
  ${({ theme }) => `
    max-width: 832px;
    max-height: 540px;
    border-radius: 24px;
    padding: 32px;
    background: ${theme.colors.backgroundSecondaryGrey05};
    box-shadow: ${theme.elevations.extraSunken};
  
    @media (${theme.breakpoints.small} < width <= ${theme.breakpoints.medium}) {
      padding: 24px;
    }
    @media (width <= ${theme.breakpoints.small}) {
      padding: 16px;
      width: 100%;
      border-radius: 0;
    }
  `}
`;

export const SpinnerContainer = styled.div`
  ${({ theme }) => `
    display: flex;

    @media (width >= ${theme.breakpoints.medium}) {
      width: 768px;
      height: 400px;
    }
    @media (${theme.breakpoints.small} <= width < ${theme.breakpoints.medium}) {
      width: 676px;
      height: 345px;
    }
    @media (width < ${theme.breakpoints.small}) {
      width: 90vw;
      height: 250px;
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.p`
  ${({ theme }) => `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.textPrimarySecondary1100};
    margin-left: 9px;
    font-family: ${FONT_FAMILY};
  `}
`;

export const ToolTipkW = styled.p`
  ${({ theme }) => `
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    white-space: pre-wrap;
    font-family: ${FONT_FAMILY};
    color: ${theme.colors.chartChartBlueSecondary2100};
  `}
`;

export const TooltipTime = styled(ToolTipkW)`
  ${({ theme }) => `
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.textSecondarySubtleGrey80};
  `}
`;

export const ToolTipContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    height: 56px;
    @media (width < ${theme.breakpoints.medium}) {
      height: 52px;
    }
  `}
`;

export const ToolTipTextContainer = styled.div`
  ${({ theme }) => `
    padding: 8px 12px;
    width: fit-content;
    height: fit-content;
    background: ${theme.colors.cardElevationMidWhite};
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.high};
    border-radius: 8px;

    @media (width <= ${theme.breakpoints.medium}) {
      ${TooltipTime} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  `}
`;

export const Line = styled.line`
  ${({ theme }) => `
    stroke: ${theme.colors.componentStrongEmphasisGrey};
    stroke-linecap: round;
  `}
`;
