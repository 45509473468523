export const colors = {
  primary: '#006FC8',
  primaryHover: '#0257A4',
  primaryActive: '#22345B',
  secondary: '#FFF',
  secondaryHover: '#FFF',
  secondaryActive: '#FFF',
  tertiary: 'transparent',
  tertiaryHover: 'transparent',
  tertiaryActive: 'transparent',
  permanentPrimary: '#D80D00',
  permanentPrimaryHover: '#C20C00',
  permanentPrimaryActive: '#970900',
  permanentSecondary: '#FFF',
  permanentSecondaryHover: '#FFF',
  permanentSecondaryActive: '#FFF',
  // primary
  blue: '#0096db',
  green: '#68bb45',
  // secondary
  secondaryDarkBlue: '#22345b',
  secondaryBlue: '#006fc8',
  secondaryGreen: '#a2d43f',
  secondaryLightBlue: '#daebf6',
  // product
  activeStateBlue: '#0257a4',
  red: '#d80d00',
  orange: '#fd8901',
  yellow: '#fbd300',
  // lighter
  blue40: '#66c0e9',
  green40: '#a4d68f',
  yellow40: '#fde566',
  orange40: '#feb967',
  red40: '#e86e66',
  // lightest
  blue20: '#cceaf8',
  green20: '#e1f1da',
  yellow20: '#fef6cc',
  orange20: '#ffe7cc',
  red20: '#f7cfcc',
  // neutrals
  white: '#fff',
  black: '#000',
  gray90: '#464646',
  gray80: '#6a6a6a',
  gray50: '#949494',
  gray40: '#a8a8a8',
  gray20: '#dbdbdb',
  gray10: '#eaeaea',
  gray05: '#fafafa',
} as const;

export type ColorsKey = keyof typeof colors;

export type Color = (typeof colors)[ColorsKey];

export default colors;
