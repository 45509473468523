import React from 'react';
import { TThemeColorsKey } from 'shared/design-system/theme/colors/TThemeColors';
import { StatusPillStyled, StatusText } from './StatusPill.styles';

type StatusPillProps = {
  bgColor?: TThemeColorsKey;
  text: string;
};

/**
 * @param {string} [bgColor] - background color of indicator (defaults to green20)
 * @param {string} [text] - text displayed
 */

export function StatusPill({ bgColor, text }: StatusPillProps) {
  return (
    <StatusPillStyled $bgColor={bgColor}>
      <StatusText as="p">{text}</StatusText>
    </StatusPillStyled>
  );
}

export default StatusPill;
