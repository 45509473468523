import React, { useCallback, useEffect } from 'react';
import { Message } from 'legacy/components/common/myeverbright/Message/Message';
import { Link } from 'react-router-dom';
import pages from 'pages';
import LoginForm from '../../components/forms/LoginForm';
import { useNotifications } from '../../../contexts/Notifications';
import { AccountFooter } from '../../components/AccountFooter/AccountFooter';
import { Container, LinkContainer, LoginContainer } from './AuthStyles';

function Login() {
  const { clearNotifications, notifications } = useNotifications();

  const handleClearNotifications = useCallback(() => {
    clearNotifications();
  }, [clearNotifications]);

  useEffect(
    () => () => {
      handleClearNotifications();
    },
    [handleClearNotifications],
  );

  return (
    <Container>
      <LoginContainer>
        <h3>Welcome to MyEverBright</h3>
        <p>
          Log in to track the status of your installation, manage your payments, access solar system
          details, and view your financing agreement.
        </p>
        {notifications?.map((notification) => (
          <Message key={notification.text} severity={notification.severity}>
            {notification.text}
          </Message>
        ))}

        <LoginForm handleClearNotifications={handleClearNotifications} />
        <LinkContainer>
          <Link to={`/${pages.FORGOT_PASSWORD}`}>Forgot password?</Link>
        </LinkContainer>
      </LoginContainer>
      <AccountFooter />
    </Container>
  );
}

export default Login;
