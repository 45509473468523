import { DateRangeZoomLevel } from 'api/types';

export const getZoomLevelFromLocalTimescale = (
  timescale: string,
  localTimescales: Record<string, string>,
): DateRangeZoomLevel => {
  let zoomLevel;
  Object.entries(localTimescales).forEach(([k, v]) => {
    if (timescale === v) zoomLevel = k as DateRangeZoomLevel;
  });

  if (zoomLevel) {
    return zoomLevel;
  }
  throw new Error(`Unsupported timescale: ${timescale}`);
};

export default getZoomLevelFromLocalTimescale;
