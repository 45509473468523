import React from 'react';
import PerformanceIcon from 'static/performance-icon.svg';
import { noSystemCopy } from 'legacy/copy';
import { Card } from '../Card';
import { Content, Icon, Text } from '../CardStyles';

export function NoSystemCard() {
  return (
    <Card title="System" color="green" fullWidth fullHeight>
      <>
        <Icon alt="System" src={PerformanceIcon} />
        <Content>
          <Text>{noSystemCopy}</Text>
        </Content>
      </>
    </Card>
  );
}

export default NoSystemCard;
