import React from 'react';
import { Accordion } from 'legacy/components/common/design-system';
import { SystemType } from 'legacy/types';
import { formatInverterManufacturer } from 'legacy/utils';
import { Address } from '../../common/myeverbright';
import InfoCard from './InfoCard';
import BatterySection from './BatterySection';
import { AdminAccordionWrapper } from './UserDetailStyles';

type Props = {
  system: SystemType;
};

export default function SystemSection({ system }: Props) {
  const items = [
    { label: 'Panels', value: system.panels || '', id: 'sys1' },
    { label: 'Inverters', value: system.inverters || '', id: 'sys2' },
    { label: 'Capacity', value: system.capacity?.toString() || '', id: 'sys3' },
    { label: 'System Mounting', value: system.system_mounting_type || '', id: 'sys4' },
    { label: 'Estimated Offset', value: system.estimated_offset || '', id: 'sys5' },

    {
      label: 'Inverter Manufacturer',
      value: formatInverterManufacturer(system.inverter_manufacturer_id?.toString()) || '',
      id: 'sys8',
    },
    {
      label: 'Site ID',
      value: system.site_id?.toString() || '',
      id: 'sys9',
    },
    {
      label: 'System Address',
      value: <Address address={system.address} />,
      id: 'sys6',
    },
    { label: 'Archived', value: system.archived.toString(), id: 'us6' },
  ];

  const content = (
    <InfoCard items={items} archived={system.archived} enableArchive={false}>
      <>
        {system.batteries.map((battery) => (
          <BatterySection key={battery.id} battery={battery} />
        ))}
      </>
    </InfoCard>
  );

  return (
    <AdminAccordionWrapper>
      <Accordion
        id="admin-accordion"
        isOpen
        styleVariant="secondary"
        items={[{ label: 'System', content }]}
      />
    </AdminAccordionWrapper>
  );
}
