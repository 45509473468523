import styled from 'styled-components';

export const DisclamerContainer = styled.div`
  display: flex;
  text-align: left;
  margin-bottom: 16px;
`;

export const LinkContainer = styled.a`
  font-family: AvenirLTPro;
  font-style: bold;
  font-weight: 700;
  font-size: 16px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
