import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { SupportCard } from 'legacy/components/OverviewCards/SupportCard';
import { AgreementType } from 'legacy/types';
import routes from 'routes';
import { formatDate, formatDateObj, formatMoney, parseDateOnly } from 'legacy/utils';
import { DocumentsCard } from 'legacy/components/DocumentsCard/DocumentsCard';
import { Card, Column, Row } from 'legacy/components/common/myeverbright';

function Agreement() {
  const [agreement, setAgreement] = useState<AgreementType | null>(null);
  const { agreementId } = useParams();

  useEffect(() => {
    if (agreementId) {
      axios.get(routes.agreement(agreementId)).then((response) => {
        setAgreement(response.data);
      });
    }
  }, [agreementId]);

  return (
    <Column>
      <h1>Agreement Details</h1>
      <Row $collapsing>
        <Column $flex="2">
          <Card $fullWidth>
            <h3>
              {agreement?.product_display_name} #{agreement?.homeowner_contract_id}
            </h3>
            <Row $flexWrap="wrap">
              <Column $gap="0px" $width="30%">
                <div className="label">Signed Date</div>
                <p>{agreement ? formatDate(agreement.contract_signed_date) : ''}</p>
              </Column>
              {Boolean(agreement?.contract_start_date) && (
                <Column $gap="0px" $width="30%">
                  <div className="label">Contract Start Date</div>
                  <p>
                    {agreement ? formatDateObj(parseDateOnly(agreement.contract_start_date)) : ''}
                  </p>
                </Column>
              )}
              <Column $gap="0px" $width="30%">
                <div className="label">Term</div>
                <p>{agreement?.contract_term} years</p>
              </Column>
              {agreement?.is_ric ? (
                <>
                  {Boolean(agreement?.interest_rate) && (
                    <Column $gap="0px" $width="30%">
                      <div className="label">Interest Rate</div>
                      <p>{Number(agreement.interest_rate).toFixed(2)}%</p>
                    </Column>
                  )}
                  {Boolean(agreement?.amount_financed) && (
                    <Column $gap="0px" $width="30%">
                      <div className="label">Amount Financed</div>
                      <p>{formatMoney(agreement.amount_financed || undefined)}</p>
                    </Column>
                  )}
                </>
              ) : (
                <>
                  {Boolean(agreement?.rate_contract) && (
                    <Column $gap="0px" $width="30%">
                      <div className="label">Estimated Solar Rate</div>
                      <p>{formatMoney(agreement?.rate_contract, 3)}/kWh</p>
                    </Column>
                  )}
                  {Boolean(agreement?.rate_escalator) && (
                    <Column $gap="0px" $width="30%">
                      <div className="label">Escalator</div>
                      <p>{agreement?.rate_escalator}%</p>
                    </Column>
                  )}
                </>
              )}
            </Row>
          </Card>
          {agreement && <DocumentsCard agreement={agreement} />}
        </Column>
        <Row $flex="1" $height="fit-content">
          <SupportCard fullHeight />
        </Row>
      </Row>
    </Column>
  );
}

export default Agreement;
