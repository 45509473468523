import styled from 'styled-components';
import { MOBILE_MAX_WIDTH } from '../constants';

export type GridProps = {
  $columns?: number;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ $columns = 3 }) => `repeat(${$columns}, 1fr)`};
  gap: 28px;
  margin: 14px 0;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
