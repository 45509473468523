import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/components/Button/Button';
import { TextInput } from 'shared/components/TextInput/TextInput';
import { BodyText, RegistrationEmailFormStyled } from './RegistrationEmailForm.styles';

export interface RegistrationEmailFormInput {
  email: string;
}

type Props = {
  onSubmitForm: SubmitHandler<RegistrationEmailFormInput>;
};

export function RegistrationEmailForm({ onSubmitForm }: Props) {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationEmailFormInput>();

  return (
    <RegistrationEmailFormStyled onSubmit={handleSubmit(onSubmitForm)}>
      <BodyText as="p">{t('registration.enterProvidedEmail')}</BodyText>

      <TextInput
        id="registration-form-email"
        ariaLabel={t('registration.emailAddress')}
        label={t('registration.emailAddress')}
        hasError={Boolean(errors.email)}
        errorMessage={errors.email?.message}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('email', { required: t('account.required') })}
      />

      <Button
        margin="24px 0 0"
        type="submit"
        label={t('claimAccount.registerAccount')}
        isFullWidth
      />
    </RegistrationEmailFormStyled>
  );
}

export default RegistrationEmailForm;
