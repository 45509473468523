import { PhoneInput, Select, TextInput } from 'legacy/components/common/design-system';
import { E164Number } from 'libphonenumber-js';
import React, { useMemo } from 'react';
import { RolesKey } from 'legacy/types';
import { TDropdownDataItem } from 'legacy/components/common/design-system/Dropdown/TDropdown';
import { STATES } from 'constants/states';
import { isHomeowner } from 'legacy/utils';
import { USER_ROLES } from '../../../constants';
import { Grid } from './UserFormStyles';

export interface UserFormType {
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_number: string;
  role: RolesKey;
  address?: {
    street_1?: string;
    street_2?: string;
    city?: string;
    state?: string;
    zip_code?: string;
  };
}

function UserForm({
  user,
  setUser,
}: {
  user: UserFormType;
  setUser: (user: UserFormType) => void;
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedUser = {
      ...user,
      address: {
        ...user.address,
        [e.target.name]: e.target.value,
      },
    };

    setUser(updatedUser);
  };
  const handlePhoneChange = (number?: E164Number) => {
    setUser({ ...user, phone_number: number?.toString() || '' });
  };

  const handleRoleChange = (value: string) => {
    setUser({ ...user, role: value as RolesKey });
  };

  const handleStateChange = (value: string) => {
    const updatedUser = {
      ...user,
      address: {
        ...user.address,
        state: value,
      },
    };
    setUser(updatedUser);
  };

  const selectOptions = Object.keys(USER_ROLES).map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
    value: key,
  }));

  const stateOptions: TDropdownDataItem[] = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  return (
    <>
      <Grid>
        <TextInput
          ariaLabel="first name"
          label="First Name"
          name="first_name"
          onChange={handleChange}
        />
        <TextInput
          ariaLabel="last name"
          label="Last Name"
          name="last_name"
          onChange={handleChange}
        />

        <TextInput
          ariaLabel="email"
          label="Email"
          name="email"
          type="email"
          isRequired
          onChange={handleChange}
        />

        <PhoneInput ariaLabel="Phone Number" label="Phone Number" onChange={handlePhoneChange} />

        <Select
          label="Role"
          placeholder="Role"
          value={user.role}
          options={selectOptions}
          onSelectOption={handleRoleChange}
        />
      </Grid>

      {isHomeowner(user.role) ? (
        <>
          <h3>Add Address</h3>
          <Grid>
            <TextInput
              ariaLabel="street_1"
              label="Street 1"
              name="street_1"
              onChange={handleAddressChange}
            />

            <TextInput
              ariaLabel="street_2"
              label="Street 2"
              name="street_2"
              onChange={handleAddressChange}
            />

            <TextInput ariaLabel="city" label="City" name="city" onChange={handleAddressChange} />

            <Select
              label="State"
              placeholder="Select a state"
              options={stateOptions}
              onSelectOption={handleStateChange}
            />

            <TextInput
              ariaLabel="zip_code"
              label="ZIP Code"
              name="zip_code"
              onChange={handleAddressChange}
            />
          </Grid>
        </>
      ) : null}
    </>
  );
}

export default UserForm;
