import axios, { AxiosPromise } from 'axios';
import routes from 'routes';
import { FeatureFlag } from 'api/types';
import { guessEnvironment } from 'shared/utils/guessEnvironment';

export function getFeatureFlags(): AxiosPromise<FeatureFlag[]> {
  const timeout = guessEnvironment() === 'prod' ? 2000 : 15000;

  return axios.get(routes.featureFlags, { timeout });
}

export default getFeatureFlags;
