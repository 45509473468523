import React, { useMemo } from 'react';
import { SystemType } from 'api/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getEnergySummary, getPowerFlow } from 'api/system';
import { MonitoringQueryKey } from 'modules/system/utils/prefetchQueries';
import { MonitoringStatistic } from './MonitoringStatistic';
import { MonitoringSnapshotWrapper } from './MonitoringSnapshot.styles';

type Props = {
  system?: SystemType;
};

export function MonitoringSnapshot({ system }: Props) {
  const { t } = useTranslation();

  const { data: energySummary } = useQuery(
    [MonitoringQueryKey.ENERGY_SUMMARY, system?.id],
    () => getEnergySummary(String(system?.id)).then((response) => response.data),
    {
      enabled: Boolean(system?.id),
      refetchOnWindowFocus: false,
    },
  );
  const { data: powerFlow } = useQuery(
    [MonitoringQueryKey.POWER_FLOW, system?.id],
    () => getPowerFlow(String(system?.id)).then((response) => response.data),
    {
      enabled: Boolean(system?.id),
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 10, // refetch every 10 seconds
    },
  );

  const [energyToday, energyThisMonth, energyAllTime] = useMemo(() => {
    if (energySummary) {
      const { day_kwh: dayKwh, month_kwh: monthKwh, all_time_kwh: allTimeKwh } = energySummary;
      return [dayKwh, monthKwh, allTimeKwh];
    }
    return [null, null, null];
  }, [energySummary]);

  return (
    <MonitoringSnapshotWrapper>
      <MonitoringStatistic
        label={t('system.monitoring.currentPower')}
        value={powerFlow?.system?.power_kw || null}
        unit="kW"
      />
      <MonitoringStatistic label={t('system.monitoring.today')} value={energyToday} unit="kWh" />
      <MonitoringStatistic
        label={t('system.monitoring.thisMonth')}
        value={energyThisMonth}
        unit="kWh"
      />
      <MonitoringStatistic
        label={t('system.monitoring.allTime')}
        value={energyAllTime}
        unit="kWh"
      />
    </MonitoringSnapshotWrapper>
  );
}

export default MonitoringSnapshot;
