import React, { useState } from 'react';
import parsePhoneNumberFromString, {
  AsYouType,
  E164Number,
  CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { TInputStyleVariantsKey } from 'theme/inputs/TInputs';
import { TextInput } from '../TextInput/TextInput';

const initialFormat = (number: string, country: CountryCode) => {
  const phoneNumber = parsePhoneNumberFromString(number, country);
  return phoneNumber?.formatNational() || '';
};

type Props = {
  /**
   * Aria Label for accesibility
   */
  ariaLabel: string;
  /**
   * is the input required in a form?
   */
  isRequired?: boolean;
  /**
   * default selected value
   */
  defaultValue?: string;
  /**
   * Optional data test ID value for testing purposes
   */
  dataTestId?: string;
  /**
   * Input Style Variant
   */
  styleVariant?: TInputStyleVariantsKey;
  /**
   * Optional error status boolean
   */
  hasError?: boolean;
  /**
   * Optional id
   */
  id?: string;
  /**
   * Optional Margin CSS value
   */
  margin?: string;
  /**
   * Optional Label text
   */
  label?: string;
  /**
   * Input reference for hook form to register
   */
  ref?: React.Ref<HTMLInputElement>;
  /**
   * Input's name being registered.
   */
  name?: string;
  /**
   * Fired after phone number is validated
   */
  onValidate?: (value: boolean) => void;
  /**
   * Optional onChange handler
   */
  onChange?: (number?: E164Number) => void;
  /**
   * Optional onBlur handler
   */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onClick handler
   */
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyDown handler
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyUp handler
   */
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onFocus handler
   */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseEnter handler
   */
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseDown handler
   */
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseLeave handler
   */
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseUp handler
   */
  onMouseUp?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

export function PhoneInput({
  ariaLabel,
  isRequired,
  defaultValue: initialValue = '',
  dataTestId,
  styleVariant,
  hasError,
  id,
  margin,
  label,
  ref,
  name,
  onValidate,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
}: Props) {
  const [country] = useState<CountryCode>('US');
  const [number, setNumber] = useState(initialFormat(initialValue, country));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const parsedNumber = parsePhoneNumberFromString(value, country);

    // when using national format for US backspacing gets stuck, this checks the value length for area code to allow backspacing
    if (parsedNumber?.nationalNumber && parsedNumber?.nationalNumber.length > 3) {
      const formatted = new AsYouType(country).input(value);
      setNumber(formatted);
    } else {
      setNumber(value);
    }
    const valid = isValidPhoneNumber(parsedNumber?.number ?? '');
    onValidate?.(valid);
    onChange?.(parsedNumber?.number);
  };

  return (
    <TextInput
      ariaLabel={ariaLabel}
      isRequired={isRequired}
      value={number}
      dataTestId={dataTestId}
      styleVariant={styleVariant}
      hasError={hasError}
      id={id}
      margin={margin}
      label={label}
      ref={ref}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
    />
  );
}

export default PhoneInput;
