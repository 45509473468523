import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Toast } from 'shared/components/Toast/Toast';
import { darkTheme, lightTheme, TThemeName } from 'shared/design-system/theme/theme';

type Props = {
  children: JSX.Element;
};
export function ThemeLayout({ children }: Props) {
  const [theme] = useState<TThemeName>('lightTheme');

  return (
    <ThemeProvider theme={theme === 'lightTheme' ? lightTheme : darkTheme}>
      <Toast />
      {children}
    </ThemeProvider>
  );
}

export default ThemeLayout;
