import styled from 'styled-components';

export type TDividerProps = {
  /**
   * Optional maximum width for the divider
   */
  $maxWidth?: string;
  /**
   * Optional color
   */
  $lineColor?: string;
  /**
   * Optional thickness of the divider
   */
  $height?: string;
  /**
   * Optional margin
   */
  $margin?: string;
};

export const Divider = styled('hr').attrs<TDividerProps>(() => ({
  role: 'separator',
}))<TDividerProps>`
  ${({ $maxWidth = 'none', $lineColor = '#a8a8a8', $height = '1px', $margin = '0px' }) => `
        border: none;
        background-color: ${$lineColor};
        height: ${$height};
        margin: ${$margin};
        width: 100%;
        max-width: ${$maxWidth};
  `}
`;

export default Divider;
