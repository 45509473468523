import {
  TDropdownStyleVariantsKey,
  TDropdownStatesKey,
  getDropdownStyleVariantValues,
} from 'shared/design-system/theme/dropdowns';
import styled, { css } from 'styled-components';

export const CustomSelectContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

export const NativeSelect = styled.select`
  display: none;
`;

type SelectSelectedProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const SelectScrim = styled.div<{ $show: boolean }>`
  ${({ theme, $show }) => `
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${theme.colors.backgroundOpacityOpacityScrimGrey8075};
        display: block;
        display: ${$show ? 'block' : 'none'};
        @media (min-width: ${theme.breakpoints.small}) {
            display: none;
        }
    `}
`;

export const SelectSelected = styled.div<SelectSelectedProps>`
  ${({ $styleVariant, $state, theme }) => {
    const { background, border, borderRadius } = getDropdownStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      padding: 8px 12px;
      background: ${background};
      border: ${border};
      border-radius: ${borderRadius};
      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow:
          0px 0px 0px 1px #fff,
          0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
        z-index: 1;
      }
    `;
  }}
`;
