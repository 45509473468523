import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyRegular } from 'legacy/components/common/design-system/Typography';

export default function ContractSigningContent() {
  const { t } = useTranslation();

  return (
    <BodyRegular $margin="0 0 16px">
      {t('statusAccordionCopy.contractSigning.copy.body1')}
    </BodyRegular>
  );
}
