import React from 'react';
import useUser from 'hooks/useUser';

import SupportCard from 'legacy/components/OverviewCards/SupportCard';
import Banner from 'legacy/components/common/design-system/Banner/Banner';
import { InfoIcon } from 'legacy/components/common/design-system/Icons';
import ProjectStatus from 'legacy/components/ProjectStatus/ProjectStatus';
import { LoadingSpinner } from 'legacy/components/common/design-system';
import colors from 'theme/colors';
import { statusBannerText } from 'legacy/copy';
import EarlyAccess from './EarlyAccess';

import {
  ContentWrapper,
  MainContent,
  StatusContainer,
  SupportCardBox,
  WelcomeText,
} from './StatusStyles';

export default function Status() {
  const { userResult } = useUser();

  const isLoading = !userResult || userResult.loading;

  const activeAccounts =
    userResult?.user?.accounts.filter((userAccount) => userAccount.agreements.length) || [];

  const isEarlyAccess = Boolean(activeAccounts?.length);

  return (
    <StatusContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {isEarlyAccess && (
            <Banner
              bannerKey="earlyAccessWelcomeBanner"
              bannerText={statusBannerText}
              bgColor={colors.green20}
              icon={<InfoIcon />}
            />
          )}
          <WelcomeText id="welcome-text">Hello, {userResult?.user?.first_name}!</WelcomeText>
          <ContentWrapper>
            <MainContent>
              {isEarlyAccess ? (
                <EarlyAccess accounts={activeAccounts} />
              ) : (
                <ProjectStatus currentMilestone="CANCELED" />
              )}
            </MainContent>

            <SupportCardBox>
              <SupportCard navigateToSupportPage={false} showOtherSupportMethod={false} />
            </SupportCardBox>
          </ContentWrapper>
        </>
      )}
    </StatusContainer>
  );
}
