import styled, { css } from 'styled-components';

export type TDividerProps = {
  /**
   * Optional maximum width for the divider
   */
  $maxWidth?: string;
  /**
   * Optional color
   */
  $lineColor?: string;
  /**
   * Optional thickness of the divider
   */
  $height?: string;
  /**
   * Optional margin
   */
  $margin?: string;
};

export const Divider = styled('hr').attrs<TDividerProps>(() => ({
  role: 'separator',
}))<TDividerProps>`
  ${({ $maxWidth = 'none', $lineColor, $height = '1px', $margin = '0px', theme }) => css`
    border: none;
    background-color: ${$lineColor || theme.colors.backgroundInteractionPressedSecondary4100};
    height: ${$height};
    margin: ${$margin};
    width: 100%;
    max-width: ${$maxWidth};
  `}
`;

export default Divider;
