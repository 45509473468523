import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

type Props = IIconProps & {
  filled?: boolean;
};

export function SupportIcon({ color: colorProp, filled = false }: Props) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.6875C4.96055 1.6875 1.6875 4.96055 1.6875 9V10.4062C1.6875 10.8738 1.31133 11.25 0.84375 11.25C0.376172 11.25 0 10.8738 0 10.4062V9C0 4.02891 4.02891 0 9 0C13.9711 0 18 4.02891 18 9V14.066C18 15.7746 16.6148 17.1598 14.9027 17.1598L11.025 17.1562C10.7332 17.659 10.1883 18 9.5625 18H8.4375C7.50586 18 6.75 17.2441 6.75 16.3125C6.75 15.3809 7.50586 14.625 8.4375 14.625H9.5625C10.1883 14.625 10.7332 14.966 11.025 15.4688L14.9062 15.4723C15.6832 15.4723 16.3125 14.843 16.3125 14.066V9C16.3125 4.96055 13.0395 1.6875 9 1.6875ZM5.0625 7.3125H5.625C6.24727 7.3125 6.75 7.81523 6.75 8.4375V12.375C6.75 12.9973 6.24727 13.5 5.625 13.5H5.0625C3.82148 13.5 2.8125 12.491 2.8125 11.25V9.5625C2.8125 8.32148 3.82148 7.3125 5.0625 7.3125ZM12.9375 7.3125C14.1785 7.3125 15.1875 8.32148 15.1875 9.5625V11.25C15.1875 12.491 14.1785 13.5 12.9375 13.5H12.375C11.7527 13.5 11.25 12.9973 11.25 12.375V8.4375C11.25 7.81523 11.7527 7.3125 12.375 7.3125H12.9375Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.12478 8.99824C1.12478 4.65026 4.65026 1.12478 8.99824 1.12478C13.3462 1.12478 16.8717 4.65026 16.8717 8.99824V14.0633C16.8717 14.9947 16.116 15.7504 15.1845 15.7504L12.2777 15.7469C12.0457 15.0931 11.42 14.6221 10.6854 14.6221H8.43585C7.50439 14.6221 6.74868 15.3779 6.74868 16.3093C6.74868 17.2408 7.50439 17.9965 8.43585 17.9965H10.6854C11.42 17.9965 12.0457 17.5255 12.2777 16.8717L15.1845 16.8752C16.7381 16.8752 18 15.6169 18 14.0633V8.99824C17.9965 4.02812 13.9684 0 8.99824 0C4.02812 0 0 4.02812 0 8.99824V10.6854C0 10.9947 0.253076 11.2478 0.56239 11.2478C0.871705 11.2478 1.12478 10.9947 1.12478 10.6854V8.99824ZM11.2478 16.3093C11.2478 16.6186 10.9947 16.8717 10.6854 16.8717H8.43585C8.12654 16.8717 7.87346 16.6186 7.87346 16.3093C7.87346 16 8.12654 15.7469 8.43585 15.7469H10.6854C10.9947 15.7469 11.2478 16 11.2478 16.3093ZM5.06151 7.87346H5.6239V12.3726H5.06151C4.13005 12.3726 3.37434 11.6169 3.37434 10.6854V9.56063C3.37434 8.62918 4.13005 7.87346 5.06151 7.87346ZM2.24956 9.56063V10.6854C2.24956 12.239 3.50791 13.4974 5.06151 13.4974H5.6239C6.24605 13.4974 6.74868 12.9947 6.74868 12.3726V7.87346C6.74868 7.25132 6.24605 6.74868 5.6239 6.74868H5.06151C3.50791 6.74868 2.24956 8.00703 2.24956 9.56063ZM12.3726 7.87346H12.935C13.8664 7.87346 14.6221 8.62918 14.6221 9.56063V10.6854C14.6221 11.6169 13.8664 12.3726 12.935 12.3726H12.3726V7.87346ZM12.935 6.74868H12.3726C11.7504 6.74868 11.2478 7.25132 11.2478 7.87346V12.3726C11.2478 12.9947 11.7504 13.4974 12.3726 13.4974H12.935C14.4886 13.4974 15.7469 12.239 15.7469 10.6854V9.56063C15.7469 8.00703 14.4886 6.74868 12.935 6.74868Z"
        fill={color}
      />
    </svg>
  );
}

export default SupportIcon;
