import React from 'react';
import { AgreementType } from 'legacy/types';
import { formatCapacity } from 'legacy/utils';
import { Grid } from './SystemCardStyles';
import { Card } from './common/myeverbright';

type Props = {
  agreement: AgreementType;
};
function SystemCard({ agreement }: Props) {
  const { system } = agreement;

  const capacity = formatCapacity(system.capacity);
  const systemDetails = [
    { label: 'Panels', value: system.panels },
    { label: 'Inverters', value: system.inverters },
    { label: 'Capacity', value: `${capacity} kW` },
    { label: 'System Mounting', value: system.system_mounting_type },
  ];

  return (
    <Card $fullWidth $bgColor={agreement.archived ? 'gray20' : 'white'}>
      <h3>{`${agreement.product_display_name} #${agreement.homeowner_contract_id}`}</h3>
      <Grid>
        {systemDetails.map((item) => (
          <div key={`${agreement.id}-${item.label}`}>
            <div className="label">{item.label}</div>
            <p>{item.value}</p>
          </div>
        ))}
      </Grid>
      {system.batteries.map((battery) => {
        const batteryDetails = [
          { label: 'Storage Capacity', value: `${formatCapacity(battery.capacity)} kWh` },
          { label: 'Battery Power Rating', value: `${battery.power_rating} kW` },
          { label: 'Battery Count', value: battery.count },
          { label: 'Battery Manufacturer', value: battery.manufacturer },
          { label: 'Battery Models', value: battery.model },
        ];
        return (
          <React.Fragment key={battery.id}>
            <h4>Battery</h4>
            <Grid>
              {batteryDetails.map((item) => (
                <div key={`${agreement.id}-${item.label}`}>
                  <div className="label">{item.label}</div>
                  <p>{item.value}</p>
                </div>
              ))}
            </Grid>
          </React.Fragment>
        );
      })}
    </Card>
  );
}

export default SystemCard;
