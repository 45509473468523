import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function ArrowLeftIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.37225 10.69C5.55592 10.8965 5.53979 11.2128 5.33513 11.3985C5.13005 11.5835 4.81353 11.5669 4.62915 11.3613L0.128187 6.3483C-0.0427291 6.15644 -0.0427291 5.86871 0.128187 5.67669L4.62846 0.692864C4.81303 0.487308 5.12943 0.470643 5.33444 0.655649C5.55064 0.852089 5.54642 1.16907 5.37157 1.36422L1.17294 6.01312L5.37225 10.69Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowLeftIcon;
