import React from 'react';

export const TOOLTIP_ICON_SIZE = 28;

export function TooltipIcon() {
  return (
    <svg width={TOOLTIP_ICON_SIZE} height={TOOLTIP_ICON_SIZE} viewBox="0 0 28 29" fill="none">
      <g filter="url(#filter0_dd_2878_10397)">
        <path d="M17.964 14a4 4 0 11-8 0 4 4 0 018 0z" fill="#0257A4" />
      </g>
      <g opacity={0.8} filter="url(#filter1_dd_2878_10397)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 10a4 4 0 100 8 4 4 0 000-8zm12 4c0 6.627-5.373 12-12 12S2 20.627 2 14 7.373 2 14 2s12 5.373 12 12z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
