import styled from 'styled-components';
import { Container } from 'legacy/views/dashboard/DashboardStyles';
import { Box } from 'legacy/components/common/myeverbright';
import { MOBILE_MAX_WIDTH } from '../../constants';

export const WelcomeText = styled.h1`
  margin: 32px 0 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  margin-right: 60px;
  flex: 1;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin: 0;
  }
`;

export const SupportCardBox = styled(Box)`
  width: 355px;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin-top: 48px;
  }
`;

export const StatusContainer = styled(Container)`
  padding-left: 20px;
  #status-accordion {
    overflow: unset;
  }

  > #welcome-text:first-child {
    margin-top: 12px !important;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 0 16px;
  }
`;
