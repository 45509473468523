import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from './DashboardStyles';

type Props = {
  children?: JSX.Element;
};

export function DashboardLayout({ children }: Props) {
  return <Container>{children || <Outlet />}</Container>;
}

export default DashboardLayout;
