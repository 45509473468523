import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function ArrowUpIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0579 15.5015C19.764 15.8258 19.2897 15.8195 18.9976 15.5572L12.0001 9.25777L5.00169 15.5578C4.6926 15.8333 4.21935 15.8091 3.94138 15.5021C3.66576 15.1921 3.6906 14.7187 3.9981 14.4421L11.4559 7.69215C11.743 7.43579 12.1736 7.43579 12.4609 7.69215L19.9187 14.4421C20.3111 14.7187 20.3345 15.1921 20.0579 15.5015Z"
        fill={color || theme.colors.textPrimarySecondary1100}
      />
    </svg>
  );
}

export default ArrowUpIcon;
