import React, { useState } from 'react';
import { resolvePath, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useSendAccessCode } from 'api/account';
import { pages } from 'pages';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import {
  RegistrationEmailForm,
  RegistrationEmailFormInput,
} from 'modules/auth/components/RegistrationEmailForm/RegistrationEmailForm';
import { AuthBox, AuthWrapper, LinkStyled, LoginFooter } from 'modules/auth/Auth.styles';

export function Registration() {
  const [verificationEmail, setVerificationEmail] = useState('');

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate: sendAccessCode } = useSendAccessCode({
    onSuccess: () => {
      const params = new URLSearchParams({ email: verificationEmail });
      navigate(`/${pages.VERIFICATION}?${params.toString()}`);
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('registration.invalidEmail'),
        theme,
      });
    },
  });

  const handleSubmitEmailForm = ({ email }: RegistrationEmailFormInput) => {
    setVerificationEmail(email);
    sendAccessCode({ email });
  };

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('registration.tellUsYour')}
          titleBold={t('registration.emailAddress')}
          showLogo
        />
        <RegistrationEmailForm onSubmitForm={handleSubmitEmailForm} />
      </AuthBox>
      <LoginFooter>
        {t('claimAccount.alreadyHaveAnAccount')}{' '}
        <LinkStyled to={resolvePath(`/${pages.LOGIN}`)}>{t('loginScreen.logInHere')}</LinkStyled>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default Registration;
