import { colors } from '../colors';
import { TAccordions } from './TAccordions';

export const lightThemeAccordions: TAccordions = {
  variants: {
    primary: {
      background: colors.secondaryDarkBlue,
      labelColor: colors.white,
    },
    secondary: {
      background: colors.gray10,
      labelColor: colors.secondaryDarkBlue,
    },
    tertiary: {
      background: colors.white,
      labelColor: colors.secondaryDarkBlue,
    },
  },
};

export default lightThemeAccordions;
