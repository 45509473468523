import React, { useRef } from 'react';

import Text from 'shared/components/Text/Text';
import {
  DISABLED_DROPDOWN_STATE,
  ENABLED_DROPDOWN_STATE,
  TDropdownDataItem,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';
import {
  DropdownItemButtonWrapper,
  DropdownItemStyled,
  DropdownTitle,
  IconContainer,
} from './DropdownItemStyles';

export type TDropdownItemProps = {
  id?: string;
  ariaRole?: 'listitem' | 'option';
  dropdownItemData: TDropdownDataItem;
  styleVariant?: TDropdownStyleVariantsKey;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLLIElement>) => void;
  fontSize?: number;
};

/**
 * @param {string} [id] - id for the dropdown item  (optional)
 * @param {'listitem' | 'option'} [ariaRole] - role for item (optional)
 * @param {TDropdownDataItem} [dropdownItemData] - item data
 * @param {TDropdownStyleVariantsKey} [styleVariant] -  defaults to primary style (optional)
 * @param {function} [onClick] - onClick handler
 * @param {function} [onKeyDown] - onKeyDown handler
 * @param {function} [onMouseMove] - onMouseMove handler
 * @param {function} [onClick] - onClick handler
 * @param {number} [fontSize] - optional font size
 */

export function DropdownItem({
  id,
  dropdownItemData,
  onClick,
  onKeyDown,
  onMouseMove,
  onMouseEnter,
  styleVariant = 'default',
  ariaRole = 'listitem',
  fontSize,
}: TDropdownItemProps) {
  const { title, Icon, isDisabled } = dropdownItemData;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const testId = `${title?.split(' ').join('-').toLowerCase()}-dropdownItem`;

  const buttonState = isDisabled ? DISABLED_DROPDOWN_STATE : ENABLED_DROPDOWN_STATE;

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };
  return (
    <DropdownItemStyled
      data-testid={testId}
      id={id}
      role={ariaRole}
      $styleVariant={styleVariant}
      $state={buttonState}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      $hasIcon={!!Icon}
    >
      <DropdownItemButtonWrapper
        ref={buttonRef}
        type="button"
        role="button"
        aria-label={title}
        $styleVariant={styleVariant}
        $state={buttonState}
        tabIndex={isDisabled ? -1 : undefined}
      >
        {Icon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <DropdownTitle>
          <Text as="h5" $fontSize={fontSize}>
            {title}
          </Text>
        </DropdownTitle>
      </DropdownItemButtonWrapper>
    </DropdownItemStyled>
  );
}
