export const getEmptyValues = (
  inputs: {
    name: 'phoneNumber' | 'street1' | 'street2' | 'city' | 'state' | 'zip';
    value: string;
  }[],
) => {
  const emptyValues = inputs.filter((input) => !input.value.trim()).map((input) => input.name);

  return emptyValues;
};

export default getEmptyValues;
