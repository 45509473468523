import styled from 'styled-components';
import { Footer } from '../CardStyles';

export const SystemCopy = styled.p`
  letter-spacing: 0.5px;
`;

export const SystemCapacityText = styled.div`
  display: flex;
  font-size: 18px;
  align-items: end;

  margin-bottom: 16px;

  p:first-child {
    margin: 0;
    font-size: 48px;
    margin-right: 5px;
    line-height: 48px;
  }
`;

export const SystemPerformanceLinkWrapper = styled(Footer)`
  a:first-child {
    display: block;
    margin-bottom: 16px;
  }
`;
