import { TTheme } from '../theme';
import { TButtons, TButtonStyleVariantsKey } from './TButtons';

// RETURNS ALL STYLES RELATED TO BUTTON STYLE VARIANT
export const getButtonStyleVariantValues = (
  theme: TTheme,
  themeButtons: (theme: TTheme) => TButtons,
  variant: TButtonStyleVariantsKey,
) => ({
  ...themeButtons(theme).variants[variant].enabled,
  ...themeButtons(theme).variants[variant],
});

export default getButtonStyleVariantValues;
