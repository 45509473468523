import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { resolvePath } from 'react-router';

import URLS from 'constants/urls';
import { Card } from 'shared/components/Card/Card';
import { CardDescription } from 'shared/components/Card/Card.styles';
import { BillingIcon } from 'shared/components/icons/BillingIcon';
import SystemIcon from 'shared/components/icons/SystemIcon';
import pages from 'pages';
import SupportIcon from 'shared/components/icons/SupportIcon';
import { OverViewCardsWrapper } from './OverviewCards.styles';

export function OverviewCards() {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <OverViewCardsWrapper>
      <Card
        title={t('overview.billing&Payment')}
        url={URLS.EVERBRIGHT_ONLINE_ACCOUNT}
        icon={{
          element: <BillingIcon color={colors.chartChartGreenFunction540} />,
          color: 'green',
        }}
      >
        <CardDescription as="p">{t('overview.makePaymentsViewBilling')}</CardDescription>
      </Card>

      <Card
        title={t('overview.system')}
        route={resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname}
        icon={{
          element: <SystemIcon color={colors.chartChartGreenFunction540} />,
          color: 'blue',
        }}
      >
        <CardDescription as="p">{t('overview.viewYourSystemDetails')}</CardDescription>
      </Card>

      <Card
        title={t('overview.customerSupport')}
        route={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`).pathname}
        icon={{
          element: <SupportIcon color={colors.chartChartGreenFunction540} />,
          color: 'magenta',
        }}
      >
        <CardDescription as="p">{t('overview.getHelp')}</CardDescription>
      </Card>
    </OverViewCardsWrapper>
  );
}

export default OverviewCards;
