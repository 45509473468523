import React, { useState } from 'react';
import { Button, TextInput } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import { MessageType } from 'legacy/types';
import { backfillWorkflows } from 'api/system';
import { validateUuid } from 'legacy/utils';
import { FormGroup, Label } from './BackfillWorkflowFormStyles';

function BackfillWorkflowsForm() {
  const [workflows, setWorkflows] = useState<string[]>([]);
  const [newWorkflows, setNewWorkflows] = useState<string>('');
  const [message, setMessage] = useState<MessageType | null>(null);

  const addWorkflowIds = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const uuidsToAdd = newWorkflows.split(',').map((uuid) => uuid.trim());
    const validUuids: Set<string> = new Set();
    let hasInvalid = false;
    uuidsToAdd?.forEach((uuid) => {
      if (validateUuid(uuid)) {
        setMessage(null);
        validUuids.add(uuid);
      } else {
        setMessage({ message: `Invalid UUID: "${uuid}"`, type: 'error' });
        hasInvalid = true;
      }
    });

    if (!hasInvalid) {
      setWorkflows([...workflows, ...Array.from(validUuids)]);
      setNewWorkflows('');
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    if (workflows.length < 1) {
      setMessage({ message: 'Invalid workflow length', type: 'error' });
    } else {
      backfillWorkflows(workflows)
        .then(() => {
          setMessage({
            message: 'Backfill process for subset of workflows has started.',
            type: 'success',
          });
          setWorkflows([]);
        })
        .catch(() => {
          setMessage({ message: 'Unable to start backfill process.', type: 'error' });
          setWorkflows([]);
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewWorkflows(e.target.value);
  };

  return (
    <>
      {workflows.map((workflow) => (
        <Label className="uuid-label" key={workflow}>
          {workflow}
        </Label>
      ))}

      {message && <Message severity={message.type}>{message.message}</Message>}

      <FormGroup>
        <TextInput
          ariaLabel="input workflow"
          placeholder="workflow_id_1, workflow_id_2, workflow_id_3, ..."
          value={newWorkflows}
          onChange={handleChange}
        />
        <Button label="Add Workflow(s)" onClick={addWorkflowIds} />
      </FormGroup>

      <div>
        <Button label="Backfill Subset" onClick={handleSubmit} />
      </div>
    </>
  );
}

export default BackfillWorkflowsForm;
