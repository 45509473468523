import React, { useState } from 'react';
import axios from 'axios';
import { MessageType } from 'legacy/types';
import { Card, Message } from 'legacy/components/common/myeverbright';
import routes from 'routes';

import AgreementForm, { AgreementFormType } from '../forms/AgreementForm';

const defaultAgreement: AgreementFormType = {
  product_name: null,
  product_type: null,
  system: {
    address: {
      street_1: null,
      street_2: '',
      city: null,
      state: undefined,
      zip_code: null,
    },
    panels: null,
    inverters: null,
    capacity: null,
    estimated_offset: null,
    install_cost: null,
    system_mounting_type: '',
    batteries: [],
  },
  homeowner_contract_id: null,
  platform_uuid: null,
  initial_balance: null,
  current_balance: null,
  contract_type: null,
  contract_signed_date: null,
  contract_term: null,
  monthly_payment: null,
  rate_contract: null,
  rate_escalator: null,
};

export default function AddAgreement({
  accountId,
  addAgreement,
}: {
  accountId: string;
  addAgreement: Function;
}) {
  const [adding, setAdding] = useState(false);

  const [agreement, setAgreement] = useState<AgreementFormType>(defaultAgreement);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    axios
      .post(routes.agreements, {
        ...agreement,
        account: accountId,
        contract_signed_date: new Date(String(agreement.contract_signed_date)),
      })
      .then((response) => {
        addAgreement(response.data);
        setAdding(false);
        setMessage({ message: 'Agreement successfully created', type: 'success' });
      })
      .catch((error) => {
        setMessage({ message: JSON.stringify(error.response.data), type: 'error' });
      });
  };

  return adding ? (
    <Card $fullWidth>
      <h3>
        Add Agreement
        <div
          className="pull-right edit"
          role="button"
          tabIndex={0}
          onClick={() => setAdding(!adding)}
          onKeyDown={() => setAdding(!adding)}
        >
          Close
        </div>
      </h3>

      {message && <Message severity={message.type}>{message.message}</Message>}

      <AgreementForm
        agreement={agreement}
        setAgreement={setAgreement}
        handleSubmit={handleSubmit}
      />
    </Card>
  ) : (
    <h3>
      <div
        className="edit"
        role="button"
        tabIndex={0}
        onClick={() => setAdding(!adding)}
        onKeyDown={() => setAdding(!adding)}
      >
        + Add Agreement
      </div>
    </h3>
  );
}
