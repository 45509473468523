import styled from 'styled-components';
import { TThemeColorsKey } from 'shared/design-system/theme/colors/TThemeColors';
import { Text } from '../Text/Text';

export const StatusPillStyled = styled.div<{ $bgColor?: TThemeColorsKey }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: fit-content;
  padding: 4px 16px;
  background-color: ${({ theme, $bgColor = 'backgroundSystemSuccessPrimary220' }) =>
    theme.colors[$bgColor]};
`;

export const StatusText = styled(Text)`
  //styleName: Subtitle 2-400;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;
