import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  DEFAULT_DROPDOWN_STYLE_VARIANT,
  TDropdownStyleVariantsKey,
  getDropdownState,
  getDropdownStyleVariantValues,
  lightThemeDropdowns,
} from 'theme/dropdowns';

import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import { generateRandomString } from 'shared/utils';
import { AccountIcon } from '../icons/AccountIcon';
import {
  CustomSelectContainer,
  SelectSelected,
  NativeSelect,
  DropdownWrapper,
  Scrim,
} from './AccountMenu.styles';
import { Text } from '../Text/Text';
import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../Dropdown/DropdownItem/DropdownItem';

type TSelectProps = {
  id?: string;
  ariaLabelledBy?: string;
  role?: string;
  title?: string;
  value?: string;
  isDisabled?: boolean;
  defaultOption?: TDropdownDataItem;
  options: TDropdownDataItem[];
  styleVariant?: TDropdownStyleVariantsKey;
  onSelectOption?: (value: string) => void;
  className?: string;
  isAccountPage: boolean;
};

/**
 * @param {string} [id] - id (optional)
 * @param {string} [ariaLabelledBy] - aria-labelledby (optional)
 * @param {string} [role] - role of dropdown container. defaults to listbox (optional)
 * @param {string} [title] - title of the account menu
 * @param {string} [value] - value for select (optional)
 * @param {boolean} [isDisabled] - is the select disabled (optional)
 * @param {TDropdownDataItem} [options] - select options
 * @param {TDropdownStyleVariantsKey} [styleVariant] - (optional)
 * @param {Function} [onSelectOption] - click handler (optional)
 * @param {string} [className] - required to expose the overall component to styled-components
 * @param {boolean} [isAccountPage] - determines whether to display solid or hollow icon
 */

export function AccountMenu({
  id,
  ariaLabelledBy,
  defaultOption,
  role = 'listbox',
  title = 'Account',
  value,
  isDisabled = false,
  options,
  styleVariant = DEFAULT_DROPDOWN_STYLE_VARIANT,
  onSelectOption,
  className,
  isAccountPage,
}: TSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TDropdownDataItem>();

  const theme = useTheme();

  const inputId = useMemo(() => id || generateRandomString(), [id]);

  const dropdownState = getDropdownState(isDisabled);

  const { fontColor } = getDropdownStyleVariantValues(
    lightThemeDropdowns,
    styleVariant,
    dropdownState,
  );

  useEffect(() => {
    let newOption = options.find((option) => option.value === value);
    newOption = newOption || defaultOption;
    if (newOption) setSelectedOption(newOption);
  }, [options, value, defaultOption]);

  useEffect(() => {
    if (isClosing) setIsClosing(false);
  }, [isClosing]);

  const handleOpenDropdown = () => {
    if (!isClosing) setIsOpen(true);
    setIsClosing(false);
  };

  const handleCloseDropdown = () => {
    setIsClosing(true);
    setIsOpen(false);
  };

  const handleOptionClick = (option: TDropdownDataItem) => {
    onSelectOption?.(option.value || '');
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <CustomSelectContainer
      id={id}
      aria-labelledby={ariaLabelledBy}
      role={role}
      className={className}
    >
      <NativeSelect id={`${inputId}-TextInput`} value={selectedOption?.value} />
      <SelectSelected
        $styleVariant={styleVariant}
        $state={dropdownState}
        onClick={handleOpenDropdown}
      >
        <AccountIcon
          color={theme.colors.iconIconPrimaryPressedSecondary2100}
          filled={isAccountPage}
        />
        <Text as="h5" color={fontColor}>
          {title}
        </Text>
      </SelectSelected>

      <DropdownWrapper>
        <Dropdown
          styleVariant={styleVariant}
          isOpen={isOpen}
          isFullWidth
          onClickOutside={handleCloseDropdown}
        >
          {options.map((option) => (
            <DropdownItem
              styleVariant={styleVariant}
              key={option.title}
              onClick={() => handleOptionClick(option)}
              dropdownItemData={option}
              fontSize={16}
            />
          ))}
        </Dropdown>

        <Scrim $show={isOpen} />
      </DropdownWrapper>
    </CustomSelectContainer>
  );
}

export default AccountMenu;
