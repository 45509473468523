export const getPaginationValues = (activeIndex: number, totalPageCount: number): number[] => {
  const maxPageNumbers = 4;
  const halfMaxPageNumbers = Math.floor(maxPageNumbers / 2);

  // Calculate the start page based on the active index
  let startPage = Math.max(activeIndex - halfMaxPageNumbers, 1);

  // Calculate the end page based on the start page and maximum page numbers
  const endPage = Math.min(startPage + maxPageNumbers - 1, totalPageCount);

  // Adjust the start page if the range of pages is less than the maximum page numbers
  if (endPage - startPage + 1 < maxPageNumbers) {
    startPage = Math.max(endPage - maxPageNumbers + 1, 1);
  }

  // Generate an array of page numbers within the calculated range
  return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
};

export const calculateShowingRange = (
  activeIndex: number,
  totalPages: number,
  pageSize: number,
) => {
  const start = pageSize * (activeIndex - 1) + 1;
  const end = Math.min(start + pageSize - 1, totalPages * pageSize);
  return { start, end };
};
