import React from 'react';
import { TextInput } from 'legacy/components/common/design-system';
import { BatteryType } from 'legacy/types';
import { AddressForm, AddressFormType } from './AddressForm';
import { Column, Row } from '../common/myeverbright';

export interface SystemFormType {
  address: AddressFormType;
  panels: string | null;
  inverters: string | null;
  install_cost: number | null;
  capacity: number | null;
  estimated_offset: string | null;
  system_mounting_type: string;
  batteries: BatteryType[];
}

function SystemForm({ system, setSystem }: { system: SystemFormType; setSystem: any }) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSystem({ ...system, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (address: AddressFormType) => {
    setSystem({ ...system, address });
  };

  return (
    <Column>
      <Row>
        <Row $flex="1">
          <TextInput
            ariaLabel="Panels"
            label="Panels"
            name="panels"
            isRequired
            defaultValue={system.panels || ''}
            onChange={handleChange}
          />
        </Row>
        <Row $flex="1">
          <TextInput
            ariaLabel="Inverters"
            label="Inverters"
            name="inverters"
            isRequired
            defaultValue={system.inverters || ''}
            onChange={handleChange}
          />
        </Row>
      </Row>
      <Row $justifyContent="space-between">
        <Row>
          <TextInput
            type="number"
            ariaLabel="Capacity"
            label="Capacity"
            name="capacity"
            defaultValue={system.capacity?.toString() || ''}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <TextInput
            ariaLabel="Estimated Offset"
            label="Estimated Offset"
            name="estimated_offset"
            defaultValue={system.estimated_offset || ''}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <TextInput
            ariaLabel="Array Types"
            label="Array Types"
            name="system_mounting_type"
            isRequired
            defaultValue={system.system_mounting_type}
            onChange={handleChange}
          />
        </Row>
        <Row>
          <TextInput
            type="number"
            ariaLabel="Install Cost"
            label="Install Cost ( $ )"
            name="install_cost"
            isRequired
            defaultValue={system.install_cost?.toString() || ''}
            onChange={handleChange}
          />
        </Row>
      </Row>
      <Column $gap="0px">
        <p>Address</p>
        <AddressForm address={system.address} onAddressChange={handleAddressChange} />
      </Column>
    </Column>
  );
}

export default SystemForm;
