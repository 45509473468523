import { AgreementType, SystemType } from 'api/types';

export const hasMonitoring = (system: SystemType): boolean =>
  system.has_solar_monitoring ||
  system.has_battery_monitoring ||
  system.has_home_monitoring ||
  system.has_grid_monitoring;

export const getAgreementWithMonitoring = (agreements?: AgreementType[]) =>
  agreements?.find((ag) => hasMonitoring(ag.system));
