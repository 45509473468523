import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function LocationPinIcon({ color = colors.secondaryDarkBlue, size = 'small' }: IcoProps) {
  const dimentions = size === 'small' ? '16' : '24';
  return (
    <svg
      width={dimentions}
      height={dimentions}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 6.01557C10.5 7.4004 9.38125 8.52205 8 8.52205C6.61875 8.52205 5.5 7.4004 5.5 6.01557C5.5 4.63073 6.61875 3.50908 8 3.50908C9.38125 3.50908 10.5 4.63073 10.5 6.01557ZM8 7.51946C8.82812 7.51946 9.5 6.84584 9.5 6.01557C9.5 5.18529 8.82812 4.51168 8 4.51168C7.17188 4.51168 6.5 5.18529 6.5 6.01557C6.5 6.84584 7.17188 7.51946 8 7.51946ZM14 6.01557C14 8.7539 10.3438 13.629 8.74062 15.6405C8.35625 16.1198 7.64375 16.1198 7.25938 15.6405C5.62813 13.629 2 8.7539 2 6.01557C2 2.69322 4.68625 0 8 0C11.3125 0 14 2.69322 14 6.01557ZM8 1.00259C5.2375 1.00259 3 3.2459 3 6.01557C3 6.50433 3.16969 7.17482 3.5175 8.00196C3.85844 8.81343 4.3375 9.69384 4.87937 10.5742C5.94375 12.3068 7.20625 13.9643 8 14.9637C8.79375 13.9643 10.0562 12.3068 11.1219 10.5742C11.6625 9.69384 12.1406 8.81343 12.4812 8.00196C12.8313 7.17482 13 6.50433 13 6.01557C13 3.2459 10.7625 1.00259 8 1.00259Z"
        fill={color}
      />
    </svg>
  );
}

export default LocationPinIcon;
