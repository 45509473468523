import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { routes } from '../../../routes';
import AccountChart, { SeriesData } from './AccountChart';
import { Column, Row } from '../common/myeverbright';

export default function UserStatistics() {
  const [invited, setInvited] = useState(0);
  const [claimedAccounts, setClaimedAccounts] = useState(0);
  const [unclaimedAccounts, setUnclaimedAccounts] = useState(0);
  const [invitedSeriesData, setInvitedSeriesData] = useState<SeriesData>([]);
  const [claimedSeriesData, setClaimedSeriesData] = useState<SeriesData>([]);
  const [claimedOffset, setClaimedOffset] = useState(0);

  useEffect(() => {
    axios.get(routes.userStatistics).then((response) => {
      const {
        data: {
          total_homeowners_invited: totalHomeownersInvited,
          total_homeowner_accounts_claimed: totalHomeownerAccountsClaimed,
        },
      } = response;
      setInvited(totalHomeownersInvited);
      setClaimedAccounts(totalHomeownerAccountsClaimed);
      setUnclaimedAccounts(totalHomeownersInvited - totalHomeownerAccountsClaimed);
      setInvitedSeriesData(response.data.invited);
      setClaimedSeriesData(response.data.claimed);
      setClaimedOffset(response.data.claimed_offset);
    });
  }, []);

  return (
    <Column>
      <Row $justifyContent="space-around">
        <Column>
          <div className="user-statistic">
            <div className="value">{invited}</div>
            <div className="label">Homeowners Invited</div>
          </div>
        </Column>
        <Column>
          <div className="user-statistic">
            <div className="value">{claimedAccounts}</div>
            <div className="label">Claimed Homeowner Accounts</div>
          </div>
        </Column>
        <Column>
          <div className="user-statistic">
            <div className="value">{unclaimedAccounts}</div>
            <div className="label">Unclaimed Homeowner Accounts</div>
          </div>
        </Column>
      </Row>
      <Row>
        <Column $width="100%">
          <AccountChart
            claimedOffset={claimedOffset}
            claimedSeriesData={claimedSeriesData}
            invitedSeriesData={invitedSeriesData}
          />
        </Column>
      </Row>
    </Column>
  );
}
