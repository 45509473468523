import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageCodeKey, WelcomeChecklistItemType } from 'legacy/types';
import { RadioButtonGroup } from 'legacy/components/common/design-system';
import { BodyBold } from 'legacy/components/common/design-system/Typography';
import {
  ChecklistItem,
  ErrorText,
  QuestionsContainer,
  TextWrapper,
} from './WelcomeChecklistStyles';

export type SkippedQuestionsType = {
  [order: number]: WelcomeChecklistItemType;
};

type Props = {
  questions: WelcomeChecklistItemType[];
  skippedQuestions: SkippedQuestionsType;
  primaryLanguage: LanguageCodeKey;
  onChangeAnswer: (questionOrder: number, answer: string) => void;
};

export function Questions({ questions, skippedQuestions, primaryLanguage, onChangeAnswer }: Props) {
  const { t } = useTranslation();

  return (
    <QuestionsContainer>
      {questions.map((question, index) => {
        const primaryLanguageQuestion = question[`question_${primaryLanguage}`];

        return (
          <ChecklistItem
            key={primaryLanguageQuestion}
            id={`checklist-item-${index}`}
            $hideBorder={index === questions.length - 1}
          >
            <TextWrapper>
              <BodyBold>{primaryLanguageQuestion}</BodyBold>
              {!question?.answer && skippedQuestions[index] && (
                <ErrorText>{t('welcomeChecklist.selectOneError')}</ErrorText>
              )}
            </TextWrapper>
            <RadioButtonGroup
              id={`radio-${index}`}
              name={`group-${index}`}
              onChange={(_, value) => {
                onChangeAnswer(index, value);
              }}
              value={question?.answer}
              options={[
                {
                  id: `radio-yes-${index}`,
                  value: t('welcomeChecklist.yes'),
                  label: t('welcomeChecklist.yes'),
                },
                {
                  id: `radio-no-${index}`,
                  value: t('welcomeChecklist.no'),
                  label: t('welcomeChecklist.no'),
                },
              ]}
              margin="auto 18px auto 0"
            />
          </ChecklistItem>
        );
      })}
    </QuestionsContainer>
  );
}

export default Questions;
