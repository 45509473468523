import { UpdateUserRequestBody } from 'api/user';
import parsePhoneNumberFromString, { E164Number } from 'libphonenumber-js';

export interface IFormInput {
  phoneNumber: E164Number;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export const getUpdatedData = (
  formData: IFormInput,
  defaultValues: IFormInput,
): UpdateUserRequestBody => {
  const updatedData: UpdateUserRequestBody = {};

  if (
    formData.phoneNumber !==
    parsePhoneNumberFromString(defaultValues.phoneNumber, 'US')?.formatNational()
  ) {
    updatedData.phone_number = formData.phoneNumber;
  }
  if (
    formData.street1 !== defaultValues.street1 ||
    formData.street2 !== defaultValues.street2 ||
    formData.city !== defaultValues.city ||
    formData.state !== defaultValues.state ||
    formData.zip !== defaultValues.zip
  ) {
    updatedData.address = {
      street_1: formData.street1,
      street_2: formData.street2,
      city: formData.city,
      state: formData.state,
      zip_code: formData.zip,
    };
  }

  return updatedData;
};
