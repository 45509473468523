import React, { useContext } from 'react';

import { useTooltip } from 'hooks/useTooltip';

type TooltipContextProps = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<TooltipContextProps>(null);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context === null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export { useTooltipContext, TooltipContext };
