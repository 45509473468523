import React, { useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Box, Pagination } from 'legacy/components/common/myeverbright';
import { TextInput } from 'legacy/components/common/design-system';
import { AdminContext } from '../../../contexts/AdminContext';
import { Grid } from './TableViewStyles';
import { getAgreements } from '../../../api/agreement';
import AgreementTable from '../../components/admin/AgreementTable';

export default function AgreementTableView() {
  const { agreementTableState, setAgreementTableState } = useContext(AdminContext);
  const { page, pageSize, direction, sortBy } = agreementTableState;

  const [query, setQuery] = useState('');
  const debouncedSetQuery = debounce((q) => setQuery(q), 750);

  useEffect(() => {
    getAgreements({
      search: query,
      page,
      page_size: pageSize,
      sort_by: sortBy,
      direction,
    }).then((response) => {
      setAgreementTableState({
        agreements: response.data.results,
        page,
        pageSize,
        count: response.data.count,
        totalPages: Math.ceil(response.data.count / pageSize),
        query,
        direction,
        sortBy,
      });
    });
  }, [query, page, pageSize, sortBy, direction, setAgreementTableState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetQuery(e.target.value);
  };

  const handlePageChange = (activePage: number) => {
    setAgreementTableState({
      ...agreementTableState,
      page: activePage,
    });
  };

  return (
    <Grid>
      <Box $width="25%">
        <TextInput
          ariaLabel="Search"
          hasLabel={false}
          placeholder="Search Agreements"
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              debouncedSetQuery.cancel();
              setQuery((e.target as HTMLInputElement).value);
            }
          }}
        />
      </Box>

      <AgreementTable />

      <Pagination
        totalPages={agreementTableState.totalPages}
        activePage={agreementTableState.page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </Grid>
  );
}
