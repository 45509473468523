import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors } from 'theme/colors';
import { Container as DashboardContainer } from '../dashboard/DashboardStyles';

export const Container = styled(DashboardContainer)`
  .edit {
    color: ${colors.secondaryDarkBlue};
    cursor: pointer;
  }

  .profile-name {
    font-size: 20px;
  }

  .pull-right {
    float: right;
  }

  .tags {
    display: flex;
    margin: 5px 0;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;

    .tag {
      display: flex;
      align-items: center;
      background: ${colors.gray10};
      padding: 10px;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 8px;
      line-height: 14px;
    }
  }

  .user-statistic {
    padding-top: 14px;
    .value {
      font-size: 48px;
      text-align: center;
      padding: 14px;
    }
    .label {
      text-align: center;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  border-bottom: 1px solid #d4d4d5;

  color: rgba(0, 0, 0, 0.95) !important;
  a:hover {
    color: rgba(0, 0, 0, 0.95);
  }
`;

export const MenuItem = styled(NavLink)`
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 2px solid transparent;
  padding: 0.92857143em 1.42857143em;
  color: rgba(0, 0, 0, 0.87) !important;

  &.active {
    background: none #fff;
    border-color: #d4d4d5;
    border-radius: 8px 8px 0 0;
    border-top-width: 1px;
    font-weight: 700;
    margin-bottom: -1px;
  }
`;
