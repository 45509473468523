import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionItemType } from 'shared/components/Accordion/Accordion';
import TextInput from 'shared/components/TextInput/TextInput';
import { FAQWrapper, InputWrapper, Title } from './FAQs.styles';

type Props = {
  searchQuery: string;
  items: AccordionItemType[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function FAQs({ searchQuery, items, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <FAQWrapper>
      <Title as="h3">{t('support&FAQs.fAQs')}</Title>

      <InputWrapper>
        <TextInput
          ariaLabel={t('support&FAQs.searchFAQ')}
          placeholder={t('support&FAQs.searchFAQ')}
          onChange={onChange}
        />
      </InputWrapper>

      <Accordion
        styleVariant="tertiary"
        items={items}
        size="small"
        isOpen={searchQuery.length > 0 && items.length > 0}
      />
    </FAQWrapper>
  );
}
export default FAQs;
