import React, { useCallback, useMemo, useState } from 'react';
import { ActiveUnderline, Button, Container, TabsContainer, Text, Underline } from './TabsStyles';

export type Props = {
  /**
   * Tab text
   */
  tabValues: string[];
  /**
   * Optional active default Tab
   */
  defaultTab?: number;
  /**
   * Optional onClick handler
   */
  onClick?: (value: string) => void;
};

// TODO: Once design has Tabs in a design system we'll need to refactor and move this to common components
export function Tabs({ tabValues, defaultTab = 0, onClick }: Props) {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleClick = useCallback(
    (idx: number) => {
      setActiveTab(idx);
      onClick?.(tabValues[idx]);
    },
    [tabValues, onClick],
  );

  const tabs = useMemo(
    () =>
      tabValues.map((tab, idx) => {
        const text = tab.charAt(0).toUpperCase() + tab.slice(1);
        return (
          <Button
            key={`${text}-buttonTab`}
            type="button"
            onClick={() => handleClick(idx)}
            active={activeTab === idx}
          >
            <Text>{text}</Text>
            {activeTab === idx ? <ActiveUnderline /> : null}
          </Button>
        );
      }),
    [activeTab, handleClick, tabValues],
  );

  return (
    <Container>
      <TabsContainer>{tabs}</TabsContainer>
      <Underline />
    </Container>
  );
}

export default Tabs;
