import React from 'react';
import { TTableRow } from '../TTable';
import { TableCell, TableRowStyled } from './TableRowStyles';

type Props = {
  tableRowData: TTableRow;
};
export function TableRow({ tableRowData }: Props) {
  return (
    <TableRowStyled
      $hasCursor={Boolean(tableRowData.handleClick)}
      onClick={tableRowData.handleClick}
    >
      {tableRowData.cells.map((item, idx) => {
        const key = `${tableRowData.key}-${idx}`;
        return <TableCell key={key}>{item}</TableCell>;
      })}
    </TableRowStyled>
  );
}

export default TableRow;
