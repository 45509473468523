import React from 'react';
import { Outlet } from 'react-router-dom';

import ErrorBoundary from 'shared/components/ErrorBoundary/ErrorBoundary';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import { Wrapper } from './LayoutStyles';

type Props = {
  accountView?: boolean;
  hasNav?: boolean;
  hasFooter?: boolean;
  children?: JSX.Element;
};

function Layout({ accountView, hasNav, hasFooter, children }: Props) {
  return (
    <Wrapper $accountView={accountView}>
      {hasNav ? (
        <ErrorBoundary>
          <NavBar />
        </ErrorBoundary>
      ) : null}
      <main>
        <ErrorBoundary>{children || <Outlet />}</ErrorBoundary>
      </main>
      {hasFooter ? <Footer /> : null}
    </Wrapper>
  );
}

export default Layout;
