import React from 'react';
import { useTheme } from 'styled-components';

export function ChartLinearGradient() {
  const theme = useTheme();

  return (
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop stopColor={theme.colors.chartGradientStart1} stopOpacity="0.9" />
        <stop offset=".5" stopColor={theme.colors.chartGradientMiddle1} stopOpacity="0.45" />
        <stop offset="1" stopColor={theme.colors.chartGradientEnd1} stopOpacity="0.15" />
      </linearGradient>
    </defs>
  );
}

export default ChartLinearGradient;
