import { colors } from '../colors';
import { TButtons } from './TButtons';

export const lightThemeButtons: TButtons = {
  variants: {
    primary: {
      enabled: {
        // default
        background: colors.primary,
        border: `1.5px solid ${colors.primary}`,
        fontColor: colors.secondary,
        padding: '10px 24px',

        // hover
        backgroundHover: colors.primaryHover,
        borderHover: `1.5px solid ${colors.primaryHover}`,
        fontColorHover: colors.secondaryHover,

        // active
        backgroundActive: colors.primaryActive,
        borderActive: `1.5px solid ${colors.primaryActive}`,
        fontColorActive: colors.secondaryActive,
      },
    },
    secondary: {
      enabled: {
        // default
        background: colors.secondary,
        border: `1.5px solid ${colors.primary}`,
        fontColor: colors.primary,
        padding: '10px 24px',

        // hover
        backgroundHover: colors.secondaryHover,
        borderHover: `1.5px solid ${colors.primaryHover}`,
        fontColorHover: colors.primaryHover,

        // active
        backgroundActive: colors.secondaryActive,
        borderActive: `1.5px solid ${colors.primaryActive}`,
        fontColorActive: colors.primaryActive,
      },
    },
    tertiary: {
      enabled: {
        // default
        background: colors.tertiary,
        border: 'none',
        fontColor: colors.primary,
        padding: '0',

        // hover
        backgroundHover: colors.tertiaryHover,
        borderHover: 'none',
        fontColorHover: colors.primaryHover,

        // active
        backgroundActive: colors.tertiaryActive,
        borderActive: 'none',
        fontColorActive: colors.primaryActive,
      },
    },
    permanentPrimary: {
      enabled: {
        // default
        background: colors.permanentPrimary,
        border: `1.5px solid ${colors.permanentPrimary}`,
        fontColor: colors.permanentSecondary,
        padding: '10px 24px',

        // hover
        backgroundHover: colors.permanentPrimaryHover,
        borderHover: `1.5px solid ${colors.permanentPrimaryHover}`,
        fontColorHover: colors.permanentSecondaryHover,

        // active
        backgroundActive: colors.permanentPrimaryActive,
        borderActive: `1.5px solid ${colors.permanentPrimaryActive}`,
        fontColorActive: colors.permanentSecondaryActive,
      },
    },
    permanentSecondary: {
      enabled: {
        // default
        background: colors.permanentSecondary,
        border: `1.5px solid ${colors.permanentPrimary}`,
        fontColor: colors.permanentPrimary,
        padding: '10px 24px',

        // hover
        backgroundHover: colors.permanentSecondaryHover,
        borderHover: `1.5px solid ${colors.permanentPrimaryHover}`,
        fontColorHover: colors.permanentPrimaryHover,

        // active
        backgroundActive: colors.permanentSecondaryActive,
        borderActive: `1.5px solid ${colors.permanentPrimaryActive}`,
        fontColorActive: colors.permanentPrimaryActive,
      },
    },
  },
};

export default lightThemeButtons;
