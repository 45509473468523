import styled, { css } from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const FormWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
  max-width: 555px;
  box-shadow: ${({ theme }) => theme.elevations.low};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 24px;
  }
`;

export const StaticTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 12px;
  }
`;

export const Label = styled(Text)<{ $styleVariant?: 'default' | 'large' }>`
  ${({ theme, $styleVariant }) => css`
    font-family: Avenir;
    font-size: ${$styleVariant === 'large' ? '16px' : '14px'};
    font-weight: 400;
    line-height: 24px;
    color: ${theme.colors.textSecondarySubtleGrey80};
  `}
`;

export const Value = styled(Label)`
  ${({ theme, $styleVariant }) => css`
    font-size: ${$styleVariant === 'large' ? '16px' : '14px'};
    font-weight: ${$styleVariant === 'large' ? '800' : '400'};
    color: ${theme.colors.textPrimarySecondary1100};
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 4px;
  button {
    max-width: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: 8px;
  }
`;
