/**
 *
 * STYLE VARIANTS
 *
 */

import { Color } from 'theme/colors';

// INTERACTIONS
export type TAccordionStyles = {
  background: string;
  labelColor: Color;
};

// STYLE VARIANTS
export type TAccordionStyleVariants = {
  primary: TAccordionStyles;
  secondary: TAccordionStyles;
  tertiary: TAccordionStyles;
};
export type TAccordionStyleVariantsKey = keyof TAccordionStyleVariants;
export const PRIMARY_ACCORDION_STYLE_VARIANT: TAccordionStyleVariantsKey = 'primary';
export const SECONDARY_ACCORDION_STYLE_VARIANT: TAccordionStyleVariantsKey = 'secondary';
export const TERTIARY_ACCORDION_STYLE_VARIANT: TAccordionStyleVariantsKey = 'tertiary';

/**
 *
 * TAccordions in the theme
 *
 */
export type TAccordions = {
  variants: TAccordionStyleVariants; // used for all accordions
};
