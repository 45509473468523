import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useNavigate } from 'react-router';

import { Text } from 'shared/components/Text/Text';
import { AgreementType } from 'api/types';
import { pages } from 'pages';
import {
  AgreementsSectionWrapper,
  CardWrapper,
  SubText,
  TitleWrapper,
} from './AgreementsSection.styles';
import { AgreementCard } from '../AgreementCard/AgreementCard';

type Props = {
  agreements: AgreementType[];
};

export function AgreementsSection({ agreements }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AgreementsSectionWrapper>
      <TitleWrapper>
        <Text as="h2">{t('overview.agreements')}</Text>
        <SubText as="p">{t('overview.clickAgreement')}</SubText>
      </TitleWrapper>
      <CardWrapper>
        {agreements.map((agreement) => {
          const term =
            agreement.contract_term === 1
              ? t('agreementDetails.termValue', { value: agreement.contract_term })
              : t('agreementDetails.termValuePlural', { value: agreement.contract_term });

          const handleClick = () => {
            navigate(resolvePath(`${pages.AGREEMENTS}/${agreement.id}`, `/${pages.DASHBOARD}`));
          };

          return (
            <AgreementCard
              key={agreement.id}
              title={agreement.product_display_name}
              number={`#${agreement?.homeowner_contract_id}`}
              term={term}
              onClick={handleClick}
            />
          );
        })}
      </CardWrapper>
    </AgreementsSectionWrapper>
  );
}

export default AgreementsSection;
