import React from 'react';
import { OrganizationType } from 'legacy/types';
import StatusDetailsCard from 'legacy/components/StatusDetailsCard/StatusDetailsCard';
import { LogoImage } from 'legacy/components/StatusDetailsCard/StatusDetailsCardStyles';

interface Props {
  organization: OrganizationType;
}

export function InstallerDetailsCard({ organization }: Props) {
  return (
    <StatusDetailsCard
      title="Installation Partner Details"
      bodyText={[
        {
          label: 'Install Partner',
          subText: organization.name,
        },
        {
          label: 'Installer Website',
          subText: organization.website_url,
        },
      ]}
    >
      {organization.logo_image_url ? <LogoImage src={organization.logo_image_url} /> : undefined}
    </StatusDetailsCard>
  );
}

export default InstallerDetailsCard;
