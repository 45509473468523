import styled from 'styled-components';
import colors from 'theme/colors';
import { BodyRegular } from 'legacy/components/common/design-system/Typography';
import { MOBILE_MAX_WIDTH } from '../../../constants';

export const ReviewContainer = styled.div`
  display: grid;
  width: 100%;
  padding-top: 8px;
  max-height: 664px;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionsHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 64px;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: auto 0;
    overflow: hidden;
    gap: 0;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SubHeadingLeft = styled.div<{ bold?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.secondaryDarkBlue};
  font-weight: 400;
`;

export const SubHeadingRight = styled(SubHeadingLeft)`
  font-weight: 700;
  margin-right: 24px;
  span:first-child {
    margin: 0 48px 0 14px;
  }
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`;

export const ChecklistItem = styled(QuestionsHeaderContainer)<{ $hideBorder: boolean }>`
  border-bottom: ${({ $hideBorder }) => ($hideBorder ? 0 : '0.5px solid #dbdbdb')};

  fieldset .radio-button-outer-container:first-child {
    margin-right: 56px;
  }

  .radio-label {
    display: none;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
    flex-direction: column;
    align-items: flex-start;
    fieldset .radio-button-outer-container:first-child {
      margin-right: 48px;
    }

    .radio-group-container {
      margin: 0 0 12px;
    }

    .radio-label {
      display: inline;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 606px;
  width: 100%;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    padding: 16px 0px 16px 0px;
  }
`;

export const ErrorText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.red};
  margin-top: 4px;
`;

export const ReviewPageSubTitle = styled(BodyRegular)`
  display: inline-block;
  margin-bottom: 16px;
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const ReviewItem = styled.div<{ boldText?: boolean }>`
  display: flex;
  margin-bottom: 16px;
  line-height: 24px;

  p {
    font-weight: ${({ boldText }) => (boldText ? 900 : 400)};
  }
`;
