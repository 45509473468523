import { BodyRegular } from 'legacy/components/common/design-system/Typography';
import styled from 'styled-components';
import colors, { Color } from 'theme/colors';

export const Text = styled(BodyRegular)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkWrapper = styled.div<{ $linkColor?: Color }>`
  margin: auto 0 0;

  a {
    color: ${({ $linkColor = colors.secondaryDarkBlue }) => $linkColor};
  }
`;
