import React, { useEffect, useState } from 'react';
import { CaptionProps, DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { addMonths, format, subMonths, endOfMonth } from 'date-fns';
import { CustomCaption } from './CustomCaption';
import { CustomFooter } from './CustomFooter';

import { StyledDayPicker } from './DateSelector.styles';
import { isDateDisabled, formatWeekdayName } from './DateSelector.utils';

type DateMode = 'single' | 'range';
type SelectedDateType = Date | DateRange;

type Props = {
  mode?: DateMode;
  selectedDate: SelectedDateType;
  onDayClick: (date: Date) => void;
  onCancel: () => void;
  onOk: () => void;
  dateRange: { oldest: Date; newest: Date };
  inServiceDate: Date;
};

const initCurrentMonth = (mode: DateMode, selectedDate: SelectedDateType): Date => {
  if (mode === 'single') return (selectedDate as Date) || new Date();
  return (selectedDate as DateRange).from || new Date();
};

export function Calendar({
  mode = 'single',
  selectedDate,
  onDayClick,
  onCancel,
  onOk,
  dateRange,
  inServiceDate,
}: Props) {
  const [currentMonth, setCurrentMonth] = useState(initCurrentMonth(mode, selectedDate));
  const [isEarliestMonth, setIsEarliestMonth] = useState(false);
  const [isLatestMonth, setIsLatestMonth] = useState(false);

  useEffect(() => {
    // disable dates before the system was in service (or if that information isn't available use the turn of the century)
    const earliestDate = inServiceDate;
    setIsEarliestMonth(earliestDate > currentMonth);
    setIsLatestMonth(endOfMonth(currentMonth) > new Date());
  }, [dateRange, currentMonth, inServiceDate]);

  const handlePrevClick = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextClick = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  return mode === 'single' ? (
    <StyledDayPicker
      components={{
        Caption: ({ displayMonth }: CaptionProps) =>
          CustomCaption({
            header: format(displayMonth, 'MMMM yyyy'),
            onPrevClick: isEarliestMonth ? undefined : handlePrevClick,
            onNextClick: isLatestMonth ? undefined : handleNextClick,
            hasHeaderSpacer: true,
          }),
        Footer: () => CustomFooter({ onCancelClick: onCancel, onOkClick: onOk }),
      }}
      mode="single"
      selected={selectedDate as Date}
      onDayClick={onDayClick}
      month={currentMonth}
      disabled={(date) => isDateDisabled(date, false, inServiceDate)}
      formatters={{
        formatWeekdayName,
      }}
      classNames={{
        table: 'custom-table',
        cell: `custom-cell`,
        day_selected: 'custom-day-selected',
        day_range_start: 'custom-day-range-start',
        day_range_middle: 'custom-day-range-middle',
        day_range_end: 'custom-day-range-end',
        head_cell: 'custom-head-cell',
        month: `custom-month`,
        nav_button_next: `custom-nav-next`,
        nav_button_previous: `custom-nav-prev`,
      }}
    />
  ) : (
    <StyledDayPicker
      components={{
        Caption: ({ displayMonth }: CaptionProps) =>
          CustomCaption({
            header: format(displayMonth, 'MMMM yyyy'),
            onPrevClick: isEarliestMonth ? undefined : handlePrevClick,
            onNextClick: isLatestMonth ? undefined : handleNextClick,
            hasHeaderSpacer: true,
          }),
        Footer: () => CustomFooter({ onCancelClick: onCancel, onOkClick: onOk }),
      }}
      mode="range"
      selected={selectedDate as DateRange}
      onDayClick={onDayClick}
      disabled={(date) => isDateDisabled(date, true, inServiceDate)}
      month={currentMonth}
      formatters={{
        formatWeekdayName,
      }}
      classNames={{
        table: 'custom-table',
        cell: `custom-cell`,
        day_selected: 'custom-day-selected',
        day_range_start: 'custom-day-range-start',
        day_range_middle: 'custom-day-range-middle',
        day_range_end: 'custom-day-range-end',
        head_cell: 'custom-head-cell',
        month: `custom-month`,
        nav_button_next: `custom-nav-next`,
        nav_button_previous: `custom-nav-prev`,
      }}
    />
  );
}

export default Calendar;
