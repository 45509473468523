import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authCopyAckNeeded } from 'legacy/utils';
import { MessageType } from 'legacy/types';
import { CtaButton } from 'legacy/components/common/design-system/Modal/types';
import useUser from 'hooks/useUser';
import { acknowledgeAuthCopy } from 'api/agreement';
import { Modal } from 'legacy/components/common/design-system';
import AuthCopyAckConfirmed from 'legacy/components/AuthCopyAckConfirmed';
import AuthCopyAck from 'legacy/components/AuthCopyAck/AuthCopyAck';
import { ModalBodyError } from 'legacy/components/ModalBodyError';
import { perSessionModalKey } from '../../constants';
import { BodyWrapper } from './AuthCopyAckModalStyles';

type Props = {
  modalKey?: string;
};

export function AuthCopyAckModal({ modalKey }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [modalBodyError, setModalBodyError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  const { t } = useTranslation();

  const { userResult } = useUser();

  useEffect(() => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);
    const { isNeeded } = authCopyAckNeeded(userResult?.user || undefined);

    if (!isNeeded) return; // we don't need to show the modal

    if (perSessionModalsRaw != null && modalKey) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      const savedModalVisible =
        modalsObj[modalKey] === undefined ? (modalsObj[modalKey] = true) : modalsObj[modalKey];

      localStorage.setItem(perSessionModalKey, JSON.stringify(modalsObj));
      setIsOpen(savedModalVisible);
    } else if (modalKey) {
      localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
      setIsOpen(true);
    }
  }, [modalKey, userResult]);

  const handleAcknowledge = async () => {
    setMessage(null);

    if (!confirmed) {
      setMessage({
        message: t('acknowledgeAuthoritativeCopy.acknowledgeCopy.checkboxError'),
        type: 'error',
      });
      return;
    }

    setIsLoading(true);
    try {
      const { isNeeded, agreements } = authCopyAckNeeded(userResult?.user || undefined);
      if (isNeeded) {
        const promises = agreements.map((agreement) => acknowledgeAuthCopy(agreement.id));

        await Promise.all(promises);
        setShowConfirmed(true);
      }
    } catch (error) {
      setModalBodyError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeConfirm = () => {
    setConfirmed((c) => !c);
  };

  const handleClose = () => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);
    if (perSessionModalsRaw != null && modalKey) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      modalsObj[modalKey] = false;
      localStorage.setItem(perSessionModalKey, JSON.stringify(modalsObj));
    }

    setIsOpen(false);
  };

  const generateCtaButtons = () => {
    const primary: CtaButton = {
      label: showConfirmed ? t('cta.close') : t('cta.submit'),
      isLoading,
      onClick: showConfirmed ? handleClose : handleAcknowledge,
    };

    const tertiary: CtaButton = {
      label: t('cta.cancel'),
      onClick: handleClose,
    };

    if (modalBodyError) {
      primary.label = t('cta.tryAgain');
      primary.onClick = handleAcknowledge;
    }

    return {
      tertiary: showConfirmed ? undefined : tertiary,
      primary,
    };
  };

  const modalBody = showConfirmed ? (
    <AuthCopyAckConfirmed />
  ) : (
    <AuthCopyAck onChangeConfirm={handleChangeConfirm} confirmed={confirmed} message={message} />
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('acknowledgeAuthoritativeCopy.title')}
      contentLabel=""
      styleVariant="tertiary"
      hasFadeEffect
      onRequestClose={handleClose}
      ctaButtons={generateCtaButtons()}
    >
      <BodyWrapper>{modalBodyError ? <ModalBodyError /> : modalBody}</BodyWrapper>
    </Modal>
  );
}

export default AuthCopyAckModal;
