import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const FAQsSectionWrapper = styled.div``;

export const FAQsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  div {
    flex-basis: 100%;
    margin: 0;
    max-width: unset;

    h2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      font-family: Avenir;
      font-weight: 800 !important;
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 48px;
  }
`;

export const CardDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 100;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    -webkit-line-clamp: 2;
  }
`;
