import React, { useState } from 'react';
import { isMarketing, isSupport } from 'legacy/utils';
import { LinkIcon } from 'legacy/components/common/design-system/Icons';
import { Tooltip } from 'legacy/components/common/myeverbright';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import pages from 'pages';
import { FaqType } from 'legacy/types';

export default function FaqTitle({ faq }: { faq: FaqType }) {
  const [showLinkIcon, setShowLinkIcon] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const faqPath = `/${pages.FAQ.replace(':faqId', faq.id)}/${faq.url_slug}`;

  const { userResult } = useUser();
  const user = userResult?.user;

  const navigate = useNavigate();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.host + faqPath}`);
    setLinkCopied(true);
    setTimeout(() => setShowLinkIcon(false), 1200);
  };

  return (
    <div
      className="faq-title"
      onMouseEnter={() => setShowLinkIcon(true)}
      onMouseLeave={() => setShowLinkIcon(false)}
    >
      <span
        role="button"
        tabIndex={0}
        onKeyDown={() => navigate(faqPath)}
        onClick={() => navigate(faqPath)}
      >
        {faq.question}
      </span>
      {user && (isSupport(user.role) || isMarketing(user.role)) && showLinkIcon && (
        <Tooltip
          content={linkCopied ? 'Copied!' : 'Copy link to FAQ'}
          triggerIcon={<LinkIcon />}
          onClick={handleCopyLink}
          onMouseLeave={() => setLinkCopied(false)}
        />
      )}
    </div>
  );
}
