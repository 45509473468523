import { Select, TextInput } from 'legacy/components/common/design-system';
import React, { useContext, useMemo } from 'react';
import { Subtitle2 } from 'legacy/components/common/design-system/Typography';
import { Checkbox, Column, Row } from 'legacy/components/common/myeverbright';
import { TDropdownDataItem } from 'legacy/components/common/design-system/Dropdown/TDropdown';
import { AdminContext } from 'contexts/AdminContext';
import { TextAreaWrapper } from './FaqFormStyles';

export interface FaqFormType {
  question: string;
  answer: string;
  hidden: boolean;
  order: number;
}
function FaqForm({ faq, setFaq }: { faq: FaqFormType; setFaq: any }) {
  const { faqs } = useContext(AdminContext);

  const orderOptions = useMemo(
    () =>
      Array.from(
        { length: faqs.length + 1 },
        (_, idx): TDropdownDataItem => ({
          title: (idx + 1).toString(),
          value: (idx + 1).toString(),
        }),
      ),
    [faqs],
  );

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFaq({ ...faq, question: e.target.value });
  };

  const handleOrderChange = (value: string) => {
    setFaq({ ...faq, order: Number(value) });
  };

  return (
    <Column $gap="12px">
      <TextInput
        ariaLabel="faq question"
        label="Question"
        margin="0 0 12px"
        onChange={handleQuestionChange}
      />
      <TextAreaWrapper>
        <Subtitle2>Answer</Subtitle2>
        <textarea value={faq.answer} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} />
      </TextAreaWrapper>

      <Row $alignItems="flex-end">
        <Select
          label="Order"
          value={faq.order.toString()}
          options={orderOptions}
          onSelectOption={handleOrderChange}
        />

        <Checkbox
          isChecked={faq.hidden}
          text="Hidden"
          onChange={() => setFaq({ ...faq, hidden: !faq.hidden })}
        />
      </Row>
    </Column>
  );
}

export default FaqForm;
