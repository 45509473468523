import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { dimensions, mobileDimensions } from './ProgressBarStyles';
import { Segment } from './Segment';
import { MOBILE_MAX_WIDTH } from '../../constants';

export interface ProgressBarProps {
  stages: string[];
  currentStage: string;
}

export function ProgressBar({ stages, currentStage }: ProgressBarProps) {
  const isSmallScreen = useMediaQuery({
    maxWidth: MOBILE_MAX_WIDTH,
  });

  const { width, height, dividerRun } = isSmallScreen ? mobileDimensions : dimensions;

  const segmentWidth = width / stages.length;

  const segments = stages.map((_, idx) => (
    <Segment
      key={`progress-segment-${stages[idx]}`}
      segmentIdx={idx}
      stages={stages}
      currentStage={currentStage}
      isSmallScreen={isSmallScreen}
    />
  ));

  const dividerLines = Array.from({ length: stages.length - 1 }).map((_, idx) => (
    <line
      key={`progress-divider-${stages[idx]}`}
      x1={segmentWidth * (idx + 1) + dividerRun / 2}
      y1="0"
      x2={segmentWidth * (idx + 1) - dividerRun / 2}
      y2="100%"
      stroke="white"
      strokeWidth="1"
    />
  ));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="inherit"
      height="inherit"
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d={
          isSmallScreen
            ? 'M0 21C0 9.40202 9.40202 0 21 0H322C333.598 0 343 9.40202 343 21C343 32.598 333.598 42 322 42H21C9.40201 42 0 32.598 0 21Z'
            : 'M0 34C0 15.2223 15.2223 0 34 0H715C733.778 0 749 15.2223 749 34C749 52.7777 733.778 68 715 68H34C15.2223 68 0 52.7777 0 34Z'
        }
        fill="url(#paint0_linear_1112_5167)"
      />
      {segments}
      {dividerLines}
      <defs>
        <linearGradient
          id="paint0_linear_1112_5167"
          x1="0"
          y1="0"
          x2={width}
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0096DB" />
          <stop offset="0.5" stopColor="#00B3B5" />
          <stop offset="1" stopColor="#68BB45" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ProgressBar;
