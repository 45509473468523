import React from 'react';
import { useTranslation } from 'react-i18next';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { UserType } from 'api/types';
import { Button } from 'shared/components/Button/Button';
import { ButtonGroup, FormWrapper, Header, Label, StaticTextWrapper, Value } from '../Form.styles';

type Props = {
  user: UserType;
  showEditButton: boolean;
  onEdit: () => void;
};

export function ContactCard({ user, showEditButton, onEdit }: Props) {
  const { t } = useTranslation();

  const handleEdit = () => {
    onEdit();
  };

  return (
    <FormWrapper>
      {!showEditButton && (
        <Label as="p" $margin="0 0 12px">
          {t('account.contactSupportToUpdate')}
        </Label>
      )}
      <Header as="h3">{t('account.primaryContact')}</Header>

      <StaticTextWrapper>
        <div>
          <Label as="p">{t('account.name')}</Label>
          <Value as="p">
            {user.first_name} {user.last_name}
          </Value>
        </div>

        <div>
          <Label as="p">{t('account.email')}</Label>
          <Value as="p">{user.email}</Value>
        </div>

        <div>
          <Label as="p">{t('account.phoneNumber')}</Label>
          <Value as="p">
            {parsePhoneNumberFromString(user.phone_number, 'US')?.formatNational()}{' '}
          </Value>
        </div>

        <div>
          <Label as="p">{t('account.address')}</Label>
          <Value as="p">
            {user.address.street_1}, {user.address.street_2} {user.address.city}{' '}
            {user.address.state} {user.address.zip_code}
          </Value>
        </div>

        {showEditButton && (
          <ButtonGroup>
            <Button
              dataTestId={`${t('cta.edit')}-btn`}
              styleVariant="secondary"
              label={t('cta.edit')}
              maxWidth="100px"
              onClick={handleEdit}
            />
          </ButtonGroup>
        )}
      </StaticTextWrapper>
    </FormWrapper>
  );
}

export default ContactCard;
