import React from 'react';
import { toast as reactToastify, Slide } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { useTheme } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

import { mode1ThemePrimatives } from 'shared/design-system/theme/colors/themeColors';
import { TTheme } from 'shared/design-system/theme/theme';
import { CloseButton, Message, Title, ToastStyled } from './Toast.styles';
import {
  CircleCheckIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  TriangleExclamationIcon,
  XIcon,
} from '../icons';

export type Severity = 'success' | 'error' | 'info' | 'warning';

type ToastProps = {
  type: Severity;
  title: string;
  message: string;
  theme: TTheme;
};

export const toast = ({ type, title, message, theme }: ToastProps) => {
  reactToastify.dismiss();
  switch (type) {
    case 'success':
      reactToastify.success(
        <div>
          <Title as="h3">{title}</Title>
          <Message as="p">{message}</Message>
        </div>,

        {
          icon: <CircleCheckIcon color={mode1ThemePrimatives.secondaryS1100} />,
          style: { backgroundColor: theme.colors.backgroundSystemSuccessPrimary220 },
        },
      );
      break;

    case 'warning':
      reactToastify.warn(
        <div>
          <Title as="h3">{title}</Title>
          <Message as="p">{message}</Message>
        </div>,

        {
          icon: <TriangleExclamationIcon color={mode1ThemePrimatives.secondaryS1100} />,
          style: { backgroundColor: theme.colors.backgroundSystemWarnFunction220 },
        },
      );
      break;

    case 'error':
      reactToastify.error(
        <div>
          <Title as="h3">{title}</Title>
          <Message as="p">{message}</Message>
        </div>,

        {
          icon: <CircleExclamationIcon color={mode1ThemePrimatives.secondaryS1100} />,
          style: { backgroundColor: theme.colors.backgroundSystemErrorFunction120 },
        },
      );
      break;

    default:
      reactToastify.info(
        <div>
          <Title as="h3">{title}</Title>
          <Message as="p">{message}</Message>
        </div>,

        {
          icon: <CircleInfoIcon color={mode1ThemePrimatives.secondaryS1100} />,
          style: { backgroundColor: theme.colors.backgroundSystemInfoSecondary4100 },
        },
      );

      break;
  }
};

export function Toast() {
  const theme = useTheme();

  const closeButton = ({ closeToast }: CloseButtonProps) => (
    <CloseButton onClick={closeToast}>
      <XIcon color={mode1ThemePrimatives.secondaryS1100} />
    </CloseButton>
  );

  return (
    <ToastStyled
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      rtl={false}
      pauseOnFocusLoss
      closeButton={closeButton}
      transition={Slide}
      draggable
      pauseOnHover
      theme={theme}
    />
  );
}
