import React, { useState } from 'react';
import { Button, TextInput } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';

import { resolvePath, useNavigate } from 'react-router-dom';
import { MAXIMUM_PASSWORD_LENGTH } from 'legacy/auth';
import { ButtonWrapper } from './LoginFormStyles';
import { pages } from '../../../pages';
import { useUser } from '../../../hooks/useUser';
import { getDashboardRedirectPath } from '../../utils';

type Props = { handleClearNotifications: () => void };

function LoginForm({ handleClearNotifications }: Props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useUser();

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    handleClearNotifications();

    const response = await login({ email, password });

    if (response.error) {
      if (response.errorData?.password) {
        setErrorMessage(
          `Password exceeds maximum length of ${MAXIMUM_PASSWORD_LENGTH} characters. Please reset your password using the forgot password link below.`,
        );
      } else {
        setErrorMessage('Invalid email address or password.');
      }
    } else {
      const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
        response.user,
        response.user?.accounts?.[0],
      );

      if (hasDashboardAccess) navigate(resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`));
      else navigate(redirectPath);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        ariaLabel="email"
        label="Email Address"
        inputMode="email"
        type="email"
        isRequired
        onChange={handleEmailChange}
        margin="0 0 16px"
      />

      <TextInput
        ariaLabel="email"
        label="Password"
        inputMode="text"
        type="password"
        isRequired
        onChange={handlePasswordChange}
        margin="0 0 48px"
      />
      {errorMessage && <Message severity="error">{errorMessage}</Message>}
      <ButtonWrapper>
        <Button type="submit" label="Log In" isFullWidth />
      </ButtonWrapper>
    </form>
  );
}

export default LoginForm;
