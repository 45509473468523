export const getDisplayUrl = (url?: string) => {
  try {
    return url
      ? new URL(url.startsWith('http') ? url : `https://${url}`).host.split('www.').at(1) || ''
      : '';
  } catch {
    return '';
  }
};

export default {};
