import React from 'react';

type Props = {
  label: string;
  value: number | null;
  unit: string;
};

export default function MonitoringStatistic({ label, value, unit }: Props) {
  return (
    <div className="monitoring-statistic">
      <div className="label">{label}</div>
      <div>
        <span className="value">{value === null ? '-----' : value.toFixed(2)}</span>
        <span className="unit">{unit}</span>
      </div>
    </div>
  );
}
