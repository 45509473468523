import React, { useCallback, useMemo } from 'react';
import DOMPurify from 'dompurify';
import { getInputState } from 'theme/inputs';
import { TInputStyleVariantsKey } from 'theme/inputs/TInputs';
import { generateRandomString } from 'legacy/utils';
import { Input, InputLabel, TextInputContainerOuter } from './TextInputStyles';

export type InputMode =
  | 'text'
  | 'search'
  | 'none'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | undefined;

export type TTextInputProps = {
  /**
   * is the input focused on page load?
   */
  isAutofocused?: boolean;
  /**
   * Aria Label for accessibility
   */
  ariaLabel: string;
  /**
   * is the input required in a form?
   */
  isRequired?: boolean;
  /**
   * default selected value
   */
  defaultValue?: string;
  /**
   * for controlled inputs: current value of the text input.
   */
  value?: string;
  /**
   * Optional data test ID value for testing purposes
   */
  dataTestId?: string;
  /**
   * Input Style Variant
   */
  styleVariant?: TInputStyleVariantsKey;
  /**
   * Optional error status boolean
   */
  hasError?: boolean;
  /**
   * Optional id
   */
  id?: string;
  /**
   * Optional Label display override
   */
  hasLabel?: boolean;
  /**
   * Optional error message
   */
  errorMessage?: string;
  /**
   * Optional Margin CSS value
   */
  margin?: string;
  /**
   * Optional placeholder message
   */
  placeholder?: string;
  /**
   * Optional full width style
   */
  isFullWidth?: boolean;
  /**
   * Optional Hint Message
   */
  hint?: string;
  /**
   * Optional boolean to disable input
   */
  isDisabled?: boolean;
  /**
   * Optional tab index value for accessibility
   */
  tabIndex?: number;
  /**
   * Optional Label text
   */
  label?: string;
  /**
   * input autocomplete value
   */
  autoComplete?: string;
  /**
   * input autocomplete aria label
   */
  ariaAutoComplete?: 'none' | 'list' | 'inline' | 'both';
  /**
   * input aria control
   */
  ariaControls?: string;
  /**
   * Max length of characters
   */
  maxLength?: number;
  /**
   * Min length of characters
   */
  minLength?: number;
  /**
   * Input reference for hook form to register
   */
  ref?: React.Ref<HTMLInputElement>;
  /**
   * Input's name being registered.
   */
  name?: string;
  /**
   * Optional input type
   */
  type?: string;
  /**
   * Optional inputmode for mobile keyboard
   */
  inputMode?: InputMode;
  /**
   * Optional onChange handler
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional onBlur handler
   */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional onClick handler
   */
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyDown handler
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onKeyUp handler
   */
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Optional onFocus handler
   */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseEnter handler
   */
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseDown handler
   */
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseLeave handler
   */
  onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * Optional MouseUp handler
   */
  onMouseUp?: (e: React.MouseEvent<HTMLInputElement>) => void;
};

// @ts-ignore
export const TextInput: React.FC<TTextInputProps> = React.forwardRef(
  (
    {
      ariaAutoComplete,
      ariaControls,
      ariaLabel,
      autoComplete = 'off',
      dataTestId,
      defaultValue,
      hasLabel = true,
      id,
      inputMode,
      type,
      isAutofocused = false,
      isDisabled = false,
      isFullWidth = true,
      isRequired = false,
      margin = '',
      maxLength,
      minLength,
      name,
      placeholder,
      label,
      tabIndex = 0,
      value,
      styleVariant = 'default',
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
    },
    forwardRef: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputState = getInputState(isDisabled, false);

    const inputId = useMemo(() => id || generateRandomString(), [id]);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = DOMPurify.sanitize(e.target.value);
        onChange?.(e);
      },
      [onChange],
    );

    return (
      <TextInputContainerOuter
        $isFullWidth={isFullWidth}
        $margin={margin}
        $styleVariant={styleVariant}
        $inputState={inputState}
      >
        {hasLabel && (
          <InputLabel
            id={`${inputId}-TextInput-title`}
            htmlFor={`${inputId}-TextInput`}
            $styleVariant={styleVariant}
            $inputState={inputState}
          >
            {label}
          </InputLabel>
        )}

        <Input
          autoFocus={isAutofocused}
          aria-required={isRequired}
          required={isRequired}
          autoComplete={autoComplete}
          ref={forwardRef}
          inputMode={inputMode}
          type={type}
          maxLength={maxLength}
          minLength={minLength}
          id={`${inputId}-TextInput`}
          $isFullWidth={isFullWidth}
          aria-labelledby={hasLabel ? `${inputId}-TextInput-title` : ''}
          aria-describedby={`${inputId}-TextInput-hint`}
          aria-label={hasLabel ? '' : ariaLabel}
          aria-autocomplete={ariaAutoComplete}
          aria-controls={ariaControls}
          data-testid={dataTestId}
          disabled={isDisabled}
          placeholder={placeholder || label}
          $styleVariant={styleVariant}
          $inputState={inputState}
          onChange={handleChange}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          tabIndex={isDisabled ? -1 : tabIndex}
          name={name}
          defaultValue={defaultValue}
          value={value}
        />
      </TextInputContainerOuter>
    );
  },
);

export default TextInput;
