import styled from 'styled-components';

export const LoginFormStyled = styled.form`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;

    input {
      align-self: flex-start;
    }

    a {
      font-weight: 700;
      color: ${theme.colors.buttonButtonPrimarySecondary2100};
      align-self: end;
    }

    button {
      width: 100%;
    }
    
  `}
`;

export default LoginFormStyled;
