import React from 'react';
import { Link, resolvePath } from 'react-router-dom';
import pages from 'pages';
import { ArrowUpRightFromSquare } from 'legacy/components/common/design-system/Icons';
import colors from 'theme/colors';
import PerformanceIcon from 'static/performance-icon.svg';
import { systemPerformanceCopy } from 'legacy/copy';
import { EVERBRIGHT_OMNIDIAN_LOGIN } from '../../../constants';
import { Content, Icon, Text } from '../CardStyles';
import { Card } from '../Card';
import { SystemPerformanceLinkWrapper } from './SystemStyles';

export function SystemPerformanceCard() {
  return (
    <Card title="System Performance" color="green" fullWidth fullHeight>
      <>
        <Icon alt="system performance" src={PerformanceIcon} />

        <Content>
          <Text>{systemPerformanceCopy}</Text>
        </Content>

        <SystemPerformanceLinkWrapper>
          <a href={EVERBRIGHT_OMNIDIAN_LOGIN} target="_blank" rel="noopener noreferrer">
            <span>Performance Dashboard&nbsp;</span>
            <ArrowUpRightFromSquare color={colors.primary} />
          </a>

          <Link to={resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname}>
            View System Details
          </Link>
        </SystemPerformanceLinkWrapper>
      </>
    </Card>
  );
}

export default SystemPerformanceCard;
