import React from 'react';
import { Link } from 'react-router-dom';
import { AgreementType } from 'legacy/types';
import { Card, Column, Row, Section } from './common/myeverbright';
import { H3 } from './common/design-system/Typography';

function AgreementCard({ agreement }: { agreement: AgreementType }) {
  return (
    <Card $bgColor="white">
      <Section>
        <H3 $margin="0 0 24px 0">
          {agreement.product_display_name} #{agreement.homeowner_contract_id}
        </H3>
        <Column>
          <Row $flexWrap="wrap">
            <Column $flex="1">
              <div>
                <div className="label">Term</div>
                <p>{agreement.contract_term} years</p>
              </div>
            </Column>
            {agreement.is_ric ? (
              <Column $flex="1">
                {Boolean(agreement.interest_rate) && (
                  <div>
                    <div className="label">Interest Rate</div>
                    <p>{Number(agreement.interest_rate).toFixed(2)}%</p>
                  </div>
                )}
              </Column>
            ) : (
              <Column $flex="1">
                <div>
                  <div className="label">Estimated Solar Rate</div>
                  <p>${Number(agreement.rate_contract).toFixed(3)}/kWh</p>
                </div>
              </Column>
            )}
          </Row>
        </Column>
      </Section>
      <Link to={`/dashboard/agreement/${agreement.id}`}>View Details</Link>
    </Card>
  );
}

export default AgreementCard;
