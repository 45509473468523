import React, { useState } from 'react';

import { AgreementType, MessageType } from 'legacy/types';
import { DownloadIcon } from 'legacy/components/common/design-system/Icons';
import { Button } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import StatusDetailsCard from 'legacy/components/StatusDetailsCard/StatusDetailsCard';
import { downloadContract } from 'api/agreement';
import { formatDate } from 'legacy/utils';

interface Props {
  agreement: AgreementType;
}

export function FinancingCard({ agreement }: Props) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleDownload = async () => {
    setMessage(null);
    setLoading(true);
    downloadContract(agreement.id)
      .then((response) => {
        const { presigned_url: presignedUrl } = response.data;
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = 'signed_everbright_contract.pdf';
        link.click();
        link.remove();
      })
      .catch(() => {
        setMessage({
          message: 'Something went wrong. Please try again later, or contact support.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StatusDetailsCard
      title="Financing Overview"
      bodyText={[
        {
          label: 'Product',
          subText: `${agreement.product_display_name} #${agreement.homeowner_contract_id}`,
        },
        {
          label: 'Contract Signed',
          subText: formatDate(agreement.contract_signed_date),
        },
        { label: 'Contract Term', subText: agreement.contract_term?.toString() },
      ]}
    >
      <>
        <Button
          onClick={handleDownload}
          label="Contract"
          iconRight
          Icon={DownloadIcon}
          styleVariant="secondary"
          isLoading={loading}
        />

        {message && <Message severity={message.type}>{message.message}</Message>}
      </>
    </StatusDetailsCard>
  );
}

export default FinancingCard;
