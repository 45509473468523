import styled from 'styled-components';
import { lightThemeInputs } from 'theme/inputs/lightThemeInputs';
import { TInputState, TInputStyleVariantsKey } from 'theme/inputs/TInputs';
import { getInputStyleVariantValues } from 'theme/inputs/utils';

export type TInputStyledProps = {
  $styleVariant: TInputStyleVariantsKey;
  $isFullWidth: boolean;
  $inputState: TInputState;
};

export const Input = styled.input<TInputStyledProps>`
  ${({ $isFullWidth, $styleVariant, $inputState }) => {
    const {
      background,
      backgroundActive,
      border,
      borderActive,
      borderRadius,
      placeholderFontColor,
      valueFontColor,
    } = getInputStyleVariantValues(lightThemeInputs, $styleVariant, $inputState);

    const focusStyles = `
      background: ${backgroundActive};
      border: ${borderActive};
      z-index: 1;
    `;
    return `
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    font-family: AvenirLTPro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 12px;
    border: ${border};  
    border-radius: ${borderRadius};
    color: ${valueFontColor};
    width: ${$isFullWidth ? '100%' : 'auto'};
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: ellipsis;
    background: ${background}; 
    &::placeholder {
      color: ${placeholderFontColor};
    }
    &:-ms-input-placeholder {
      color: ${placeholderFontColor};
    }

    &:focus {
      outline: none;
      ${focusStyles}
    }
`;
  }}
`;

type TTextInputContainerOuterProps = {
  $styleVariant: TInputStyleVariantsKey;
  $isFullWidth: boolean;
  $margin: string;
  $inputState: TInputState;
};

export const TextInputContainerOuter = styled.div<TTextInputContainerOuterProps>`
  ${({ $margin, $styleVariant, $isFullWidth, $inputState }) => {
    const { backgroundHover, borderHover } = getInputStyleVariantValues(
      lightThemeInputs,
      $styleVariant,
      $inputState,
    );

    return `
      margin: ${$margin};
      display: flex;
      flex-direction: column;
      width: ${$isFullWidth ? '100%' : 'auto'};
      max-width: 700px;
      
      &:hover input:not(:focus) {
        background: ${backgroundHover};
        border: ${borderHover};
      }
  `;
  }}
`;

type InputLabelProps = {
  $styleVariant: TInputStyleVariantsKey;
  $inputState: TInputState;
};

export const InputLabel = styled.label<InputLabelProps>`
  ${({ $styleVariant, $inputState }) => {
    const { labelFontColor } = getInputStyleVariantValues(
      lightThemeInputs,
      $styleVariant,
      $inputState,
    );

    return `
    margin: 0 auto 4px 0;
    color: ${labelFontColor};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    `;
  }}
`;
