import React, { useMemo } from 'react';
import { generateRandomString } from 'legacy/utils';

import { Input, Label, Text, OuterContainer } from './RadioButtonStyles';
import { RadioIcon } from '../Icons';

export type TRadioButtonProps = {
  dataTestId?: string;
  id?: string;
  name: string;
  forwardRef?: React.MutableRefObject<HTMLInputElement | null>;
  isRequired?: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  value: string;
  label?: string | JSX.Element;
  margin?: string;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
};

/**
 * @param {string} [dataTestId] -  id used for testing (optional)
 * @param {string} [id] -  id used for radio button. for accessibility (optional)
 * @param {string} [name] - name for the radio button
 * @param {React.MutableRefObject<HTMLInputElement | null>} [forwardRef] - ref object to pass to the component (optional)
 * @param {boolean} [isRequired] - is this input required? (optional)
 * @param {boolean} [isSelected] - is this input selected (optional)
 * @param {boolean} [isDisabled] - is this input disabled (optional)
 * @param {string} [value] - value of the radio button.
 * @param {string} [label] - label text contents (optional)
 * @param {string} [margin] - margin css value (optional)
 * @param {function} [onClick] - onClick handler (optional)
 */

export function RadioButton({
  dataTestId,
  id: idProp,
  name,
  forwardRef,
  isRequired = false,
  isSelected,
  isDisabled = false,
  value,
  label,
  margin = '0',
  onClick,
}: TRadioButtonProps) {
  const radioButtonId = useMemo(() => idProp || generateRandomString(), [idProp]);

  return (
    <OuterContainer className="radio-button-outer-container" $margin={margin}>
      <Label
        aria-describedby={`${radioButtonId}-label`}
        id={radioButtonId}
        data-testid={`${dataTestId}-label`}
        htmlFor={`${radioButtonId}-input`}
        $isDisabled={isDisabled}
        onClick={onClick}
      >
        <Input
          name={name}
          required={isRequired}
          aria-required={isRequired}
          ref={forwardRef}
          id={`${radioButtonId}-input`}
          aria-checked={isSelected}
          checked={isSelected}
          type="radio"
          disabled={isDisabled}
          readOnly // This is a controlled input.
          value={value}
          data-testid={dataTestId}
        />
        <RadioIcon />
        {label && (
          <Text data-testid={`${radioButtonId}-text`} className="radio-label">
            {label}
          </Text>
        )}
      </Label>
    </OuterContainer>
  );
}
