import React from 'react';
import Button from 'shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { InlineButtonWrapper } from './DateSelector.styles';

type Props = {
  onCancelClick: () => void;
  onOkClick: () => void;
};
export function CustomFooter({ onCancelClick, onOkClick }: Props) {
  const { t } = useTranslation();

  return (
    <InlineButtonWrapper>
      <Button styleVariant="tertiary" label={t('cta.cancel')} onClick={onCancelClick} />
      <Button styleVariant="tertiary" label={t('cta.ok')} onClick={onOkClick} />
    </InlineButtonWrapper>
  );
}

export default CustomFooter;
