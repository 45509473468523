import React from 'react';

export function EverBrightLogo() {
  return (
    <svg
      width="124"
      height="123"
      viewBox="0 0 124 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M28.3447 26.4019L49.2665 31.1873L60.6895 13.0274L36.39 0.123322L28.3447 26.4019Z"
          fill="url(#paint0_linear_2837_5834)"
        />
        <path
          id="Vector_2"
          d="M95.2456 26.4019L87.1799 0.123322L62.8804 13.0274L74.3239 31.1873L95.2456 26.4019Z"
          fill="url(#paint1_linear_2837_5834)"
        />
        <path
          id="Vector_3"
          d="M31.5794 48.8563L26.7891 27.9561L0.483398 36.0135L13.3804 60.288L31.5794 48.8563Z"
          fill="url(#paint2_linear_2837_5834)"
        />
        <path
          id="Vector_4"
          d="M13.3804 62.4557L0.483398 86.7301L26.7891 94.7875L31.5794 73.8874L13.3804 62.4557Z"
          fill="url(#paint3_linear_2837_5834)"
        />
        <path
          id="Vector_5"
          d="M62.8804 109.716L87.1799 122.62L95.2456 96.3418L74.3239 91.5564L62.8804 109.716Z"
          fill="url(#paint4_linear_2837_5834)"
        />
        <path
          id="Vector_6"
          d="M28.3447 96.3418L36.39 122.62L60.6895 109.716L49.2665 91.5564L28.3447 96.3418Z"
          fill="url(#paint5_linear_2837_5834)"
        />
        <path
          id="Vector_7"
          d="M92.0107 73.8874L96.8011 94.7875L123.107 86.7301L110.189 62.4557L92.0107 73.8874Z"
          fill="url(#paint6_linear_2837_5834)"
        />
        <path
          id="Vector_8"
          d="M110.189 60.288L123.107 36.0135L96.8011 27.9561L92.0107 48.8563L110.189 60.288Z"
          fill="url(#paint7_linear_2837_5834)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2837_5834"
          x1="44.5114"
          y1="-3.32553"
          x2="44.5114"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2837_5834"
          x1="79.0635"
          y1="-3.32553"
          x2="79.0635"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2837_5834"
          x1="16.0355"
          y1="-3.32554"
          x2="16.0355"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2837_5834"
          x1="16.0355"
          y1="-3.32553"
          x2="16.0355"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2837_5834"
          x1="79.0635"
          y1="-3.32554"
          x2="79.0635"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2837_5834"
          x1="44.5114"
          y1="-3.32554"
          x2="44.5114"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2837_5834"
          x1="107.555"
          y1="-3.32553"
          x2="107.555"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2837_5834"
          x1="107.555"
          y1="-3.32554"
          x2="107.555"
          y2="120.446"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0199D6" />
          <stop offset="0.25" stopColor="#0199D6" />
          <stop offset="0.85" stopColor="#76BC43" />
          <stop offset="1" stopColor="#76BC43" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EverBrightLogo;
