import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useNavigate } from 'react-router';

import pages from 'pages';
import Text from 'shared/components/Text/Text';
import useUser from 'hooks/useUser';
import filterFAQs from 'modules/overview/utils/filterFAQs';
import Card from 'shared/components/Card/Card';
import { FaqType } from 'api/types';
import { CircleQuestionIcon } from 'shared/components/icons/CircleQuestionIcon';
import { CardDescription, FAQsCardWrapper, FAQsSectionWrapper } from './FAQsSection.styles';

type Props = {
  faqs: FaqType[];
};

export function FAQsSection({ faqs }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userResult } = useUser();

  const filteredFAQs = filterFAQs(faqs, userResult?.user)?.slice(0, 3);

  const handleClick = () => {
    navigate(resolvePath(`${pages.SUPPORT}`, `/${pages.DASHBOARD}`));
  };

  return (
    <FAQsSectionWrapper>
      <Text as="h2" $margin="0 0 16px 0">
        {t('overview.frequentlyAskedQuestions')}
      </Text>
      <FAQsCardWrapper>
        {filteredFAQs?.map((faq) => (
          <Card key={faq.id} title={faq.question} height="200px" onClick={handleClick}>
            <CardDescription as="p">{faq.answer}</CardDescription>
          </Card>
        ))}

        <Card
          title={t('overview.dontSeeFAQ')}
          actionText={t('overview.viewAllFAQs')}
          icon={{ element: <CircleQuestionIcon />, color: 'teal' }}
          onClick={handleClick}
        />
      </FAQsCardWrapper>
    </FAQsSectionWrapper>
  );
}

export default FAQsSection;
