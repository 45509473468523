import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Path, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { unimpersonateUser } from 'api/user';
import pages, { PageKey } from 'pages';
import { useUser } from 'hooks/useUser';
import {
  BreadCrumbs,
  HeaderStyled,
  LinkStyled,
  LogoutText,
  Title,
  TitleBold,
  TitleWrapper,
} from './Header.styles';
import { AccountMenu } from '../AccountMenu/AccountMenu';
import { Modal } from '../Modal/Modal';
import { toast } from '../Toast/Toast';
import { Text } from '../Text/Text';

enum OptionsEnum {
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  LOGOUT = 'LOGOUT',
  UNIMPERSONATE = 'UNIMPERSONATE',
}

type OptionKeys = keyof typeof OptionsEnum;

type Props = {
  pageName: PageKey;
  title: string;
  routeBreadCrumbs?: { label: string; link?: Path }[];
};

export function Header({ pageName, title, routeBreadCrumbs }: Props) {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { logout, userResult, setUserResult } = useUser();

  const toggleModal = () => setOpenLogoutModal(!openLogoutModal);

  const { mutate: unimpersonateUserMutation } = useMutation({
    mutationFn: unimpersonateUser,
    onSuccess: ({ data }) => {
      const newUser = data.user;
      const newImpersonator = data?.impersonator;

      setUserResult({
        user: newUser,
        impersonator: newImpersonator,
        selectedAccount: newUser.accounts[0],
      });

      navigate(`/${pages.ADMIN}`);
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const handleLogout = () => {
    logout();
  };

  const name = userResult?.user?.first_name || '';
  let headerTitle = <Title as="h1">{title}</Title>;
  let breadCrumbs: JSX.Element[] | null = null;

  if (pageName === 'OVERVIEW' || pageName === 'STATUS') {
    headerTitle = (
      <>
        <Title as="h1">{title}</Title> <TitleBold as="h1">{name}</TitleBold>
      </>
    );
  }

  if (
    pageName === 'ACCOUNT' ||
    pageName === 'AGREEMENT' ||
    pageName === 'SYSTEM' ||
    pageName === 'HARDWARE_INFO'
  ) {
    headerTitle = <TitleBold as="h1">{title}</TitleBold>;
  }

  if (routeBreadCrumbs) {
    breadCrumbs = routeBreadCrumbs.map((path) => {
      if (path.link) {
        return (
          <LinkStyled key={path.label} to={path.link}>
            {path.label} /
          </LinkStyled>
        );
      }

      return (
        <Text key={path.label} as="p">
          {path.label}
        </Text>
      );
    });
  }

  const optionHandler = {
    [OptionsEnum.ACCOUNT_SETTINGS]: {
      title: t('account.accountSettings'),
      value: OptionsEnum.ACCOUNT_SETTINGS,
      onClick: () => {
        navigate(`/${pages.ACCOUNT}`);
      },
    },
    [OptionsEnum.LOGOUT]: {
      title: t('logoutModal.logout'),
      value: OptionsEnum.LOGOUT,
      onClick: toggleModal,
    },
    [OptionsEnum.UNIMPERSONATE]: {
      title: t('account.stopImpersonation'),
      value: OptionsEnum.UNIMPERSONATE,
      onClick: unimpersonateUserMutation,
    },
  };

  const optionsArray = [
    ...(userResult?.impersonator ? [OptionsEnum.UNIMPERSONATE] : []),
    OptionsEnum.ACCOUNT_SETTINGS,
    OptionsEnum.LOGOUT,
  ];

  const accountMenuOptions = optionsArray.map((key) => ({
    title: optionHandler[key].title,
    value: optionHandler[key].value,
  }));

  const handleSelectOption = (value: string) => {
    optionHandler[value as OptionKeys].onClick();
  };

  return (
    <HeaderStyled $hasBreadCrumbs={Boolean(breadCrumbs)}>
      <AccountMenu
        title={t('account.account')}
        options={accountMenuOptions}
        onSelectOption={handleSelectOption}
        isAccountPage={pageName === 'ACCOUNT'}
      />

      <TitleWrapper>
        {breadCrumbs && <BreadCrumbs>{breadCrumbs}</BreadCrumbs>}
        {headerTitle}
      </TitleWrapper>

      <Modal
        isOpen={openLogoutModal}
        contentLabel="Log Out Modal"
        title={t('logoutModal.logout')}
        styleVariant="tertiary"
        isFullWidth
        onRequestClose={toggleModal}
        ctaButtons={{
          tertiary: { label: t('cta.cancel'), onClick: toggleModal },
          primary: { label: t('logoutModal.logout'), onClick: handleLogout },
        }}
      >
        <LogoutText as="p">{t('logoutModal.areYouSure')}</LogoutText>
      </Modal>
    </HeaderStyled>
  );
}

export default Header;
