import { darkThemeColors as colors } from '../colors/themeColors';
import { TDropdowns } from './TDropdown';

export const darkThemeDropdowns: TDropdowns = {
  variants: {
    default: {
      enabled: {
        // default
        fontColor: colors.textPrimarySecondary1100,
        background: colors.backgroundPrimaryWhite,
        border: `1px solid ${colors.strokePrimaryGrey20}`,
        borderRadius: '8px',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.backgroundInteractionActiveGrey20,

        // active
        dropdownItemBackgroundActive: colors.backgroundInteractionPressedSecondary4100,
      },
      disabled: {
        fontColor: colors.textSecondarySubtleGrey80,
        background: colors.backgroundPrimaryWhite,
        border: `1px solid ${colors.strokePrimaryGrey20}`,
        borderRadius: '8px',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.backgroundPrimaryWhite,

        // active
        dropdownItemBackgroundActive: colors.backgroundPrimaryWhite,
      },
    },
    secondary: {
      enabled: {
        // default
        fontColor: colors.textInverseWhite,
        background: colors.backgroundAccentBlueStrongestSecondary1100,
        border: 'none',
        borderRadius: '0',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: 'rgba(0,0,0,.05)',

        // active
        dropdownItemBackgroundActive: 'rgba(0,0,0,.05)',
      },
      disabled: {
        fontColor: colors.strokeAccentGreyStrongGrey40,
        background: colors.backgroundAccentBlueStrongestSecondary1100,
        border: 'none',
        borderRadius: '0',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.backgroundAccentBlueStrongestSecondary1100,

        // active
        dropdownItemBackgroundActive: colors.backgroundAccentBlueStrongestSecondary1100,
      },
    },
  },
};

export default darkThemeDropdowns;
