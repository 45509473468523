import { useLayoutEffect, useState } from 'react';
import { breakpointValues } from 'shared/design-system/theme/breakpoints';
import { Sizes } from 'shared/design-system/theme/grid';

/** Makes window height and width available to component */
export function useWindowSize() {
  const [size, setSize] = useState<{ width: number | null; height: number | null }>({
    width: null,
    height: null,
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

/** Reflects most likely use case for useWindowSize by directly returning screen sizes according to themed breakpoints */
export function useBreakpoints() {
  const [screenSize, setScreenSize] = useState<Sizes>('lg');

  useLayoutEffect(() => {
    const sizes: Sizes[] = ['sm', 'md', 'lg', 'xl'];
    const handleResize = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [idx, val] of Object.values(breakpointValues)
        .sort((a, b) => a - b)
        .entries()) {
        if (window.innerWidth < val) {
          setScreenSize(sizes[idx]);
          return;
        }
      }
      setScreenSize('xl');
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
}

export default useWindowSize;
