import { breakpoints } from 'shared/design-system/theme/breakpoints';
import styled from 'styled-components';
import { Color } from 'theme/colors';

export const FONT_FAMILY = 'AvenirLTPro';

const STYLED_TEXT_PROPS: Record<string, Record<string, any>> = {
  h1: {
    size: '34',
    weight: '100',
    lineHeight: '44',
    responsiveSize: {
      XL: '34',
      large: '34',
      medium: '24',
      small: '24',
    },
  },
  h2: {
    size: '24',
    weight: '100',
    lineHeight: '36',
    responsiveSize: {
      XL: '24',
      large: '24',
      medium: '20',
      small: '20',
    },
  },
  h3: {
    size: '20',
    weight: '600',
    lineHeight: '28',
    responsiveSize: {
      XL: '20',
      large: '20',
      medium: '16',
      small: '16',
    },
  },
  h5: {
    size: '14',
    weight: '400',
    lineHeight: '20',
    responsiveSize: {
      XL: '14',
      large: '14',
      medium: '14',
      small: '14',
    },
  },
  sub: {
    size: '12',
    weight: '700',
    lineHeight: '16',
  },
  body: {
    size: '16',
    weight: '100',
    lineHeight: '24',
  },
  p: {
    size: '16',
    weight: '100',
    lineHeight: '24',
  },
  a: {
    size: '12',
    weight: '400',
    lineHeight: '16',
  },
};

type ResponsiveScreenSize = 'XL' | 'large' | 'medium' | 'small';

export interface StyledTextProps {
  as: keyof HTMLElementTagNameMap;
  color?: Color;
  $margin?: string;
  $textAlign?: string;
  $textDecoration?: string;
  $fontWeight?: number;
  $fontSize?: number;
}

export const Text = styled.p<StyledTextProps>`
  ${({ $margin, $textAlign, color, as, $textDecoration, $fontWeight, $fontSize, theme }) => {
    const textProps = STYLED_TEXT_PROPS[as];
    const { size, weight, lineHeight } = textProps;
    const getFontSize = (screenSize: ResponsiveScreenSize) =>
      STYLED_TEXT_PROPS[as].responsiveSize?.[screenSize] || size;
    const computedLineHeight = lineHeight / size || `${lineHeight}px`;

    return `
    font-family: ${FONT_FAMILY};
    @media (width < ${breakpoints.small}) {
      font-size: ${$fontSize || getFontSize('small')}px;
      line-height: ${computedLineHeight};
    }
    @media (${breakpoints.small} <= width < ${breakpoints.medium}) {
      font-size: ${$fontSize || getFontSize('medium')}px;
      line-height: ${computedLineHeight};
    }
    @media (${breakpoints.medium} <= width < ${breakpoints.large}) {
      font-size: ${$fontSize || getFontSize('large')}px;
      line-height: ${computedLineHeight};
    }
    @media (${breakpoints.large} <= width) {
      font-size: ${$fontSize || getFontSize('XL')}px;
      line-height: ${computedLineHeight};
    }
    font-weight: ${$fontWeight || weight};
    color: ${color || theme.colors.textPrimarySecondary1100};
    margin: ${$margin || '0'};
    text-align: ${$textAlign || 'left'};
    text-decoration: ${$textDecoration}
  `;
  }}
`;

export default Text;
