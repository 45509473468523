import styled, { css } from 'styled-components';
import { getAccordionStyleVariantValues } from 'theme/accordions';
import { lightThemeAccordions } from 'theme/accordions/lightThemeAccordions';
import { globalStyles } from 'theme/globalStyles';
import {
  TAccordionStyleVariantsKey,
  TERTIARY_ACCORDION_STYLE_VARIANT,
} from 'theme/accordions/TAccordions';

export const AccordionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
`;

type AccordionItemStyledProps = {
  $styleVariant: TAccordionStyleVariantsKey;
  $size: 'large' | 'small';
  $isExpanded: boolean;
};

export const AccordionItemStyled = styled.div<AccordionItemStyledProps>`
  ${({ $styleVariant, $size, $isExpanded }) => {
    let marginBottom = $size === 'large' ? '24px' : '20px';
    if ($styleVariant === TERTIARY_ACCORDION_STYLE_VARIANT) marginBottom = '32px';
    return css`
      box-shadow: ${$isExpanded ? globalStyles.ebStandardDropShadow : 'none'};
      background-color: transparent;
      border-radius: 8px;
      margin-bottom: ${marginBottom};
      overflow: hidden;
    `;
  }}
`;

type AccordionItemButtonProps = {
  $isExpanded: boolean;
  $styleVariant: TAccordionStyleVariantsKey;
  $size: 'large' | 'small';
};

export const AccordionItemButton = styled.button<AccordionItemButtonProps>`
  ${({ $styleVariant, $size }) => {
    const { background } = getAccordionStyleVariantValues(lightThemeAccordions, $styleVariant);
    let padding = $size === 'large' ? '10px 24px' : '9px 16px';

    if ($styleVariant === 'tertiary') {
      padding = '16px 24px';
    }

    return `
      width: 100%;
      color: #fff;
      outline-color: #fff;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: ${background};
      padding: ${padding};
      svg { 
        margin-left: auto;
      }
    `;
  }}
`;

type AccordionItemContentProps = {
  $isExpanded: boolean;
  $bgColor?: string;
  $size?: 'large' | 'small';
  $styleVariant: TAccordionStyleVariantsKey;
};

export const AccordionItemContent = styled.div<AccordionItemContentProps>`
  ${({ $isExpanded, $bgColor, $size = 'large', $styleVariant }) => {
    let padding = $size === 'small' ? '16px' : '24px';

    if ($styleVariant === 'tertiary') padding = '0 24px 20px';

    return `
        display: flex;
        margin-bottom: 16px;
        max-height: ${
          $isExpanded ? '99999px' : '0px'
        }; // we have to give it some height for the css to work.
        opacity: ${$isExpanded ? 1 : 0};
        box-sizing: border-box;
        overflow: ${$isExpanded ? 'visible' : 'hidden'};
        visibility: ${$isExpanded ? 'visible' : 'hidden'};
        background-color: ${$bgColor ?? 'inherit'};
        list-style: none;
        margin: 0;
        padding: ${$isExpanded ? padding : 0};
        overflow-y: visible;
      `;
  }}
`;
