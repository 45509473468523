import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from 'theme/colors';
import { Row } from 'legacy/components/common/myeverbright';
import { StatusIndicator } from 'legacy/components/common/design-system';
import { MilestoneType } from 'legacy/types';
import { H3, Subtitle2 } from 'legacy/components/common/design-system/Typography';
import {
  contractSignedCopy,
  inServiceCopy,
  installationCompletedCopy,
  projectQualificationCompletedCopy,
} from 'legacy/copy';
import { Container } from './ProjectStatusStyles';

export type CurrentMilestoneType = MilestoneType | 'CANCELED';

interface Props {
  currentMilestone: CurrentMilestoneType;
}

export function ProjectStatus({ currentMilestone }: Props) {
  const { t } = useTranslation();

  const milestoneDisplayMap = {
    [MilestoneType.CONTRACT_SIGNED]: { label: 'Qualification', copy: contractSignedCopy },
    [MilestoneType.PROJECT_QUALIFICATION_COMPLETED]: {
      label: 'Installation',
      copy: projectQualificationCompletedCopy,
    },
    [MilestoneType.INSTALLATION_COMPLETED]: {
      label: 'System Activation',
      copy: installationCompletedCopy,
    },
    [MilestoneType.IN_SERVICE]: { label: 'In-Service', copy: inServiceCopy },
    CANCELED: { label: t('status.canceled'), copy: t('status.yourProjectCanceled') },
  };

  return (
    <Container>
      <Row $gap="16px" $alignItems="baseline">
        <H3 $margin="0">Project Status:</H3>
        <StatusIndicator
          text={milestoneDisplayMap[currentMilestone].label}
          bgColor={currentMilestone === 'CANCELED' ? colors.red20 : colors.green20}
        />
      </Row>
      <Subtitle2>{milestoneDisplayMap[currentMilestone]?.copy}</Subtitle2>
    </Container>
  );
}

export default ProjectStatus;
