import React from 'react';
import { BodyRegular } from 'legacy/components/common/design-system/Typography';
import { SeverityType } from 'legacy/types';
import { Container, HeaderText } from './MessageStyles';

export type MessageProps = {
  header?: string;
  content?: string;
  severity?: SeverityType;
  children?: React.ReactNode;
};

/**
 * @param {string} [header] - message header (optional)
 * @param {string} [content] - message content (optional)
 * @param {SeverityType} [severity] - type of message (error, warning, success) (optional)
 * @param {React.ReactNode} [children] - children. if defined will override header & content (optional)
 */

export function Message({ header, content, severity, children }: MessageProps) {
  return (
    <Container $severityType={severity}>
      {children || (
        <div>
          <HeaderText $severityType={severity}>{header}</HeaderText>
          <BodyRegular>{content}</BodyRegular>
        </div>
      )}
    </Container>
  );
}

export default Message;
