import React, { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import colors from 'theme/colors';
import { useLocation } from 'react-router-dom';
import { Button } from './common/design-system';
import { XIcon } from './common/design-system/Icons';
import { IconButton } from './common/myeverbright';
import { ANDROID_DOWNLOAD, IOS_DOWNLOAD } from '../constants';
import appstore from '../../static/appstore.svg';

export default function MobileAppBanner() {
  const [bannerVisible, setBannerVisible] = useState(false);
  const showBannerKey = 'showBanner';

  // There's a larger set of Android devices than iOS devices, so default to Android
  const downloadLink = isIOS ? IOS_DOWNLOAD : ANDROID_DOWNLOAD;

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const noBanner = queryParams.get('no_banner');

  useEffect(() => {
    if (noBanner === 'true') {
      setBannerVisible(false);
    } else {
      const savedBannerVisibleRaw = localStorage.getItem(showBannerKey);
      const savedBannerVisible = JSON.parse(
        savedBannerVisibleRaw === null
          ? JSON.stringify(true)
          : JSON.stringify(savedBannerVisibleRaw),
      );
      setBannerVisible(savedBannerVisible);
    }
  }, [noBanner]);

  const dismissBannerHandler = () => {
    setBannerVisible(false);
    localStorage.setItem(showBannerKey, JSON.stringify(false));
  };

  const handleClick = () => {
    window.location.href = downloadLink;
  };

  return bannerVisible && isMobile ? (
    <>
      <div className="mobile-app-spacer" />
      <div className="mobile-app-banner">
        <div className="mobile-app-banner-content">
          <IconButton type="button" onClick={dismissBannerHandler}>
            <XIcon color={colors.gray80} />
          </IconButton>
          <img src={appstore} alt="everbright mobile app icon" />
          <div>
            <b>MyEverBright</b>
            <p>Download our app for a more seamless experience.</p>
          </div>
          <div className="flex-spacer" />
          <Button label="Install" styleVariant="secondary" onClick={handleClick} />
        </div>
      </div>
    </>
  ) : null;
}
