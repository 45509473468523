import React, { Suspense, useState } from 'react';
import { Button, LoadingSpinner, TextInput } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import { MessageType } from 'legacy/types';
import axios from 'axios';
import { lazyWithRetry } from 'legacy/utils';
import routes from 'routes';
import { MINIMUM_PASSWORD_LENGTH, MAXIMUM_PASSWORD_LENGTH } from 'legacy/auth';

const ConfirmPasswordFields = lazyWithRetry(
  () => import('./ConfirmPasswordFields'),
  'ConfirmPasswordFields',
);

function ChangePasswordForm() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [message, setMessage] = useState<MessageType | null>(null);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    setSubmitted(true);

    if (
      passwordConfirmed &&
      oldPassword.length >= MINIMUM_PASSWORD_LENGTH &&
      oldPassword.length <= MAXIMUM_PASSWORD_LENGTH
    ) {
      axios
        .post(routes.changePassword, {
          old_password: oldPassword,
          new_password: newPassword,
        })
        .then(() => {
          setMessage({ message: 'Password successfully changed.', type: 'success' });
        })
        .catch((error) => {
          const errorMessage =
            typeof error.response.data === 'string'
              ? error.response.data
              : error.response.data.detail || String(error.response.data);
          setMessage({ message: errorMessage, type: 'error' });
        })
        .finally(() => {
          setOldPassword('');
          setSubmitted(false);
        });
    }
  };

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const handlePasswordChange = (password: string, isValid: boolean) => {
    setNewPassword(password);
    setPasswordConfirmed(isValid);
  };

  return (
    <form onSubmit={handleSubmit}>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <TextInput
        ariaLabel="old password"
        label="Old Password"
        type="password"
        onChange={handleOldPasswordChange}
        margin="0 0 16px"
        value={oldPassword}
      />
      <Suspense fallback={<LoadingSpinner absoluteCenter />}>
        <ConfirmPasswordFields clearFields={submitted} onPasswordChange={handlePasswordChange} />
      </Suspense>
      <Button label="Submit" margin="16px 0 0 auto" onClick={handleSubmit} />
    </form>
  );
}

export default ChangePasswordForm;
