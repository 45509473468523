/**
 *
 * STYLE VARIANTS
 *
 */
// INTERACTIONS
export type TInputInteractionsStyles = {
  // enabled
  background: string;
  border: string;
  hintFontColor: string;
  labelFontColor: string;
  placeholderFontColor: string;
  valueFontColor: string;

  // hover
  backgroundHover: string;
  borderHover: string;
  hintFontColorHover: string;
  labelFontColorHover: string;
  placeholderFontColorHover: string;
  valueFontColorHover: string;

  // active
  backgroundActive: string;
  borderActive: string;
  hintFontColorActive: string;
  labelFontColorActive: string;
  placeholderFontColorActive: string;
  valueFontColorActive: string;
};

// STATES
export type TInputStates = {
  // states
  enabled: TInputInteractionsStyles;
  disabled: TInputInteractionsStyles;
  error: TInputInteractionsStyles;
  // other
  name: string;
  borderRadius: string;
  hasLabel: boolean;
};
export type TInputState =
  | typeof ENABLED_INPUT_STATE
  | typeof ERROR_INPUT_STATE
  | typeof DISABLED_INPUT_STATE;
export const ENABLED_INPUT_STATE = 'enabled';
export const ERROR_INPUT_STATE = 'error';
export const DISABLED_INPUT_STATE = 'disabled';

// STYLE VARIANTS
export type TInputStyleVariants = {
  default: TInputStates;
};
export type TInputStyleVariantsKey = keyof TInputStyleVariants;

export const DEFAULT_INPUT_VARIANT: TInputStyleVariantsKey = 'default';

/**
 *
 * TInputs in the theme
 *
 */
export type TInputs = {
  variants: TInputStyleVariants;
};
