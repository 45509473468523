import styled from 'styled-components';
import { Card } from 'legacy/components/common/myeverbright';
import colors, { ColorsKey } from 'theme/colors';

type ContainerProps = {
  $borderColor?: ColorsKey;
};

export const Container = styled(Card)<ContainerProps>`
  ${({ $borderColor = 'secondaryDarkBlue', $fullWidth = false }) => `
    border: 1px solid  ${colors[$borderColor]};
    border-left: 8px solid ${colors[$borderColor]};
    padding: 24px;
    width: ${$fullWidth ? '100%' : ''};
    `}
`;

export const Content = styled.div<{ $margin?: string }>`
  margin: ${({ $margin = '24px 0' }) => $margin};
`;

export const Footer = styled.div`
  margin: auto 0 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px 0 24px;
`;

export const IconWrapper = styled.div`
  display: flex;
  p {
    font-size: 14px;
  }
`;

export const Icon = styled.img`
  height: 72px;
  align-self: flex-start;
  margin: 0 24px 0 0;
`;

export const Text = styled.p`
  letter-spacing: 0.5px;
`;
