import React from 'react';
import { HashLink } from 'react-router-hash-link';
import pages from 'pages';
import { H4 } from 'legacy/components/common/design-system/Typography';
import { FaqType } from 'legacy/types';
import FaqTitle from './FaqTitle';
import { Card, Section } from '../common/myeverbright/Card/Card';
import { LinkWrapper, Text } from './FaqStyles';

function DashboardFaqCard({ faq }: { faq: FaqType }) {
  return (
    <Card $bgColor="white" $fullHeight>
      <Section>
        <H4 $margin="0 0 8px" $bold>
          <FaqTitle faq={faq} />
        </H4>
        <Text>{faq.answer}</Text>
      </Section>

      <LinkWrapper>
        <HashLink
          className="section bottom"
          to={`/${pages.FAQ.replace(':faqId', faq.id)}/${faq.url_slug}`}
        >
          Read More
        </HashLink>
      </LinkWrapper>
    </Card>
  );
}

export default DashboardFaqCard;
