import styled from 'styled-components';

export const OverHeaderWrapper = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 48px;
  }
`;

export default {};
