import React from 'react';
import {
  textStyles,
  numberTextStyles,
  dimensions,
  getSegmentStyles,
  mobileDimensions,
} from './ProgressBarStyles';

interface SegmentProps {
  segmentIdx: number;
  stages: string[];
  currentStage: string;
  isSmallScreen?: boolean;
}

export function Segment({ segmentIdx, stages, currentStage, isSmallScreen = false }: SegmentProps) {
  const { circleHeight, circleTextHeight, textHeight, textCircleRadius } = dimensions;
  const { width, radius } = isSmallScreen ? mobileDimensions : dimensions;
  const { bgColor, textColor, numberColor, numberBgColor, points, centerX } = getSegmentStyles(
    stages,
    currentStage,
    segmentIdx,
    isSmallScreen,
  );
  const isLeftSegment = segmentIdx === 0;
  const isRightSegment = stages.length - 1;

  return (
    <>
      {isLeftSegment && <circle cx={radius} cy={radius} r={radius} fill={bgColor} />}
      {isRightSegment && <circle cx={width - radius} cy={radius} r={radius} fill={bgColor} />}
      <polygon points={points} fill={bgColor} />
      {!isSmallScreen && (
        <>
          <circle cx={centerX} cy={circleHeight} r={textCircleRadius} fill={numberBgColor} />
          <text
            x={centerX}
            y={circleTextHeight}
            textAnchor="middle"
            fill={numberColor}
            style={numberTextStyles}
          >
            {segmentIdx + 1}
          </text>
          <text x={centerX} y={textHeight} textAnchor="middle" fill={textColor} style={textStyles}>
            {stages[segmentIdx]}
          </text>
        </>
      )}
    </>
  );
}

export default Segment;
