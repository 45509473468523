import React from 'react';
import {
  TextAnchorType,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import { DateRange } from 'api/system/utils/createDateRange';
import { AxisType, ChartDataType, Tuple } from 'modules/types';
import { DateRangeZoomLevel, EnergyUnit } from 'api/types';
import { useTheme } from 'styled-components';
import { useBreakpoints } from 'hooks/useWindowSize';
import { getChartStyles } from '../getChartStyles';
import { ToolTip } from '../Tooltip/ToolTip';
import { GridComponent } from '../GridComponent';
import { ChartLinearGradient } from '../ChartLinearGradient';

export type Axis = {
  domain?: [number, number];
  tickValues?: unknown[];
  tickFormat?: (tick: any, index: number, ticks: any) => {};
};

type Props = {
  data: ChartDataType[] | undefined;
  dateRange: DateRange;
  axes?: { x: AxisType; y: AxisType };
  domain: {
    x: Tuple<number>;
    y: Tuple<number>;
  };
  yAxisUnit: EnergyUnit | undefined;
  tooltipDateFormatter: (date: Date, dateRange: DateRange) => string;
  zoomLevel: DateRangeZoomLevel;
};

export function BarChart({
  yAxisUnit,
  domain,
  dateRange,
  tooltipDateFormatter,
  data,
  axes,
  zoomLevel,
}: Props) {
  const theme = useTheme();
  const screenSize = useBreakpoints();

  const chartStyles = getChartStyles(theme, screenSize, zoomLevel);

  return (
    <VictoryChart
      domain={domain}
      singleQuadrantDomainPadding={false}
      domainPadding={{
        x: chartStyles.domainPadding.x,
        y: chartStyles.padding.domainY,
      }}
      padding={chartStyles.padding}
      height={chartStyles.height}
      width={chartStyles.width}
      name="chart"
      containerComponent={
        <VictoryVoronoiContainer
          labels={({ datum }) => datum.y}
          labelComponent={
            <ToolTip
              dateRange={dateRange}
              tooltipDateFormatter={tooltipDateFormatter}
              chartType="bar"
            />
          }
          voronoiDimension="x"
        />
      }
    >
      <VictoryAxis
        dependentAxis
        tickFormat={axes?.y.tickFormat}
        style={{
          grid: { strokeWidth: 0.5, stroke: theme.colors.chartChartLightblueSecondary4100 },
          axis: { stroke: 'transparent' },
          tickLabels: chartStyles.tickLabels,
        }}
        domain={domain}
        tickValues={axes?.y.tickValues}
        tickLabelComponent={
          <VictoryLabel
            dy={chartStyles.yTickLabelOffset.y}
            dx={chartStyles.yTickLabelOffset.x}
            textAnchor="start"
          />
        }
        gridComponent={<GridComponent />}
        standalone={false}
      />
      <ChartLinearGradient />

      <VictoryBar
        domain={domain}
        cornerRadius={zoomLevel === DateRangeZoomLevel.MONTH && screenSize !== 'sm' ? 4 : 2}
        style={{
          data: {
            fill: 'url(#myGradient)',
            stroke: theme.colors.chartChartBlueSecondary2100,
            strokeWidth: 1.0,
            width: chartStyles.barWidth,
          },
        }}
        data={data}
        name="bar"
      />

      <VictoryAxis
        name="xAxis"
        domain={domain}
        tickValues={axes?.x.tickValues}
        tickFormat={axes?.x.tickFormat}
        style={{
          axis: { stroke: theme.colors.strokeAccentGreyStrongerGrey50 },
          tickLabels: chartStyles.tickLabels,
        }}
      />
      <VictoryLabel
        x={chartStyles.label.x}
        dx={chartStyles.label.dx}
        dy={chartStyles.label.dy}
        y={chartStyles.label.y}
        textAnchor={chartStyles.label.textAnchor as TextAnchorType}
        text={yAxisUnit}
        style={{ ...chartStyles.label.style, fontWeight: chartStyles.unitLabel.fontWeight }}
      />
    </VictoryChart>
  );
}

export default BarChart;
