import React from 'react';
import { useTheme } from 'styled-components';
import { ArrowLeftIcon, ArrowRightIcon } from 'shared/components/icons';
import {
  PaginationButton,
  PaginationButtonWrapper,
  PaginationHeader,
  PaginationWrapper,
} from './DateSelector.styles';

export interface Props {
  header: string;
  size?: 'small' | 'large';
  onNextClick?: () => void;
  onPrevClick?: () => void;
  hasHeaderSpacer?: boolean;
}

export function CustomCaption({
  header,
  onPrevClick,
  onNextClick,
  size = 'large',
  hasHeaderSpacer,
}: Props) {
  const theme = useTheme();

  return (
    <PaginationWrapper $hasHeaderSpacer={hasHeaderSpacer}>
      <PaginationButtonWrapper>
        <PaginationButton disabled={!onPrevClick} onClick={onPrevClick}>
          <ArrowLeftIcon color={onPrevClick ? undefined : theme.colors.cardElevationMidWhite} />
        </PaginationButton>
        <PaginationHeader size={size}>{header}</PaginationHeader>
        <PaginationButton disabled={!onNextClick} onClick={onNextClick}>
          <ArrowRightIcon color={onNextClick ? undefined : theme.colors.cardElevationMidWhite} />
        </PaginationButton>
      </PaginationButtonWrapper>
    </PaginationWrapper>
  );
}

export default CustomCaption;
