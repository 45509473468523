/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserType } from 'api/types';
import { Button } from 'shared/components/Button/Button';
import { ButtonGroup, FormWrapper, Header, Label, StaticTextWrapper, Value } from '../Form.styles';

type Props = {
  user: UserType;
  onEdit: () => void;
};

export function LoginCard({ user, onEdit }: Props) {
  const { t } = useTranslation();

  const handleEdit = () => {
    onEdit();
  };

  return (
    <FormWrapper>
      <Header as="h3">{t('account.loginDetails')}</Header>

      <StaticTextWrapper>
        <div>
          <Label as="p">{t('account.email')}</Label>
          <Value as="p">{user.email}</Value>
        </div>

        <div>
          <Label as="p">{t('account.password')}</Label>
          <Value as="p">**********</Value>
        </div>

        <ButtonGroup>
          <Button
            dataTestId={`${t('cta.edit')}-btn`}
            styleVariant="secondary"
            label={t('cta.edit')}
            maxWidth="100px"
            onClick={handleEdit}
          />
        </ButtonGroup>
      </StaticTextWrapper>
    </FormWrapper>
  );
}

export default LoginCard;
