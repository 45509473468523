import styled from 'styled-components';

type ButtonStyledProps = {
  $margin: string;
  $isActive: boolean;
  $isHovering: boolean;
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
  ${({ theme, $margin, $isActive, $isHovering }) => `
    display: flex;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 6px 8px 6px 14px;
    color: ${$isActive ? theme.colors.textPrimarySecondary1100 : theme.colors.textSecondaryGrey90};
    margin: ${$margin};
    font-family: 'AvenirLTPro';
    font-style: normal;
    font-weight: ${$isActive ? '700' : '400'};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    height: 36px;
    background: transparent;
    border: none;
    width: 122px;

    &:hover {
      color: ${theme.colors.textLinkLinkSecondary2100};
    }
    &:active {
      color: ${theme.colors.textLinkLinkVisitedFunction4100};
    }
    svg {
      transform: translateY(-1px); // Avenir font seems to have a weird vertical alignment
      height: auto; 
      width: 16px;
      height: 16px;

      path {
        ${
          $isActive
            ? ''
            : `
          fill: ${
            $isHovering
              ? theme.colors.buttonButtonPrimaryHoverFunction4100
              : theme.colors.iconIconSecondaryGrey90
          };
        `
        }
      }
    }

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      font-size: 12px;
      line-height: 16px;
      span {
        margin-top: 4px;
      }
    }
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;
