import { MilestoneType } from 'modules/types';
import { Status } from '../components/StatusAccordion/StatusAccordion.styles';

export const getStatus = (currentMileStone: MilestoneType) => {
  const milestones = Object.values(MilestoneType);
  const currentMileStoneIdx = milestones.indexOf(currentMileStone);
  const mileStoneInProgressIdx = currentMileStoneIdx + 1;

  const result = milestones.reduce(
    (acc, milestone, idx) => {
      if (idx <= currentMileStoneIdx) {
        acc[milestone] = Status.COMPLETED;
      } else if (idx === mileStoneInProgressIdx) {
        acc[milestone] = Status.IN_PROGRESS;
      } else {
        acc[milestone] = Status.INCOMPLETE;
      }

      return acc;
    },
    {} as { [key in MilestoneType]: Status },
  );

  return result;
};

export default {};
