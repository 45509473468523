import { TTheme } from '../theme';
import { TButtons } from './TButtons';

export const themeButtons = (theme: TTheme): TButtons => ({
  variants: {
    primary: {
      enabled: {
        // default
        background: theme.colors.buttonButtonPrimarySecondary2100,
        border: `1.5px solid ${theme.colors.buttonButtonPrimarySecondary2100}`,
        fontColor: theme.colors.textInverseWhite,
        padding: '10px 24px',

        // hover
        backgroundHover: theme.colors.buttonButtonPrimaryHoverFunction4100,
        borderHover: `1.5px solid ${theme.colors.buttonButtonPrimaryHoverFunction4100}`,
        fontColorHover: theme.colors.buttonButtonInverseWhite,

        // active
        backgroundActive: theme.colors.buttonButtonPrimaryPressedSecondary1100,
        borderActive: `1.5px solid ${theme.colors.buttonButtonPrimaryPressedSecondary1100}`,
        fontColorActive: theme.colors.buttonButtonInverseWhite,
      },
    },
    secondary: {
      enabled: {
        // default
        background: theme.colors.buttonButtonInverseWhite,
        border: `1.5px solid ${theme.colors.buttonButtonPrimarySecondary2100}`,
        fontColor: theme.colors.buttonButtonPrimarySecondary2100,
        padding: '10px 24px',

        // hover
        backgroundHover: theme.colors.buttonButtonInverseWhite,
        borderHover: `1.5px solid ${theme.colors.buttonButtonPrimaryHoverFunction4100}`,
        fontColorHover: theme.colors.buttonButtonPrimaryHoverFunction4100,

        // active
        backgroundActive: theme.colors.buttonButtonInverseWhite,
        borderActive: `1.5px solid ${theme.colors.buttonButtonPrimaryPressedSecondary1100}`,
        fontColorActive: theme.colors.buttonButtonPrimaryPressedSecondary1100,
      },
    },
    tertiary: {
      enabled: {
        // default
        background: 'transparent',
        border: 'none',
        fontColor: theme.colors.buttonButtonPrimarySecondary2100,
        padding: '0',

        // hover
        backgroundHover: 'transparent',
        borderHover: 'none',
        fontColorHover: theme.colors.buttonButtonPrimaryHoverFunction4100,

        // active
        backgroundActive: 'transparent',
        borderActive: 'none',
        fontColorActive: theme.colors.buttonButtonPrimaryPressedSecondary1100,
      },
    },

    permanentPrimary: {
      enabled: {
        // default
        background: theme.colors.buttonSystemButtonErrorFunction1100,
        border: `1.5px solid ${theme.colors.buttonSystemButtonErrorFunction1100}`,
        fontColor: theme.colors.buttonButtonInverseWhite,
        padding: '10px 24px',

        // hover
        backgroundHover: theme.colors.buttonSystemButtonErrorHoverFunction1120,
        borderHover: `1.5px solid ${theme.colors.buttonSystemButtonErrorHoverFunction1120}`,
        fontColorHover: theme.colors.buttonButtonInverseWhite,

        // active
        backgroundActive: theme.colors.buttonSystemButtonErrorPressedFunction1160,
        borderActive: `1.5px solid ${theme.colors.buttonSystemButtonErrorPressedFunction1160}`,
        fontColorActive: theme.colors.buttonButtonInverseWhite,
      },
    },
    permanentSecondary: {
      enabled: {
        // default
        background: theme.colors.buttonButtonInverseWhite,
        border: `1.5px solid ${theme.colors.buttonSystemButtonErrorFunction1100}`,
        fontColor: theme.colors.buttonSystemButtonErrorFunction1100,
        padding: '10px 24px',

        // hover
        backgroundHover: theme.colors.buttonButtonInverseWhite,
        borderHover: `1.5px solid ${theme.colors.buttonSystemButtonErrorHoverFunction1120}`,
        fontColorHover: theme.colors.buttonSystemButtonErrorHoverFunction1120,

        // active
        backgroundActive: theme.colors.buttonButtonInverseWhite,
        borderActive: `1.5px solid ${theme.colors.buttonSystemButtonErrorPressedFunction1160}`,
        fontColorActive: theme.colors.buttonSystemButtonErrorPressedFunction1160,
      },
    },
  },
});

export default themeButtons;
