import { useEffect, useRef } from 'react';

/**
 * Hook that listens for clicks outside of the element referenced by the returned ref.
 *
 * @param {function} [onOutsideClick] - function called when a click is detected outside of the ref'd element
 * @param {boolean} [isActive] - if false onOutsideClick won't be called. defaults to true
 *
 * @returns {RefObject} - react ref to be attached to the element to monitor
 */

export const useOutsideClick = (onOutsideClick?: () => void, isActive: boolean = true) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && isActive) {
        onOutsideClick?.();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isActive, onOutsideClick]);

  return ref;
};

export default useOutsideClick;
