import React, { useContext, useEffect } from 'react';
import { getUsers } from 'api/user';
import InternalUserTable from '../../components/admin/InternalUserTable';
import { AdminContext } from '../../../contexts/AdminContext';

export default function AdminTableView() {
  const { internalUsers, setInternalUsers } = useContext(AdminContext);

  useEffect(() => {
    getUsers({
      internal_users: true,
      page_size: 100,
    }).then((response) => {
      setInternalUsers(response.data);
    });
  }, [setInternalUsers]);

  return <InternalUserTable internalUsers={internalUsers} updateInternalUsers={setInternalUsers} />;
}
