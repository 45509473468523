import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from 'theme/colors';
import { AccountType } from 'legacy/types';
import { useUser } from 'hooks/useUser';
import pages from 'pages';
import { getDashboardRedirectPath } from 'legacy/utils';
import { Select } from '../common/design-system';
import { TDropdownDataItem } from '../common/design-system/Dropdown/TDropdown';
import { LocationPinIcon } from '../common/design-system/Icons';
import { SelectAndIcon } from './AccountSelectStyles';

function AccountSelect({ accounts }: { accounts: AccountType[] }) {
  const { setSelectedAccount, selectedAccount, userResult } = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeAccounts = accounts.filter((account) => account.agreements.length);

  const accountOptions: TDropdownDataItem[] = activeAccounts.map((account: AccountType) => ({
    value: account.homeowner_account_id,
    title:
      account.agreements.length > 0
        ? account.agreements[0].system.address.street_1
        : 'NO AGREEMENTS?',
  }));

  const handleSelect = (value: string) => {
    const selected = activeAccounts.find(
      (account: AccountType) => account.homeowner_account_id === value,
    );

    if (pathname === `/${pages.STATUS}` && userResult?.user) {
      const { hasDashboardAccess } = getDashboardRedirectPath(userResult.user, selected);
      if (hasDashboardAccess) {
        navigate(`/${pages.DASHBOARD}`);
      }
    }
    setSelectedAccount(selected);
  };

  return activeAccounts.length > 1 ? (
    <SelectAndIcon>
      <LocationPinIcon color={colors.white} size="large" />

      <Select
        styleVariant="secondary"
        options={accountOptions}
        value={selectedAccount?.homeowner_account_id}
        onSelectOption={handleSelect}
      />
    </SelectAndIcon>
  ) : (
    <div className="ui selection dropdown account-select not-actually-dropdown">
      {activeAccounts[0]?.agreements[0]?.system.address.street_1}
    </div>
  );
}

export default AccountSelect;
