import { TThemeColors } from './colors/TThemeColors';

export const accordionThemeColors: Record<string, Record<string, keyof TThemeColors>> = {
  primary: {
    background: 'cardElevationMidWhite',
    label: 'textPrimarySecondary1100',
  },
  secondary: {
    background: 'backgroundAccentGreySubtleGrey10',
    label: 'textPrimarySecondary1100',
  },
  tertiary: {
    background: 'backgroundPrimaryWhite',
    label: 'textPrimarySecondary1100',
  },
};

export default accordionThemeColors;
