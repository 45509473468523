import styled from 'styled-components';

export const BackButtonStyled = styled.button`
  ${({ theme }) => `
        background: transparent;
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        width: 128px;
        align-items: center;
        margin-bottom: 25px;

        font-size: 16px;
        color: ${theme.colors.textSystemInfoSecondary2100};
        svg {
            height: 16px;
            width: 16px;
            margin-right: 12px;
        }
    `}
`;

export default BackButtonStyled;
