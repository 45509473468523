import React from 'react';
import { Link } from 'react-router-dom';
import pages from 'pages';
import SupportIcon from 'static/support-icon.svg';
import Or from 'static/-OR-.svg';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'shared/utils/formatPhoneNumber';
import { PHONE_NUMBERS } from 'constants/phoneNumbers';
import { Button } from '../common/design-system';
import { customerSupportCopy } from '../../copy';
import { SUPPORT_EMAIL } from '../../constants';
import { Footer, ButtonGroup, IconWrapper, Icon, Content } from './CardStyles';
import { Card } from './Card';

const handleClick = () => {
  window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
};

type Props = {
  fullHeight?: boolean;
  navigateToSupportPage?: boolean;
  showOtherSupportMethod?: boolean;
};

export function SupportCard({
  fullHeight = false,
  navigateToSupportPage = true,
  showOtherSupportMethod = true,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card title="Customer Support" fullWidth fullHeight={fullHeight}>
      <>
        <IconWrapper>
          <Icon alt="customer support" src={SupportIcon} />
          <p>{customerSupportCopy}</p>
        </IconWrapper>

        {showOtherSupportMethod && (
          <Content $margin={navigateToSupportPage ? '24px 0' : '24px 0 0'}>
            <img alt="or" src={Or} width="100%" />

            <ButtonGroup>
              <Button
                id="email-us"
                styleVariant="secondary"
                label="Email Us"
                onClick={handleClick}
              />

              <div className="call-us">
                <div>{t('support.callUs')}</div>
                <a href={`tel:+1${PHONE_NUMBERS.CUSTOMER_SUPPORT}`}>
                  {formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT)}
                </a>
              </div>
            </ButtonGroup>
          </Content>
        )}

        {navigateToSupportPage && (
          <Footer>
            <Link to={`/${pages.SUPPORT}`}>View Support & FAQs</Link>
          </Footer>
        )}
      </>
    </Card>
  );
}

export default SupportCard;
