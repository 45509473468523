import React, { useEffect, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from 'legacy/components/common/design-system/Icons';
import { colors } from 'theme/colors';
import { Container, NumberButton, PaginationButton, Text } from './PaginationStyles';
import { getPaginationValues, calculateShowingRange } from './utils';

export type PaginationProps = {
  activePage?: number;
  totalPages: number;
  pageSize?: number;
  onPageChange: (activeIndex: number) => void;
  onNextPage?: () => void;
};

/**
 * @param {number} [activePage] - what page are you on? (optional)
 * @param {number} totalPages - total page count
 * @param {number} [pageSize] - total number of items on a page
 * @param {function} onPageChange - handler fires when clicking on an active button (optional)
 * @param {function} [onNextPage] - fires when you hit next (right arrowed) button. Can be useful if you aren't sure how many pages you have and need to make another request (optional)
 */

export function Pagination({
  activePage = 0,
  totalPages,
  pageSize,
  onPageChange,
  onNextPage,
}: PaginationProps) {
  const [activeIndex, setActiveIndex] = useState<number>(activePage);
  const [paginationArray, setPaginationArray] = useState<number[]>([]);
  const [showingRange, setShowingRange] = useState({ start: 1, end: pageSize });

  useEffect(() => {
    setPaginationArray(getPaginationValues(activeIndex, totalPages));

    if (pageSize) {
      setShowingRange(calculateShowingRange(activeIndex, totalPages, pageSize));
    }
  }, [activeIndex, totalPages, pageSize]);

  const handleGoToFirstPage = () => {
    if (activeIndex === 1) return;

    setActiveIndex(1);
    onPageChange(1);
  };
  const handleGoToLastPage = () => {
    if (activeIndex === totalPages) return;

    setActiveIndex(totalPages);
    onPageChange(totalPages);
  };

  const handlePreviousPage = () => {
    if (activeIndex === 1) return;

    setActiveIndex((p) => {
      const page = p - 1;
      onPageChange(page);
      return page;
    });
  };

  const handleNextPage = () => {
    if (activeIndex === totalPages) return;

    onNextPage?.();
    setActiveIndex((p) => {
      const page = p + 1;
      onPageChange(page);
      return page;
    });
  };

  const pages = paginationArray.map((value) => (
    <NumberButton
      key={`pagination-${value}`}
      $active={activeIndex === value}
      onClick={() => {
        setActiveIndex(value);
        onPageChange(value);
      }}
    >
      {value}
    </NumberButton>
  ));

  return (
    <Container>
      {pageSize && (
        <Text>
          Showing {showingRange.start} - {showingRange.end}
        </Text>
      )}
      <PaginationButton $disabled={activeIndex === 1} onClick={handleGoToFirstPage}>
        First
      </PaginationButton>
      <PaginationButton $disabled={activeIndex === 1} onClick={handlePreviousPage}>
        <ArrowLeftIcon color={activeIndex === 1 ? colors.gray50 : colors.secondaryDarkBlue} />
      </PaginationButton>

      {pages}

      <PaginationButton $disabled={activeIndex === totalPages} onClick={handleNextPage}>
        <ArrowRightIcon
          color={activeIndex === totalPages ? colors.gray50 : colors.secondaryDarkBlue}
        />
      </PaginationButton>
      <PaginationButton $disabled={activeIndex === totalPages} onClick={handleGoToLastPage}>
        Last
      </PaginationButton>
    </Container>
  );
}

export default Pagination;
