import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import pages from 'pages';
import { SetPasswordForm } from '../../components/forms/SetPasswordForm';
import { routes } from '../../../routes';
import { useNotifications } from '../../../contexts/Notifications';
import { Container, InnerContainer } from './AuthStyles';

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const resetPasswordSubmit = (password: string) =>
    axios
      .post(routes.resetPassword, { token, password })
      .then(() => {
        addNotification({ severity: 'success', text: 'Your password was successfully reset.' });
        navigate(`/${pages.LOGIN}`, { replace: true });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          addNotification({
            severity: 'warning',
            text: 'This password reset request is expired.',
          });
          navigate(`/${pages.LOGIN}`, { replace: true });
        } else if (error.respose.status === 400) {
          addNotification({ severity: 'error', text: 'This password reset request is invalid.' });
          navigate(`/${pages.LOGIN}`, { replace: true });
        }
      });

  return (
    <Container>
      <InnerContainer>
        <h3>Reset Your Password</h3>
        <SetPasswordForm formSubmit={resetPasswordSubmit} showEmailAddress={false} />
      </InnerContainer>
    </Container>
  );
}

export default ResetPassword;
