import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import useUser from 'hooks/useUser';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';

type Props = {
  children?: JSX.Element;
  needsSession: boolean;
  redirectPath: string;
};
export function AuthGuard({ needsSession, redirectPath, children }: Props) {
  const { userResult } = useUser();
  const location = useLocation();

  if (userResult?.loading) return <LoadingLogo />;

  const hasSession = Boolean(userResult?.user);
  let isAllowed = false;

  if (needsSession && hasSession) isAllowed = true;

  if (!needsSession && !hasSession) isAllowed = true;

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ path: location.pathname }} />;
  }

  return children || <Outlet />;
}

export default AuthGuard;
