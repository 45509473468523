import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const AgreementsSectionWrapper = styled.div`
  display: flex;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};

  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  margin: 32px 0;
  gap: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    width: auto;
    padding: 24px;
    margin: 24px 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 32px;
    margin: 32px 0;
    gap: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 32px 48px;
    margin: 48px 0;
    gap: 48px;
  }
`;

export const TitleWrapper = styled.div`
  h2 {
    margin-bottom: 8px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    max-width: 134px;
  }
`;

export const SubText = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  div {
    margin: 0;
    max-width: 405px;
    width: 100%;
    flex: 1 1 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      flex: 1 1 calc(33.33% - 24px);
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 32px;

    div {
      flex: 1 1 calc(33.33% - 32px);
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 48px;

    div {
      flex: 1 1 calc(33.33% - 48px);
    }
  }
`;
