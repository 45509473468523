import React from 'react';
import { useMergeRefs, FloatingPortal } from '@floating-ui/react';
import { useTooltipContext } from 'contexts/TooltipContext';
import { Content } from './TooltipStyles';

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ ...props }, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    if (!context.open) return null;

    return (
      <FloatingPortal>
        <Content
          ref={ref}
          style={{
            ...context.floatingStyles,
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  },
);

export default TooltipContent;
