import React from 'react';

import {
  ButtonGroup,
  FormWrapper,
  Header,
  Label,
  StaticTextWrapper,
  Value,
} from './DetailsCard.styles';

export type DetailsStyleVariant = 'large' | 'default';

type Props = {
  header: string;
  data: { label: string; value: string | string[] | null; styleVariant?: DetailsStyleVariant }[];
  buttons?: JSX.Element[];
};
export function DetailsCard({ header, data, buttons }: Props) {
  const items = data.filter((d) => d.value);

  return (
    <FormWrapper>
      <Header as="h3">{header}</Header>

      <StaticTextWrapper>
        {items.map((item) => (
          <div key={item.label}>
            <Label as="p" $styleVariant={item.styleVariant}>
              {item.label}
            </Label>
            {Array.isArray(item.value) ? (
              item.value.map((value) => (
                <Value as="p" $styleVariant={item.styleVariant}>
                  {value}
                </Value>
              ))
            ) : (
              <Value as="p" $styleVariant={item.styleVariant}>
                {item.value}
              </Value>
            )}
          </div>
        ))}

        {buttons && <ButtonGroup>{buttons}</ButtonGroup>}
      </StaticTextWrapper>
    </FormWrapper>
  );
}

export default DetailsCard;
