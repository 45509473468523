import styled, { css } from 'styled-components';
import colors, { Color } from 'theme/colors';

type LoadingSpinnerStyledProps = {
  $color?: Color;
  $spinnerBackgroundColor?: Color;
  $size?: 'small' | 'large';
  $absoluteCenter?: boolean;
  $localCenter?: boolean;
};
export const LoadingSpinnerStyled = styled.div<LoadingSpinnerStyledProps>`
  ${({
    $color = colors.primary,
    $spinnerBackgroundColor = colors.secondary,
    $size,
    $absoluteCenter = false,
    $localCenter = false,
  }) => {
    const size = $size === 'large' ? '60px' : '16px';
    const borderSize = $size === 'large' ? '4px' : '1px';
    const absoluteCenter = $absoluteCenter
      ? `
    position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
       `
      : '';
    const localCenter = $localCenter ? 'margin: auto;' : '';

    return css`
      border: ${borderSize} solid ${$spinnerBackgroundColor};
      border-radius: 50%;
      border-top: ${borderSize} solid ${$color};
      width: ${size};
      height: ${size};
      -webkit-animation: spin 0.65s linear infinite; /* Safari */
      animation: spin 0.65s linear infinite;
      ${absoluteCenter}
      ${localCenter}
      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `;
  }}
`;

export default LoadingSpinnerStyled;
