import React from 'react';
import { colors } from 'theme/colors';
import { IcoProps } from './types';

export function SortIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.03042 5.68748C4.73753 5.98037 4.26265 5.98037 3.96976 5.68748C3.67687 5.39458 3.67687 4.91971 3.96976 4.62682L7.62691 0.96967C7.76756 0.829018 7.95833 0.75 8.15724 0.75C8.35615 0.75 8.54692 0.829018 8.68757 0.96967L12.3447 4.62682C12.6376 4.91971 12.6376 5.39458 12.3447 5.68748C12.0518 5.98037 11.5769 5.98037 11.2841 5.68748L8.15724 2.56066L5.03042 5.68748ZM5.03042 10.8126C4.73753 10.5197 4.26266 10.5197 3.96976 10.8126C3.67687 11.1054 3.67687 11.5803 3.96976 11.8732L7.62691 15.5304C7.76756 15.671 7.95833 15.75 8.15724 15.75C8.35615 15.75 8.54692 15.671 8.68757 15.5304L12.3447 11.8732C12.6376 11.5803 12.6376 11.1054 12.3447 10.8126C12.0518 10.5197 11.5769 10.5197 11.2841 10.8126L8.15724 13.9394L5.03042 10.8126Z"
        fill={color}
      />
    </svg>
  );
}

export default SortIcon;
