import styled from 'styled-components';

const UserButton = styled.button`
  display: flex;
  border: none;
  padding: 3px;
  flex-direction: column;
  max-width: 100%;
  text-wrap: wrap;
  background: transparent;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
  border-radius: 4px;

  &:hover {
    background: #c1d9f9;
  }

  &:last-of-type {
    border-bottom: none;
  }

  h4 {
    text-align: left;
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 14px;
    text-wrap: wrap;
    max-width: 100%;
    text-align: left;
  }
`;

export default UserButton;
