import React from 'react';
import { IconProps } from './types';

export function Minus({ color = 'white', size = 'large' }: IconProps) {
  const dimensions = size === 'large' ? '16' : '13';

  return (
    <svg
      width={dimensions}
      height={dimensions}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.92308C16 8.43462 15.5885 8.84615 15.0769 8.84615H0.923077C0.411538 8.84615 0 8.43462 0 7.92308C0 7.41154 0.411538 7 0.923077 7H15.0769C15.5885 7 16 7.41154 16 7.92308Z"
        fill={color}
      />
    </svg>
  );
}

export default Minus;
