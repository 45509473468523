import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'legacy/components/common/design-system';
import { TTableRow } from 'legacy/components/common/design-system/Table/TTable';
import { generateRandomString } from 'legacy/utils';
import { routes } from '../../../routes';
import UserStatistics from '../../components/admin/UserStatistics';
import { Column, Row } from '../../components/common/myeverbright';

type FieldCountType = {
  value: string;
  count: number;
}[];

type StatsType = {
  product_names: FieldCountType;
  product_types: FieldCountType;
  contract_types: FieldCountType;
  states: FieldCountType;
  current_milestones: FieldCountType;
  welcome_checklist_results: FieldCountType;
};

function FieldCountTable({ name, fields }: { name: string; fields: FieldCountType }) {
  const columns = [{ title: name }, { title: 'Amount' }];

  const rows: TTableRow[] = fields.map((field) => ({
    key: generateRandomString(),
    cells: [field.value ? field.value : <i>None</i>, field.count],
  }));

  return <Table columns={columns} rows={rows} />;
}

export default function StatisticsView() {
  const [stats, setStats] = useState<StatsType>({
    product_names: [],
    product_types: [],
    contract_types: [],
    states: [],
    current_milestones: [],
    welcome_checklist_results: [],
  });

  useEffect(() => {
    axios.get(routes.agreementStatistics).then((response) => {
      setStats(response.data);
    });
  }, []);

  return (
    <Column>
      <UserStatistics />
      <Row>
        <Column>
          <FieldCountTable name="Current Milestones" fields={stats.current_milestones} />
          <FieldCountTable
            name="Welcome Checklist Results"
            fields={stats.welcome_checklist_results}
          />
          <FieldCountTable name="Contract Types" fields={stats.contract_types} />
          <FieldCountTable name="Product Types" fields={stats.product_types} />
        </Column>
        <Column>
          <FieldCountTable name="Product Names" fields={stats.product_names} />
        </Column>
        <Column>
          <FieldCountTable name="States" fields={stats.states} />
        </Column>
      </Row>
    </Column>
  );
}
