import styled, { css } from 'styled-components';

export const HeaderStyled = styled.div<{ $showLogo: boolean; $headerAlwaysCentered: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  align-items: ${({ $showLogo }) => ($showLogo ? 'left' : 'center')};
  ${({ $headerAlwaysCentered }) =>
    $headerAlwaysCentered
      ? css`
          h1 {
            text-align: center;
          }
        `
      : ''}
  svg {
    margin: 0 auto 12px;
    height: 48px;
    width: 48px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    svg {
      margin: 0 auto 20px;
      height: 60px;
      width: 60px;
    }
  }
`;

export default {};
