/* eslint-disable class-methods-use-this */
import { addHours, addMinutes, differenceInHours, differenceInSeconds, format } from 'date-fns';
import { MonitoringDatum, RawMonitoringDatumType } from 'api/types';
import { Sizes } from 'shared/design-system/theme/grid';
import { DateRange } from 'api/system/utils/createDateRange';
import { AxisType, Tuple } from 'modules/types';
import { ChartDataProvider } from './ChartDataProvider';
import { AnyChartDataProvider } from './AnyChartDataProvider';
import { generateDatesInRange } from './generateDatesInRange';

export class DayChartDataProvider extends ChartDataProvider implements AnyChartDataProvider {
  /** Parsing dates like this pretends every timestamp we get back from the system monitoring information happened in our local timezone */
  parseDates<T>(rawData: RawMonitoringDatumType<T>[]): { value: number; time: Date; unit: T }[] {
    return rawData?.map(({ value, timestamp, unit }) => ({
      value,
      time: addMinutes(new Date(timestamp), this.relativeSystemTimeOffset),
      unit,
    }));
  }

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    const dailyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit = null;

    const filteredData = this.filterData(monitoringData, dateRange);

    filteredData.forEach(({ value, time, unit }) => {
      if (value > largestProductionValue) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      }
      const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
      const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
      dailyProduction.set(hoursSinceCurrentDate, { time, value, unit });
    });

    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (_: DateRange, __: number | null): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, 0],
    y: [0, 0],
  });

  tickXFormat = (hours: number, range: DateRange): string => {
    const date = addHours(range.startDate, hours);
    return format(date, 'ha').toLowerCase();
  };

  tickYFormat = <T>(value: number, _: T): string => `${value.toFixed(1)}`;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date: Date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = (maximumValue: number, screenSize: Sizes): number[] => {
    const numberOfTicks = ['xl', 'lg'].includes(screenSize) ? 4 : 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return tickValues;
  };

  getYAxis = <T>(maximumValue: number, unit: T, screenSize: Sizes): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue, screenSize),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = (date: Date): string => format(date, 'h:mma').toLowerCase();
}

export default DayChartDataProvider;
