import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function ArrowLeftToLine({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 6C14 6.275 13.7687 6.5 13.4812 6.5H1.69093L6.08781 11.1656C6.28021 11.3697 6.26453 11.6862 6.05287 11.8716C5.95312 11.9562 5.82812 12 5.70312 12C5.56234 12 5.42159 11.9453 5.31937 11.8359L0.134996 6.33593C-0.0447544 6.1455 -0.0447544 5.85437 0.134996 5.66406L5.31937 0.16406C5.51281 -0.0410029 5.84093 -0.0546904 6.05218 0.129872C6.26384 0.315435 6.27953 0.631747 6.08712 0.83581L1.69093 5.5H13.4816C13.7687 5.5 14 5.725 14 6Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowLeftToLine;
