import { DropdownContainer } from 'shared/components/Dropdown/Dropdown.styles';
import {
  DropdownItemStyled,
  DropdownTitle,
} from 'shared/components/Dropdown/DropdownItem/DropdownItemStyles';
import Select from 'shared/components/Select/Select';
import { SelectSelected } from 'shared/components/Select/Select.styles';
import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const AddressSelectorWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    max-width: 520px;
  
    @media screen and (min-width: ${theme.breakpoints.large}}) {
      margin-top: 20px;
    }
    `}
`;

export const AddressSelector = styled(Select)`
  ${({ theme }) => `
        min-width: 120px;
        max-width: 345px;

        ${SelectSelected} {
            border: none;
            background: ${theme.colors.backgroundPrimaryWhite};
            border-radius: 4px;
            justify-content: unset;
            ${Text} {
                color: ${theme.colors.textSecondaryGrey90};
                font-size: 20px;
                font-weight: 100;
                line-height: 1.4;
            }
            svg {
                min-width: 24px;
                margin-left: 12px;
            }
        }
        ul {
            background: ${theme.colors.backgroundPrimaryWhite};
            border: 1px solid ${theme.colors.strokeSecondaryGrey10};
            color: ${theme.colors.textPrimarySecondary1100};
            border-radius: 4px;
            padding: 4px 0px;
            box-shadow: ${theme.elevations.high};
        }
        ${DropdownItemStyled} {
            padding: 8px 36px 8px 12px;
            border-radius: 0px;
            ${DropdownTitle} > ${Text} {
                font-size: 16px;
            }
            button {
                padding: 0;
                background: inherit;
                display: flex;
                align-items: flex-start;
            }
            svg {
                width: 16px;
                height: 16px;
                position: relative;
                top: 2px;
                path {
                    fill: ${theme.colors.textPrimarySecondary1100};
                }
            }

            &:hover {
                background: ${theme.colors.cardInteractionHoverGrey05};
            }

            &:active {
                background: ${theme.colors.cardInteractionActiveSecondary420};
            }
        }

        @media (max-width: ${theme.breakpoints.small}) {   
            ${SelectSelected} {
                display: none;
            }
            ${DropdownContainer} {
                position: fixed;
                top: 100%;
                transform: translateY(-100%);
                border-radius: 0;
            }
                
        }
    `}
`;

export const IconSpacer = styled.div`
  min-width: 16px;
`;
