import { Column } from 'legacy/components/common/myeverbright';
import styled from 'styled-components';
import colors from 'theme/colors';
import { globalStyles } from 'theme/globalStyles';

export const Container = styled(Column)`
  border-radius: 20px;
  padding: 24px 32px;
  gap: 16px;
  box-shadow: ${globalStyles.ebSmallDropShadow};
  background: ${colors.white};
`;

export default Container;
