import React from 'react';
import { Wrapper } from 'legacy/views/LayoutStyles';
import NavBar from '../../components/NavBar/NavBar';
import { Content } from './ChatStyles';

export default function Chat() {
  return (
    <Wrapper>
      <NavBar />
      <main>
        <Content>
          <h1>Live Chat</h1>
          <div>Chat with an EverBright Support</div>
          <div>agent using the chat bubble.</div>
        </Content>
      </main>
    </Wrapper>
  );
}
