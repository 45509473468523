import React from 'react';

export const handleFocusedKeyPress = (
  e: React.KeyboardEvent<HTMLElement>,
  onKeyPress: () => void,
) => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault();
    onKeyPress();
  }
};

export default handleFocusedKeyPress;
