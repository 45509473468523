import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { WelcomeChecklistStatusType } from 'modules/types';
import { formatPhoneNumber } from 'shared/utils/formatPhoneNumber';
import { PHONE_NUMBERS } from 'constants/phoneNumbers';
import {
  isWelcomeChecklistFailed,
  isWelcomeChecklistPassed,
  isWelcomeChecklistRequired,
} from 'modules/status/utils/getWelcomeChecklistStatus';
import {
  BodyText,
  BodyTextBold,
  ProjectQualificationCopyWrapper,
} from './ProjectQualification.styles';

type Props = {
  welcomeChecklistStatus: WelcomeChecklistStatusType;
  isCurrentMilestone: boolean;
};
export function ProjectQualificationCopy({ welcomeChecklistStatus, isCurrentMilestone }: Props) {
  const { t } = useTranslation();

  const installerSteps: string[] = t('projectQualification.installerSteps', {
    returnObjects: true,
  });

  const welcomeChecklistRequired = isWelcomeChecklistRequired(welcomeChecklistStatus!);
  const welcomeChecklistFail = isWelcomeChecklistFailed(welcomeChecklistStatus!);
  const welcomeChecklistPass = isWelcomeChecklistPassed(welcomeChecklistStatus!);

  let checklistStatus: React.ReactNode | null = null;

  if (isCurrentMilestone) {
    if (welcomeChecklistRequired) {
      checklistStatus = (
        <BodyTextBold as="p">{t('projectQualification.completeWelcomeChecklist')}</BodyTextBold>
      );
    }

    if (welcomeChecklistPass) {
      checklistStatus = (
        <BodyText as="p">
          <BodyTextBold as="p">{t('projectQualification.welcomeChecklistCompleted')}</BodyTextBold>
        </BodyText>
      );
    }

    if (welcomeChecklistFail) {
      checklistStatus = (
        <BodyText as="p">
          <Trans
            i18nKey="projectQualification.welcomeChecklistFail"
            values={{ phoneNumber: formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT) }}
            components={{
              strong: <BodyTextBold as="p" />,
            }}
          />
        </BodyText>
      );
    }
  }

  return (
    <ProjectQualificationCopyWrapper>
      <BodyText as="p">
        <span>{t('projectQualification.excitedToJoin')}</span>
      </BodyText>

      {checklistStatus}

      <BodyText as="p">{t('projectQualification.installerWillGetSystemReady')}</BodyText>

      <ul>
        {Array.isArray(installerSteps) &&
          installerSteps.map((text) => (
            <li key={text}>
              <BodyText as="p">{text}</BodyText>
            </li>
          ))}
      </ul>

      {welcomeChecklistFail ? null : (
        <BodyText as="p">{t('projectQualification.welcomeToEverBright')}</BodyText>
      )}
    </ProjectQualificationCopyWrapper>
  );
}

export default ProjectQualificationCopy;
