import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyText } from '../ProjectQualification/ProjectQualification.styles';

export function SystemInstallation() {
  const { t } = useTranslation();

  const body2List: string[] = t('systemInstallation.body2List', {
    returnObjects: true,
  });

  return (
    <div>
      <BodyText as="p">{t('systemInstallation.body1')}</BodyText>
      <ul>
        {Array.isArray(body2List)
          ? body2List.map((text) => (
              <li key={text}>
                <BodyText as="p">{text}</BodyText>
              </li>
            ))
          : null}
      </ul>
      <BodyText as="p" $margin="0 0 16px !important">
        {t('systemInstallation.body3')}
      </BodyText>
    </div>
  );
}

export default SystemInstallation;
