import React from 'react';
import { Route, Routes } from 'react-router-dom';
import pages from 'pages';
import { useUser } from 'hooks/useUser';

import ProtectedRoute from 'legacy/ProtectedRoute';
import { getDashboardRedirectPath } from 'legacy/utils';

import { Overview } from 'modules/overview/screens/Overview';
import { Billing } from 'modules/billing/screens/Billing';
import { Support } from 'modules/support/screens/Support';
import { SystemPage } from 'modules/system/screens/SystemPage/SystemPage';
import { HardwareInfo } from 'modules/system/screens/HardwareInfo/HardwareInfo';
import { AgreementDetails } from 'modules/overview/screens/AgreementDetails/AgreementDetails';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';

export function Dashboard() {
  const { userResult, selectedAccount } = useUser();

  const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
    userResult?.user || undefined,
    selectedAccount,
  );

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={hasDashboardAccess}
            redirectPath={redirectPath}
            hasSession
            isLoading={userResult?.loading}
          />
        }
      >
        <Route index element={<Overview />} />
        <Route path={pages.OVERVIEW} element={<Overview />} />
        <Route path={`${pages.AGREEMENTS}/:agreementId`} element={<AgreementDetails />} />
        <Route path={pages.SYSTEM} element={<SystemPage />} />
        <Route path={`${pages.SYSTEM}/${pages.HARDWARE_INFO}`} element={<HardwareInfo />} />
        <Route path={pages.BILLING} element={<Billing />} />
        <Route path={pages.SUPPORT} element={<Support />} />
        <Route path="*" element={<ErrorFallback is404 />} />
      </Route>
    </Routes>
  );
}

export default Dashboard;
