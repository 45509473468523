import { perSessionBannersKey } from 'constants/localStorageKeys';

export const initializeBannerVisibility = (bannerKey?: string): boolean => {
  if (!bannerKey) return true; // Default to visible if no bannerKey is provided

  const perSessionBannersRaw = localStorage.getItem(perSessionBannersKey);
  if (perSessionBannersRaw != null) {
    const bannersObj = JSON.parse(perSessionBannersRaw);
    return bannersObj[bannerKey] === undefined ? true : bannersObj[bannerKey];
  }
  localStorage.setItem(perSessionBannersKey, JSON.stringify({ [bannerKey]: true }));
  return true;
};

export default initializeBannerVisibility;
