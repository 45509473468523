import styled from 'styled-components';
import { BoxProps, GridProps, RowProps } from 'legacy/types';
import { MOBILE_MAX_WIDTH } from '../../../../constants';

export const Box = styled.div<BoxProps>`
  display: flex;
  flex: ${({ $flex }) => $flex};
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  flex-basis: ${({ $flexBasis }) => $flexBasis};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
  align-items: ${({ $alignItems }) => $alignItems || 'stretch'};
  flex-wrap: ${({ $flexWrap }) => $flexWrap || 'nowrap'};
  gap: ${({ $gap }) => $gap};
  column-gap: ${({ $columnGap }) => $columnGap};
  row-gap: ${({ $rowGap }) => $rowGap};
  margin: ${({ $margin }) => $margin};
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

export const Column = styled(Box)`
  flex-direction: column;
  gap: ${({ $gap }) => $gap || '28px'};
`;

export const Row = styled(Box)<RowProps>`
  flex-direction: row;
  gap: ${({ $gap }) => $gap || '28px'};
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    flex-direction: ${({ $collapsing }) => ($collapsing ? 'column' : 'row')};
  }
`;

export const Grid = styled.div<GridProps>`
  display: grid;
  gap: ${({ $gap = '28px' }) => $gap};
  grid-template-columns: ${({ $columns = 1 }) => `repeat(${$columns}, 1fr)`};
  grid-template-rows: ${({ $rows = 1 }) => `repeat(${$rows}, 1fr)`};
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Box;
