import React from 'react';
import { IcoProps } from './types';

export function PlusSign({ color = 'white', size = 'large' }: IcoProps) {
  const widthHeight = size === 'large' ? '24' : '13';
  return (
    <svg
      width={widthHeight}
      height={widthHeight}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0657 11.25H23.8256C24.2483 11.25 24.5942 11.5875 24.5942 12C24.5942 12.4125 24.2483 12.75 23.8256 12.75H13.0657V23.25C13.0657 23.6625 12.7198 24 12.2539 24C11.8744 24 11.4853 23.6625 11.4853 23.25V12.75H0.768568C0.344126 12.75 0 12.4125 0 12C0 11.5875 0.344126 11.25 0.768568 11.25H11.4853V0.75C11.4853 0.335812 11.8744 0 12.2539 0C12.7198 0 13.0657 0.335812 13.0657 0.75V11.25Z"
        fill={color}
      />
    </svg>
  );
}

export default PlusSign;
