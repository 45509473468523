import React from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipContext } from 'contexts/TooltipContext';
import { TriggerButton } from './TooltipStyles';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
};

export const TooltipTrigger = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, asChild = false, ...props }, propRef) => {
    const context = useTooltipContext();

    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    if (asChild && React.isValidElement(children)) {
      return React.cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          'data-state': context.open ? 'open' : 'closed',
        }),
      );
    }

    return (
      <TriggerButton
        type="button"
        ref={ref}
        data-state={context.open ? 'open' : 'closed'}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...context.getReferenceProps(props)}
      >
        {children}
      </TriggerButton>
    );
  },
);

export default TooltipTrigger;
