import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
`;

export const Legend = styled.legend`
  ${({ theme }) => `
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  color: ${theme.colors.textPrimarySecondary1100};
  margin-bottom: 16px;
`}
`;
