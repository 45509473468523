import colors from 'theme/colors';
import { TDropdowns } from './TDropdown';

export const lightThemeDropdowns: TDropdowns = {
  variants: {
    default: {
      enabled: {
        // default
        fontColor: colors.secondaryDarkBlue,
        background: colors.white,
        border: `1px solid ${colors.gray20}`,
        borderRadius: '8px',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.gray20,

        // active
        dropdownItemBackgroundActive: colors.secondaryLightBlue,
      },
      disabled: {
        fontColor: colors.gray80,
        background: colors.white,
        border: `1px solid ${colors.gray20}`,
        borderRadius: '8px',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.white,

        // active
        dropdownItemBackgroundActive: colors.white,
      },
    },
    secondary: {
      enabled: {
        // default
        fontColor: colors.white,
        background: colors.secondaryDarkBlue,
        border: 'none',
        borderRadius: '0',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: 'rgba(0,0,0,.05)',

        // active
        dropdownItemBackgroundActive: 'rgba(0,0,0,.05)',
      },
      disabled: {
        fontColor: colors.gray40,
        background: colors.secondaryDarkBlue,
        border: 'none',
        borderRadius: '0',
        padding: '4px 0',
        dropdownItemPadding: '4px 16px',

        // hover
        dropdownItemBackgroundHover: colors.secondaryDarkBlue,

        // active
        dropdownItemBackgroundActive: colors.secondaryDarkBlue,
      },
    },
  },
};

export default lightThemeDropdowns;
