import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const Title = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const Label = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;

export const Value = styled(Label)`
  font-family: Avenir;
  font-weight: 800;
`;

export const SectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BatteryDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 48px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    gap: 48px;
  }
`;
