import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function WarningIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1018_947)">
        <path
          d="M12.0023 16.875C11.3812 16.875 10.9194 17.3784 10.9194 18C10.9194 18.6211 11.4233 19.125 12.0023 19.125C12.6233 19.125 13.0851 18.6211 13.0851 18C13.1273 17.3766 12.6257 16.875 12.0023 16.875ZM12.0023 15C12.4168 15 12.7101 14.6646 12.7101 14.25V7.5C12.7101 7.08544 12.3747 6.75 12.0023 6.75C11.6298 6.75 11.2523 7.0875 11.2523 7.5V14.25C11.2523 14.6625 11.5898 15 12.0023 15ZM23.6413 18.6234L14.2757 2.78344C13.8023 1.98141 12.9491 1.50141 12.0023 1.5C11.0554 1.5 10.2069 1.97906 9.72881 2.7825L0.358501 18.6262C-0.112593 19.4217 -0.119624 20.3766 0.338729 21.1809C0.81272 22.0078 1.66866 22.5 2.63194 22.5H21.3773C22.3387 22.5 23.1937 22.0064 23.6652 21.1795C24.1241 20.3766 24.1148 19.4203 23.6413 18.6234ZM22.3194 20.3953C22.1601 20.7891 21.7898 21 21.3351 21H2.63194C2.21447 21 1.84538 20.7898 1.64381 20.4375C1.45266 20.1021 1.45561 19.7203 1.65114 19.3894L11.0226 3.54656C11.2241 3.20438 11.5898 3 12.0023 3C12.0023 3 12.0015 3 12.0023 3C12.4131 3.00073 12.7785 3.20433 12.9801 3.54562L22.3504 19.3894C22.5069 19.7203 22.5538 20.1 22.3194 20.3953Z"
          fill={color || theme.colors.iconIconPrimarySecondary1100}
        />
      </g>
      <defs>
        <clipPath id="clip0_1018_947">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WarningIcon;
