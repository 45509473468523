import React from 'react';

import { H3, H5, Subtitle2 } from 'legacy/components/common/design-system/Typography';
import { Divider } from 'legacy/components/common/design-system';
import colors from 'theme/colors';
import { ContentWrapper, Content, StatusDetailsCardStyled } from './StatusDetailsCardStyles';

interface Props {
  title: string;
  bodyText: { label: string; subText?: string }[];
  children?: JSX.Element;
}

export function StatusDetailsCard({ title, bodyText, children }: Props) {
  return (
    <StatusDetailsCardStyled>
      <H3>{title}</H3>
      <Divider $margin="16px 0" />
      <ContentWrapper>
        {bodyText.map((text) => (
          <Content key={text.label}>
            <Subtitle2 color={colors.gray80}>{text.label}</Subtitle2>
            <H5 $margin="8px 0 0">{text.subText}</H5>
          </Content>
        ))}
        <ContentWrapper>{children}</ContentWrapper>
      </ContentWrapper>
    </StatusDetailsCardStyled>
  );
}

export default StatusDetailsCard;
