import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import { FaqType, MessageType } from 'legacy/types';
import axios from 'axios';
import { useGlobalState } from 'hooks/useGlobalState';
import routes from 'routes';
import { AdminContext } from 'contexts/AdminContext';
import FaqForm from './FaqForm/FaqForm';

export default function EditFaqForm({ faq, onSubmit }: { faq: FaqType; onSubmit: Function }) {
  const { setFaqs: setGlobalFaqs } = useGlobalState();
  const { setFaqs: setAdminFaqs } = useContext(AdminContext);
  const [message, setMessage] = useState<MessageType | null>(null);

  const [editedFaq, setEditedFaq] = useState({
    question: '',
    answer: '',
    order: -1,
    hidden: false,
  });

  useEffect(() => {
    setEditedFaq(faq);
  }, [faq]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    axios
      .patch(routes.faq(faq.id), editedFaq)
      .then(() => {
        axios.get(routes.faqs).then((response) => {
          setAdminFaqs(response.data);
          setGlobalFaqs(response.data);
        });
        onSubmit();
      })
      .catch((error) => {
        setMessage({ message: JSON.stringify(error.response.data), type: 'error' });
      });
  };

  return (
    <form onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <FaqForm faq={editedFaq} setFaq={setEditedFaq} />
      <Button label="Submit" margin="0 0 0 auto" onClick={handleSubmit} />
    </form>
  );
}
