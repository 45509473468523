export const breakpointValues = {
  small: 640,
  medium: 960,
  large: 1200,
  xLarge: 1920,
};

export const breakpoints = {
  small: `${breakpointValues.small}px`,
  medium: `${breakpointValues.medium}px`,
  large: `${breakpointValues.large}px`,
  xLarge: `${breakpointValues.xLarge}px`,
};

export type TBreakpoints = typeof breakpoints;
export type TBreakpointKeys = keyof TBreakpoints;
