import styled, { css } from 'styled-components';
import { defaultStyles, Styles } from 'react-modal';
import colors from 'theme/colors';
import { getModalStyleVariantValues, lightThemeModals } from 'theme/modals';
import { TModalStyleVariantsKey } from 'theme/modals/TModals';
import { breakpoints, MOBILE_MAX_WIDTH } from '../../../../constants';

export const modalStyles = (): Styles => ({
  overlay: {
    ...defaultStyles.overlay,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: 500,
  },
  content: {
    ...defaultStyles.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    inset: 0,
    border: 'none',
    background: 'none',
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
});

type InnerContainerProps = {
  $isFullHeight?: boolean;
  $isFullWidth?: boolean;
  $styleVariant: TModalStyleVariantsKey;
};

export const InnerContainer = styled.div<InnerContainerProps>`
  ${({ $styleVariant, $isFullHeight, $isFullWidth }) => {
    const {
      innerModalContainer: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      max-height: 100vh;
      height: fit-content;
      width: ${$isFullWidth ? '100%' : 'auto'};
      max-width: 850px;
      min-width: 300px;
      overflow: hidden;
      padding: ${padding};

      @media (max-width: ${breakpoints.small}) {
        height: ${$isFullHeight ? '100%' : 'fit-content'};
        margin: 0;
        padding: 0;
      }
    `;
  }}
`;

export const HeaderAndContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  @media (max-width: ${breakpoints.small}) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

type ContainerCloseProps = {
  $showButton: boolean;
  $styleVariant: TModalStyleVariantsKey;
};

export const ContainerClose = styled.button<ContainerCloseProps>`
  ${({ $styleVariant, $showButton }) => {
    const {
      closeIcon: { top, right },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);
    return css`
      position: absolute;
      background: none;
      cursor: pointer;
      border: none;
      top: ${top};
      right: ${right};
      padding: 0;
      display: ${$showButton ? 'block' : 'none'};
    `;
  }}
`;

type HeaderProps = {
  $styleVariant: TModalStyleVariantsKey;
  $showButton: boolean;
  $hasSubheader: boolean;
};

export const Header = styled.div<HeaderProps>`
  ${({ $styleVariant, $showButton, $hasSubheader }) => {
    const {
      header: {
        color,
        fontSize,
        lineHeight,
        backgroundColor,
        justifyContent,
        padding,
        paddingWithCloseIcon,
      },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return css`
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: ${$hasSubheader ? 'column' : 'row'};
      background-color: ${backgroundColor};
      justify-content: ${justifyContent};
      padding: ${$showButton ? paddingWithCloseIcon : padding};
      h3 {
        font-weight: 500;
        margin: 0;
        color: ${color};
        font-size: ${fontSize};
        line-height: ${lineHeight};
      }
    `;
  }}
`;

export const ContentWrapper = styled.div`
  background: ${colors.white};
  position: relative;
  overflow: auto;

  @media (max-width: ${breakpoints.small}) {
    height: 100%;
  }
`;

type ContentProps = {
  $styleVariant: TModalStyleVariantsKey;
};

export const Content = styled.div<ContentProps>`
  ${({ $styleVariant }) => {
    const {
      content: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return css`
      padding: ${padding};
      background: ${colors.white};
      position: relative;
      height: 100%;
    `;
  }}
`;

type FadedContainerProps = {
  $show: boolean;
  $styleVariant: TModalStyleVariantsKey;
};

export const FadedContainer = styled.div<FadedContainerProps>`
  ${({ $styleVariant, $show }) => {
    const {
      fadeEffect: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);
    return css`
      position: absolute;
      z-index: 999;
      padding: ${padding};
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      pointer-events: none;
      display: ${$show ? 'block' : 'none'};
    `;
  }}
`;

export const BackgroundGradient = styled.div`
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.72) 0%,
    rgba(255, 255, 255, 0.72) 28.31%,
    rgba(255, 255, 255, 0) 70.94%
  );
`;

export const Footer = styled.div<{ $ctaSpacedBetween?: boolean }>`
  display: flex;
  padding: 20px 24px;
  background: ${colors.white};
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: ${breakpoints.small}) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const PageFormat = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: ${colors.gray80};
  font-size: 14px;
  font-family: AvenirLTPro;
  line-height: 20px;
  min-width: 113px;
  padding-bottom: 11px;
  padding-top: 13px;
  padding-left: 8px;
  margin-right: auto;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 32px 0 16px;
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    margin: 16px 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  :not(:first-child) {
    margin-left: 24px;
  }
`;
