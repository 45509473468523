import React from 'react';
import { ColorsKey } from 'theme/colors';
import { H3 } from 'legacy/components/common/design-system/Typography';
import { Container } from './CardStyles';

type Props = {
  title?: string;
  children?: React.ReactElement;
  color?: ColorsKey;
  fullWidth?: boolean;
  fullHeight?: boolean;
};

export function Card({ title, children, color, fullWidth = false, fullHeight = false }: Props) {
  return (
    <Container $borderColor={color} $fullWidth={fullWidth} $fullHeight={fullHeight}>
      {title && <H3 $margin="0 0 24px">{title}</H3>}
      {children}
    </Container>
  );
}

export default Card;
