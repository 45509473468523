import React from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';
import { pages } from 'pages';
import { ArrowLeftIcon } from 'shared/components/icons';
import { useTranslation } from 'react-i18next';
import { BackButtonStyled } from './BackButton.styles';

export function BackButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const goToLogin = () => {
    navigate(`/${pages.LOGIN}`, { replace: true });
  };

  return (
    <BackButtonStyled onClick={goToLogin}>
      <ArrowLeftIcon color={theme.colors.backgroundSystemCtaSecondary2100} />{' '}
      {t('forgotPasswordScreen.backButton')}
    </BackButtonStyled>
  );
}

export default BackButton;
