import React from 'react';
import { resolvePath, useNavigate } from 'react-router-dom';
import pages from 'pages';
import { Button } from 'legacy/components/common/design-system';
import PerformanceIcon from 'static/performance-icon.svg';
import { systemProductionCopy } from 'legacy/copy';
import { Footer, Icon, Text } from '../CardStyles';
import { Card } from '../Card';

export function SystemProductionCard() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname);
  };
  return (
    <Card title="System Production" color="green" fullWidth fullHeight>
      <>
        <Icon alt="system production" src={PerformanceIcon} />

        <Text>{systemProductionCopy}</Text>

        <Footer>
          <Button label="View Production Data" isFullWidth onClick={handleClick} />
        </Footer>
      </>
    </Card>
  );
}

export default SystemProductionCard;
