import React, { lazy } from 'react';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import pages, { Page } from 'pages';
import { format, parse } from 'date-fns';
import { useUser } from 'hooks/useUser';
import { AccountType, AgreementType, FaqType, SystemType, UserType } from 'api/types';
import { MilestoneType, RolesKey } from 'modules/types/util';
import { ENPHASE_MANUFACTURER_ID, SOLAREDGE_MANUFACTURER_ID, USER_ROLES } from './constants';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export function parseDateOnly(dateString: string) {
  return parse(dateString, 'yyyy-MM-dd', new Date());
}

export function formatDateObj(date: Date) {
  return format(date, 'MMMM d, yyyy');
}

export function formatDateObjShort(date: Date) {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
}

export function formatDate(dateStr: string) {
  if (!dateStr) return '';

  const date = new Date(dateStr);
  return format(date, 'MMMM d, yyyy');
}

export function formatDescription(template_description: string, email_template: string) {
  if (!template_description) return email_template;

  return (
    <span>
      {template_description}
      <br />({email_template})
    </span>
  );
}

export function formatDateShort(dateStr: string) {
  if (!dateStr) return '';

  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
}

export function formatDateTime(dateStr?: string) {
  if (!dateStr) return '';

  const date = new Date(dateStr);
  return date.toLocaleString('en-US');
}

export const formatMoney = (amount?: number, precision?: number): string => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision || 2,
    maximumFractionDigits: precision || 2,
  });
  return currency.format(amount || 0);
};

export function formatInverterManufacturer(manufacturerId: string | undefined): string | undefined {
  switch (manufacturerId) {
    case SOLAREDGE_MANUFACTURER_ID:
      return 'SolarEdge';
    case ENPHASE_MANUFACTURER_ID:
      return 'Enphase';
    default:
      return manufacturerId;
  }
}

export function validateUuid(uuid: string) {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  return regex.test(uuid);
}

export function validateEmail(email: string) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
}

export function getEnv(key: string) {
  const val = import.meta.env[key];
  if (val === undefined) {
    throw new Error(`${key} should not be undefined`);
  } else {
    return val;
  }
}

export function getEnvBool(key: string) {
  const val = getEnv(key);
  if (val === 'true') {
    return true;
  }
  if (val === 'false') {
    return false;
  }
  throw new Error(`${key} is not "true" or "false"`);
}

export function getEnvInt(key: string) {
  return parseInt(getEnv(key), 10);
}

interface TableReducerActionType {
  column?: string;
  type: 'LOAD_DATA' | 'CHANGE_SORT';
  tableData?: readonly Object[];
  getter?: (obj: any) => any;
}

export function tableDataReducer(state: any, action: TableReducerActionType) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          tableData: state.tableData.slice().reverse(),
          direction: state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        tableData: _.sortBy(state.tableData, [action.getter || action.column]),
        direction: 'ascending',
      };
    case 'LOAD_DATA':
      return {
        ...state,
        tableData: action.tableData,
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      };
    default:
      throw new Error();
  }
}

function getUserProductTypes(accounts: AccountType[]) {
  return new Set(
    accounts.flatMap((account) => account.agreements.map((agreement) => agreement.product_type)),
  );
}

export function filterFaqs(faqs: FaqType[] | null, user?: UserType | null, searchQuery?: string) {
  const userProductTypes = getUserProductTypes(user ? user.accounts : []);

  return faqs
    ?.sort((a, b) => a.order - b.order)
    .filter((faq) => faq.tags.some((tag) => userProductTypes.has(tag.tag)))
    .filter((faq) => !faq.hidden)
    .filter(
      (faq) =>
        searchQuery === undefined ||
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase()),
    );
}

export function formatCapacity(capacity: number | undefined) {
  return capacity ? _.round(capacity, 3) : '';
}

export function isHomeowner(role?: RolesKey): boolean {
  return role === USER_ROLES.HOMEOWNER;
}

export function isSuperAdmin(role?: RolesKey): boolean {
  return role === USER_ROLES.SUPER_ADMIN;
}

export function isSupport(role?: RolesKey): boolean {
  return (
    role === USER_ROLES.SUPPORT || role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN
  );
}

export function isAdmin(role?: RolesKey): boolean {
  return role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN;
}

export function isMarketing(role?: RolesKey): boolean {
  return (
    role === USER_ROLES.MARKETING || role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN
  );
}

export function isEarlyAccess(accounts: AccountType[]): boolean {
  return accounts
    .flatMap((account) => account.agreements)
    .some(
      (agreement) =>
        agreement.current_milestone !== null &&
        agreement.current_milestone !== MilestoneType.IN_SERVICE,
    );
}

export function guessEnvironment(): string {
  if (import.meta.env.DEV) return 'local';
  if (import.meta.env.PROD) {
    const subdomain = window.location.host.split('.')[0];
    if (subdomain === 'app-dev') return 'app-dev';
    if (subdomain === 'dev') return 'dev';
    if (subdomain === 'qa') return 'qa';
    if (subdomain === 'myeverbright') return 'prod';
  }
  // eslint-disable-next-line no-console
  console.log(
    `error: could not infer deployment environment for ${import.meta.env.MODE} and ${
      window.location.host
    }`,
  );
  return 'unknown';
}

export function RequireAdmin({ children }: { children: JSX.Element }) {
  const { userResult } = useUser();
  const user = userResult?.user;

  const location = useLocation();

  if (isSupport(user?.role) || isMarketing(user?.role)) {
    return children;
  }

  return <Navigate to={`/${pages.DASHBOARD}`} state={{ from: location }} />;
}

export const generateRandomString = (length = 10): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let randomString = '';
  for (let i = 0; i < length; i += 1) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return randomString;
};

export const validMonitoringID = (system: {
  inverter_manufacturer_id?: string;
  site_id?: string;
}) => system.inverter_manufacturer_id === SOLAREDGE_MANUFACTURER_ID && system.site_id?.length === 7;

export const invalidMonitoringID = (system: {
  inverter_manufacturer_id: string | undefined;
  site_id: string | undefined;
}) => system.site_id?.length !== 7;

export const authCopyAckNeeded = (user?: UserType) => {
  let isNeeded = false;
  let agreements: AgreementType[] = [];

  if (!user) return { isNeeded, agreements };

  agreements = user.accounts.reduce<AgreementType[]>((filteredAgreements, account) => {
    const unacknowledgedAgreements = account.agreements.filter(
      (agreement) => agreement.authoritative_copy_acknowledged === false,
    );
    return filteredAgreements.concat(unacknowledgedAgreements);
  }, []);

  isNeeded = Boolean(agreements.length);

  return { isNeeded, agreements };
};

export const getDashboardRedirectPath = (user?: UserType, account?: AccountType) => {
  let hasDashboardAccess = isHomeowner(user?.role) && Boolean(account);
  let redirectPath: `/${Page}` = `/${pages.ADMIN}`;

  const activeAccounts = user?.accounts?.filter((userAccount) => userAccount.agreements.length);

  // contract/workflow has been canceled if no agreements are on an account
  if (isHomeowner(user?.role) && !activeAccounts?.length) {
    hasDashboardAccess = false;
    redirectPath = `/${pages.STATUS}`;
  } else if (account) {
    const agreement = account.agreements[0];

    if (
      hasDashboardAccess &&
      agreement?.product_version !== 1 && // RIC 1.0 aren't a part of Early Access
      agreement?.current_milestone && // current_milestone is null for older agreements that are in service.
      agreement?.current_milestone !== MilestoneType.IN_SERVICE
    ) {
      hasDashboardAccess = false;
      redirectPath = `/${pages.STATUS}`;
    }
  }

  return { hasDashboardAccess, redirectPath };
};

export const lazyWithRetry = (componentImport: () => Promise<any>, name: string) =>
  lazy(async () => {
    // checks if the window refreshed already
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
    );

    try {
      const componentModule = await componentImport();

      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');

      return componentModule;
    } catch (error) {
      if (!hasRefreshed) {
        // refresh with the assumption in case the user is not on the latest app version

        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');

        return window.location.reload();
      }

      // if we already tried refreshing the app to its latest version, throw an error
      throw error;
    }
  });

export const hasSystemMonitoring = (system: SystemType): boolean =>
  system.has_solar_monitoring ||
  system.has_battery_monitoring ||
  system.has_home_monitoring ||
  system.has_grid_monitoring;

export const hasMonitoring = (agreements: AgreementType[]) =>
  agreements.some((agreement) => hasSystemMonitoring(agreement.system));

export const getActiveAccount = (user: UserType) => {
  const activeAccount = user.accounts.find((account: AccountType) => account.agreements?.length);

  return activeAccount || user.accounts[0];
};
