import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';
import { CardMainBody } from 'shared/components/Card/Card.styles';

export const BillingWrapper = styled.div`
  ${({ theme }) => `
    height: 100%;
    h1 {
      font-weight: 700;
    }
      ${CardMainBody} {
        cursor: default;
        &:hover, &:focus {
          background: ${theme.colors.cardElevationMidWhite};
        }
      }
  `}
`;

export const BodyText = styled(Text)`
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
  font-weight: 400;
  line-height: 24px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    h2 {
      margin: 0 !important;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    div {
      max-width: unset;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    div {
      max-width: 731px;
      gap: 24px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    width: fit-content;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    flex-direction: row !important;
    gap: 24px !important;
  }
`;
