export const formatMoney = (amount?: number, precision?: number): string => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision || 2,
    maximumFractionDigits: precision || 2,
  });
  return currency.format(amount || 0);
};

export default {};
