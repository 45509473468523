import axios, { AxiosPromise } from 'axios';
import { FaqType } from 'api/types';
import { routes } from '../../routes';

type AddTagRequest = {
  faqId: string;
  tagId: string;
};

export function getFAQs(): AxiosPromise<FaqType[]> {
  return axios.get(routes.faqs);
}

export function getTags() {
  return axios.get(routes.tags);
}

export function addTag({ faqId, tagId }: AddTagRequest) {
  return axios.post(routes.faqTag(faqId), {}, { params: { tag_id: tagId } });
}

export function removeTag({ faqId, tagId }: AddTagRequest) {
  return axios.delete(routes.faqTag(faqId), { params: { tag_id: tagId } });
}
