/* eslint-disable class-methods-use-this */
import { capitalize } from 'lodash';
import { addDays, differenceInCalendarDays, format, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { MonitoringDatum, AxisType, Tuple, PaddingType } from 'legacy/types';
import { DateRange } from 'api/system/utils/createDateRange';
import { ChartDataProvider } from './ChartDataProvider';
import { AnyChartDataProvider } from './AnyChartDataProvider';

export class MonthChartDataProvider extends ChartDataProvider implements AnyChartDataProvider {
  static placeholderMaximumValue = 30.0;

  t: (key: string) => string;

  constructor(t: (key: string) => string) {
    super();

    this.t = t;
  }

  barWidth = 5;

  shouldShowDateLabel = (day: number, dateRange: DateRange): boolean => {
    const currentDate = addDays(dateRange.startDate, day);
    const daysTillEnd = differenceInCalendarDays(dateRange.endDate, currentDate);
    // in the final days of the month, don't show the multiple of 7. Instead, show the last day of the range
    const omitDaysInFinalDays = 4;
    if (isSameDay(dateRange.endDate, currentDate)) {
      return true;
    }
    if (daysTillEnd < omitDaysInFinalDays) {
      return false;
    }
    if (day === 0) {
      return true;
    }
    if (day % 7 === 0) {
      return true;
    }
    return false;
  };

  getDomain = (
    dateRange: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => {
    const monthOfFirstDate = dateRange.startDate.getMonth();
    const daysInMonth = new Date(
      dateRange.startDate.getFullYear(),
      monthOfFirstDate + 1,
      0,
    ).getDate();
    return {
      x: [0, daysInMonth - 1],
      y: [0, maximumValue || MonthChartDataProvider.placeholderMaximumValue],
    };
  };

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    const dailyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      largestProductionValue = Math.max(largestProductionValue, value);
      if (value === largestProductionValue) {
        largestProductionUnit = unit;
      }
      const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
      if (dateNumber >= 0) {
        dailyProduction.set(dateNumber, { time, value, unit });
      }
    });
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  tickXFormat = (day: number, range: DateRange): string => {
    const date = addDays(range.startDate, day);
    const dayOfMonth: number = date.getDate();
    const key = `system.ordinals.${dayOfMonth}`;
    const ordinal = this.t(key);
    return this.shouldShowDateLabel(day, range) ? ordinal : '';
  };

  tickYFormat = <T>(value: number, unit: T | null): string => {
    if (!unit) {
      return '';
    }
    return `${value.toFixed(1)}`;
  };

  domainPaddingX: PaddingType = [50, 3];

  yTickLabelOffset = {
    x: -39,
    y: 15,
  };

  getTickValuesXAxis = (range: DateRange): number[] => {
    const daysInRange = differenceInCalendarDays(range.endDate, range.startDate) + 1;
    const values = Array.from(Array(daysInRange).keys()).map((value) => value);
    return values;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = (maximumValue: number): number[] => {
    const numberOfTicks = 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return tickValues;
  };

  getYAxis = <T>(maximumValue: number, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = (date: Date, dateRange: DateRange): string => {
    const zoned = toZonedTime(date, dateRange.systemTimezone);
    return capitalize(format(zoned, 'eee d').toLowerCase());
  };
}

export default MonthChartDataProvider;
