import React from 'react';
import {
  TextAnchorType,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import { AxisType, ChartDataType, EnergyUnit, PaddingType, Tuple } from 'legacy/types';
import { DateRange } from 'api/system/utils/createDateRange';
import { chartStyles, Container } from './ChartStyles';
import { Tooltip } from './Tooltip/ToolTip';
import { GridComponent } from './GridComponent';
import { ChartLinearGradient } from './ChartLinearGradient';

export type Axis = {
  domain?: [number, number];
  tickValues?: unknown[];
  tickFormat?: (tick: any, index: number, ticks: any) => {};
};

type Props = {
  data: ChartDataType[] | undefined;
  dateRange: DateRange;
  axes?: { x: AxisType; y: AxisType };
  domain: {
    x: Tuple<number>;
    y: Tuple<number>;
  };
  yAxisUnit: EnergyUnit | undefined;
  domainPaddingX: PaddingType;
  tooltipDateFormatter: (date: Date, dateRange: DateRange) => string;
};

export function BarChart({
  yAxisUnit,
  domain,
  domainPaddingX,
  dateRange,
  tooltipDateFormatter,
  data,
  axes,
}: Props) {
  const yTickLabelOffset = {
    x: -48,
    y: 15,
  };

  return (
    <Container>
      <VictoryChart
        domain={domain}
        singleQuadrantDomainPadding={false}
        domainPadding={{
          x: domainPaddingX,
          y: chartStyles.padding.domainY,
        }}
        padding={chartStyles.padding}
        height={chartStyles.height}
        width={chartStyles.width}
        name="chart"
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => datum.y}
            labelComponent={
              <Tooltip dateRange={dateRange} tooltipDateFormatter={tooltipDateFormatter} />
            }
            voronoiDimension="x"
          />
        }
      >
        <VictoryAxis
          dependentAxis
          tickFormat={axes?.y.tickFormat}
          style={{
            grid: { strokeWidth: 0.5, stroke: '#DAEBF6' },
            axis: { stroke: 'transparent' },
          }}
          domain={domain}
          tickValues={axes?.y.tickValues}
          tickLabelComponent={
            <VictoryLabel dy={yTickLabelOffset.y} dx={yTickLabelOffset.x} textAnchor="start" />
          }
          gridComponent={<GridComponent />}
          standalone={false}
        />
        <ChartLinearGradient />

        <VictoryBar
          domain={domain}
          cornerRadius={2}
          style={{
            data: {
              fill: 'url(#myGradient)',
              stroke: '#006FC8',
              strokeWidth: 1.0,
              width: axes?.x.tickValues && axes.x.tickValues.length > 13 ? 8 : 24,
            },
          }}
          data={data}
          name="bar"
        />

        <VictoryAxis
          name="xAxis"
          domain={domain}
          tickValues={axes?.x.tickValues}
          tickFormat={axes?.x.tickFormat}
          style={{ tickLabels: chartStyles.tickLabels }}
        />
        <VictoryLabel
          x={chartStyles.label.x}
          dx={chartStyles.label.dx}
          dy={chartStyles.label.dy}
          y={chartStyles.label.y}
          textAnchor={chartStyles.label.textAnchor as TextAnchorType}
          text={yAxisUnit}
          style={chartStyles.label.style}
        />
      </VictoryChart>
    </Container>
  );
}

export default BarChart;
