import styled from 'styled-components';

export const FieldWrapper = styled.div<{ $margin?: string }>`
  ${({ $margin, theme }) => `
        margin: ${$margin};
        text-align: left;

        label {
            display: inline-block;
            margin-bottom: 4px;
        }
        
        span {
            font-size: 12px;
            color: ${theme.colors.textSystemErrorFunction1100};   
            line-height: 16px;
        }
    `}
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  display: flex;
`;

export const FieldIconsWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-self: center;
  align-items: center;
  gap: 16px;
  svg {
    max-height: 16px;
    max-width: 16px;
    margin: auto;
    cursor: pointer;
    transform: translateX(0%);
  }
`;

export const FieldInputWrapper = styled.div<{ $isActive: boolean; $hasError: boolean }>`
  ${({ theme, $isActive, $hasError }) => `
        display: flex;
        max-width: 100%;
        padding: 8px 12px;
        height: 42px;
        background: none;
        border-radius: 4px;

        input {
            border: none;
            outline: none;
            text-overflow: ellipsis;
            font-size: 16px;
            color: ${theme.colors.textPrimarySecondary1100};
            line-height: 24px;
            background: inherit;
            width: 100%;

            &::placeholder {
                color: ${theme.colors.textSecondarySubtleGrey80};
            }
        }

        ${
          $hasError
            ? `
              border: 1px solid ${theme.colors.strokeSystemErrorFunction1100};
              margin-bottom: 0;
            `
            : `border: 1px solid ${
                $isActive
                  ? theme.colors.strokeInteractionActiveStrongFunction4100
                  : theme.colors.strokePrimaryGrey20
              };`
        };

        &:hover {       
            border: ${
              $isActive || $hasError ? '' : `1px solid ${theme.colors.strokeAccentGreyStrongGrey40}`
            };   
        }
        &:focus, &:focus-visible, &:focus-within {
            ${
              $isActive || $hasError
                ? ''
                : `box-shadow: 0px 0px 0px 1px #FFF, 0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue}`
            };
        }
    `}
`;

export const FormInput = styled.input``;
