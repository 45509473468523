import React from 'react';
import { Column, Row } from 'legacy/components/common/myeverbright';
import { Card as OverViewCard } from 'legacy/components/OverviewCards/Card';
import { Content } from 'legacy/components/OverviewCards/CardStyles';
import { getSystemWithMonitoring } from 'api/system/utils/getSystemWithMonitoring';
import { AgreementType } from 'api/types';
import { MonitoringCard } from 'legacy/components/MonitoringCard/MonitoringCard';
import { SupportCard } from 'legacy/components/OverviewCards/SupportCard';
import { noSystemCopy, systemPerformanceCopy } from 'legacy/copy';
import { Button } from 'legacy/components/common/design-system';
import { ArrowUpRightFromSquare } from 'legacy/components/common/design-system/Icons/ArrowUpRightFromSquare';
import SystemCard from 'legacy/components/SystemCard';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { ContractType, EVERBRIGHT_OMNIDIAN_LOGIN } from '../../../constants';

const handleClick = () => {
  window.open(EVERBRIGHT_OMNIDIAN_LOGIN, '_blank');
};

function MonitoringSystem({ agreements }: { agreements: AgreementType[] }) {
  const system = getSystemWithMonitoring(agreements);

  const selectedAgreement = system
    ? agreements.find((agreement) => agreement.system.id === system.id)
    : undefined;

  return (
    <Row $collapsing>
      <Column $flex="2">
        <ErrorBoundary>
          <MonitoringCard
            systemId={system?.id || ''}
            systemCapacity={system?.capacity}
            homeOwnerContractId={selectedAgreement?.homeowner_contract_id}
          />
        </ErrorBoundary>

        {agreements?.map((agreement) =>
          agreement.is_beam ? (
            <p key={agreement.id}>{noSystemCopy}</p>
          ) : (
            <SystemCard key={agreement.id} agreement={agreement} />
          ),
        )}
      </Column>

      <Column $flex="1">
        {agreements.every((agreement) => agreement.contract_type === ContractType.PPA) && (
          <OverViewCard title="System Service" color="green">
            <>
              <Content>
                <p>{systemPerformanceCopy}</p>
              </Content>

              <Button
                styleVariant="tertiary"
                label="Visit Omnidian"
                Icon={ArrowUpRightFromSquare}
                iconRight
                onClick={handleClick}
                margin="0 auto 0 0"
              />
            </>
          </OverViewCard>
        )}

        <SupportCard />
      </Column>
    </Row>
  );
}

export default MonitoringSystem;
