import React, { useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { CustomPicker } from './CustomPicker';
import { CustomPickerButton } from './DatePickerStyles';

interface Props {
  onDateChange: MonthChangeEventHandler;
  currentDate: Date;
  onCloseModal: () => void;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dateRange: { oldest: Date; newest: Date };
}

export function YearPicker({ onDateChange, currentDate, onCloseModal, dateRange }: Props) {
  const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
  const yearsPerPage = 12;

  const onYearClick = (year: number) => {
    setSelectedYear(year);
  };

  const onOkClick = () => {
    onDateChange(new Date(selectedYear, 0));
    onCloseModal();
  };

  const renderYearPickerGrid = () => {
    const yearGrid: React.ReactNode[] = [];
    const startYear = dateRange.oldest.getFullYear();
    for (let i = startYear; i < startYear + yearsPerPage; i += 1) {
      let disableButton = false;
      if (i > dateRange.newest.getFullYear() || i < dateRange.oldest.getFullYear())
        disableButton = true;

      yearGrid.push(
        <CustomPickerButton
          key={i}
          selected={i === selectedYear}
          onClick={() => onYearClick(i)}
          disabled={disableButton}
        >
          {i}
        </CustomPickerButton>,
      );
    }
    return yearGrid;
  };

  return (
    <CustomPicker
      header="Year"
      items={renderYearPickerGrid()}
      onCloseModal={onCloseModal}
      onOkClick={onOkClick}
    />
  );
}

export default YearPicker;
