import styled from 'styled-components';

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Label = styled.label`
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 4px;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  border: 0px solid transparent;
  border-radius: 0.28571429rem;
`;
