import React, { useEffect, useState } from 'react';
import { Fieldset, Legend } from './RadioButtonGroupStyles';
import { OuterContainer } from '../RadioButton/RadioButtonStyles';
import { RadioButton } from '../RadioButton/RadioButton';

export type TRadioOption = {
  id?: string;
  label?: string;
  value: string;
};

export type TRadioButtonGroupProps = {
  id: string;
  name: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  legend?: string | JSX.Element;
  options: TRadioOption[];
  value?: string;
  useControlledValue?: boolean;
  margin?: string;
  onChange?: (e: React.ChangeEvent<HTMLFieldSetElement>, value: string) => void;
};

/**
 * @param {string} [id] -  id used for radio button group. for accessibility
 * @param {string} [name] - name for the radio buttons inside the radio group
 * @param {boolean} [isDisabled] - is this input disabled (optional)
 * @param {boolean} [isRequired] - is this input required? (optional)
 * @param {string} [legend] - text content for the legend/heading of the fieldset (optional)
 * @param {TRadioOption[]} [options] - radio button data to be assigned to the buttons within the group
 * @param {string} [value] -  if undefined, all radio buttons will be unselected. If it has a value the component will pre-select a Radio button whose value is equal (optional)
 * @param {boolean} [useControlledValue] - controls whether updating the value prop will always overwrite the current state (optional)
 * @param {string} [margin] - margin css value (optional)
 * @param {function} [onChange] - onChange handler (optional)
 */

export function RadioButtonGroup({
  id,
  name,
  isDisabled = false,
  isRequired,
  legend,
  options,
  value,
  useControlledValue = false,
  margin = '0px',
  onChange,
}: TRadioButtonGroupProps) {
  const [selectedOptionValue, setSelectedOption] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLFieldSetElement>) => {
    const newValue = e.target.getAttribute('value') as string;

    setSelectedOption(newValue);
    if (onChange) {
      onChange(e, newValue);
    }
  };

  useEffect(() => {
    if (!useControlledValue) return;
    setSelectedOption(value);
  }, [value, useControlledValue]);

  return (
    <OuterContainer className="radio-group-container" $margin={margin}>
      <Fieldset
        role="radiogroup"
        aria-required={isRequired}
        data-testid={id}
        disabled={isDisabled}
        onChange={handleChange}
        id={id}
      >
        {legend && <Legend>{legend}</Legend>}
        {options.map((option: TRadioOption, i: number) => {
          let buttonMargin = '0';
          if (i !== options.length - 1) {
            if (option.label) buttonMargin = '0 48px 0 0';
            else buttonMargin = '0 56px 0 0';
          }
          return (
            <RadioButton
              key={`radio-group-${name}-option-${option.id}`}
              dataTestId={option.id}
              margin={buttonMargin}
              name={name}
              label={option.label}
              id={`${name}-option-${i + 1}`}
              value={option.value}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isSelected={selectedOptionValue === option.value}
            />
          );
        })}
      </Fieldset>
    </OuterContainer>
  );
}

export default RadioButtonGroup;
