import styled, { css, DefaultTheme } from 'styled-components';
import { defaultStyles, Styles } from 'react-modal';
import { getModalStyleVariantValues, lightThemeModals } from 'theme/modals';
import { TModalStyleVariantsKey } from 'theme/modals/TModals';
import { FONT_FAMILY } from '../Text/Text';

export const modalStyles = (theme: DefaultTheme): Styles => ({
  overlay: {
    ...defaultStyles.overlay,
    backgroundColor: `${theme.colors.backgroundAccentGreyStrongerGrey80}bf`,
    zIndex: 500,
  },
  content: {
    ...defaultStyles.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    inset: 0,
    border: 'none',
    background: 'none',
    overflow: 'hidden',
    padding: 0,
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
});

type InnerContainerProps = {
  $isFullHeight?: boolean;
  $isFullWidth?: boolean;
  $styleVariant: TModalStyleVariantsKey;
  $fitContent?: boolean;
  $padding?: string;
};

export const HeaderAndContent = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;


    @media (max-width: ${theme.breakpoints.small}) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  `}
`;

type ContainerCloseProps = {
  $showButton: boolean;
  $styleVariant: TModalStyleVariantsKey;
};

export const ContainerClose = styled.button<ContainerCloseProps>`
  ${({ $styleVariant, $showButton }) => {
    const {
      closeIcon: { top, right },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);
    return css`
      position: absolute;
      background: none;
      cursor: pointer;
      border: none;
      top: ${top};
      right: ${right};
      padding: 0;
      display: ${$showButton ? 'block' : 'none'};
    `;
  }}
`;

type HeaderProps = {
  $styleVariant: TModalStyleVariantsKey;
  $showButton: boolean;
  $hasSubheader: boolean;
  $hasElevation?: boolean;
};

export const Header = styled.div<HeaderProps>`
  ${({ $styleVariant, $showButton, $hasSubheader, $hasElevation, theme }) => {
    const {
      header: {
        color,
        fontSize,
        lineHeight,
        backgroundColor,
        justifyContent,
        padding,
        paddingWithCloseIcon,
      },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return css`
      position: relative;
      display: flex;
      flex-direction: ${$hasSubheader ? 'column' : 'row'};
      background-color: ${backgroundColor};
      justify-content: ${justifyContent};
      padding: ${$showButton ? paddingWithCloseIcon : padding};
      padding-bottom: ${$hasSubheader ? '0' : '16px'};
      background: ${theme.colors.cardElevationHighWhite};
      h3 {
        font-weight: 500;
        margin: 0;
        color: ${color};
        font-size: ${fontSize};
        line-height: ${lineHeight};
        color: ${theme.colors.textPrimarySecondary1100};
      }
      z-index: 1000;
      box-shadow: ${$hasElevation ? `${theme.elevations.mid}` : ''};
    `;
  }}
`;

export const ContentWrapper = styled.div<{ $hasScrollbar: boolean }>`
  ${({ theme, $hasScrollbar }) => `
    background: ${theme.colors.cardElevationMidWhite};
    position: relative;
    overflow: auto;
    width: 100%;
    padding: 0;

    ${
      $hasScrollbar
        ? `
      &::-webkit-scrollbar {
        width: 24px;
        position: relative;
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.colors.strokeAccentGreyStrongGrey40};
        width: 8px;
        height: 80px;
        border-radius: 360px;
        border: 8px solid ${theme.colors.cardElevationMidWhite};
      }
    `
        : ``
    }

    @media (max-width: ${theme.breakpoints.small}) {
      height: 100%;
    }
  `}
`;

type ContentProps = {
  $styleVariant: TModalStyleVariantsKey;
  $hasScrollbar: boolean;
};

export const Content = styled.div<ContentProps>`
  ${({ $styleVariant, theme, $hasScrollbar }) => {
    const {
      content: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return `
      padding: ${padding};
      background: ${theme.colors.cardElevationHighWhite};
      position: relative;
      height: 100%;
      ${$hasScrollbar ? 'padding-right: 6px;' : ''}
    `;
  }}
`;

type FadedContainerProps = {
  $show: boolean;
  $styleVariant: TModalStyleVariantsKey;
};

export const FadedContainer = styled.div<FadedContainerProps>`
  ${({ $styleVariant, $show }) => {
    const {
      fadeEffect: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);
    return css`
      position: absolute;
      z-index: 999;
      padding: ${padding};
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      pointer-events: none;
      display: ${$show ? 'block' : 'none'};
    `;
  }}
`;

export const BackgroundGradient = styled.div`
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.72) 0%,
    rgba(255, 255, 255, 0.72) 28.31%,
    rgba(255, 255, 255, 0) 70.94%
  );
`;

export const Footer = styled.div<{
  $ctaSpacedBetween?: boolean;
  $hasElevation?: boolean;
  $hasContent?: boolean;
}>`
  ${({ theme, $hasElevation, $hasContent }) => `
    display: flex;
    ${$hasContent ? `padding: 20px 24px;` : `padding-bottom: 24px;`}
    background: ${theme.colors.cardElevationHighWhite};
    position: relative;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    @media (max-width: ${theme.breakpoints.small}) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    box-shadow: ${$hasElevation ? `${theme.elevations.midInverse}` : ''};
  `}
`;

export const PageFormat = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: ${theme.colors.textSecondarySubtleGrey80};
    font-size: 14px;
    font-family: AvenirLTPro;
    line-height: 20px;
    min-width: 113px;
    padding: 13px 0px 11px 8px;
    margin-right: auto;
  `}
`;

export const SubHeader = styled.div`
  ${({ theme }) => `
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 24px 0 16px;
      @media (max-width: ${theme.breakpoints.small}) {
        margin: 16px 0;
      }
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  :not(:first-child) {
    margin-left: 24px;
  }
`;

export const LoadingLogoWrapper = styled.div`
  ${() => css`
    height: 122px;
    width: 122px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  `}
`;

export const InnerContainer = styled.div<InnerContainerProps>`
  ${({ $styleVariant, $isFullHeight, $isFullWidth, $fitContent, theme, $padding }) => {
    const {
      innerModalContainer: { padding },
    } = getModalStyleVariantValues(lightThemeModals, $styleVariant);

    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      max-height: ${$fitContent ? 'fit-content' : '600px'};
      height: fit-content;
      width: ${$isFullWidth ? '100%' : 'auto'};
      max-width: 850px;
      min-width: 300px;
      overflow: hidden;
      padding: ${padding};
      font-family: ${FONT_FAMILY};

      ${$padding
        ? `
        ${Content} {
          padding: 0 ${$padding};
        }
        ${Header} {
          padding: ${$padding} ${$padding} 0;
        }
        ${Footer} {
          padding-bottom: ${$padding};
        }
      `
        : ''}

      @media (max-width: ${theme.breakpoints.small}) {
        height: ${$isFullHeight ? '100%' : 'fit-content'};
        margin: 0;
        padding: 0;
      }
    `;
  }}
`;
