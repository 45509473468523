import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import pages from 'pages';
import { Billing } from 'modules/billing/screens/Billing';
import { AgreementDetails } from 'modules/overview/screens/AgreementDetails/AgreementDetails';
import { Overview } from 'modules/overview/screens/Overview';
import { Support } from 'modules/support/screens/Support';
import { HardwareInfo } from 'modules/system/screens/HardwareInfo/HardwareInfo';
import { SystemPage } from 'modules/system/screens/SystemPage/SystemPage';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { ThemeLayout } from 'layouts/ThemeLayout';
import { DashboardLayout } from 'layouts/DashboardLayout';

export const Dashboard: RouteObject[] = [
  {
    element: (
      <ThemeLayout>
        <DashboardLayout />
      </ThemeLayout>
    ),
    children: [
      {
        path: `${pages.DASHBOARD}/*`,
        children: [
          {
            index: true,
            element: <Navigate to={`/${pages.DASHBOARD}/${pages.OVERVIEW}`} replace />,
          },
          { path: pages.OVERVIEW, element: <Overview /> },
          { path: `${pages.AGREEMENTS}/:agreementId`, element: <AgreementDetails /> },
          {
            path: pages.SYSTEM,
            children: [
              { index: true, element: <SystemPage /> },
              { path: pages.HARDWARE_INFO, element: <HardwareInfo /> },
            ],
          },
          { path: pages.BILLING, element: <Billing /> },
          { path: pages.SUPPORT, element: <Support /> },
        ],
        errorElement: <ErrorFallback is404 />,
      },
    ],
  },
];

export default Dashboard;
