import React from 'react';
import { Link } from 'react-router-dom';
import pages from 'pages';
import { useUser } from 'hooks/useUser';
import { FooterStyled } from 'legacy/views/LayoutStyles';
import { Subtitle2 } from 'legacy/components/common/design-system/Typography';
import { getEnv, isAdmin } from '../../utils';

function Footer() {
  const { userResult } = useUser();
  const user = userResult?.user;

  const copyright = `Copyright © ${new Date().getFullYear()} EverBright LLC.`;

  const links = (
    <Subtitle2>
      {user && isAdmin(user.role) && `${getEnv('VITE_APP_VERSION')} | `}
      <a href="https://www.goeverbright.com/privacy-policy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
      {' | '}
      <a href="https://www.goeverbright.com/privacy-policy?hsLang=en#California ">
        California Residents Notice at Collection
      </a>
      {' | '}
      <a href="https://www.goeverbright.com/terms-and-conditions">Terms & Conditions</a>
      {' | '}
      {user ? (
        <Link to={pages.SUPPORT}>Contact</Link>
      ) : (
        <a href="https://www.goeverbright.com/contact">Contact</a>
      )}
    </Subtitle2>
  );

  return (
    <FooterStyled>
      <Subtitle2>{copyright} All Rights Reserved.</Subtitle2>
      {links}
    </FooterStyled>
  );
}

export default Footer;
