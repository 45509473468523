import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 685px;
`;

export const StatusGroup = styled.div`
  display: flex;
  gap: 8px;
`;

export const ProjectStatusText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const StatusText = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;
