import axios, { AxiosPromise } from 'axios';
import { E164Number } from 'libphonenumber-js/types';
import parsePhoneNumberFromString from 'libphonenumber-js';

import routes from 'routes';
import { GetUsersRequestBody } from 'legacy/types';
import { AddressFormType } from 'legacy/components/forms/AddressForm';
import { RolesKey } from 'modules/types/util';
import { AddressType, UserType } from 'api/types';

export function getUser(id: string) {
  return axios.get(routes.user(id));
}

export function getUsers(body?: GetUsersRequestBody) {
  return axios.get(routes.users, { params: { ...body } });
}

type UnImpersonateUserResponse = {
  user: UserType;
  impersonator: UserType;
};

export async function unimpersonateUser(): AxiosPromise<UnImpersonateUserResponse> {
  await axios.post(routes.unimpersonate);
  const response = await axios.get(routes.me);

  return response;
}

export function impersonateUser(user_id: string) {
  return axios.post(routes.impersonate, { user_id });
}

export function updateUserPhone(user_id: string, phone_number: E164Number) {
  return axios.patch(routes.user(user_id), { phone_number });
}

export type UpdateUserRequestBody = {
  email?: string;
  phone_number?: E164Number;
  archived?: boolean;
  role?: RolesKey;
  address?: AddressType | AddressFormType; // TODO: remove AddressFormType when taking out legacy code
};

export function updateUser({ user_id, body }: { user_id: string; body: UpdateUserRequestBody }) {
  const updatedBody = {
    ...body,
    ...(body.phone_number
      ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
      : {}),
  };
  return axios.patch(routes.user(user_id), updatedBody);
}
