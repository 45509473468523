import { datadogLogs } from '@datadog/browser-logs';
import { UserType } from 'api/types';

type PropertiesType = Record<string, any> & { user?: UserType };

class DataDogLogs {
  // sanitize user-related data or other sensitive objects
  static sanitizeUserData = (data: PropertiesType) => {
    const sanitizedData = { ...data };

    if (sanitizedData?.user) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      const { address, phone_number, ...safeUserData } = sanitizedData.user;

      sanitizedData.user = {
        ...safeUserData,
        address: {
          ...address,
          street_1: '[REDACTED]',
          street_2: '[REDACTED]',
        },
        phone_number: '[REDACTED]',
      };
    }

    return sanitizedData;
  };

  static log = (
    message: string,
    properties: PropertiesType = {},
    level: 'debug' | 'info' | 'warn' | 'error' = 'info',
    error?: Error,
  ) => {
    let sanitizedProperties = properties;

    // Check if properties contain user data or sensitive fields
    if (properties.user) {
      sanitizedProperties = DataDogLogs.sanitizeUserData(properties);
    }

    datadogLogs.logger.log(message, sanitizedProperties, level, error);
  };

  static error = (message: string, properties: PropertiesType = {}, error?: Error) => {
    DataDogLogs.log(message, properties, 'error', error);
  };
}

export default DataDogLogs;
