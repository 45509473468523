import styled from 'styled-components';
import colors, { Color } from 'theme/colors';

interface TextProps {
  color?: Color;
  $margin?: string;
  $textAlign?: string;
}

const LEGACY_FONT_FAMILY = 'AvenirLTPro';

export const Subtitle2 = styled.label<TextProps>`
  font-family: ${LEGACY_FONT_FAMILY};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};

  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Subtitle3 = styled.label<TextProps>`
  font-family: ${LEGACY_FONT_FAMILY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

export const H2 = styled.h2<TextProps>`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

export const H3 = styled.h3<TextProps>`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

type H4Type = TextProps & {
  $bold?: boolean;
};

export const H4 = styled.h4<H4Type>`
  font-family: ${LEGACY_FONT_FAMILY};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ $bold = false }) => ($bold ? 900 : 700)};
  line-height: 24px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

export const H5 = styled.h5<TextProps>`
  font-family: ${LEGACY_FONT_FAMILY};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

export const BodyRegular = styled.p<TextProps>`
  font-family: ${LEGACY_FONT_FAMILY};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ color = colors.secondaryDarkBlue }) => color};
  margin: ${({ $margin = 0 }) => $margin};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
`;

export const BodyBold = styled(BodyRegular)`
  font-weight: 700;
`;
