import styled from 'styled-components';
import { Grid as SystemGrid } from 'legacy/components/SystemCardStyles';
import { MOBILE_MAX_WIDTH } from '../../constants';

export const Grid = styled(SystemGrid)`
  align-items: center;
  p {
    margin: 0;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    gap: 8px;
    p:first-child {
      font-size: 20px;
      grid-column: span ${({ $columns }) => $columns};
    }
  }
`;

export default Grid;
