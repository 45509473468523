export const MOBILE_MAX_WIDTH = 991;
export const DESKTOP_MIN_WIDTH = MOBILE_MAX_WIDTH + 1;
export const USER_ROLES_INTERNAL = {
  ADMIN: 'ADMIN',
  SUPPORT: 'SUPPORT',
  MARKETING: 'MARKETING',
  SUPER_ADMIN: 'SUPER_ADMIN',
} as const;

export const USER_ROLES = {
  ...USER_ROLES_INTERNAL,
  HOMEOWNER: 'HOMEOWNER',
} as const;

export enum ProductType {
  LOAN_BUYDOWN_PROMO = 'load_buydown_promo',
  VARIABLE_PPA = 'variable_ppa',
  FIXED_PPA = 'fixed_ppa',
  LEASE = 'lease',
}

export enum ContractType {
  LOA = 'LOA',
  LSE = 'LSE',
  PPA = 'PPA',
}

export const breakpointValues = {
  xSmall: 375,
  small: 626,
  medium: 1024,
  large: 1240,
  xLarge: 1440,
};

export const breakpoints = {
  xSmall: `${breakpointValues.xSmall}px`,
  small: `${breakpointValues.small}px`,
  medium: `${breakpointValues.medium}px`,
  large: `${breakpointValues.large}px`,
  xLarge: `${breakpointValues.xLarge}px`,
};

export type TBreakpoints = typeof breakpoints;
export type TBreakpointKeys = keyof TBreakpoints;

export const SOLAREDGE_MANUFACTURER_ID = '16941363-3cc3-471d-a9de-cc401c81c23d';
export const ENPHASE_MANUFACTURER_ID = '61b93d95-cd4e-4b20-a3e1-0648f36e7f34';

export const SUPPORT_EMAIL = 'support@myeverbright.com';

export const ANDROID_DOWNLOAD =
  'https://play.google.com/store/apps/details?id=com.goeverbright.MyEverBright';

export const IOS_DOWNLOAD = 'https://apps.apple.com/us/app/myeverbright/id1641960636';

export const EVERBRIGHT_OMNIDIAN_LOGIN = 'https://everbright.omnidian.com/#/login';

export const EVERBRIGHT_ONLINE_ACCOUNT_LINK =
  'https://everbright.youronlineaccount.com/Login.aspx?ReturnUrl=%2F';

export const perSessionBannersKey = 'perSessionBanners';

export const perSessionFetchBannersKey = 'perSessionFetchBanners';

export const perSessionModalKey = 'perSessionModalKey';

export const authenticatedKey = 'authenticated';

export const achBannerText = 'If you have not already, enroll in AutoPay today.';

export const achBannerLinkText = 'Click to learn how';

export const achPDFLink =
  'https://www.goeverbright.com/hubfs/EverBright/Homeowner - How to enroll in ACH.pdf';

export const STATES: { text: string; key: string; value: string }[] = [
  { text: 'Alabama', key: 'AL', value: 'AL' },
  { text: 'Alaska', key: 'AK', value: 'AK' },
  { text: 'American Samoa', key: 'AS', value: 'AS' },
  { text: 'Arizona', key: 'AZ', value: 'AZ' },
  { text: 'Arkansas', key: 'AR', value: 'AR' },
  { text: 'California', key: 'CA', value: 'CA' },
  { text: 'Colorado', key: 'CO', value: 'CO' },
  { text: 'Connecticut', key: 'CT', value: 'CT' },
  { text: 'Delaware', key: 'DE', value: 'DE' },
  { text: 'District Of Columbia', key: 'DC', value: 'DC' },
  { text: 'Federated States Of Micronesia', key: 'FM', value: 'FM' },
  { text: 'Florida', key: 'FL', value: 'FL' },
  { text: 'Georgia', key: 'GA', value: 'GA' },
  { text: 'Guam', key: 'GU', value: 'GU' },
  { text: 'Hawaii', key: 'HI', value: 'HI' },
  { text: 'Idaho', key: 'ID', value: 'ID' },
  { text: 'Illinois', key: 'IL', value: 'IL' },
  { text: 'Indiana', key: 'IN', value: 'IN' },
  { text: 'Iowa', key: 'IA', value: 'IA' },
  { text: 'Kansas', key: 'KS', value: 'KS' },
  { text: 'Kentucky', key: 'KY', value: 'KY' },
  { text: 'Louisiana', key: 'LA', value: 'LA' },
  { text: 'Maine', key: 'ME', value: 'ME' },
  { text: 'Marshall Islands', key: 'MH', value: 'MH' },
  { text: 'Maryland', key: 'MD', value: 'MD' },
  { text: 'Massachusetts', key: 'MA', value: 'MA' },
  { text: 'Michigan', key: 'MI', value: 'MI' },
  { text: 'Minnesota', key: 'MN', value: 'MN' },
  { text: 'Mississippi', key: 'MS', value: 'MS' },
  { text: 'Missouri', key: 'MO', value: 'MO' },
  { text: 'Montana', key: 'MT', value: 'MT' },
  { text: 'Nebraska', key: 'NE', value: 'NE' },
  { text: 'Nevada', key: 'NV', value: 'NV' },
  { text: 'New Hampshire', key: 'NH', value: 'NH' },
  { text: 'New Jersey', key: 'NJ', value: 'NJ' },
  { text: 'New Mexico', key: 'NM', value: 'NM' },
  { text: 'New York', key: 'NY', value: 'NY' },
  { text: 'North Carolina', key: 'NC', value: 'NC' },
  { text: 'North Dakota', key: 'ND', value: 'ND' },
  { text: 'Northern Mariana Islands', key: 'MP', value: 'MP' },
  { text: 'Ohio', key: 'OH', value: 'OH' },
  { text: 'Oklahoma', key: 'OK', value: 'OK' },
  { text: 'Oregon', key: 'OR', value: 'OR' },
  { text: 'Palau', key: 'PW', value: 'PW' },
  { text: 'Pennsylvania', key: 'PA', value: 'PA' },
  { text: 'Puerto Rico', key: 'PR', value: 'PR' },
  { text: 'Rhode Island', key: 'RI', value: 'RI' },
  { text: 'South Carolina', key: 'SC', value: 'SC' },
  { text: 'South Dakota', key: 'SD', value: 'SD' },
  { text: 'Tennessee', key: 'TN', value: 'TN' },
  { text: 'Texas', key: 'TX', value: 'TX' },
  { text: 'Utah', key: 'UT', value: 'UT' },
  { text: 'Vermont', key: 'VT', value: 'VT' },
  { text: 'Virgin Islands', key: 'VI', value: 'VI' },
  { text: 'Virginia', key: 'VA', value: 'VA' },
  { text: 'Washington', key: 'WA', value: 'WA' },
  { text: 'West Virginia', key: 'WV', value: 'WV' },
  { text: 'Wisconsin', key: 'WI', value: 'WI' },
  { text: 'Wyoming', key: 'WY', value: 'WY' },
];
