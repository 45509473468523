import React, { useState } from 'react';
import { usePageName } from 'hooks/usePageName';

import { ButtonStyled, IconWrapper } from './MenuButton.styles';
import { TIcon } from '../icons/TIcon';

export type TButtonProps = {
  ariaLabel?: string;
  id?: string;
  dataTestId?: string;
  label?: string;
  margin?: string;
  Icon?: TIcon;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

/**
 * @param {string} [ariaLabel] - aria Label for accesibility (optional)
 * @param {string} [id] - id value for the button (optional)
 * @param {string} [dataTestId] - id value used for testing (optional)
 * @param {boolean} [isDisabled] - is button disabled (optional)
 * @param {string} [label] - button text contents (optional)
 * @param {TButtonStyleVariantsKey} [styleVariant] - defaults to primary style (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {boolean} [isFullWidth] - defaults to false (width = auto) (optional)
 * @param {string} [maxWidth] - defaults to false (max-width = none) (optional)
 * @param {function} [Icon] - icon to use that renders inside the button (optional)
 * @param {string} [iconRight] - places icon to the right. Defaults to left (optional)
 * @param {string} [margin] - css margin value. Defaults to 0 (optional)
 * @param {function} [onBlur] - onBlur handler (optional)
 * @param {function} [onClick] - onClick handler (optional)
 * @param {function} [onKeyDown] - onKeyDown handler (optional)
 * @param {function} [onKeyUp] - onKeyUp handler (optional)
 * @param {function} [onFocus] - onFocus handler (optional)
 * @param {function} [onMouseEnter] - onMouseEnter handler (optional)
 * @param {function} [onMouseDown] - onMouseDown handler (optional)
 * @param {function} [onMouseLeave] - onMouseLeave handler (optional)
 * @param {function} [onMouseUp] - onMouseUp handler (optional)
 * @param {number} [tabIndex] - tab index value (optional)
 * @param {string} [type] - HTML button type (optional)
 */

export function MenuButton({
  ariaLabel,
  id,
  dataTestId,
  margin = '0',
  label,
  Icon,
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
}: TButtonProps) {
  const [isHovering, setIsHovering] = useState(false);

  const { currentPage } = usePageName();

  const isActive = currentPage === label?.toUpperCase();

  const handleOnMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHovering(true);
    onMouseEnter?.(e);
  };

  const handleOnMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHovering(false);
    onMouseLeave?.(e);
  };

  return (
    <ButtonStyled
      type="button"
      aria-label={ariaLabel}
      id={id}
      data-testid={dataTestId}
      $margin={margin}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      $isActive={isActive}
      $isHovering={isHovering}
    >
      <>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        <span>{label}</span>
      </>
    </ButtonStyled>
  );
}

export default MenuButton;
