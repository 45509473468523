import React, { useMemo } from 'react';
import { generateRandomString } from 'legacy/utils';
import {
  PRIMARY_ACCORDION_STYLE_VARIANT,
  TAccordionStyleVariantsKey,
} from 'theme/accordions/TAccordions';

import { AccordionItem } from './AccordionItem';
import { AccordionStyled } from './AccordionStyles';

export type AccordionItemType = {
  id?: string;
  label: string;
  content: React.ReactNode;
};

export type AccordionProps = {
  id?: string;
  styleVariant?: TAccordionStyleVariantsKey;
  contentBgColor?: string;
  isOpen?: boolean;
  size?: 'small' | 'large';
  items: AccordionItemType[];
  children?: React.ReactNode;
};

/**
 * @param {string} [id] - id needed for setting accessibility control roles on the accordion items (optional)
 * @param {TAccordionStyleVariantsKey} [styleVariant] - defaults to primary (optional)
 * @param {string} [contentBgColor] - optional background color for content (optional)
 * @param {boolean} isOpen -  defaults to false (optional)
 * @param {'small' | 'large'} [size] - defaults to large (optional)
 * @param {AccordionItemType[]} [items] - shape of each accordion item
 * @param {JSX.Element} [children] - children in replace of the AccordionItem component (optional)
 */

export function Accordion({
  id,
  styleVariant = PRIMARY_ACCORDION_STYLE_VARIANT,
  contentBgColor,
  isOpen = false,
  size = 'large',
  items,
  children,
}: AccordionProps) {
  const accordionId = useMemo(() => id || generateRandomString(), [id]);

  return (
    <AccordionStyled id={accordionId}>
      {children ||
        items.map((item: AccordionItemType, i: number) => (
          <AccordionItem
            key={
              item.id
                ? `${accordionId}-item-${item.id}`
                : `${accordionId}-item-${generateRandomString()}`
            }
            id={item.id ?? `${accordionId}-item-${i}`}
            styleVariant={styleVariant}
            contentBgColor={contentBgColor}
            label={item.label}
            isOpen={isOpen}
            size={size}
          >
            {item.content}
          </AccordionItem>
        ))}
    </AccordionStyled>
  );
}

export default Accordion;
