import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

type Props = IIconProps & {
  filled?: boolean;
};

export function OverviewIcon({ color: colorProp, filled = false }: Props) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.875 1.8125C6.875 0.880859 6.11914 0.125 5.1875 0.125H1.8125C0.880859 0.125 0.125 0.880859 0.125 1.8125V5.1875C0.125 6.11914 0.880859 6.875 1.8125 6.875H5.1875C6.11914 6.875 6.875 6.11914 6.875 5.1875V1.8125ZM6.875 10.8125C6.875 9.88086 6.11914 9.125 5.1875 9.125H1.8125C0.880859 9.125 0.125 9.88086 0.125 10.8125V14.1875C0.125 15.1191 0.880859 15.875 1.8125 15.875H5.1875C6.11914 15.875 6.875 15.1191 6.875 14.1875V10.8125ZM9.125 1.8125V5.1875C9.125 6.11914 9.88086 6.875 10.8125 6.875H14.1875C15.1191 6.875 15.875 6.11914 15.875 5.1875V1.8125C15.875 0.880859 15.1191 0.125 14.1875 0.125H10.8125C9.88086 0.125 9.125 0.880859 9.125 1.8125ZM15.875 10.8125C15.875 9.88086 15.1191 9.125 14.1875 9.125H10.8125C9.88086 9.125 9.125 9.88086 9.125 10.8125V14.1875C9.125 15.1191 9.88086 15.875 10.8125 15.875H14.1875C15.1191 15.875 15.875 15.1191 15.875 14.1875V10.8125Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.92857 1.28571C1.575 1.28571 1.28571 1.575 1.28571 1.92857V5.78571C1.28571 6.13929 1.575 6.42857 1.92857 6.42857H5.78571C6.13929 6.42857 6.42857 6.13929 6.42857 5.78571V1.92857C6.42857 1.575 6.13929 1.28571 5.78571 1.28571H1.92857ZM0 1.92857C0 0.863839 0.863839 0 1.92857 0H5.78571C6.85045 0 7.71429 0.863839 7.71429 1.92857V5.78571C7.71429 6.85045 6.85045 7.71429 5.78571 7.71429H1.92857C0.863839 7.71429 0 6.85045 0 5.78571V1.92857ZM1.92857 11.5714C1.575 11.5714 1.28571 11.8607 1.28571 12.2143V16.0714C1.28571 16.425 1.575 16.7143 1.92857 16.7143H5.78571C6.13929 16.7143 6.42857 16.425 6.42857 16.0714V12.2143C6.42857 11.8607 6.13929 11.5714 5.78571 11.5714H1.92857ZM0 12.2143C0 11.1496 0.863839 10.2857 1.92857 10.2857H5.78571C6.85045 10.2857 7.71429 11.1496 7.71429 12.2143V16.0714C7.71429 17.1362 6.85045 18 5.78571 18H1.92857C0.863839 18 0 17.1362 0 16.0714V12.2143ZM16.0714 1.28571H12.2143C11.8607 1.28571 11.5714 1.575 11.5714 1.92857V5.78571C11.5714 6.13929 11.8607 6.42857 12.2143 6.42857H16.0714C16.425 6.42857 16.7143 6.13929 16.7143 5.78571V1.92857C16.7143 1.575 16.425 1.28571 16.0714 1.28571ZM12.2143 0H16.0714C17.1362 0 18 0.863839 18 1.92857V5.78571C18 6.85045 17.1362 7.71429 16.0714 7.71429H12.2143C11.1496 7.71429 10.2857 6.85045 10.2857 5.78571V1.92857C10.2857 0.863839 11.1496 0 12.2143 0ZM12.2143 11.5714C11.8607 11.5714 11.5714 11.8607 11.5714 12.2143V16.0714C11.5714 16.425 11.8607 16.7143 12.2143 16.7143H16.0714C16.425 16.7143 16.7143 16.425 16.7143 16.0714V12.2143C16.7143 11.8607 16.425 11.5714 16.0714 11.5714H12.2143ZM10.2857 12.2143C10.2857 11.1496 11.1496 10.2857 12.2143 10.2857H16.0714C17.1362 10.2857 18 11.1496 18 12.2143V16.0714C18 17.1362 17.1362 18 16.0714 18H12.2143C11.1496 18 10.2857 17.1362 10.2857 16.0714V12.2143Z"
        fill={color}
      />
    </svg>
  );
}

export default OverviewIcon;
