import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import pages from 'pages';
import ProtectedRoute from 'legacy/ProtectedRoute';
import { AdminContextProvider } from 'contexts/AdminContext';
import Dashboard from 'legacy/views/dashboard/Dashboard';
import Admin from 'legacy/views/admin/Admin';
import Authorize from 'legacy/views/authorize/Authorize';
import Chat from 'legacy/views/chat/Chat';
import Layout from 'legacy/views/Layout';
import ResetPassword from 'legacy/views/auth/ResetPassword';
import ClaimAccount from 'legacy/views/auth/ClaimAccount';
import Login from 'legacy/views/auth/Login';
import ForgotPassword from 'legacy/views/auth/ForgotPassword';
import NoMatch from 'legacy/views/noMatch/NoMatch';
import AccountSettings from 'legacy/views/dashboard/AccountSettings';
import Faqs from 'legacy/views/dashboard/Faqs';
import Faq from 'legacy/views/dashboard/Faq';
import Status from 'legacy/views/status/Status';
import { isHomeowner } from 'legacy/utils';
import '../styles.less';
import '../semantic-ui/semantic-ui-less/semantic.less'; // Used to contain styles within AppDependentRoutes.ts. Once reskin happens this will all be legacy

export function AppDependentRoutes() {
  const { userResult } = useUser();

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={Boolean(userResult?.user)}
            redirectPath={pages.LOGIN}
            hasSession
            isLoading={userResult?.loading}
          />
        }
      >
        <Route element={<Layout hasNav hasFooter />}>
          <Route index element={<Navigate to={pages.DASHBOARD} />} />
          <Route path={`${pages.DASHBOARD}/*`} element={<Dashboard />} />
          <Route path={pages.AUTHORIZE} element={<Authorize />} />
          <Route
            path={`${pages.ADMIN}/*`}
            element={
              <AdminContextProvider>
                <Admin />
              </AdminContextProvider>
            }
          />
          <Route path={pages.ACCOUNT} element={<AccountSettings />} />
          <Route path={pages.SUPPORT} element={<Faqs />} />
          <Route path={`${pages.FAQ}/*`} element={<Faq />} />
          <Route path={pages.STATUS} element={<Status />} />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute
            isAllowed={!userResult?.user}
            redirectPath={
              isHomeowner(userResult?.user?.role) ? `/${pages.DASHBOARD}` : `/${pages.ADMIN}`
            }
            isLoading={userResult?.loading}
          />
        }
      >
        <Route element={<Layout accountView hasNav hasFooter />}>
          <Route path={pages.LOGIN} element={<Login />} />
          <Route path={pages.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={pages.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={pages.CLAIM_ACCOUNT} element={<ClaimAccount />} />
        </Route>
      </Route>

      <Route path={pages.CHAT} element={<Chat />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default AppDependentRoutes;
