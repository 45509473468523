import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getFeatureFlags } from 'api/feature_flags';
import { FlagName } from 'api/types';

/*
    Below: provide default values to use as a fallback to response from the API
*/
const defaultValues: Record<FlagName, boolean> = {
  [FlagName.webV2]: false,
  [FlagName.webV2_1]: false,
  [FlagName.createBrowserRouter]: false,
  [FlagName.achModal]: false,
  [FlagName.statusV2]: false,
  [FlagName.webDarkTheme]: false,
};

type TFeatureFlagContext = Record<FlagName, boolean>;

interface FeatureFlagContextProps {
  flags: TFeatureFlagContext;
  loading: boolean;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextProps>({
  flags: defaultValues,
  loading: true,
});

function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
  const { data: queryData, isLoading } = useQuery(
    'featureFlags',
    () => getFeatureFlags().then((response) => response.data),
    {
      select: (data) =>
        data.reduce((acc: TFeatureFlagContext, flag) => {
          acc[flag.name] = flag.enabled;

          return acc;
        }, defaultValues),
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const value = useMemo(
    () => ({
      flags: queryData || defaultValues,
      loading: isLoading,
    }),
    [queryData, isLoading],
  );

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}

export { FeatureFlagProvider, FeatureFlagContext };

/*
    Example of usage:

const NewFeature = () => {
    return <Text>New Feature</Text>;
}

const FeatureFlagConsumerExample = () => {
    const {
      flags: { [FlagName.NewFeature]: newFeature }
    } = useFeatureFlags();

    return (
        <>
        {newFeature && <NewFeature />}
        </>
    );
}
*/
