import { startOfDay } from 'date-fns';
import { AccountType } from 'api/types';
import { getAgreementWithMonitoring } from './hasMonitoring';

export const getMonitoringSystemInfo = (selectedAccount?: AccountType) => {
  const agreementWithMonitoring = getAgreementWithMonitoring(selectedAccount?.agreements);
  const system = agreementWithMonitoring?.system;
  let inServiceDate: Date | null | undefined = null;
  if (agreementWithMonitoring?.in_service_date)
    inServiceDate = startOfDay(agreementWithMonitoring?.in_service_date);
  return { system, inServiceDate };
};

export default getMonitoringSystemInfo;
