import React from 'react';
import { Button } from 'legacy/components/common/design-system';
import { InlineButtonWrapper } from './DatePickerStyles';

type Props = {
  onCancelClick: () => void;
  onOkClick: () => void;
};
export function CustomFooter({ onCancelClick, onOkClick }: Props) {
  return (
    <InlineButtonWrapper>
      <Button styleVariant="tertiary" label="Cancel" onClick={onCancelClick} />
      <Button styleVariant="tertiary" label="OK" onClick={onOkClick} />
    </InlineButtonWrapper>
  );
}

export default CustomFooter;
