import styled from 'styled-components';

export const ClaimAccountLoadingContainer = styled.div`
  min-height: 168px;
  position: relative;
`;

export const LogoWrapper = styled.div`
  position: relative;
  height: 84px;
  div {
    height: 48px;
    width: 48px;
  }
  img {
    height: 48px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    border-radius: 18px;
    padding: 24px 35px;

    div {
      height: 60px;
      width: 60px;
    }
    img {
      height: 60px;
    }
  }
`;
