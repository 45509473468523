import styled from 'styled-components';
import colors from 'theme/colors';

export const Content = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.gray10};
  background: ${colors.white};
  padding: 16px;

  /* Elevation/Mid */
  box-shadow:
    0px 4px 8px -2px rgba(34, 52, 91, 0.1),
    0px 2px 4px -2px rgba(34, 52, 91, 0.06);
`;

export const TriggerButton = styled.button`
  background: transparent;
  border: none;
`;
