import React, { useMemo, useState } from 'react';
import { useGlobalState } from 'hooks/useGlobalState';
import { debounce } from 'lodash';
import { useUser } from '../../../hooks/useUser';
import { filterFaqs } from '../../utils';
import { Accordion, AccordionItemType, TextInput } from '../../components/common/design-system';
import Support from './Support';
import { Row } from '../../components/common/myeverbright';
import { DashboardLayout } from './DashboardLayout';

export default function Faqs() {
  const { faqs } = useGlobalState();
  const { userResult } = useUser();

  const [searchQuery, setSearchQuery] = useState('');

  const filteredFAQs = useMemo(
    () => filterFaqs(faqs, userResult?.user, searchQuery),
    [faqs, userResult?.user, searchQuery],
  );

  const items = useMemo((): AccordionItemType[] => {
    if (filteredFAQs)
      return filteredFAQs.map((faq) => ({
        label: faq.question,
        id: faq.id,
        content: <p>{faq.answer}</p>,
      }));
    return [];
  }, [filteredFAQs]);

  const debouncedSetQuery = debounce((q) => setSearchQuery(q), 300);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetQuery(e.target.value);
  };

  return (
    <DashboardLayout>
      <Support>
        <Row>
          <TextInput ariaLabel="searchFaq" onChange={handleInputChange} placeholder="Search FAQ" />
        </Row>
        <Accordion
          styleVariant="secondary"
          items={items}
          size="small"
          isOpen={searchQuery.length > 0 && items.length > 0}
        />
      </Support>
    </DashboardLayout>
  );
}
