import React from 'react';
import { resolvePath, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import { PasswordForm, PasswordFormInput } from 'modules/auth/components/PasswordForm/PasswordForm';
import { useSetPasswordWithAccessCode } from 'api/accounts';
import { ClaimAccountLoading } from 'modules/auth/components/ClaimAccountLoading/ClaimAccountLoading';
import { AuthBox, AuthWrapper, LinkStyled, LoginFooter } from 'modules/auth/Auth.styles';

export function Password() {
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email') || '';
  const accessCode = searchParams.get('access_code') || '';

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate, isLoading } = useSetPasswordWithAccessCode({
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('password.yourAccountWasCreated'),
        theme,
      });

      navigate(`/${pages.LOGIN}`);
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const handleSubmitForm = ({ password }: PasswordFormInput) => {
    mutate({ email, password, access_code: accessCode });
  };

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('password.createYour')}
          titleBold={t('password.password')}
          showLogo={!isLoading}
        />
        {isLoading ? <ClaimAccountLoading /> : null}

        {email && !isLoading ? (
          <PasswordForm email={email} onSubmitForm={handleSubmitForm} />
        ) : null}
      </AuthBox>
      <LoginFooter>
        {t('claimAccount.alreadyHaveAnAccount')}{' '}
        <LinkStyled to={resolvePath(`/${pages.LOGIN}`)}>{t('loginScreen.logInHere')}</LinkStyled>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default Password;
