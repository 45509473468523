import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { submitWelcomeChecklist } from 'api/agreement';
import { Modal } from 'legacy/components/common/design-system';
import { welcomeChecklistErrorMessage } from 'legacy/copy';
import useUser from 'hooks/useUser';
import {
  AgreementType,
  WelcomeChecklistItemType,
  WelcomeChecklistResult,
  WelcomeChecklistType,
} from 'legacy/types';
import { CtaButtons } from 'legacy/components/common/design-system/Modal/types';
import { WelcomeChecklistHeader } from './WelcomeChecklistHeader';
import { Questions, SkippedQuestionsType } from './Questions';
import { ReviewAnswers } from './ReviewAnswers';
import { getPrimaryLanguage } from '../utils';

type Props = {
  agreement: AgreementType;
  checklistQuestions: WelcomeChecklistItemType[];
  isOpen: boolean;
  handleCloseWelcomeChecklist: () => void;
};

function WelcomeChecklist({
  agreement,
  isOpen,
  checklistQuestions,
  handleCloseWelcomeChecklist,
}: Props) {
  const { userResult } = useUser();
  const [activePage, setActivePage] = useState(1);
  const [useQuestions, setUseQuestions] = useState<WelcomeChecklistItemType[]>([]);
  const [confirmed, setConfirmed] = useState(false);
  const [showAckRequired, setShowAckRequired] = useState(false);
  const [skippedQuestions, setSkippedQuestions] = useState<SkippedQuestionsType>({});
  const [message, setMessage] = useState<{ error: boolean; message: React.ReactNode }>({
    error: false,
    message: '',
  });
  const { t, i18n } = useTranslation();

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setUseQuestions(checklistQuestions);
  }, [checklistQuestions]);

  useEffect(() => {
    const primaryLanguage = getPrimaryLanguage(agreement.primary_language);
    if (primaryLanguage === 'es') {
      i18n.changeLanguage('es');
    }
  }, [i18n, agreement]);

  const totalPages =
    useQuestions.reduce((lastPage, question) => Math.max(lastPage, question.page), 0) + 1;
  const lastPage = totalPages;

  const reviewPage = activePage === lastPage;
  const continueButton = activePage !== lastPage;

  const questions = useMemo(
    () => useQuestions.filter((question) => activePage === question.page),
    [activePage, useQuestions],
  );

  const nextPage = () => {
    const skipped: SkippedQuestionsType = {};
    let firstError: HTMLElement | null = null;

    questions.forEach((question, index) => {
      if (!question.answer) {
        firstError = document.getElementById(`checklist-item-${index}`);
        skipped[index] = question;
      }
    });

    if (firstError) {
      (firstError as HTMLElement).scrollIntoView();

      setSkippedQuestions(skipped);
    } else if (activePage < totalPages) {
      setSkippedQuestions({});
      setActivePage(activePage + 1);
    }
  };
  const previousPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleChangeAnswer = (activePageIndex: number, answer: string) => {
    setUseQuestions((items) => {
      const updatedQuestions = [...items];
      const questionsOnPreviousPages = updatedQuestions.filter(
        (question) => question.page < activePage,
      );
      const questionIndex = questionsOnPreviousPages.length + activePageIndex;
      updatedQuestions[questionIndex].answer = answer;

      return updatedQuestions;
    });
  };

  const handleChangeConfirmed = () => {
    setConfirmed((c) => !c);
  };

  const handleSubmit = async () => {
    if (confirmed) {
      setShowAckRequired(false);
      setSubmitting(true);
      try {
        const { data: updatedWelcomeChecklist }: { data: WelcomeChecklistType } =
          await submitWelcomeChecklist({ agreement, welcomeChecklist: useQuestions });

        if (updatedWelcomeChecklist.result === WelcomeChecklistResult.PASS) {
          handleCloseWelcomeChecklist();
        } else {
          const callSupportCopy = t('welcomeChecklist.callSupport');
          setMessage({
            error: false,
            message: callSupportCopy ? <Trans>{callSupportCopy}</Trans> : '',
          });
        }
      } catch (e: any) {
        let errorMessage = userResult?.impersonator
          ? "you're impersonating you can't do that"
          : welcomeChecklistErrorMessage;

        if (e.response && e.response.status === 409 && e.response.data && e.response.data.detail) {
          errorMessage = e.response.data.detail;
        }

        setMessage({ error: true, message: errorMessage });
      } finally {
        setSubmitting(false);
      }
    } else {
      setShowAckRequired(true);
    }
  };

  const ctaButtons = () => {
    const buttons: CtaButtons = {
      tertiary: undefined,
      primary: undefined,
    };

    if (message.message) {
      buttons.primary = message.error
        ? {
            label: t('cta.tryAgain'),
            onClick: () => {
              setMessage({ error: false, message: '' });
            },
          }
        : { label: t('cta.close'), onClick: handleCloseWelcomeChecklist };

      buttons.tertiary = message.error
        ? { label: t('cta.cancel'), onClick: handleCloseWelcomeChecklist }
        : undefined;
    } else {
      buttons.primary = continueButton
        ? { label: t('cta.continue'), onClick: nextPage }
        : { label: t('cta.submit'), onClick: handleSubmit, isLoading: isSubmitting };

      buttons.tertiary =
        activePage > 1 ? { label: t('cta.back'), onClick: previousPage } : undefined;
    }

    return buttons;
  };

  return (
    <Modal
      contentLabel={t('welcomeChecklist.welcomeChecklist')}
      isOpen={isOpen}
      onRequestClose={handleCloseWelcomeChecklist}
      totalPages={totalPages - 1}
      activePage={message.message ? undefined : activePage}
      isFullWidth
      isFullHeight
      title={t('welcomeChecklist.welcomeChecklist')}
      subHeader={<WelcomeChecklistHeader hideHeader={reviewPage || Boolean(message.message)} />}
      ctaButtons={ctaButtons()}
      styleVariant="tertiary"
      hasFadeEffect
    >
      {reviewPage ? (
        <ReviewAnswers
          checklist={useQuestions}
          confirmed={confirmed}
          showAckRequired={showAckRequired}
          onChangeConfirmed={handleChangeConfirmed}
          message={message.message}
          primaryLanguage={getPrimaryLanguage(agreement.primary_language)}
        />
      ) : (
        <Questions
          questions={questions}
          skippedQuestions={skippedQuestions}
          primaryLanguage={getPrimaryLanguage(agreement.primary_language)}
          onChangeAnswer={handleChangeAnswer}
        />
      )}
    </Modal>
  );
}

export default WelcomeChecklist;
