import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useNavigate } from 'react-router';

import { useUser } from 'hooks/useUser';
import { Button } from 'shared/components/Button/Button';
import { Card } from 'shared/components/Card/Card';
import { ArrowUpRightFromSquare } from 'shared/components/icons';
import { URLS } from 'constants/urls';
import { Header } from 'shared/components/Header/Header';
import pages from 'pages';
import { Body, BodyText, BillingWrapper, ButtonWrapper } from './Billing.styles';

export function Billing() {
  const { t } = useTranslation();
  const { selectedAccount } = useUser();
  const navigate = useNavigate();

  const handleClick = () => {
    window.open(URLS.EVERBRIGHT_ONLINE_ACCOUNT, '_blank');
  };

  return (
    <BillingWrapper>
      <Header pageName="BILLING" title={t('billing.billing')} />

      <Body>
        {selectedAccount?.agreements?.map((agreement, idx) => {
          const handleClickAgreementDetails = () => {
            navigate(resolvePath(`${pages.AGREEMENTS}/${agreement.id}`, `/${pages.DASHBOARD}`));
          };
          return (
            <Card
              key={agreement.homeowner_contract_id}
              title={`${agreement.product_display_name} #${agreement.homeowner_contract_id}`}
            >
              <BodyText as="p">{t('billing.makePayments')}</BodyText>

              <ButtonWrapper>
                <Button
                  dataTestId={`go-to-billing-button${idx}`}
                  label={t('billing.goToBilling')}
                  Icon={ArrowUpRightFromSquare}
                  iconRight
                  onClick={handleClick}
                />
                <Button
                  dataTestId={`view-agreement-button${idx}`}
                  styleVariant="tertiary"
                  label={t('billing.viewAgreementDetails')}
                  onClick={handleClickAgreementDetails}
                />
              </ButtonWrapper>
            </Card>
          );
        })}
      </Body>
    </BillingWrapper>
  );
}
export default Billing;
