import React from 'react';
import { handleFocusedKeyPress } from 'shared/utils/handleFocusedKeypress';
import { SegmentControl, SegmentWrapper } from './TimescaleControl.styles';

type TimescaleEn = 'day' | 'week' | 'month' | 'year';

interface TimescaleControlProps {
  timescaleOptions: Record<TimescaleEn, string>;
  activeTimescale: string;
  handleSetTimescale: (timescale: string) => void;
}

export function TimescaleControl({
  timescaleOptions,
  activeTimescale,
  handleSetTimescale,
}: TimescaleControlProps) {
  const { day, week, month, year } = timescaleOptions;
  const segments = [day, week, month, year];

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, segment: string) => {
    handleFocusedKeyPress(e, () => handleSetTimescale(segment));
  };

  return (
    <SegmentWrapper>
      {segments.map((segment) => (
        <SegmentControl
          $isActive={segment === activeTimescale}
          onClick={(e) => {
            // prevents focused styling being applied on click
            e.currentTarget.blur();
            handleSetTimescale(segment);
          }}
          key={segment}
          tabIndex={0}
          onKeyDown={(e) => handleKeyPress(e, segment)}
        >
          {segment}
        </SegmentControl>
      ))}
    </SegmentWrapper>
  );
}

export default TimescaleControl;
