import React, { useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { useUser } from 'hooks/useUser';
import EditAccountSettingsForm from 'legacy/components/forms/EditAccountSettingsForm';
import { Button } from 'legacy/components/common/design-system';
import EditPhoneNumberForm from 'legacy/components/forms/EditPhoneNumberForm';
import { Address, Box, Card, Column, Message, Row } from 'legacy/components/common/myeverbright';
import { MessageType } from 'legacy/types';
import { SupportCard } from 'legacy/components/OverviewCards/SupportCard';
import Security from 'legacy/components/Security/Security';
import FaqRow from 'legacy/components/Faqs/FaqRow';
import { isEarlyAccess } from 'legacy/utils';
import { DashboardLayout } from './DashboardLayout';

export type HandleMessage = (msg: MessageType | null, delay?: number) => void;

function AccountSettings() {
  const { userResult, selectedAccount } = useUser();
  const user = userResult?.user;
  const impersonator = userResult?.impersonator;

  const [editingBillingAddress, setEditingBillingAddress] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleMessage: HandleMessage = (msg, delay) => {
    if (msg?.type !== 'error' && delay) {
      setTimeout(() => setMessage(null), delay);
    }
    setMessage(msg);
  };

  const handleEditingBillingAddress = () => setEditingBillingAddress(!editingBillingAddress);
  const handleEditingPhone = () => setEditingPhone(!editingPhone);

  return (
    <DashboardLayout>
      <Column>
        <h1>Account #{selectedAccount?.homeowner_account_id}</h1>
        <Row $collapsing>
          <Column $flex="2">
            {impersonator && (
              <Message
                header="Account settings are read-only"
                content="Account settings cannot be changed while impersonating a user. To change account settings, return to the admin view."
                severity="warning"
              />
            )}

            {selectedAccount && user && (
              <Card $fullWidth>
                <h3>Primary Contact</h3>
                <div className="subtitle-1">
                  If you need to update this information, please contact support.
                </div>
                <div>
                  <div className="label">Name</div>
                  <p>
                    {user?.first_name} {user?.last_name}
                  </p>
                  <div className="label">Email</div>
                  <p>{user?.email}</p>
                  <div className="edit-btn-container">
                    <div className="label">Phone</div>
                    {!impersonator && !isEarlyAccess(user?.accounts) && (
                      <Button
                        label={editingPhone ? 'Close' : 'Edit'}
                        onClick={handleEditingPhone}
                        styleVariant="tertiary"
                      />
                    )}
                  </div>
                  {editingPhone ? (
                    <Card $fullWidth $hideShadow>
                      <EditPhoneNumberForm
                        currentNumber={user.phone_number}
                        setEditingPhone={setEditingPhone}
                        handleMessage={handleMessage}
                      />
                    </Card>
                  ) : (
                    <p>{parsePhoneNumber(user.phone_number, 'US')?.formatNational()}</p>
                  )}
                  <div className="edit-btn-container">
                    <div className="label">Billing Address</div>
                    {!impersonator && !isEarlyAccess(user?.accounts) && (
                      <Button
                        label={editingBillingAddress ? 'Close' : 'Edit'}
                        onClick={handleEditingBillingAddress}
                        styleVariant="tertiary"
                      />
                    )}
                  </div>
                  {editingBillingAddress ? (
                    <Card $fullWidth $hideShadow>
                      <EditAccountSettingsForm
                        selectedAccount={selectedAccount}
                        setEditing={setEditingBillingAddress}
                        handleMessage={handleMessage}
                      />
                    </Card>
                  ) : (
                    <p>
                      <Address address={selectedAccount.address} />
                    </p>
                  )}
                </div>
                {message && <Message severity={message.type}>{message.message}</Message>}
              </Card>
            )}
            <Security />
          </Column>

          <Column $flex="1">
            <Box>
              <SupportCard fullHeight />
            </Box>
          </Column>
        </Row>

        <FaqRow />
      </Column>
    </DashboardLayout>
  );
}

export default AccountSettings;
