import React, { useState, useEffect } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { Calendar } from './Calendar';

type Props = {
  currentDate: Date;
  onDateChange: MonthChangeEventHandler;
  onCloseModal: () => void;
  dateRange: { oldest: Date; newest: Date };
};

export function DayPicker({ currentDate, onDateChange, onCloseModal, dateRange }: Props) {
  const [selected, setSelected] = useState(currentDate);

  useEffect(() => {
    setSelected(currentDate);
  }, [currentDate]);

  const handleOk = () => {
    onDateChange(selected);
    onCloseModal();
  };

  const handleDayClick = (date: Date) => {
    setSelected(date);
  };

  return (
    <Calendar
      selectedDate={selected}
      onDayClick={handleDayClick}
      onCancel={onCloseModal}
      onOk={handleOk}
      dateRange={dateRange}
    />
  );
}

export default DayPicker;
