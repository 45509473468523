import React from 'react';
import {
  VictoryAxis,
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { format } from 'date-fns';
import { chartStyles } from '../../views/dashboard/System/MonitoringChart/Chart/ChartStyles';
import { Tooltip } from '../../views/dashboard/System/MonitoringChart/Chart/Tooltip/ToolTip';
import { formatDate } from '../../utils';
import { colors } from '../../../theme/colors';

export type SeriesData = {
  date: string;
  count: number;
}[];

type Props = {
  invitedSeriesData: SeriesData;
  claimedSeriesData: SeriesData;
  claimedOffset: number;
};

const getCumulativeData = (seriesData: SeriesData, offset = 0) => {
  for (let i = 0; i + 1 < seriesData.length; i += 1) {
    const date = new Date(seriesData[i].date);
    const nextDate = new Date(seriesData[i + 1].date);
    date.setDate(date.getDate() + 1);

    if (formatDate(date.toISOString()) !== formatDate(nextDate.toISOString())) {
      const datum = { count: 0, date: date.toISOString() };
      seriesData.splice(i + 1, 0, datum);
    }
  }

  let i = offset;
  return seriesData.map((datum) => {
    const date = new Date(datum.date);
    i += datum.count;
    return {
      x: date,
      y: i,
      customTooltip: `${format(date, 'M/d/yy')}\nInvites: ${i}`,
    };
  });
};

export default function AccountChart({
  invitedSeriesData,
  claimedSeriesData,
  claimedOffset,
}: Props) {
  return (
    <VictoryChart
      width={chartStyles.width}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={() => ' '}
          labelComponent={<VictoryTooltip flyoutComponent={<Tooltip />} />}
        />
      }
    >
      <VictoryAxis dependentAxis style={{ tickLabels: chartStyles.tickLabels }} />
      <VictoryAxis
        tickFormat={(date) => format(date, 'M/yy')}
        style={{ tickLabels: chartStyles.tickLabels }}
      />
      <VictoryArea
        style={{ data: { fill: colors.blue40 } }}
        data={getCumulativeData(invitedSeriesData)}
      />
      <VictoryArea
        style={{ data: { fill: colors.green40 } }}
        data={getCumulativeData(claimedSeriesData, claimedOffset)}
      />
      <VictoryLabel
        dx={chartStyles.label.x}
        y={chartStyles.label.y}
        style={chartStyles.label.style}
      />
    </VictoryChart>
  );
}
