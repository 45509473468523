import styled from 'styled-components';
import { lightThemeButtons } from 'theme/buttons/lightThemeButtons';
import { TButtonStyleVariantsKey } from 'theme/buttons/TButtons';
import { getButtonStyleVariantValues } from 'theme/buttons/utils';

type ButtonStyledProps = {
  $margin: string;
  $isFullWidth: boolean;
  $maxWidth?: string;
  $styleVariant: TButtonStyleVariantsKey;
};

export const ButtonStyled = styled.button<ButtonStyledProps>`
  ${({ $margin, $isFullWidth, $maxWidth, $styleVariant }) => {
    const {
      fontColor,
      fontColorHover,
      fontColorActive,
      background,
      backgroundHover,
      backgroundActive,
      border,
      borderHover,
      borderActive,
      padding,
    } = getButtonStyleVariantValues(lightThemeButtons, $styleVariant);
    let width = '100%';
    if (!$isFullWidth) {
      width = $styleVariant === 'tertiary' ? 'fit-content' : 'auto';
    }

    return `
    display: flex;
    cursor: pointer;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: ${padding};
    gap: 8px;
    color: ${fontColor};
    background: ${background};
    border: ${border};
    border-radius: 4px;
    margin: ${$margin};
    font-family: 'AvenirLTPro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    height: 44px;
    width: ${width};
    min-width: ${$styleVariant === 'tertiary' ? '40px' : '100px'};
    max-width: ${$maxWidth || 'none'};
    &:hover {
      color: ${fontColorHover};
      background: ${backgroundHover};
      border: ${borderHover};
    }
    &:active {
      color: ${fontColorActive};
      background: ${backgroundActive};
      border: ${borderActive};
    }
    svg {
      transform: translateY(-2px); // Avenir font seems to have a weird vertical alignment
      height: auto; 
    }
    `;
  }}
`;

export const IconWrapper = styled.div<{ $iconRight?: boolean }>`
  display: flex;
  align-items: center;
  order: ${({ $iconRight }) => ($iconRight ? 2 : 1)};
`;
