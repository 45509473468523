import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyText } from '../ProjectQualification/ProjectQualification.styles';

export function InService() {
  const { t } = useTranslation();

  return (
    <div>
      <BodyText as="p" $margin="0 0 16px !important">
        {t('systemOperational.body1')}
      </BodyText>
      <BodyText as="p" $margin="0 0 16px !important">
        {t('systemOperational.body2')}
      </BodyText>
      <BodyText as="p" $margin="0 0 16px !important">
        {t('systemOperational.body3')}
      </BodyText>
    </div>
  );
}

export default InService;
