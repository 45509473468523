import styled from 'styled-components';
import colors, { Color } from 'theme/colors';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export const DateHeading = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 8px;
  width: 247px;
  height: 32px;
  margin: 10px auto;
  margin-bottom: 16px;
  padding: 6px 10px;
  p {
    margin: 0 0 0 4px;
    font-size: 14px;
    line-height: 20px;
    color: #22345b;
  }
`;

export const StyledDayPicker = styled(DayPicker)`
  display: flex;
  justify-content: center;

  .custom-table {
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    thead {
      width: 100%;
      tr {
        display: flex;
        justify-content: space-around;
      }
    }
  }

  .rdp-day {
    padding: 8px 6px 6px;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected):not(.custom-day-selected) {
    background: #fff;
    border-width: 1px;
    border-color: ${colors.secondaryDarkBlue};
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${colors.secondaryDarkBlue};
  }
`;

export const DatePickerWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 999;
  display: ${({ active }) => (active ? 'block' : 'none')};
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 10px 10px 10px rgba(112, 144, 176, 0.05);
  border-radius: 8px;
  padding: 12px;
  margin-top: -8px;

  .custom-month {
    h2 {
      margin: 5px;
      color: ${colors.secondaryDarkBlue};
      font-weight: 400;
      font-size: 14px;
    }
  }

  .custom-head-cell {
    padding: 5px 3.5px;
    font-weight: 400;
    color: #6a6a6a;
    text-transform: unset;
  }

  .custom-nav-prev,
  .custom-nav-next {
    color: ${colors.secondaryDarkBlue};
    height: 22px;
    width: 22px;
    margin: 5px;

    &:hover:not([disabled]) {
      border-width: 1px;
      border-color: ${colors.secondaryDarkBlue};
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .custom-cell {
    --rdp-cell-size: 26px;
    font-weight: 400;
    padding: 3.5px;
    color: ${colors.secondaryDarkBlue};
    :hover:not([disabled]) {
      border-width: 1px;
      border-color: ${colors.secondaryDarkBlue};
      background-color: rgba(0, 0, 0, 0);
      &.custom-day-selected {
        background-color: ${colors.secondaryDarkBlue};
        color: ${colors.secondaryDarkBlue};
      }

      &.custom-day-range-start {
        color: #fff;
        background-color: ${colors.secondaryDarkBlue};
      }
    }
  }

  .custom-day-selected {
    color: #fff;
    background-color: ${colors.secondaryDarkBlue};
    border-width: 1px;
  }

  .custom-day-range-start {
    color: #fff;
    background-color: ${colors.secondaryDarkBlue};
    overflow: visible;
  }

  .custom-day-range-start:not(.custom-day-range-end):after {
    /* Cool, continuous blue streak that goes from start to end */
    content: '';
    background: #daebf6;
    position: absolute;
    z-index: -1;
    top: -1.55px;
    left: calc(50% + 2px);
    height: calc(100% + 3px);
    width: calc(50% + 9px);
  }

  .custom-day-range-middle {
    color: ${colors.secondaryDarkBlue};
    border-radius: 50%;
    background-color: #daebf6;
    overflow: visible;
    pointer-events: none;
  }

  .custom-day-range-middle:after {
    /* Cool, continuous blue streak that goes from start to end */
    content: '';
    background: #daebf6;
    position: absolute;
    z-index: -1;
    top: -1.55px;
    height: calc(100% + 3px);
    width: calc(100% + 9px);
  }

  .custom-day-range-end:not(.custom-day-range-start) {
    color: #fff;
    background-color: ${colors.secondaryDarkBlue};
    border-color: ${colors.secondaryDarkBlue};
    overflow: visible;
  }

  .custom-day-range-end:not(.custom-day-range-start):after {
    /* Cool, continuous blue streak that goes from start to end */
    content: '';
    background: #daebf6;
    position: absolute;
    z-index: -1;
    left: -5.5px;
    top: -1.55px;
    height: calc(100% + 3px);
    width: calc(50% + 5.5px);
  }
`;

export const CustomPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
`;

export const PaginationHeader = styled.h4`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.secondaryDarkBlue};
  margin: 0;
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type PaginationButtonProps = {
  margin?: string;
  isFullWidth?: boolean;
  maxWidth?: string;
  color?: Color;
  disabled?: boolean;
};

export const PaginationButton = styled.button<PaginationButtonProps>`
  ${({ margin, isFullWidth, maxWidth, color, disabled }) => `
      display: flex;
      :disabled {
        font-weight: 100;
        color: ${colors.gray50};
      }
      cursor: pointer;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: ${color};
      background: transparent;
      border: none;
      border-radius: 4px;
      margin: ${margin};
      height: 44px;
      width: ${isFullWidth ? '100%' : 'auto'};
      min-width: 50px;
      max-width: ${maxWidth || 'none'};
      &:hover {
        color: ${colors.activeStateBlue};
        cursor: ${disabled ? 'default' : 'pointer'};
      }
      &:active {
        color: ${colors.activeStateBlue};
      }
    `}
`;

export const CustomPickerGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 50px);
  gap: 10px;
  width: 100%;
  max-width: 400px;
  align-items: center;
  padding: 0px 12px;
`;

export const CustomPickerButton = styled.button<{ selected: boolean; disabled: boolean }>`
  ${({ selected, disabled }) => `
      color: ${selected ? colors.white : colors.secondaryDarkBlue};
      :disabled {
        font-weight: 100;
        color: ${colors.gray50};
      }
      font-family: 'AvenirLTPro';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      height: 32px;
      width: 44px;
      line-height: 20px;
      text-align: center;
      background-color: ${selected ? colors.secondaryDarkBlue : 'transparent'};
      border: none;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        border: 1px solid ${disabled ? colors.white : colors.secondaryDarkBlue};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
      }
      padding: 6px;
  `}
`;

export const InlineButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-self: flex-end;
  padding: 0px 8px;
  button {
    min-width: 50px;
  }
`;
