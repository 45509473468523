import React, { useEffect, useState } from 'react';
import { Message } from 'legacy/components/common/myeverbright';
import { Button, TextInput } from 'legacy/components/common/design-system';
import { MessageType } from 'legacy/types';

export interface FormTagType {
  id: string;
  tag: string;
}

function TagForm({
  tag,
  onSubmit,
}: {
  tag: FormTagType | null;
  onSubmit: (tagValue: string) => Promise<void>;
}) {
  const [tagValue, setTagValue] = useState('');
  const [, setSubmitDisabled] = useState(true);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    return onSubmit(tagValue)
      .then(() => {
        setSubmitDisabled(true);
        setTagValue('');
      })
      .catch((error) => {
        setMessage({ message: JSON.stringify(error.response.data), type: 'error' });
      });
  };

  useEffect(() => {
    if (tag) {
      setTagValue(tag.tag);
    }
  }, [tag]);

  useEffect(() => {
    setMessage(null);
    if (tagValue.length > 0 && tagValue !== tag?.tag) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [tagValue, tag]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue(e.target.value);
  };

  return (
    <>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <TextInput ariaLabel="tag" label="Tag Name" onChange={handleChange} />
      <Button label="Submit" margin="0 0 0 auto" onClick={handleSubmit} />
    </>
  );
}

export default TagForm;
