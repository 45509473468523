import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function CalendarIcon({ color }: IconProps) {
  const theme = useTheme();
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 0C5.41563 0 5.75 0.334375 5.75 0.75V2H10.25V0.75C10.25 0.334375 10.5844 0 11 0C11.4156 0 11.75 0.334375 11.75 0.75V2H13C14.1031 2 15 2.89687 15 4V4.5V6V14C15 15.1031 14.1031 16 13 16H3C1.89688 16 1 15.1031 1 14V6V4.5V4C1 2.89687 1.89688 2 3 2H4.25V0.75C4.25 0.334375 4.58437 0 5 0ZM13.5 6H2.5V14C2.5 14.275 2.725 14.5 3 14.5H13C13.275 14.5 13.5 14.275 13.5 14V6ZM4.5 8H7.5C7.775 8 8 8.225 8 8.5V11.5C8 11.775 7.775 12 7.5 12H4.5C4.225 12 4 11.775 4 11.5V8.5C4 8.225 4.225 8 4.5 8Z"
        fill={color || theme.colors.textSystemInfoSecondary2100}
      />
    </svg>
  );
}

export default CalendarIcon;
