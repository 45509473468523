import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function XIcon({ color }: IconProps) {
  const theme = useTheme();

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8228 13.8228C13.5884 14.0573 13.209 14.0573 12.9743 13.8228L7.9999 8.84728L3.02435 13.8228C2.78989 14.0573 2.41056 14.0573 2.17584 13.8228C1.94139 13.5884 1.94139 13.209 2.17584 12.9743L7.15252 7.9999L2.17659 3.02435C1.94214 2.78989 1.94214 2.41056 2.17659 2.17584C2.41105 1.94139 2.79038 1.94139 3.0251 2.17584L7.9999 7.15252L12.9755 2.17697C13.2099 1.94251 13.5892 1.94251 13.824 2.17697C14.0584 2.41142 14.0584 2.79076 13.824 3.02547L8.84728 7.9999L13.8228 12.9755C14.0591 13.2079 14.0591 13.5904 13.8228 13.8228Z"
        fill={color || theme.colors.textPrimarySecondary1100}
      />
    </svg>
  );
}

export default XIcon;
