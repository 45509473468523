import React, { useState } from 'react';
import { uploadAuthCopyAckCsv } from 'api/agreement';
import { MessageType } from 'legacy/types';
import { Column, Message } from 'legacy/components/common/myeverbright';
import { LoadingSpinner } from 'legacy/components/common/design-system';

function AuthCopyAckView() {
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState<MessageType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<{
    before_true: number | null;
    after_true: number | null;
    before_false: number | null;
    after_false: number | null;
  }>();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      setLoading(true);
      setMessage(null);
      uploadAuthCopyAckCsv(file)
        .then((response) => {
          setResults(response.data);
          setMessage({ message: 'Success', type: 'success' });
        })
        .catch((error) => {
          const { detail } = error.response.data;
          setMessage({
            message: detail ? JSON.stringify(detail) : 'Oops, something went wrong',
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Column>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div>
            <input id="ac_ack_csv" type="file" onChange={handleFileChange} />
          </div>
          <div>
            {file && (
              <button type="button" onClick={handleUpload}>
                Upload
              </button>
            )}
          </div>
          {message && <Message severity={message.type}>{message.message}</Message>}
          {results?.before_true !== null && (
            <>
              <p>{`Previous Acknowledged: ${results?.before_true}`}</p>
              <p>{`New Acknowledged: ${results?.after_true}`}</p>
              <p>{`Previous Unacknowledged: ${results?.before_false}`}</p>
              <p>{`New Unacknowledged: ${results?.after_false}`}</p>
            </>
          )}
        </>
      )}
    </Column>
  );
}

export default AuthCopyAckView;
