import React, { useCallback, useState } from 'react';
import { E164Number } from 'libphonenumber-js';
import { Button, PhoneInput, TextInput } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import { updateUser } from 'api/user';
import { MessageType, UserType, UpdateUserRequestBody } from 'legacy/types';
import { Content, EditUserContainer } from './UserSectionStyles';
import { AddressForm, AddressFormType } from '../../../forms/AddressForm';

type UserInfo = {
  email: { value: string; updated: boolean };
  first_name: { value: string; updated: boolean };
  last_name: { value: string; updated: boolean };
  phone_number: { value: E164Number | string; updated: boolean };
  address: { value: AddressFormType; updated: boolean };
};

type Props = {
  user: UserType;
  refreshUser: () => void;
};

export function EditUser({ user, refreshUser }: Props) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    email: { value: user.email, updated: false },
    first_name: { value: user.first_name, updated: false },
    last_name: { value: user.last_name, updated: false },
    phone_number: { value: user.phone_number, updated: false },
    address: { value: user.address, updated: false },
  });
  const [validNumber, setValidNumber] = useState(true);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserInfo({ ...userInfo, email: { value, updated: value !== user.email } });
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: 'first_name' | 'last_name',
  ) => {
    const { value } = e.target;
    setUserInfo({ ...userInfo, [fieldName]: { value, updated: value !== user[fieldName] } });
  };

  const handlePhoneChange = (number?: E164Number) => {
    setUserInfo({
      ...userInfo,
      phone_number: { value: number || '', updated: number !== user.phone_number },
    });
  };

  const handleAddressChange = (value: AddressFormType) => {
    setUserInfo({
      ...userInfo,
      address: { value, updated: JSON.stringify(value) !== JSON.stringify(user.address) },
    });
  };

  const handleMessageUpdate = useCallback(
    (newMessage: MessageType) => {
      setMessage(newMessage);
      refreshUser();
    },
    [refreshUser],
  );

  const handleSave = useCallback(async () => {
    const body: UpdateUserRequestBody = {};

    (Object.keys(userInfo) as (keyof typeof userInfo)[]).forEach((key) => {
      if (userInfo[key].updated) {
        // @ts-ignore
        body[key] = userInfo[key].value;
      }
    });

    if (Object.keys(body).length && validNumber) {
      setLoading(true);

      const newMessage: MessageType = {
        message: 'User Info Updated',
        type: 'success',
      };

      updateUser({ user_id: user.id, body })
        .then((response) => {
          setUserInfo({
            email: { value: response.data.email, updated: false },
            first_name: { value: response.data.first_name, updated: false },
            last_name: { value: response.data.last_name, updated: false },
            phone_number: {
              value: response.data.phone_number,
              updated: false,
            },
            address: {
              value: response.data.address,
              updated: false,
            },
          });
        })
        .catch((error) => {
          newMessage.message =
            error.response.data.email ||
            error.response.data.first_name ||
            error.response.data.last_name ||
            error.response.data.phone_number ||
            error.response.data.message ||
            error.message;
          newMessage.type = 'error';
        })
        .finally(() => {
          handleMessageUpdate(newMessage);
          setLoading(false);
        });
    }
  }, [handleMessageUpdate, user.id, userInfo, validNumber]);

  const handleOnValidate = (valid: boolean) => {
    if (valid) setMessage(null);
    else setMessage({ message: 'Invalid Number', type: 'error' });
    setValidNumber(valid);
  };

  return (
    <EditUserContainer>
      {loading ? (
        <div>...Loading</div>
      ) : (
        <>
          {message && <Message severity={message.type}>{message.message}</Message>}
          <Content>
            <TextInput
              ariaLabel="email"
              label="Email"
              name="email"
              defaultValue={userInfo.email.value}
              onChange={handleEmailChange}
            />
            <TextInput
              ariaLabel="firstName"
              label="First Name"
              name="firstName"
              defaultValue={userInfo.first_name.value}
              onChange={(e) => handleNameChange(e, 'first_name')}
            />
            <PhoneInput
              ariaLabel="phone"
              label="Phone"
              defaultValue={userInfo.phone_number.value}
              onChange={handlePhoneChange}
              onValidate={handleOnValidate}
            />
            <TextInput
              ariaLabel="lastName"
              label="Last Name"
              name="lastName"
              defaultValue={userInfo.last_name.value}
              onChange={(e) => handleNameChange(e, 'last_name')}
            />
          </Content>
          <br />
          <div>
            <AddressForm address={userInfo.address.value} onAddressChange={handleAddressChange} />
          </div>
          <Button label="Save" onClick={handleSave} margin="24px 0 24px auto" />
        </>
      )}
    </EditUserContainer>
  );
}

export default EditUser;
