import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Text } from 'shared/components/Text/Text';

export const FormWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
  max-width: 555px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 24px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StaticTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 12px;
  }
`;

export const BodyText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Value = styled(BodyText)`
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;

export const LinkStyled = styled(Link)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textSystemInfoSecondary2100};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 4px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: 8px;
  }
`;
