import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import en from '../../locales/en.json';
import es from '../../locales/es.json';

const initI18n = () => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      resources: {
        en: { translation: en },
        es: { translation: es },
      },
      debug: import.meta.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
};

export default initI18n;
