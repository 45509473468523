import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import pages from 'pages';
import { SetPasswordForm } from '../../components/forms/SetPasswordForm';
import { routes } from '../../../routes';
import { useNotifications } from '../../../contexts/Notifications';
import { checkClaimToken } from '../../../api/accounts';
import { Container, InnerContainer } from './AuthStyles';

function ClaimAccount() {
  const { token } = useParams();
  const { search } = useLocation();
  const [email, setEmail] = useState(new URLSearchParams(search).get('email'));
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const claimAccountSubmit = (password: string) =>
    axios.post(routes.claimUserAccount, { token, password }).then(() => {
      addNotification({ severity: 'success', text: 'Your account was successfully created.' });
      navigate(`/${pages.LOGIN}`);
    });

  useEffect(() => {
    if (token) {
      checkClaimToken(token)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            addNotification({
              severity: 'warning',
              text: 'An account with that email address already exists. Please log in.',
            });
            navigate(`/${pages.LOGIN}`, { replace: true });
          } else if (error.response.status === 400) {
            addNotification({ severity: 'error', text: 'This invitation is invalid.' });
            navigate(`/${pages.LOGIN}`, { replace: true });
          }
        });
    } else {
      addNotification({ severity: 'error', text: 'This invitation is invalid.' });
      navigate(`/${pages.LOGIN}`, { replace: true });
    }
  });

  return (
    <Container>
      <InnerContainer>
        <h3>Register Your Account</h3>
        <p>
          The email address associated with your EverBright agreements will be used to create your
          account.
        </p>
        <SetPasswordForm formSubmit={claimAccountSubmit} emailAddress={email} showAgreeText />
      </InnerContainer>
    </Container>
  );
}

export default ClaimAccount;
