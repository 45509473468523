import React, { useEffect, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { endOfWeek, startOfWeek } from 'date-fns';
import 'react-day-picker/dist/style.css';

import { Calendar } from './Calendar';

type Props = {
  currentDate: Date;
  onDateChange: MonthChangeEventHandler;
  onCloseModal: () => void;
  dateRange: { oldest: Date; newest: Date };
};

const getWeekRange = (date: Date) => {
  const from = startOfWeek(date);
  const to = endOfWeek(from);

  return { from, to };
};

export function WeekPicker({ currentDate, onDateChange, onCloseModal, dateRange }: Props) {
  const [selected, setSelected] = useState(getWeekRange(currentDate));

  useEffect(() => {
    setSelected(getWeekRange(currentDate));
  }, [currentDate]);

  const handleOk = () => {
    onDateChange(selected.from);
    onCloseModal();
  };

  const handleDayClick = (date: Date) => {
    setSelected(getWeekRange(date));
  };

  return (
    <Calendar
      mode="range"
      selectedDate={selected}
      onDayClick={handleDayClick}
      onCancel={onCloseModal}
      onOk={handleOk}
      dateRange={dateRange}
    />
  );
}

export default WeekPicker;
