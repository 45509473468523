import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function TriangleExclamationIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0015 10.25C7.58744 10.25 7.27963 10.5856 7.27963 11C7.27963 11.4141 7.61556 11.75 8.0015 11.75C8.41556 11.75 8.72338 11.4141 8.72338 11C8.7515 10.5844 8.41713 10.25 8.0015 10.25ZM8.0015 9C8.27784 9 8.47338 8.77637 8.47338 8.5V4C8.47338 3.72362 8.24978 3.5 8.0015 3.5C7.75322 3.5 7.5015 3.725 7.5015 4V8.5C7.5015 8.775 7.7265 9 8.0015 9ZM15.7609 11.4156L9.51713 0.855625C9.2015 0.320938 8.63275 0.0009375 8.0015 0C7.37025 0 6.80463 0.319375 6.48588 0.855L0.239001 11.4175C-0.0750618 11.9478 -0.0797493 12.5844 0.225819 13.1206C0.541813 13.6719 1.11244 14 1.75463 14H14.2515C14.8924 14 15.4624 13.6709 15.7768 13.1197C16.0828 12.5844 16.0765 11.9469 15.7609 11.4156ZM14.8796 12.5969C14.7734 12.8594 14.5265 13 14.2234 13H1.75463C1.47631 13 1.23025 12.8599 1.09588 12.625C0.968438 12.4014 0.970407 12.1469 1.10076 11.9263L7.34838 1.36438C7.48275 1.13625 7.7265 1 8.0015 1C8.00101 1 8.0015 1 8.0015 1C8.27541 1.00049 8.519 1.13622 8.65338 1.36375L14.9003 11.9263C15.0046 12.1469 15.0359 12.4 14.8796 12.5969Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default {};
