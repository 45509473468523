import React from 'react';
import { Outlet } from 'react-router';

import { AxiosInterceptorProvider } from 'contexts/AxiosInterceptorContext';
import { UserProvider } from 'contexts/UserContext';
import MobileAppBanner from 'legacy/components/MobileAppBanner';

export function Root() {
  return (
    <AxiosInterceptorProvider>
      <>
        <MobileAppBanner />
        <UserProvider>
          <Outlet />
        </UserProvider>
      </>
    </AxiosInterceptorProvider>
  );
}

export default Root;
