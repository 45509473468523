import styled, { css } from 'styled-components';

import { CardDescriptionBold } from '../../../shared/components/Card/Card.styles';

export const SystemDetailsCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;

  div {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    margin: 0;
    max-width: unset;
    flex: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    gap: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${CardDescriptionBold} {
    color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  }
`;

export const ImageWrapper = styled.div`
  margin: 0;
  margin-bottom: 12px;
  img {
    width: fit-content;
    max-width: 100%;
    max-height: 40px;
    padding: 2px 0px;
    border-radius: 4px;
  }
`;

export const SystemDetailsWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: 24px;
    padding: 32px 20px;
    background: ${theme.colors.backgroundSecondaryGrey05};
    box-shadow: ${theme.elevations.extraSunken};
    height: fit-content;
  `}
  h2 {
    margin-bottom: 16px;
  }
`;
