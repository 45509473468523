import { Color } from 'theme/colors';
import React from 'react';

import { LoadingSpinnerStyled } from './LoadingSpinnerStyles';

export type TLoadingSpinnerProps = {
  ariaLabel?: string;
  spinnerColor?: Color;
  spinnerBackgroundColor?: Color;
  isLoading?: boolean;
  size?: 'large' | 'small';
  absoluteCenter?: boolean;
  localCenter?: boolean;
};

/**
 * @param {string} [ariaLabel] -  id used for testing (optional)
 * @param {string} [isLoading] -  show spinner. defaults to true (optional)
 * @param {Color} [spinnerColor] - color of spinner (optional)
 * @param {Color} [spinnerBackgroundColor] - color of spinner's background (optional)
 * @param {boolean} [absoluteCenter] - absolute center spinner (optional)
 */

export function LoadingSpinner({
  ariaLabel,
  spinnerColor,
  spinnerBackgroundColor,
  isLoading = true,
  size = 'large',
  absoluteCenter = false,
  localCenter = false,
}: TLoadingSpinnerProps) {
  const label = ariaLabel || 'loading...';

  return isLoading ? (
    <LoadingSpinnerStyled
      aria-live="polite"
      aria-label={label}
      $color={spinnerColor}
      $spinnerBackgroundColor={spinnerBackgroundColor}
      $size={size}
      $absoluteCenter={absoluteCenter}
      $localCenter={localCenter}
    />
  ) : null;
}
