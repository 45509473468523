import styled, { css } from 'styled-components';
import {
  getDropdownStyleVariantValues,
  lightThemeDropdowns,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from 'theme/dropdowns';

type DropdownItemStyledProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};
export const DropdownItemStyled = styled.li<DropdownItemStyledProps>`
  ${({ $styleVariant, $state }) => {
    const { dropdownItemBackgroundHover, dropdownItemBackgroundActive } =
      getDropdownStyleVariantValues(lightThemeDropdowns, $styleVariant, $state);

    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      :hover {
        background: ${dropdownItemBackgroundHover};
      }

      :active {
        background: ${dropdownItemBackgroundActive};
      }
    `;
  }}
`;

export const DropdownTitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  text-wrap: wrap;
`;

type TDropdownItemButtonWrapper = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const DropdownItemButtonWrapper = styled.button<TDropdownItemButtonWrapper>`
  ${({ $styleVariant, $state }) => {
    const { dropdownItemPadding } = getDropdownStyleVariantValues(
      lightThemeDropdowns,
      $styleVariant,
      $state,
    );

    return css`
      cursor: ${$state === 'disabled' ? 'default' : 'pointer'};
      align-items: center;
      width: 100%;
      display: flex;
      border: 0;
      background-color: transparent;
      outline: none;
      white-space: nowrap;
      padding: ${dropdownItemPadding};
    `;
  }}
`;

export const IconContainer = styled.div`
  margin-right: 8px;
`;

export const Text = styled.p<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
