import { FONT_FAMILY } from 'shared/components/Text/Text';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    font-family: ${FONT_FAMILY};
}

html,
body {
    margin: 0;
    padding: 0;
    
    ::-webkit-scrollbar-track {
        background: transparent; 
        box-shadow: white;
      }
}

.ie-chat-button-iframe-fixed-customer-chat {
    bottom: 100px !important;
    right: 48px !important;
}
`;

export default GlobalStyles;
