import styled from 'styled-components';

export type ContentProps = {
  $columns?: number;
  $padding?: string;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  column-gap: 16px;
  width: 100%;
  padding: ${({ $padding }) => $padding || 0};
  height: 150px;

  div {
    width: 45%;
  }
`;

export const EditUserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

export const EditAccountsContainer = styled.div`
  #edit-accounts-accordion .accordion-item {
    overflow: visible;
  }
  ul {
    position: relative;
  }
`;

export const UserSectionDetails = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
`;

export const ResendClaimed = styled.div`
  display: flex;
  align-items: center;
`;
