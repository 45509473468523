import { Text } from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const ReviewContainer = styled.div`
  ${({ theme }) => `
    display: grid;
    width: 100%;
    padding-top: 8px;
    max-height: 664px;
    @media (max-width: ${theme.breakpoints.small}) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `}
`;

export const QuestionsContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    p {
      font-weight: 400;
    }
  `}
`;

export const QuestionsHeaderContainer = styled.div`
  ${({ theme }) => `
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 64px;
      @media (max-width: ${theme.breakpoints.small}px) {
        grid-template-columns: auto 0;
        overflow: hidden;
        gap: 0;
      }
    `}
`;

export const SubHeader = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: ${theme.colors.cardElevationHighWhite};
  `}
`;

export const SubHeadingLeft = styled.div<{ bold?: boolean }>`
  ${({ theme }) => `
      font-size: 16px;
      line-height: 24px;
      color: ${theme.colors.textSecondarySubtleGrey80};
      font-weight: 400;
      `}
`;

export const SubHeadingRight = styled(SubHeadingLeft)`
  ${({ theme }) => `
        font-weight: 700;
        margin-right: 12px;
        color: ${theme.colors.textPrimarySecondary1100};
        span:first-child {
          margin: 0 48px 0 14px;
        }
        @media (max-width: ${theme.breakpoints.small}) {
          display: none;
        }
      `}
`;

export const ChecklistItem = styled(QuestionsHeaderContainer)<{ $hideBorder: boolean }>`
  ${({ theme, $hideBorder }) => `
        border-bottom: ${$hideBorder ? 0 : `0.5px solid ${theme.colors.strokePrimaryGrey20}`};
      
        fieldset .radio-button-outer-container:first-child {
          margin-right: 56px;
        }
      
        .radio-label {
          display: none;
        }
      
        @media (max-width: ${theme.breakpoints.small}) {
          grid-template-columns: 1fr;
          flex-direction: column;
          align-items: flex-start;
          fieldset .radio-button-outer-container:first-child {
            margin-right: 48px;
          }
      
          .radio-group-container {
            margin: 0 0 12px;
          }
      
          .radio-label {
            display: inline;
          }
        }
        `}
`;

export const ErrorText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 4px;
`;

export const TextWrapper = styled.div`
  ${({ theme }) => `
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        max-width: 606px;
        width: 100%;
        @media (max-width: ${theme.breakpoints.small}) {
          padding: 16px 0px 16px 0px;
        }
        > span {
          font-weight: 100;
          font-size: 16px;
          color: ${theme.colors.textPrimarySecondary1100};
        }
        ${ErrorText} {
          color: ${theme.colors.iconSystemIconErrorFunction1100};
        }
        `}
`;

export const ReviewPageSubTitle = styled(Text)`
  ${({ theme }) => `
    display: inline-block;
    margin-bottom: 16px;
    color: ${theme.colors.textSecondarySubtleGrey80};
  `}
`;

export const IconWrapper = styled.div<{ $isXIcon?: boolean }>`
  margin-right: 12px;
  ${({ $isXIcon }) =>
    $isXIcon
      ? `
  position: relative;
  top: 2px;
  `
      : ''}
`;

export const ReviewItem = styled.div<{ $boldText?: boolean }>`
  display: flex;
  margin-bottom: 8px;
  line-height: 24px;

  body {
    font-weight: ${({ $boldText }) => ($boldText ? 700 : 100)};
  }
`;

export const MessageWrapper = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.textPrimarySecondary1100};
    p {
      margin: 0px;
    }
  `}
`;
