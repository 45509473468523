import { USER_ROLES, USER_ROLES_INTERNAL } from '../constants';

export enum MilestoneType {
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  PROJECT_QUALIFICATION_COMPLETED = 'PROJECT_QUALIFICATION_COMPLETED',
  INSTALLATION_COMPLETED = 'INSTALLATION_COMPLETED',
  IN_SERVICE = 'IN_SERVICE',
}

export interface OrganizationType {
  id: string;
  organization_id: string;
  name: string;
  logo_image_url?: string;
  website_url: string;
}

export enum WelcomeChecklistStatusType {
  REQUIRED = 'REQUIRED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum WelcomeChecklistResult {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export interface WelcomeChecklistType {
  id: string;
  user?: string;
  agreement: string;
  checklist: WelcomeChecklistItemType[];
  date_completed: string | null;
  result: WelcomeChecklistResult | null;
}

export interface WelcomeChecklistItemType {
  page: number;
  question_en: string;
  question_es: string;
  answer?: string;
}

export const languageCode = {
  en: 'ENGLISH',
  es: 'SPANISH',
} as const;

export type LanguageCodeKey = keyof typeof languageCode;
export type PrimaryLanguage = (typeof languageCode)[LanguageCodeKey];

export type RolesKey = keyof typeof USER_ROLES;
export type RolesKeyAdmin = keyof typeof USER_ROLES_INTERNAL;
export type Role = (typeof USER_ROLES)[RolesKey];

export interface SentEmailType {
  id: string;
  email_template: string;
  template_description: string;
  date_sent: string;
  user_email: string;
}

export interface FaqType {
  id: string;
  question: string;
  answer: string;
  hidden: boolean;
  order: number;
  tags: TagType[];
  url_slug: string;
}

export interface TagType {
  id: string;
  tag: string;
}

export type SeverityType = 'error' | 'success' | 'warning';

export type MessageType = { message: string; type?: SeverityType };
