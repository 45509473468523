import React from 'react';
import { Container } from './FullPageLoaderStyles';
import AnimatedLogo from '../../../../../static/animated-logo.svg';

export function FullPageLoader() {
  return (
    <Container>
      <img src={AnimatedLogo} alt="everbright loading logo" />
    </Container>
  );
}

export default FullPageLoader;
