import React, { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { getInputState } from 'shared/design-system/theme/inputs';
import { Input, PinOuterContainer, PinWrapper } from './PinInput.styles';
import { waitForReactUpdate } from './waitForReactUpdate';
import { InputHint } from '../TextInput/TextInput.styles';
import { CircleExclamationIcon } from '../icons';

export type TRadioButtonProps = {
  dataTestId?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onPinEntered: (pin: string) => void;
  onChange: (pin: string) => void;
};

/**
 * @param {string} [dataTestId] -  id used for testing (optional)
 * @param {boolean} [isDisabled] - is this input disabled (optional)
 * @param {boolean} [hasError] -  error status (optional)
 * @param {string} [errorMessage] -  error message (optional)
 * @param {function} [onPinEntered] - onPinEntered handler
 */

const requiredPins = ['', '', '', '', '', ''];
const numberOnlyRegex = /^\d+$/;

export function PinInput({
  dataTestId,
  isDisabled = false,
  errorMessage,
  hasError,
  onPinEntered,
  onChange,
}: TRadioButtonProps) {
  const [enteredPin, setEnteredPin] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { colors } = useTheme();

  const showErrorState = hasError || Boolean(errorMessage);

  const inputState = getInputState(isDisabled, showErrorState);

  const pinInputElements = useRef<HTMLInputElement[]>([]);

  const updatePin = useCallback(
    (val: string, id: number) => {
      const newPin = enteredPin.slice();
      newPin[id] = val;
      setEnteredPin(newPin);
      onChange(newPin.join(''));
    },
    [enteredPin, onChange],
  );

  const handlePaste = useCallback(
    async (e: React.ClipboardEvent) => {
      e.preventDefault();
      const charValidation = /\d+/g;
      const clipboardData = e?.clipboardData?.getData('text');
      const parsedData = clipboardData?.match(charValidation);

      if (clipboardData && parsedData) {
        const digits = parsedData
          .join('')
          .split('')
          .slice(0, requiredPins.length)
          .filter((digitStr) => /^\d+$/.test(digitStr));
        setEnteredPin(digits.map((digit) => digit));
        if (digits.length === requiredPins.length) {
          await onPinEntered(digits.join(''));
        } else {
          await waitForReactUpdate();
          pinInputElements.current?.[digits.length].focus();
        }
      }
    },
    [onPinEntered],
  );

  const handleInput = async (e: React.FormEvent<HTMLInputElement>, pinIdx: number) => {
    e.preventDefault();

    const elem = e.target as HTMLInputElement;

    const pin = enteredPin.slice();
    const value = elem.value[0];

    if (!numberOnlyRegex.test(value.toString())) {
      return;
    }

    updatePin(value ?? '', pinIdx);

    pin[pinIdx] = value;

    const pinValue = pin.join('');

    if (pinValue.length === requiredPins.length) {
      await onPinEntered(pinValue);
    } else {
      const nextSibling = elem.nextSibling as HTMLInputElement;
      /* we do this because we want the focus code to occur AFTER the react state is updated.
        This is because we can't focus the next sibling if it's disabled still, and it is only updated from disabled to enabled after a re-render occurs. 
        This basically just makes the code after the await happen after whatever else has been put on queue for the event loop to process (i.e., the re-render)
      */
      await waitForReactUpdate();
      nextSibling.focus();
      nextSibling.select();
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const element = e.target as HTMLInputElement;
    const previous = element.previousSibling as HTMLInputElement;

    const backspaceKeyCode = 'Backspace';

    // these are filtered out becuase number inputs allow these values for floating point
    if (e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
      e.preventDefault();
    }
    // onInput doesn't fire when backspace is pressed therefore we listen onKeyDown
    if (e.key === backspaceKeyCode) {
      if (!element.value.length && previous) {
        // check if current input is empty and if there is an input before it
        updatePin('', index - 1); // remove value of previous element
        previous.focus();
      } else {
        updatePin('', index);
      }
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>, index: number) => {
    setActiveIndex(index);
    (e.target as HTMLInputElement).select();
  };

  return (
    <PinOuterContainer onPaste={handlePaste}>
      <PinWrapper>
        {requiredPins.map((placeholder, i) => {
          const hasValue = !!enteredPin[i] || Number(enteredPin[i]) === 0;
          const isInputDisabled = isDisabled || i > enteredPin.length;
          const id = `${i}-PinInput`;

          return (
            <Input
              autoFocus={i === 0}
              ref={(ref) => {
                if (ref) {
                  pinInputElements.current.push(ref);
                }
              }}
              aria-required
              maxLength={1}
              key={id}
              $hasValue={hasValue}
              type="tel"
              onClick={(e) => {
                handleClick(e, i);
              }}
              id={id}
              aria-labelledby={`${i}-PinInput-title`}
              aria-describedby={`${i}-PinInput-hint`}
              aria-label={`Pin input field ${i + 1}`}
              data-testid={`${dataTestId}-${i}`}
              disabled={isInputDisabled}
              placeholder={placeholder}
              onInput={(e) => handleInput(e, i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
              value={hasValue ? `${enteredPin[i]}` : ''}
            />
          );
        })}
      </PinWrapper>

      <InputHint $styleVariant="default" $inputState={inputState} $showHint={showErrorState}>
        <CircleExclamationIcon color={colors.iconSystemIconErrorFunction1100} /> {errorMessage}
      </InputHint>
    </PinOuterContainer>
  );
}
