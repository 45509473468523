import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import LINKS from 'shared/links';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { LinkStyled } from 'shared/components/Header/Header.styles';
import { PasswordField } from 'shared/components/PasswordField/PasswordField';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { Text } from 'shared/components/Text/Text';
import { handlePasswordValidation } from 'modules/auth/Auth.utils';
import { ClaimAccountFormStyled, Label, Value } from './PasswordForm.styles';
import { BodyText } from '../RegistrationEmailForm/RegistrationEmailForm.styles';
import { getScoreWords } from './getScoreWords';

export interface PasswordFormInput {
  password: string;
  confirmPassword: string;
}

type Props = {
  email: string;
  claimAccount?: boolean;
  onSubmitForm: SubmitHandler<PasswordFormInput>;
};

export function PasswordForm({ email, claimAccount = false, onSubmitForm }: Props) {
  const [passwordScore, setPasswordScore] = useState(0);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [termsError, setTermsError] = useState('');

  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    control,
  } = useForm<PasswordFormInput>({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  const watchNewPassword = useWatch({
    control,
    name: 'password',
  });

  const onSubmit: SubmitHandler<PasswordFormInput> = (formInput) => {
    if (!agreedToTerms) {
      setTermsError(t('claimAccount.youMustAgreeToPrivacyPolicy'));
      return;
    }

    onSubmitForm(formInput);
  };

  const handleChangeConfirmed = () => {
    setAgreedToTerms((c) => !c);
  };

  const scoreWords = getScoreWords(t);

  return (
    <ClaimAccountFormStyled onSubmit={handleSubmit(onSubmit)}>
      {claimAccount ? (
        <div>
          <Label as="p">{t('claimAccount.emailAddress')}</Label>
          <Value as="p" $margin="0 0 8px">
            {email}
          </Value>
        </div>
      ) : (
        <BodyText as="p" $margin="0 0 8px">
          {t('password.enterYourPassword')}
        </BodyText>
      )}

      <div>
        <PasswordField
          label={claimAccount ? t('claimAccount.createPassword') : t('password.password')}
          showPlaceHolder={false}
          register={register}
          onValidate={(value) =>
            handlePasswordValidation({
              value,
              newPassword: watchNewPassword,
              passwordScore,
              translator: t,
            })
          }
          onClearInput={resetField}
          hasError={Boolean(errors.password)}
          errorMessage={errors.password?.message}
          margin={`0 0 ${errors.password?.message ? '24px ' : 0}`}
        />

        <PasswordStrengthBar
          password={watchNewPassword}
          minLength={MINIMUM_PASSWORD_LENGTH}
          shortScoreWord={watchNewPassword ? t('passwordStrength.tooWeak') : ''}
          scoreWords={scoreWords}
          onChangeScore={(score) => {
            setPasswordScore(score);
          }}
          className="password-strength-bar"
        />
      </div>

      <PasswordField
        fieldName="confirmPassword"
        label={t('claimAccount.confirmPassword')}
        showPlaceHolder={false}
        register={register}
        onValidate={(value) =>
          handlePasswordValidation({
            value,
            newPassword: watchNewPassword,
            passwordScore,
            translator: t,
            isConfirmPasswordField: true,
          })
        }
        onClearInput={resetField}
        hasError={Boolean(errors.confirmPassword)}
        errorMessage={errors.confirmPassword?.message}
        margin={`0 0 ${errors.confirmPassword?.message ? '24px' : '12px'}`}
      />

      <Checkbox
        isChecked={agreedToTerms}
        onChange={handleChangeConfirmed}
        margin="0 8px"
        error={!agreedToTerms}
        hint={!agreedToTerms ? termsError : undefined}
        text={
          <Text as="body">
            <Trans
              i18nKey={
                claimAccount
                  ? 'claimAccount.privacyPolicyConfirmation'
                  : 'password.privacyPolicyConfirmation'
              }
              components={{
                // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                a: <LinkStyled to={LINKS.PRIVACY_POLICY} />,
              }}
            />
          </Text>
        }
      />
      <Button
        type="submit"
        label={t('claimAccount.registerAccount')}
        isFullWidth
        margin={`${screenSize === 'sm' ? '6px' : '12px'} 0 0`}
      />
    </ClaimAccountFormStyled>
  );
}

export default PasswordForm;
