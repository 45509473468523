import styled, { css } from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    border-radius: 24px;
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    background: ${theme.colors.backgroundSecondaryGrey05};
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 20px;
    button {
      margin: 8px 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 24px;
  }
`;

export const Title = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 20px;
    font-weight: 500;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    font-size: 24px;
    margin-bottom: 4px;
  }
`;

export const CallUsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    z-index: 1;
    background: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};
    padding: 0 12px;
  }

  hr {
    position: absolute;
    top: 50%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 0 42px;
  p {
    text-align: center;
  }
`;

export const BodyText = styled(Text)<{ $isBold?: boolean }>`
  display: inline-block;
  font-family: Avenir;
  font-weight: ${({ $isBold }) => ($isBold ? 800 : 300)};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 8px;
  }
`;
