import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const AccountWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.cardElevationLowWhite};
`;

export const AccountNumber = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  margin-bottom: 8px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 12px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 16px;
  }
`;

export const ContactSection = styled.div`
  margin-top: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 32px;
  }
`;

export const LoginSection = styled.div`
  margin-top: 32px;
`;
