import React from 'react';
import { IcoProps } from './types';

export function ArrowUpRightFromSquare({ color = 'white', size = 'small' }: IcoProps) {
  const dimensions = size === 'small' ? '14' : '16';
  return (
    <svg
      width={dimensions}
      height={dimensions}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 1.5C8.33437 1.5 8 1.16406 8 0.75C8 0.335938 8.33437 0 8.75 0H13.25C13.6656 0 14 0.335938 14 0.75V5.25C14 5.66563 13.6656 6 13.25 6C12.8344 6 12.5 5.66563 12.5 5.25V2.55938L6.25313 8.75313C5.9875 9.07188 5.5125 9.07188 5.21875 8.75313C4.92812 8.4875 4.92812 8.0125 5.21875 7.71875L11.4406 1.5H8.75ZM0 2.75C0 1.78344 0.783437 1 1.75 1H5.25C5.66563 1 6 1.33594 6 1.75C6 2.16563 5.66563 2.5 5.25 2.5H1.75C1.61187 2.5 1.5 2.6125 1.5 2.75V12.25C1.5 12.3875 1.61187 12.5 1.75 12.5H11.25C11.3875 12.5 11.5 12.3875 11.5 12.25V8.75C11.5 8.33437 11.8344 8 12.25 8C12.6656 8 13 8.33437 13 8.75V12.25C13 13.2156 12.2156 14 11.25 14H1.75C0.783437 14 0 13.2156 0 12.25V2.75Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowUpRightFromSquare;
