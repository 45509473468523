import React, { useState } from 'react';
import { Button, PhoneInput } from 'legacy/components/common/design-system';
import { E164Number } from 'libphonenumber-js/types';
import { updateUserPhone } from 'api/user';
import useUser from 'hooks/useUser';
import { HandleMessage } from 'legacy/views/dashboard/AccountSettings';

interface Props {
  setEditingPhone: Function;
  currentNumber: E164Number | string;
  handleMessage: HandleMessage;
}

export default function EditPhoneNumberForm({
  currentNumber,
  setEditingPhone,
  handleMessage,
}: Props) {
  const { userResult, setUserResult } = useUser();
  const [phoneNumber, setPhoneNumber] = useState(currentNumber);
  const [validNumber, setValidNumber] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (validNumber && userResult?.user) {
      updateUserPhone(userResult.user.id, phoneNumber as E164Number)
        .then((response) => {
          setUserResult({ ...userResult, user: response.data });
          handleMessage({ message: 'Phone number saved successfully.', type: 'success' }, 4000);
          setEditingPhone(false);
        })
        .catch(() => {
          handleMessage({
            message: 'Failed to update phone number. Please contact support for assistance.',
            type: 'error',
          });
        });
    } else {
      handleMessage({ message: 'Please enter a valid phone number.', type: 'error' });
    }
  };

  const handleChange = (number?: E164Number) => {
    setPhoneNumber(number || '');
  };

  const handleValidate = (isValid: boolean) => {
    setValidNumber(isValid);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PhoneInput
        ariaLabel="Phone Number"
        label="Phone Number"
        defaultValue={phoneNumber}
        onChange={handleChange}
        onValidate={handleValidate}
        margin="0 0 16px"
      />
      <div className="account-settings-submit">
        <Button label="Submit" type="submit" />
      </div>
    </form>
  );
}
