import React from 'react';
import { Divider } from 'legacy/components/common/design-system';
import { Container } from './AccountFooterStyles';

export function AccountFooter() {
  return (
    <Container>
      <Divider $margin="32px 0 16px" />
      <em>
        Current partner looking for the EverBright platform?{' '}
        <a href="https://engine.goeverbright.com/auth/login?redirect=myeverbright">Log in here.</a>
      </em>
    </Container>
  );
}

export default AccountFooter;
