import React from 'react';
import { TModalStyleVariantsKey } from 'theme/modals/TModals';
import { BackgroundGradient, FadedContainer } from './ModalStyles';

type Props = {
  show?: boolean;
  styleVariant: TModalStyleVariantsKey;
};
export function FadedOverflow({ show = false, styleVariant }: Props) {
  return (
    <FadedContainer $show={show} $styleVariant={styleVariant}>
      <BackgroundGradient />
    </FadedContainer>
  );
}

export default FadedOverflow;
