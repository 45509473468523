import React, { forwardRef, useCallback, useState } from 'react';
import DOMPurify from 'dompurify';
import { CircleXIcon, VisibilityIcon } from 'shared/components/icons';
import { UseFormRegister, UseFormResetField } from 'react-hook-form';
import { InputLabel } from 'shared/components/TextInput/TextInput.styles';
import {
  FieldIconsWrapper,
  FieldInputWrapper,
  FieldWrapper,
  FormInput,
  IconButton,
} from './FormField.styles';

export interface FormFieldProps {
  label: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder: string;
  hasError: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClearInput: () => void;
  name?: string;
  value?: string;
  defaultValue?: string;
  margin?: string;
  dataTestId?: string;
}

export interface CustomFieldProps {
  register: UseFormRegister<any>;
  onClearInput: UseFormResetField<any>;
  hasError: boolean;
  errorMessage?: string;
  margin?: string;
  fieldName?: string;
  label?: string;
  showPlaceholder?: boolean;
}

export const FormField: React.FC<FormFieldProps> = forwardRef(
  (
    {
      label,
      placeholder,
      type,
      name,
      value,
      defaultValue,
      hasError,
      errorMessage,
      onChange,
      onBlur,
      onClearInput,
      margin,
      dataTestId,
    },
    forwardedRef: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const showErrorState = hasError || Boolean(errorMessage);
    const [fieldType, setFieldType] = useState(type);
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = DOMPurify.sanitize(e.target.value);
        if (e.target.value) {
          if (!showClearIcon) setShowClearIcon(true);
        } else if (showClearIcon) {
          setShowClearIcon(false);
        }
        onChange?.(e);
      },
      [onChange, showClearIcon],
    );

    const handleClear = () => {
      setShowClearIcon(false);
      onClearInput();
    };

    const toggleVisibility = () => {
      setIsActive(true);
      if (!passwordVisible) {
        setPasswordVisible(true);
        setFieldType('text');
      } else {
        setPasswordVisible(false);
        setFieldType('password');
      }
    };

    const handleFocusWithin = () => {
      if (!isActive) setIsActive(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsActive(false);
      onBlur?.(e);
    };

    return (
      <FieldWrapper $margin={margin}>
        {Boolean(label) && (
          <InputLabel $styleVariant="default" $inputState="enabled">
            {label}
          </InputLabel>
        )}
        <FieldInputWrapper $isActive={isActive} $hasError={showErrorState}>
          <FormInput
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder || label}
            type={fieldType || 'text'}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocusWithin}
            ref={forwardedRef}
          />
          <FieldIconsWrapper>
            {showClearIcon && (
              <IconButton
                type="button"
                onClick={handleClear}
                data-testid={`${dataTestId}-clearButton`}
              >
                <CircleXIcon />
              </IconButton>
            )}
            {type === 'password' && (
              <IconButton
                type="button"
                onClick={toggleVisibility}
                data-testid={`${dataTestId}-visibilityButton`}
              >
                <VisibilityIcon isVisible={passwordVisible} />
              </IconButton>
            )}
          </FieldIconsWrapper>
        </FieldInputWrapper>
        {showErrorState && <span>{errorMessage}</span>}
      </FieldWrapper>
    );
  },
);

export default FormField;
