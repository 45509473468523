import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function ArrowRightIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.87195 6.33437L1.37164 11.3344C1.18708 11.5394 0.870703 11.5561 0.665703 11.3715C0.449516 11.1755 0.453735 10.8593 0.628578 10.6646L4.8282 5.99999L0.628516 1.33437C0.444766 1.12812 0.461016 0.813742 0.665391 0.628117C0.870703 0.443742 1.18695 0.460305 1.37164 0.665305L5.87164 5.63718C6.04383 5.85624 6.04383 6.14374 5.87195 6.33437Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowRightIcon;
