import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  div {
    height: 57px;
    width: 57px;
    position: relative;
    transform: unset;
    top: unset;
    left: unset;
  }
  img {
    height: 57px;
  }
`;

export default {};
