import { Status } from 'legacy/components/StatusAccordion/StatusAccordionStyles';
import {
  LanguageCodeKey,
  MilestoneType,
  PrimaryLanguage,
  WelcomeChecklistStatusType,
  languageCode,
  AgreementType,
} from 'legacy/types';

export const getStatus = (currentMileStone: MilestoneType) => {
  const milestones = Object.values(MilestoneType);
  const currentMileStoneIdx = milestones.indexOf(currentMileStone);
  const mileStoneInProgressIdx = currentMileStoneIdx + 1;

  const result = milestones.reduce(
    (acc, milestone, idx) => {
      if (idx <= currentMileStoneIdx) {
        acc[milestone] = Status.COMPLETED;
      } else if (idx === mileStoneInProgressIdx) {
        acc[milestone] = Status.IN_PROGRESS;
      } else {
        acc[milestone] = Status.INCOMPLETE;
      }

      return acc;
    },
    {} as { [key in MilestoneType]: Status },
  );

  return result;
};

export const getPrimaryLanguage = (primaryLanguage?: PrimaryLanguage): LanguageCodeKey => {
  if (primaryLanguage === languageCode.es) return 'es';
  return 'en';
};

export const isWelcomeChecklistPassed = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.PASSED;

export const isWelcomeChecklistFailed = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.FAILED;

export const isWelcomeChecklistRequired = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.REQUIRED;
export const isWelcomeChecklistNotRequired = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  welcomeChecklistStatus === WelcomeChecklistStatusType.NOT_REQUIRED;

export const isWelcomeChecklistCompleted = (welcomeChecklistStatus: WelcomeChecklistStatusType) =>
  isWelcomeChecklistFailed(welcomeChecklistStatus) ||
  isWelcomeChecklistPassed(welcomeChecklistStatus);

export const shouldLoadWelcomeChecklist = (dwcAgreement: AgreementType) =>
  dwcAgreement && isWelcomeChecklistRequired(dwcAgreement.welcome_checklist_status);
