import { addDays, endOfMonth, endOfYear, startOfMonth, startOfToday, startOfYear } from 'date-fns';
import { DateRangeZoomLevel } from 'api/types';
import { QueryClient } from 'react-query';
import {
  getEnergySummary,
  getPowerFlow,
  getSiteTimezone,
  getSolarEnergy,
  getSolarPower,
} from 'api/system';

/**
 * Slight misnomer here, since in context the `queryKey` prop
 * actually refers to the full array provided to react-query.
 */
export enum MonitoringQueryKey {
  TIMEZONE = 'siteTimezone',
  SOLAR_POWER = 'solarPower',
  POWER_FLOW = 'powerFlow',
  ENERGY_SUMMARY = 'solarEnergySummary',
  ENERGY = 'solarEnergy',
  ENERGY_MONTH = 'solarEnergyMonth',
  ENERGY_YEAR = 'solarEnergyYear',
}

export const REFETCH_INTERVAL = 15 * 60 * 1000; // 15 minutes
export const CACHE_STALE_TIME = 15 * 60 * 1000; // cache items are invalidated after 15 minutes, the rate at which ne360 refreshes telemetry

export const prefetchMonitoringQueries = async ({
  queryClient,
  systemId,
}: {
  queryClient: QueryClient;
  systemId: string;
}) => {
  const today = startOfToday();
  const timezoneData = await queryClient.fetchQuery([MonitoringQueryKey.TIMEZONE, systemId], () =>
    getSiteTimezone(String(systemId)).then((res) => res.data),
  );

  // set initial date range
  const dateRange = {
    startDate: today,
    endDate: addDays(today, 1),
    systemTimezone: String(timezoneData?.timezone),
    zoomLevel: DateRangeZoomLevel.DAY,
  };

  const prefetchQueries = [
    queryClient.prefetchQuery(
      [MonitoringQueryKey.SOLAR_POWER, systemId, dateRange?.startDate],
      () =>
        getSolarPower({ systemId: String(systemId), dateRange }).then((response) => response.data),
      {
        retry: false,
        staleTime: CACHE_STALE_TIME,
      },
    ),
    queryClient.prefetchQuery([MonitoringQueryKey.POWER_FLOW, systemId], () =>
      getPowerFlow(String(systemId)).then((response) => response.data),
    ),
    queryClient.prefetchQuery([MonitoringQueryKey.ENERGY_SUMMARY, systemId], () =>
      getEnergySummary(String(systemId)).then((response) => response.data),
    ),
    queryClient.prefetchQuery({
      queryKey: [MonitoringQueryKey.ENERGY_MONTH, systemId],
      queryFn: () =>
        getSolarEnergy({
          systemId: String(systemId),
          dateRange: {
            startDate: startOfMonth(today),
            endDate: endOfMonth(today),
            zoomLevel: DateRangeZoomLevel.MONTH,
            systemTimezone: dateRange.systemTimezone,
          },
        }).then((response) => response.data),
      staleTime: CACHE_STALE_TIME,
    }),
    queryClient.prefetchQuery({
      queryKey: [MonitoringQueryKey.ENERGY_YEAR, systemId],
      queryFn: () =>
        getSolarEnergy({
          systemId: String(systemId),
          dateRange: {
            startDate: startOfYear(today),
            endDate: endOfYear(today),
            zoomLevel: DateRangeZoomLevel.YEAR,
            systemTimezone: dateRange.systemTimezone,
          },
        }).then((response) => response.data),
      staleTime: CACHE_STALE_TIME,
    }),
  ];

  await Promise.all(prefetchQueries);
};
