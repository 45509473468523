import pages from 'pages';
import { useMemo } from 'react';
import { resolvePath, useLocation } from 'react-router';

/** Returns a name corresponding to the screen/page currently displayed to the user */
export function usePageName() {
  const { pathname } = useLocation();

  const currentPage: keyof typeof pages | undefined = useMemo(() => {
    if (
      resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(`/${pages.DASHBOARD}`).pathname === pathname ||
      pathname.startsWith(`/${pages.DASHBOARD}/${pages.AGREEMENTS}`) ||
      resolvePath(`/${pages.STATUS}`).pathname === pathname
    )
      return 'OVERVIEW';

    if (
      resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(pages.HARDWARE_INFO, `/${pages.DASHBOARD}/${pages.SYSTEM}`).pathname === pathname
    )
      return 'SYSTEM';

    if (resolvePath(pages.BILLING, `/${pages.DASHBOARD}`).pathname === pathname) return 'BILLING';

    if (
      resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(`/${pages.SUPPORT}`).pathname === pathname
    )
      return 'SUPPORT';

    return undefined;
  }, [pathname]);

  return { currentPage };
}

export default usePageName;
