import React, { useEffect, useState } from 'react';
import { MonthChangeEventHandler } from 'react-day-picker';
import { subMonths } from 'date-fns';
import { CustomPicker } from './CustomPicker';
import { CustomPickerButton } from './DatePickerStyles';

interface Props {
  onDateChange: MonthChangeEventHandler;
  currentDate: Date;
  onCloseModal: () => void;
  /**
   * Object containing the date for the oldest and newest monitoring data points.
   */
  dateRange: { oldest: Date; newest: Date };
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function MonthPicker({ onDateChange, currentDate, onCloseModal, dateRange }: Props) {
  const [selectedMonth, setSelectedMonth] = useState<number>(currentDate.getMonth());
  const [displayedYear, setDisplayedYear] = useState(currentDate.getFullYear());
  const [earliestYear, setEarliestYear] = useState(false);
  const [latestYear, setLatestYear] = useState(false);

  useEffect(() => {
    setEarliestYear(dateRange.oldest.getFullYear() === displayedYear);
    setLatestYear(dateRange.newest.getFullYear() === displayedYear);
    if (displayedYear === currentDate.getFullYear()) {
      setSelectedMonth(currentDate.getMonth());
    } else {
      setSelectedMonth(-1);
    }
  }, [currentDate, displayedYear, dateRange]);

  const onMonthClick = (month: number) => {
    setSelectedMonth(month);
  };

  const onPrevClick = () => {
    setDisplayedYear(new Date(displayedYear - 1, 0).getFullYear());
  };

  const onNextClick = () => {
    setDisplayedYear(new Date(displayedYear + 1, 0).getFullYear());
  };

  const onOkClick = () => {
    if (selectedMonth > -1) {
      onDateChange(new Date(displayedYear, selectedMonth));
    }
    onCloseModal();
  };

  const renderMonthPickerGrid = () =>
    months.map((month, idx) => {
      let disableButton = false;
      const monthDate = new Date(displayedYear, idx);
      if (monthDate > dateRange.newest || monthDate < subMonths(dateRange.oldest, 1))
        disableButton = true;
      return (
        <CustomPickerButton
          key={month}
          selected={month === months[selectedMonth]}
          onClick={() => onMonthClick(idx)}
          disabled={disableButton}
        >
          {month}
        </CustomPickerButton>
      );
    });

  return (
    <CustomPicker
      header={displayedYear}
      items={renderMonthPickerGrid()}
      onCloseModal={onCloseModal}
      onPrevClick={earliestYear ? undefined : onPrevClick}
      onNextClick={latestYear ? undefined : onNextClick}
      onOkClick={onOkClick}
    />
  );
}

export default MonthPicker;
