import React from 'react';
import colors from 'theme/colors';
import { IcoProps } from './types';

export function InfoIcon({ color = colors.secondaryDarkBlue }: IcoProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#22345B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C12.4142 9 12.75 9.33579 12.75 9.75V17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25V9.75C11.25 9.33579 11.5858 9 12 9Z"
        fill="#22345B"
      />
      <path
        d="M12.75 6.75C12.75 7.16421 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.16421 11.25 6.75C11.25 6.33579 11.5858 6 12 6C12.4142 6 12.75 6.33579 12.75 6.75Z"
        fill={color}
      />
    </svg>
  );
}

export default InfoIcon;
