import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { DateRange } from 'api/system/utils/createDateRange';
import { ChartDataType } from 'modules/types';
import { DateRangeZoomLevel, EnergyUnit, SystemType } from 'api/types';
import Banner from 'shared/components/Banner/Banner';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { useTheme } from 'styled-components';
import { WarningIcon } from 'shared/components/icons';
import { useBreakpoints } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { AnyChartDataProvider } from 'modules/system/utils/ChartDataProviders/AnyChartDataProvider';
import { useQuery, useQueryClient } from 'react-query';
import { CACHE_STALE_TIME, MonitoringQueryKey } from 'modules/system/utils/prefetchQueries';
import { getSolarEnergy } from 'api/system';
import { SpinnerContainer } from '../Chart.styles';
import { BarChart } from './BarChart';

interface Props {
  dataProvider: AnyChartDataProvider;
  dateRange: DateRange;
  system: SystemType;
}

export function EnergyChart({ dataProvider, dateRange, system }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[] | null>(null);
  const [yAxisEnergyUnit, setYAxisEnergyUnit] = useState<EnergyUnit | null>(null);
  const [largestProductionValue, setLargestProductionValue] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { zoomLevel, startDate } = dateRange;
  
  const theme = useTheme();
  const screenSize = useBreakpoints();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {data, isError, isFetching, error} = useQuery(
      [MonitoringQueryKey.ENERGY, system?.id, dateRange],
      () =>
        getSolarEnergy({ 
          systemId: String(system?.id), 
          dateRange
          }).then(
          (response) => response.data,
        ),
      {
        enabled:
          Boolean(system?.id) &&
          Boolean(dateRange),
        retry: false,
        staleTime: CACHE_STALE_TIME,
      },
    );


  const isChartDataEmpty = chartData && chartData.length === 0;

  useEffect(() => {
    let monitoringData = data;

    if (!monitoringData) {
      //  if there's no data from the in-house query, check the appropriate cache
      if (zoomLevel === DateRangeZoomLevel.YEAR) {
        monitoringData = queryClient.getQueryData([MonitoringQueryKey.ENERGY_YEAR, system.id]);
      } else {
        monitoringData = queryClient.getQueryData([MonitoringQueryKey.ENERGY_MONTH, system.id]);
      }
    }
    if (monitoringData && dataProvider && Object.keys(monitoringData).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] =
        dataProvider.prepareData<EnergyUnit>(monitoringData, dateRange);
      setYAxisEnergyUnit(_yAxisEnergyUnit as EnergyUnit);
      setLargestProductionValue(_largestProductionValue);
      setChartData(productionData);
    }
    // if the query completes and there's no data we shouldn't leave stale data in the displayed chart
    if (!isFetching && !monitoringData) {
      setChartData([]);
    }
  }, [startDate, zoomLevel, data, isFetching, queryClient, dataProvider, dateRange, system]);

  useEffect(() => {
    // don't set errors if query is still loading
    if (!isFetching) {
      if ((isError && (error as AxiosError)?.response?.status === 400) || isChartDataEmpty) {
        setErrorMessage(t('system.monitoring.monitoringUnavailableDateRange'));
      } else if (isError) {
        setErrorMessage(t('system.monitoring.monitoringUnavailable'));
      }
    }
  }, [isError, error, t, isChartDataEmpty, isFetching]);

  return (
    <>
      {(isError || isChartDataEmpty) && errorMessage && (
        <>
          <br />
          <Banner
            bannerText={errorMessage}
            bgColor={theme.colors.bannerWarningYellow20}
            icon={<WarningIcon />}
            dismissable={false}
          />
        </>
      )}

      {!isError &&
        !isChartDataEmpty &&
        (yAxisEnergyUnit && largestProductionValue && chartData?.length && dataProvider ? (
          <BarChart
            yAxisUnit={yAxisEnergyUnit}
            domain={dataProvider.getDomain(dateRange, largestProductionValue)}
            dateRange={dateRange}
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange, screenSize),
              y: dataProvider.getYAxis(largestProductionValue, yAxisEnergyUnit, screenSize),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            zoomLevel={zoomLevel}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default EnergyChart;
