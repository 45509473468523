import { FONT_FAMILY } from 'shared/components/Text/Text';
import styled from 'styled-components';

export const SegmentWrapper = styled.div`
  ${({ theme }) => `
        display: flex;
        padding: 4px;
        border: 1px solid ${theme.colors.strokeSecondaryGrey10};
        border-radius: 8px;
        font-family: ${FONT_FAMILY};
        font-size: 14px;
        color: ${theme.colors.textPrimarySecondary1100};
        background: ${theme.colors.backgroundSecondaryGrey05};
        max-height: 46px;
        box-shadow: ${theme.elevations.extraSunken};
    `}
`;

export const SegmentControl = styled.div<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => `
        display: flex;    
        padding: 8px 12px;
        max-height: 38px;
        justify-content: center;
        align-items: center;
        width: 80px;
        min-width: 68px;
        border-radius: 6px;
        margin: 0px 1px;
        cursor: pointer;
        @media screen and (max-width: ${theme.breakpoints.small}) {
            max-height: 36px;
        }

        ${
          $isActive
            ? `
            background: ${theme.colors.cardElevationMidWhite};
            box-shadow: ${theme.elevations.mid};
        
            &:focus, &:focus-visible {
                    box-shadow: 0px 0px 0px 1px #FFF, 0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
                    outline: 0px;
                }
        `
            : `
            &:hover {
                background: ${theme.colors.backgroundInteractionHoverGrey10};
                box-shadow: ${theme.elevations.low};
            }
            &:active {
                background: ${theme.colors.backgroundInteractionActiveGrey20};
            }
            &:focus, &:focus-visible {
                background: ${theme.colors.backgroundInteractionHoverGrey10};
                box-shadow: 0px 0px 0px 1px #FFF, 0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
                outline: 0px;
            }
        `
        }
    `}
`;
