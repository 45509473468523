import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'legacy/components/common/design-system';
import { Message } from 'legacy/components/common/myeverbright';
import axios from 'axios';
import { useGlobalState } from 'hooks/useGlobalState';
import { MessageType } from 'legacy/types';
import routes from 'routes';
import { AdminContext } from 'contexts/AdminContext';
import FaqForm, { FaqFormType } from './FaqForm/FaqForm';

function AddFaqForm({ onSubmit }: { onSubmit: () => void }) {
  const { setFaqs: setStateFaqs } = useGlobalState();
  const { faqs, setFaqs } = useContext(AdminContext);

  const [faq, setFaq] = useState<FaqFormType>({
    question: '',
    answer: '',
    order: -1,
    hidden: false,
  });

  const [, setSubmitDisabled] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  useEffect(() => {
    axios.get(routes.faqs).then((response) => {
      setStateFaqs(response.data);
    });
  }, [setStateFaqs]);

  useEffect(() => {
    if (faq.order !== faqs.length + 1) {
      setFaq({ ...faq, order: faqs.length + 1 });
    }
  }, [faqs, faq]);

  useEffect(() => {
    setSubmitDisabled(false);
  }, [faq]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMessage(null);
    axios
      .post(routes.faqs, faq)
      .then(() => {
        axios.get(routes.faqs).then((response) => {
          setFaqs(response.data);
          setStateFaqs(response.data);
        });
        setSubmitDisabled(true);
        onSubmit();
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  };

  return (
    <>
      {message && <Message severity={message.type}>{message.message}</Message>}
      <FaqForm faq={faq} setFaq={setFaq} />
      <Button label="Submit" margin="0 0 0 auto" onClick={handleSubmit} />
    </>
  );
}

export default AddFaqForm;
