import React, { useEffect, useMemo, useState } from 'react';
import useUser from 'hooks/useUser';
import WelcomeChecklist from 'legacy/views/status/WelcomeChecklist/WelcomeChecklist';
import {
  AccountType,
  MilestoneType,
  WelcomeChecklistItemType,
  WelcomeChecklistResult,
} from 'legacy/types';
import { generateRandomString } from 'legacy/utils';
import { StatusAccordion } from 'legacy/components/StatusAccordion/StatusAccordion';
import { Status as StatusEnum } from 'legacy/components/StatusAccordion/StatusAccordionStyles';
import FinancingCard from 'legacy/components/FinancingCard/FinancingCard';
import { Column, Row } from 'legacy/components/common/myeverbright';
import ProjectStatus from 'legacy/components/ProjectStatus/ProjectStatus';
import ProgressBar from 'legacy/components/ProgressBar/ProgressBar';
import { InstallerDetailsCard } from 'legacy/components/InstallerDetailsCard/InstallerDetailsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getWelcomeChecklist } from 'api/agreement';
import pages from 'pages';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { getStatus, shouldLoadWelcomeChecklist } from './utils';

import InServiceContent from './status-accordion-content/InServiceContent';
import ContractSigningContent from './status-accordion-content/ContractSigningContent';
import ProjectQualificationContent from './status-accordion-content/ProjectQualificationContent';
import SystemInstallationContent from './status-accordion-content/SystemInstallationContent';

type Props = {
  accounts: AccountType[];
};
export default function EarlyAccess({ accounts }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [welcomeChecklistModalOpen, setWelcomeChecklistModalOpen] = useState(false);
  const [welcomeChecklistQuestions, setWelcomeChecklistQuestions] = useState<
    WelcomeChecklistItemType[]
  >([]);
  const [isLoadingWelcomeChecklistQuestions, setLoadingWelcomeChecklistQuestions] =
    useState<boolean>(false);

  const { setSelectedAccount, selectedAccount, refetch: refetchUser } = useUser();

  const agreement = useMemo(
    () =>
      selectedAccount?.agreements.find(
        (_agreement) =>
          _agreement.welcome_checklists?.length === 0 &&
          _agreement.current_milestone === MilestoneType.CONTRACT_SIGNED,
      ) || selectedAccount?.agreements[0],
    [selectedAccount],
  );

  const welcomeChecklists = agreement ? agreement.welcome_checklists : [];

  const hasCompletedWelcomeChecklist =
    welcomeChecklists.length > 0 &&
    welcomeChecklists[welcomeChecklists.length - 1].result === WelcomeChecklistResult.PASS;

  const milestoneDisplayMap = {
    [MilestoneType.CONTRACT_SIGNED]: 'Contract Signed',
    [MilestoneType.PROJECT_QUALIFICATION_COMPLETED]: 'Project Qualified',
    [MilestoneType.INSTALLATION_COMPLETED]: 'System Installed',
    [MilestoneType.IN_SERVICE]: 'System Operational',
  };

  const currentMilestone = agreement?.current_milestone || MilestoneType.IN_SERVICE;

  useEffect(() => {
    if (selectedAccount && selectedAccount.agreements.length === 0) {
      const nextAccount = accounts.find((account: AccountType) => account.agreements.length > 0);
      if (nextAccount) {
        setSelectedAccount(nextAccount);
      } else {
        navigate(`/${pages.ERROR}`);
      }
    }
  }, [setSelectedAccount, navigate, accounts, selectedAccount]);

  useEffect(() => {
    if (agreement && shouldLoadWelcomeChecklist(agreement)) {
      setLoadingWelcomeChecklistQuestions(true);

      getWelcomeChecklist(agreement.id)
        .then((response) => {
          setWelcomeChecklistQuestions(response.data);
        })
        .finally(() => setLoadingWelcomeChecklistQuestions(false));
    }
  }, [agreement, hasCompletedWelcomeChecklist]);

  const items = useMemo(() => {
    const milestoneStatus = getStatus(currentMilestone);

    return [
      {
        label: t('statusAccordionCopy.contractSigning.title'),
        content: <ContractSigningContent />,
        status: milestoneStatus[MilestoneType.CONTRACT_SIGNED],
        id: generateRandomString(),
        isOpen: milestoneStatus[MilestoneType.CONTRACT_SIGNED] === StatusEnum.IN_PROGRESS,
      },
      {
        label: t('statusAccordionCopy.projectQualification.title'),
        content: (
          <ProjectQualificationContent
            isLoadingWelcomeChecklistQuestions={isLoadingWelcomeChecklistQuestions}
            agreement={agreement}
            onOpenWelcomeChecklist={() => setWelcomeChecklistModalOpen(true)}
          />
        ),
        status: milestoneStatus[MilestoneType.PROJECT_QUALIFICATION_COMPLETED],
        id: generateRandomString(),
        isOpen:
          milestoneStatus[MilestoneType.PROJECT_QUALIFICATION_COMPLETED] === StatusEnum.IN_PROGRESS,
      },
      {
        label: t('statusAccordionCopy.systemInstallation.title'),
        content: <SystemInstallationContent />,
        status: milestoneStatus[MilestoneType.INSTALLATION_COMPLETED],
        id: generateRandomString(),
        isOpen: milestoneStatus[MilestoneType.INSTALLATION_COMPLETED] === StatusEnum.IN_PROGRESS,
      },
      {
        label: t('statusAccordionCopy.inService.title'),
        content: <InServiceContent />,
        status: milestoneStatus[MilestoneType.IN_SERVICE],
        id: generateRandomString(),
        isOpen:
          milestoneStatus[MilestoneType.IN_SERVICE] === StatusEnum.IN_PROGRESS ||
          milestoneStatus[MilestoneType.IN_SERVICE] === StatusEnum.COMPLETED,
      },
    ];
  }, [agreement, currentMilestone, t, isLoadingWelcomeChecklistQuestions]);

  return (
    <>
      <Column>
        <ProjectStatus currentMilestone={currentMilestone} />
        <ProgressBar
          stages={Object.values(milestoneDisplayMap)}
          currentStage={milestoneDisplayMap[currentMilestone]}
        />
      </Column>

      <StatusAccordion items={items} />

      {agreement && (
        <Row $collapsing $margin="16px 0 0">
          {agreement.organization && <InstallerDetailsCard organization={agreement.organization} />}
          <FinancingCard agreement={agreement} />
        </Row>
      )}

      {!hasCompletedWelcomeChecklist && agreement && (
        <ErrorBoundary>
          <WelcomeChecklist
            agreement={agreement}
            isOpen={welcomeChecklistModalOpen}
            checklistQuestions={welcomeChecklistQuestions}
            handleCloseWelcomeChecklist={async () => {
              await refetchUser();
              setWelcomeChecklistModalOpen(false);
            }}
          />
        </ErrorBoundary>
      )}
    </>
  );
}
