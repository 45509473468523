import React from 'react';
import { StatisticLabel, StatisticValue, StatisticWrapper } from './MonitoringSnapshot.styles';

type Props = {
  label: string;
  value: number | null;
  unit: string;
};

export function MonitoringStatistic({ label, value, unit }: Props) {
  return (
    <StatisticWrapper>
      <StatisticLabel>{label}</StatisticLabel>
      <StatisticValue>
        <span>{value === null ? '---- ' : value.toFixed(2)}</span>
        <span> {unit}</span>
      </StatisticValue>
    </StatisticWrapper>
  );
}

export default MonitoringStatistic;
