import styled, { css } from 'styled-components';
import {
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
  getDropdownStyleVariantValues,
  lightThemeDropdowns,
} from 'theme/dropdowns';

export const CustomSelectContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

export const NativeSelect = styled.select`
  display: none !important; // can probably remove !important once semantic is gone
`;

type SelectSelectedProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const SelectSelected = styled.div<SelectSelectedProps>`
  ${({ $styleVariant, $state }) => {
    const { background, border, borderRadius } = getDropdownStyleVariantValues(
      lightThemeDropdowns,
      $styleVariant,
      $state,
    );

    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      padding: 8px 12px;
      background: ${background};
      border: ${border};
      border-radius: ${borderRadius};
      &:focus {
        outline: none;
        z-index: 1;
      }
    `;
  }}
`;
