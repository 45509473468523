import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    min-height: 150px;
  }
`;

export default TextAreaWrapper;
