import { useCallback } from 'react';
import { useGlobalState } from 'hooks/useGlobalState';
import { NotificationsType, NotificationType } from './StateContext';

export interface UseNotificationsType {
  addNotification: (notification: NotificationType) => void;
  clearNotifications: () => void;
  notifications: NotificationsType;
}

export function useNotifications(): UseNotificationsType {
  const { notifications, setNotifications } = useGlobalState();

  const addNotification = useCallback(
    (notification: NotificationType) => setNotifications([...notifications, notification]),
    [setNotifications, notifications],
  );

  const clearNotifications = useCallback(() => setNotifications([]), [setNotifications]);

  return { addNotification, clearNotifications, notifications };
}
