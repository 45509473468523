import React, { Context, createContext, useMemo, useState } from 'react';
import { AgreementType, FaqType, TagType, UserType } from 'legacy/types';

interface AgreementTableStateType {
  agreements: AgreementType[];
  page: number;
  pageSize: number;
  count: number;
  totalPages: number;
  query: string;
  direction: 'ascending' | 'descending';
  sortBy: string;
}

interface MonitoringTableStateType {
  goodAgreements: AgreementType[];
  badAgreements: AgreementType[];
  page: number;
  pageSize: number;
  count: number;
  totalPages: number;
}

interface AdminContextType {
  monitoringTableState: MonitoringTableStateType;
  setMonitoringTableState: React.Dispatch<any>;
  agreementTableState: AgreementTableStateType;
  setAgreementTableState: React.Dispatch<any>;
  internalUsers: UserType[];
  setInternalUsers: React.Dispatch<any>;
  tags: TagType[];
  setTags: React.Dispatch<any>;
  faqs: FaqType[];
  setFaqs: React.Dispatch<any>;
}

const AdminContext: Context<AdminContextType> = createContext({} as AdminContextType);

function AdminContextProvider({ children }: { children: React.ReactNode }) {
  const [faqs, setFaqs] = useState([]);
  const [tags, setTags] = useState([]);

  const [monitoringTableState, setMonitoringTableState] = useState<MonitoringTableStateType>({
    goodAgreements: [],
    badAgreements: [],
    page: 1,
    pageSize: 25,
    count: 0,
    totalPages: 0,
  });

  const [agreementTableState, setAgreementTableState] = useState<AgreementTableStateType>({
    agreements: [],
    page: 1,
    pageSize: 25,
    count: 0,
    totalPages: 0,
    query: '',
    direction: 'ascending',
    sortBy: '',
  });

  const [internalUsers, setInternalUsers] = useState([]);

  const value = useMemo(
    () => ({
      monitoringTableState,
      setMonitoringTableState,
      agreementTableState,
      setAgreementTableState,
      internalUsers,
      setInternalUsers,
      faqs,
      setFaqs,
      tags,
      setTags,
    }),
    [faqs, tags, agreementTableState, internalUsers, monitoringTableState],
  );

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
}

export { AdminContext, AdminContextProvider };
