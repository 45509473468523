import React from 'react';
import { useTheme } from 'styled-components';
import { LINKS } from 'shared/links';
import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  FooterContent,
  FooterWrapper,
  MenuWrapper,
  StoreButton,
  StoreButtonLarge,
} from './Footer.styles';
import { AppStoreIcon, AppStoreIconLarge, GooglePlayIcon, GooglePlayIconLarge } from '../icons';

export function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContent>
        <MenuWrapper>
          {LINKS.FOOTER_MENU_OPTIONS.map(({ text, link }) => (
            <li key={String(Math.floor(Math.random() * 10_000))}>
              <a href={link} target="_blank" rel="noreferrer">
                {text}
              </a>
            </li>
          ))}
        </MenuWrapper>
        <ButtonsWrapper>
          <StoreButton href={LINKS.MOBILE_APP_STOREFRONTS.apple} target="_blank">
            <AppStoreIcon color={theme.colors.iconIconSecondaryGrey90} />
          </StoreButton>
          <StoreButton href={LINKS.MOBILE_APP_STOREFRONTS.google} target="_blank">
            <GooglePlayIcon color={theme.colors.iconIconSecondaryGrey90} />
          </StoreButton>
          <StoreButtonLarge href={LINKS.MOBILE_APP_STOREFRONTS.apple} target="_blank">
            <AppStoreIconLarge color={theme.colors.iconIconSecondaryGrey90} />
          </StoreButtonLarge>
          <StoreButtonLarge href={LINKS.MOBILE_APP_STOREFRONTS.google} target="_blank">
            <GooglePlayIconLarge color={theme.colors.iconIconSecondaryGrey90} />
          </StoreButtonLarge>
        </ButtonsWrapper>
      </FooterContent>
      <sub>{t('copyright', { year: new Date().getFullYear() })}</sub>
    </FooterWrapper>
  );
}

export default Footer;
