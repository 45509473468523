import styled from 'styled-components';

interface BannerStyledProps {
  $bgColor?: string;
}
export const BannerStyled = styled.div<BannerStyledProps>`
  ${({ theme, $bgColor }) => `
    width: 100%;
    display: flex;
    align-items: flex-start;
    background-color:  ${$bgColor};
    border-radius: 8px;
    padding: 16px 24px;
    box-shadow: ${theme.elevations.low};
    @media (max-width: ${theme.breakpoints.small}) {
      align-items: flex-start;
    }
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
`;

export const BannerButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0 0 0 auto;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const BannerText = styled.span<{ $margin?: string }>`
  ${({ theme, $margin }) => `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.textPrimarySecondary1100};
    margin: ${$margin || 0};
  `}
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;
