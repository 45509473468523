import {
  TInputs,
  TInputState,
  TInputStyleVariantsKey,
  DISABLED_INPUT_STATE,
  ERROR_INPUT_STATE,
  ENABLED_INPUT_STATE,
} from './TInputs';

// STATE VALUE
export const getInputState = (isDisabled: boolean, showErrorState?: boolean) => {
  if (isDisabled) {
    return DISABLED_INPUT_STATE;
  }
  return showErrorState ? ERROR_INPUT_STATE : ENABLED_INPUT_STATE;
};

// STYLE VARIANT VALUES
export const getInputStyleVariantValues = (
  theme: TInputs,
  variant: TInputStyleVariantsKey,
  state: TInputState,
) => ({
  ...theme.variants[variant][state],
  ...theme.variants[variant],
});

export default getInputStyleVariantValues;
