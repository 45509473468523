import React from 'react';
import { useTooltip, TooltipOptions } from 'hooks/useTooltip';
import { TooltipContext } from 'contexts/TooltipContext';
import { Subtitle3 } from 'legacy/components/common/design-system/Typography';
import { TooltipContent } from './TooltipContent';
import { TooltipTrigger } from './TooltipTrigger';

type Props = TooltipOptions & {
  content: React.ReactNode;
  triggerIcon?: JSX.Element;
  onClick?: () => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export function Tooltip({
  initialOpen,
  placement,
  open,
  onOpenChange,
  content,
  triggerIcon,
  onClick,
  onMouseLeave,
}: Props) {
  const tooltip = useTooltip({ initialOpen, placement, open, onOpenChange });

  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger onClick={onClick} onMouseLeave={onMouseLeave}>
        {triggerIcon}
      </TooltipTrigger>
      <TooltipContent>
        <Subtitle3>{content}</Subtitle3>
      </TooltipContent>
    </TooltipContext.Provider>
  );
}

export default Tooltip;
