import React from 'react';
import { HeaderCell, HeaderCellInner, HeaderRow, TableHeaderStyled } from './TableHeaderStyles';
import { TTableColumn } from '../TTable';
import { SortIcon } from '../../Icons';

type Props = {
  tableHeaderData: TTableColumn[];
};
export function TableHeader({ tableHeaderData }: Props) {
  return (
    <TableHeaderStyled>
      <HeaderRow>
        {tableHeaderData.map((item) => (
          <HeaderCell
            key={item.title}
            onClick={item.handleSort}
            $hasCursor={Boolean(item.handleSort)}
          >
            <HeaderCellInner>
              <span>{item.title}</span> {Boolean(item.handleSort) && <SortIcon />}
            </HeaderCellInner>
          </HeaderCell>
        ))}
      </HeaderRow>
    </TableHeaderStyled>
  );
}

export default TableHeader;
