import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

type Props = IIconProps & {
  filled?: boolean;
};

export function SystemIcon({ color: colorProp, filled = false }: Props) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4099 1.5678C11.6174 1.08616 11.4627 0.523658 11.0373 0.214283C10.6119 -0.0950921 10.0318 -0.0669671 9.63454 0.277564L0.634538 8.15256C0.282976 8.46194 0.156413 8.95764 0.321648 9.39358C0.486882 9.82952 0.908757 10.1248 1.37634 10.1248H5.29626L2.59274 16.4319C2.38532 16.9135 2.54001 17.476 2.9654 17.7854C3.39079 18.0948 3.97087 18.0666 4.36813 17.7221L13.3681 9.8471C13.7197 9.53772 13.8463 9.04202 13.681 8.60608C13.5158 8.17014 13.0974 7.87835 12.6263 7.87835H8.70641L11.4099 1.5678Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.91094 0.221063C10.0727 0.0786445 10.2801 0 10.4945 0C11.0992 0 11.5211 0.599414 11.3172 1.16965L8.92305 7.875H12.7691C13.3246 7.875 13.75 8.30039 13.75 8.82422C13.75 9.09844 13.634 9.35859 13.4301 9.53789L4.08555 17.782C3.92734 17.9227 3.71992 18 3.50652 18C2.90113 18 2.47855 17.3988 2.68211 16.8293L5.07695 10.125H1.15738C0.656406 10.125 0.25 9.71719 0.25 9.21797C0.25 8.95781 0.360848 8.71172 0.554594 8.53945L9.91094 0.221063ZM9.93203 1.71035L1.72938 9H5.875C6.05781 9 6.23008 9.08789 6.33555 9.23906C6.44102 9.38672 6.46563 9.58008 6.40586 9.75234L4.06797 16.2984L12.3367 9H8.125C7.94219 9 7.76992 8.91211 7.66445 8.76094C7.55898 8.58164 7.53437 8.41992 7.59414 8.24766L9.93203 1.71035Z"
        fill={color}
      />
    </svg>
  );
}

export default SystemIcon;
