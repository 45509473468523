import styled from 'styled-components';
import { Color, colors } from 'theme/colors';

export enum Status {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  margin-top: 32px;
`;

export const AccordionItemWrapper = styled.div`
  position: relative;
`;

export const StatusWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: -44px;
  height: 100%;
`;

type VerticalLineProps = {
  $showLine: boolean;
  $status: Status;
};

export const VerticalLine = styled.div<VerticalLineProps>`
  ${({ $showLine, $status }) =>
    $showLine
      ? `
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
      border-left: 1px solid ${$status === Status.COMPLETED ? colors.blue : colors.gray20};
  `
      : `display: none;`}
`;

type CircleProps = {
  $status: Status;
};

export const Circle = styled.div<CircleProps>`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  ${({ $status }) => {
    let bgColor: Color = colors.gray05;
    let color: Color = colors.gray80;
    let borderColor: Color = colors.gray20;

    if ($status === Status.COMPLETED) {
      bgColor = colors.blue;
      color = colors.white;
      borderColor = colors.blue;
    }

    if ($status === Status.IN_PROGRESS) {
      bgColor = colors.white;
      color = colors.blue;
      borderColor = colors.blue;
    }
    return `
    background-color: ${bgColor};
    color: ${color};
    border: 1px solid ${borderColor};
    `;
  }};
`;
