/* eslint-disable class-methods-use-this */
import { addMonths, differenceInCalendarMonths, format } from 'date-fns';
import { capitalize } from 'lodash';
import { toZonedTime } from 'date-fns-tz';

import { AxisType, Tuple, PaddingType, MonitoringDatum } from 'legacy/types';
import { DateRange } from 'api/system/utils/createDateRange';
import { ChartDataProvider } from './ChartDataProvider';
import { AnyChartDataProvider } from './AnyChartDataProvider';

export class YearChartDataProvider extends ChartDataProvider implements AnyChartDataProvider {
  static placeholderMaximumValue = 300;

  static numberOfTicks = 12;

  barWidth = 10;

  shouldShowDateLabel = (days: number, dateRange: DateRange): boolean => {
    const dayOfLastDayInMonth = new Date(
      dateRange.startDate.getFullYear(),
      dateRange.startDate.getMonth() + 1,
      0,
    ).getDate();
    if (days === dayOfLastDayInMonth) {
      return true;
    }
    if (days === 1) {
      return true;
    }
    if (days < 28 && (days - 1) % 7 === 0) {
      return true;
    }
    return false;
  };

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    const monthlyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      largestProductionValue = Math.max(largestProductionValue, value);
      if (largestProductionValue === value) {
        largestProductionUnit = unit;
      }
      const monthNumber = differenceInCalendarMonths(time, dateRange.startDate);
      monthlyProduction.set(monthNumber, { time, value, unit });
    });
    return [monthlyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, YearChartDataProvider.numberOfTicks - 1],
    y: [0, maximumValue || YearChartDataProvider.placeholderMaximumValue],
  });

  tickXFormat = (month: number, range: DateRange): string => {
    const date = addMonths(range.startDate, month);
    const zoned = toZonedTime(date, range.systemTimezone);
    const abbreviation = format(zoned, 'MMM').substring(0, 2);
    const initialCaseAbbreviation = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);
    return initialCaseAbbreviation;
  };

  tickYFormat = <T>(value: number, _: T | null): string => `${value.toFixed(1)}`;

  domainPaddingX: PaddingType = [70, 10];

  yTickLabelOffset = {
    x: -60,
    y: 15,
  };

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(YearChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (month: number) => this.tickXFormat(month, range),
  });

  getTickValuesYAxis = (maximumValue: number): number[] => {
    const numberOfTicks = 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return tickValues;
  };

  getYAxis = <T>(maximumValue: number, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = (date: Date, dateRange: DateRange): string => {
    const zoned = toZonedTime(date, dateRange.systemTimezone);
    return capitalize(format(zoned, 'MMMM').toLowerCase());
  };
}

export default YearChartDataProvider;
