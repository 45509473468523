import React from 'react';
import { useParams } from 'react-router-dom';

import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { Column } from '../../components/common/myeverbright';
import { MonitoringCard } from '../../components/MonitoringCard/MonitoringCard';

export default function MonitoringDetailView() {
  const { systemId, systemCapacity, homeOwnerContractId } = useParams();

  return (
    <Column $width="66.66%" $alignItems="center">
      <ErrorBoundary>
        <MonitoringCard
          systemId={systemId}
          systemCapacity={systemCapacity ? Number.parseInt(systemCapacity, 10) : undefined}
          homeOwnerContractId={homeOwnerContractId}
        />
      </ErrorBoundary>
    </Column>
  );
}
