import React from 'react';
import AnimatedLogo from '../../../static/animated-logo.svg';
import { LoadingLogoWrapper } from './Modal.styles';

export function LoadingLogo() {
  return (
    <LoadingLogoWrapper>
      <img src={AnimatedLogo} alt="everbright loading logo" />
    </LoadingLogoWrapper>
  );
}

export default LoadingLogo;
