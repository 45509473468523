import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, LoadingSpinner, TextInput } from 'legacy/components/common/design-system';
import { Checkbox, Message } from 'legacy/components/common/myeverbright';
import pages from 'pages';
import { lazyWithRetry } from 'legacy/utils';
import { DisclamerContainer, LinkContainer, LoadingWrapper } from './SetPasswordFormStyles';

const ConfirmPasswordFields = lazyWithRetry(
  () => import('../ConfirmPasswordFields'),
  'ConfirmPasswordFields',
);
const CONFIRMATION_TEXT = (
  <span>
    By claiming your account, you agree to the EverBright{' '}
    <LinkContainer href="https://www.goeverbright.com/privacy-policy" target="_blank">
      Privacy Policy
    </LinkContainer>
  </span>
);

type FormSubmitFunc = (password: string) => Promise<void>;

interface SetPasswordFormProps {
  formSubmit: FormSubmitFunc;
  emailAddress?: null | string;
  showAgreeText?: boolean;
  showEmailAddress?: boolean;
}

const defaultProps = {
  emailAddress: null,
  showAgreeText: false,
  showEmailAddress: true,
};

export function SetPasswordForm({
  formSubmit,
  emailAddress,
  showAgreeText,
  showEmailAddress,
}: SetPasswordFormProps) {
  const [password, setPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = (e: React.SyntheticEvent, confirmedPassword: string) => {
    e.preventDefault();
    setSubmitted(true);
    if (showAgreeText && !agreed) {
      setErrorMessage('You must agree to the EverBright Privacy Policy.');
      setSubmitted(false);
    } else {
      formSubmit(confirmedPassword)
        .catch((error) => {
          setErrorMessage(error.response?.data || 'There was an error.');
        })
        .finally(() => {
          setSubmitted(false);
        });
    }
  };

  const handlePasswordChange = (pswd: string, isValid: boolean) => {
    setPassword(pswd);
    setPasswordConfirmed(isValid);
  };

  const handleCheckboxChange = () => {
    setAgreed((a) => !a);
  };

  return !emailAddress && showEmailAddress ? (
    <LoadingWrapper>
      <LoadingSpinner />
    </LoadingWrapper>
  ) : (
    <form
      onSubmit={(e: React.SyntheticEvent) => {
        handleSubmit(e, password);
      }}
    >
      {showEmailAddress && (
        <TextInput
          ariaLabel="email address"
          label="Email Address"
          value={emailAddress || ''}
          isDisabled
          margin="0 0 24px"
        />
      )}
      {errorMessage && <Message severity="error">{errorMessage}</Message>}
      <Suspense fallback={<LoadingSpinner absoluteCenter />}>
        <ConfirmPasswordFields clearFields={submitted} onPasswordChange={handlePasswordChange} />
      </Suspense>
      {passwordConfirmed && <Message severity="success">Password matches!</Message>}
      <Divider $margin="1rem 0" />
      {showAgreeText ? (
        <>
          <DisclamerContainer>
            <Checkbox
              isChecked={agreed}
              onChange={handleCheckboxChange}
              margin="10px 0 0 0"
              text={CONFIRMATION_TEXT}
            />
          </DisclamerContainer>
          <Button
            type="submit"
            margin="0 auto 16px"
            label="Create Account"
            isDisabled={!passwordConfirmed}
          />
          <div>
            <b>Already have an account?</b> <Link to={pages.LOGIN}>Log In</Link>
          </div>
        </>
      ) : (
        <Button type="submit" label="Reset Password" margin="0 auto" />
      )}
    </form>
  );
}

SetPasswordForm.defaultProps = defaultProps;

export default SetPasswordForm;
