import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import pages from 'pages';
import UserDetailView from 'legacy/views/admin/UserDetailView';
import AgreementTableView from 'legacy/views/admin/AgreementTableView';
import AdminTableView from 'legacy/views/admin/AdminTableView';
import StatisticsView from 'legacy/views/admin/StatisticsView';
import FaqsView from 'legacy/views/admin/FaqsView';
import TagsView from 'legacy/views/admin/TagsView';
import MonitoringDetailView from 'legacy/views/admin/MonitoringDetailView';
import Monitoring from 'legacy/components/admin/monitoring/Monitoring';
import AuthCopyAckView from 'legacy/views/admin/AuthCopyAckView';
import BackfillWorkflows from 'legacy/components/admin/BackfillWorkflows';
import NoMatch from 'legacy/views/noMatch/NoMatch';
import Layout from 'legacy/views/Layout';
import { AdminLayout } from '../layouts/AdminLayout';
import { SupportGuard } from '../guards/SupportGuard';

export const Admin: RouteObject[] = [
  {
    element: <Layout hasNav hasFooter />,
    children: [
      {
        path: `${pages.ADMIN}/*`,
        element: <AdminLayout />,

        children: [
          {
            index: true,
            element: <Navigate to={`/${pages.ADMIN}/${pages.AGREEMENTS}`} replace />,
          },

          {
            path: pages.USER,
            element: (
              <SupportGuard>
                <UserDetailView />
              </SupportGuard>
            ),
          },
          { path: pages.AGREEMENTS, element: <AgreementTableView /> },
          { path: pages.ADMINS, element: <AdminTableView /> },
          { path: pages.STATISTICS, element: <StatisticsView /> },
          { path: pages.MANAGE_FAQS, element: <FaqsView /> },
          { path: pages.TAGS, element: <TagsView /> },
          { path: pages.MONITORING_DETAIL, element: <MonitoringDetailView /> },
          { path: pages.MONITORING, element: <Monitoring /> },
          { path: pages.AUTH_COPY_ACK, element: <AuthCopyAckView /> },
          { path: pages.BACKFILL, element: <BackfillWorkflows /> },
        ],
        errorElement: <NoMatch />,
      },
    ],
  },
];

export default Admin;
