import styled from 'styled-components';
import { FONT_FAMILY } from '../Text/Text';

type TOuterContainerProps = {
  $margin: string;
};

export const OuterContainer = styled.div<TOuterContainerProps>`
  margin: ${({ $margin }) => $margin};
`;

type TLabelProps = {
  $isDisabled: Boolean;
};

export const Label = styled.label<TLabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0;
  width: fit-content;
  cursor: pointer;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Input = styled.input`
  display: block;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  ~ svg {
    path:last-child {
      display: none;
    }
  }

  &:checked ~ svg {
    path:last-child {
      display: block;
    }
  }
  &:checked ~ svg:after {
    path:last-child {
      display: block;
    }
  }
`;

export const Text = styled.span`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: ${theme.colors.textPrimarySecondary1100};
  font-family: ${FONT_FAMILY};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`}
`;
