import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardElevationLowWhite};
`;

export const SupportFormWrapper = styled.div`
  margin-top: 32px;
  max-width: 655px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  div {
    max-width: 720px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
    max-width: unset;
    gap: 48px;
  }
`;

export const ProjectContainer = styled.div`
  max-width: 721px;
  width: 100%;
`;
