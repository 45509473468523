import React, { useState } from 'react';
import { resolvePath, useNavigate } from 'react-router-dom';
import pages from 'pages';
import { UserType } from 'legacy/types';
import {
  AccountIcon,
  ArrowLeftToLine,
  BarsIcon,
} from 'legacy/components/common/design-system/Icons';
import { Dropdown } from 'legacy/components/common/design-system';
import { DropdownItem } from 'legacy/components/common/design-system/Dropdown/DropdownItem';
import { DropdownIconButton, DropdownWrapper } from './NavBarStyles';

enum DropdownEnum {
  DASHBOARD = 'DASHBOARD',
  ACCOUNT = 'ACCOUNT',
}

export type Props = {
  user: UserType;
  hasDashboardAccess: boolean;
  impersonator?: UserType;
  onUnimpersonate: () => void;
  onLogoutModal: () => void;
};

export function NavDropdown({
  user,
  hasDashboardAccess,
  impersonator,
  onUnimpersonate,
  onLogoutModal,
}: Props) {
  const [openedDropdown, setOpenedDropdown] = useState<DropdownEnum | null>(null);
  const navigate = useNavigate();

  const handleDropdownClick = (val: DropdownEnum) => {
    setOpenedDropdown(val);
  };

  const handleCloseDropdown = () => {
    setOpenedDropdown(null);
  };

  const dashboardDropdownItems = [
    {
      title: 'DASHBOARD',
      value: '',
      onClick: () => {
        navigate(resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`).pathname);
        handleCloseDropdown();
      },
    },
    {
      title: 'SYSTEM',
      value: '',
      onClick: () => {
        navigate(resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname);
        handleCloseDropdown();
      },
    },
    {
      title: 'BILLING & PAYMENT',
      value: '',
      onClick: () => {
        navigate(resolvePath(pages.BILLING, `/${pages.DASHBOARD}`).pathname);
        handleCloseDropdown();
      },
    },
  ];
  const accountDropdownItems = [
    ...(impersonator
      ? [
          { title: `Logged in as ${user.email}`, value: '', isDisabled: true },
          {
            title: 'Stop Impersonation',
            value: '',
            Icon: ArrowLeftToLine,
            onClick: () => {
              onUnimpersonate();
              handleCloseDropdown();
            },
          },
        ]
      : []),
    {
      title: 'ACCOUNT SETTINGS',
      value: '',
      onClick: () => {
        navigate(`/${pages.ACCOUNT}`);
        handleCloseDropdown();
      },
    },
    {
      title: 'SUPPORT & FAQs',
      value: '',
      onClick: () => {
        navigate(`/${pages.SUPPORT}`);
        handleCloseDropdown();
      },
    },
    {
      title: 'LOG OUT',
      value: '',
      onClick: () => {
        onLogoutModal();
        handleCloseDropdown();
      },
    },
  ];

  return (
    <DropdownWrapper>
      {hasDashboardAccess && (
        <DropdownIconButton
          $hiddenOnDesktop
          onClick={() => handleDropdownClick(DropdownEnum.DASHBOARD)}
        >
          <BarsIcon />
          <Dropdown
            styleVariant="secondary"
            isOpen={openedDropdown === DropdownEnum.DASHBOARD}
            position={{ top: '58px', right: '0' }}
            onClickOutside={handleCloseDropdown}
          >
            {dashboardDropdownItems.map((item) => {
              const { onClick, ...dropdownItemData } = item;
              return (
                <DropdownItem
                  styleVariant="secondary"
                  key={dropdownItemData.title}
                  onClick={onClick}
                  dropdownItemData={dropdownItemData}
                />
              );
            })}
          </Dropdown>
        </DropdownIconButton>
      )}
      <DropdownIconButton
        onClick={() => {
          handleDropdownClick(DropdownEnum.ACCOUNT);
        }}
      >
        <AccountIcon />

        <Dropdown
          styleVariant="secondary"
          isOpen={openedDropdown === DropdownEnum.ACCOUNT}
          position={{ top: '58px', right: '0' }}
          onClick={() => handleDropdownClick(DropdownEnum.ACCOUNT)}
          onClickOutside={handleCloseDropdown}
        >
          {accountDropdownItems.map((item) => {
            const { onClick, ...dropdownItemData } = item;
            return (
              <DropdownItem
                styleVariant="secondary"
                key={dropdownItemData.title}
                onClick={onClick}
                dropdownItemData={dropdownItemData}
              />
            );
          })}
        </Dropdown>
      </DropdownIconButton>
    </DropdownWrapper>
  );
}

export default NavDropdown;
