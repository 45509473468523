import styled from 'styled-components';
import colors, { Color } from 'theme/colors';
import globalStyles from 'theme/globalStyles';
import { MOBILE_MAX_WIDTH } from '../../../../constants';

interface BannerStyledProps {
  $bgColor?: Color;
}
export const BannerStyled = styled.div<BannerStyledProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: ${globalStyles.ebSmallDropShadow};
  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    align-items: flex-start;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
`;

export const BannerButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0 0 0 auto;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const BannerText = styled.span<{ $margin?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.secondaryDarkBlue};
  margin: ${({ $margin = 0 }) => $margin};
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;
