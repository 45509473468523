import styled from 'styled-components';
import colors, { Color } from 'theme/colors';

export const StatusIndicatorStyled = styled.div<{ $bgColor?: Color }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: ${({ $bgColor = colors.green20 }) => $bgColor};
  width: fit-content;
  padding: 4px 16px;
`;

export default StatusIndicatorStyled;
