import styled from 'styled-components';

export type GridProps = {
  columns?: number;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ columns = 1 }) => `repeat(${columns}, 1fr)`};
  grid-template-rows: 'auto auto auto auto';
  gap: 28px;
  margin: 14px 0;
`;
