import styled, { css } from 'styled-components';
import { SeverityType } from 'legacy/types';

type ContainerProps = {
  $severityType?: SeverityType;
};

export const Container = styled.div<ContainerProps>`
  ${({ $severityType }) => {
    let boxShadow =
      '0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)';
    let background = '#f8f8f9';
    let color = 'rgba(0, 0, 0, 0.87)';

    if ($severityType === 'error') {
      boxShadow = '0px 0px 0px 1px #d80d00 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)';
      background = '#FFE8E6';
      color = '#d80d00';
    }

    if ($severityType === 'warning') {
      boxShadow = '0px 0px 0px 1px #B58105 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)';
      background = '#FFF8DB';
      color = '#B58105';
    }

    if ($severityType === 'success') {
      boxShadow = '0px 0px 0px 1px #1EBC30 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0)';
      background = '#E5F9E7';
      color = '#1EBC30';
    }

    return css`
      position: relative;
      min-height: 1em;
      margin: 1em 0em;
      background: ${background};
      padding: 1em 1.5em;
      line-height: 1.4285em;
      color: ${color};
      transition:
        opacity 0.1s ease,
        color 0.1s ease,
        background 0.1s ease,
        box-shadow 0.1s ease;
      border-radius: 8px;
      box-shadow: ${boxShadow};
    `;
  }}
`;

export const HeaderText = styled.div<ContainerProps>`
  ${({ $severityType }) => {
    let color = 'rgba(0, 0, 0, 0.87)';

    if ($severityType === 'error') {
      color = '#d80d00';
    }

    if ($severityType === 'warning') {
      color = '#9c6f04';
    }

    if ($severityType === 'success') {
      color = '#1EBC30';
    }

    return `
        color: ${color};
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 4px;
    `;
  }}
`;
