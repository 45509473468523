import React from 'react';
import { NavLink } from 'react-router-dom';
import pages from 'pages';
import { useUser } from 'hooks/useUser';
import { useGlobalState } from 'hooks/useGlobalState';
import { useMediaQuery } from 'react-responsive';
import { Card, Column, Row } from 'legacy/components/common/myeverbright';
import { H2, H3 } from 'legacy/components/common/design-system/Typography';
import colors from 'theme/colors';
import DashboardFaqCard from './DashboardFaqCard';
import { filterFaqs } from '../../utils';
import { DESKTOP_MIN_WIDTH } from '../../constants';
import { LinkWrapper } from './FaqStyles';

function FaqRow() {
  const { faqs } = useGlobalState();
  const { userResult } = useUser();
  const isLargeScreen = useMediaQuery({
    minWidth: DESKTOP_MIN_WIDTH,
  });

  const cardWidth = isLargeScreen ? '25%' : '100%';

  return (
    <Column $gap="16px">
      <H2>FAQs</H2>
      <Row $collapsing>
        {filterFaqs(faqs, userResult?.user)
          ?.slice(0, 3)
          .map((faq) => (
            <Column key={faq.id} $width={cardWidth}>
              <DashboardFaqCard faq={faq} />
            </Column>
          ))}
        <Column $width={cardWidth}>
          <Card $bgColor="secondaryDarkBlue" $textColor="white" $fullHeight>
            <H3 $margin="0 0 24px" color={colors.white}>{`Don't see what you're looking for?`}</H3>

            <LinkWrapper $linkColor={colors.white}>
              <NavLink to={`/${pages.SUPPORT}`}>View all FAQs</NavLink>
            </LinkWrapper>
          </Card>
        </Column>
      </Row>
    </Column>
  );
}

export default FaqRow;
