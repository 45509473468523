import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function CheckIcon({ color, size = 'small' }: IconProps) {
  const theme = useTheme();

  return (
    <svg
      width={size === 'large' ? '14' : '8'}
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8524 0.638988C14.0492 0.822528 14.0492 1.12448 13.8524 1.30802L5.35283 9.36012C5.15909 9.54663 4.84035 9.54663 4.64662 9.36012L0.146429 5.09725C-0.0488098 4.91371 -0.0488098 4.61175 0.146429 4.42821C0.341543 4.24171 0.658088 4.24171 0.85339 4.42821L4.99972 8.35657L13.1461 0.638988C13.3399 0.453671 13.6586 0.453671 13.8524 0.638988Z"
        fill={color || theme.colors.textPrimarySecondary1100}
      />
    </svg>
  );
}

export default CheckIcon;
