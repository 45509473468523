import styled, { css } from 'styled-components';

export enum Status {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
}

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  margin-top: 32px;

  #status-accordion {
    overflow: unset;
  }
`;

export const AccordionItemWrapper = styled.div`
  position: relative;
`;

export const StatusWrapper = styled.div`
  position: absolute;
  top: 6px;
  left: -44px;
  height: 100%;
`;

type VerticalLineProps = {
  $showLine: boolean;
  $status: Status;
};

export const VerticalLine = styled.div<VerticalLineProps>`
  ${({ $showLine, $status, theme: { colors } }) =>
    $showLine
      ? css`
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          height: 100%;
          border-left: 1px solid
            ${$status === Status.COMPLETED
              ? colors.strokeInteractionActiveSecondary2100
              : colors.strokePrimaryGrey20};
        `
      : `display: none;`}
`;

type CircleProps = {
  $status: Status;
};

export const Circle = styled.div<CircleProps>`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  ${({ $status, theme: { colors } }) => {
    let bgColor = colors.textInverseWhite;
    let color = colors.textSecondarySubtleGrey80;
    let borderColor = colors.strokePrimaryGrey20;

    if ($status === Status.COMPLETED) {
      bgColor = colors.backgroundPrimaryWhite;
      color = colors.textSystemInfoSecondary2100;
      borderColor = colors.strokeInteractionActiveSecondary2100;
    }

    if ($status === Status.IN_PROGRESS) {
      bgColor = colors.strokeInteractionActiveSecondary2100;
      color = colors.textInverseWhite;
      borderColor = colors.strokeInteractionActiveSecondary2100;
    }
    return css`
      background-color: ${bgColor};
      color: ${color};
      border: 1px solid ${borderColor};
    `;
  }};
`;
