import React, { useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from 'react-responsive';
import useUser from 'hooks/useUser';
import { Modal } from './common/design-system';
import { DESKTOP_MIN_WIDTH } from '../constants';
import { ArrowUpRightFromSquare } from './common/design-system/Icons';

const WARNING_TIME_MILLISECONDS = 28 * 60 * 1000; // 28 minutes
const SIGN_OUT_TIME_MILLISECONDS = WARNING_TIME_MILLISECONDS + 2 * 60 * 1000; // 2 minutes

export default function ExpiringSessionModal() {
  // NIST provides a few guidelines for when to expire inactive user sessions.
  // 30 minutes is a good threshold.

  const warningTimeoutRef = useRef(0);
  const signOutTimeoutRef = useRef(0);
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery({
    minWidth: DESKTOP_MIN_WIDTH,
  });

  const { logout } = useUser();

  const warnExpiring = () => {
    setOpen(true);
  };

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const setTimeouts = useCallback(() => {
    warningTimeoutRef.current = window.setTimeout(warnExpiring, WARNING_TIME_MILLISECONDS);
    signOutTimeoutRef.current = window.setTimeout(handleLogout, SIGN_OUT_TIME_MILLISECONDS);
  }, [handleLogout]);

  const clearTimeouts = () => {
    clearTimeout(warningTimeoutRef.current);
    clearTimeout(signOutTimeoutRef.current);
  };

  useEffect(() => {
    const resetTimeouts = () => {
      clearTimeouts();
      setTimeouts();
    };

    setTimeouts();
    window.addEventListener('click', resetTimeouts);
    window.addEventListener('scroll', resetTimeouts);
    window.addEventListener('keydown', resetTimeouts);

    return () => {
      window.removeEventListener('click', resetTimeouts);
      window.removeEventListener('scroll', resetTimeouts);
      window.removeEventListener('keydown', resetTimeouts);
      clearTimeouts();
    };
  }, [setTimeouts]);

  return (
    <Modal
      isOpen={open}
      contentLabel="Inactive Session Modal"
      title="Inactive Session"
      shouldCloseOnOverlayClick={false}
      showCloseButton={false}
      shouldCloseOnEsc={false}
      isFullWidth
      ctaButtons={{
        permanentSecondary: {
          label: 'Log out',
          onClick: handleLogout,
          Icon: isDesktop ? ArrowUpRightFromSquare : undefined,
        },
        primary: {
          label: "I'm still here!",
          onClick: () => setOpen(false),
        },
      }}
    >
      <p>
        Are you still there? For your security, sessions expire after thirty minutes of inactivity.
      </p>
    </Modal>
  );
}
