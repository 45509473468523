import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { mode1ThemePrimatives } from 'shared/design-system/theme/colors/themeColors';
import { Text } from '../Text/Text';

export const ToastStyled = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    max-width: 342px;
    width: 100%;
  }

  .Toastify__toast {
    border-radius: 8px;
    padding: 12px;
    box-shadow: ${({ theme }) => theme.elevations.high};
  }

  .Toastify__toast-icon {
    margin-top: 4px;
    margin-inline-end: 8px;
  }

  .Toastify__toast-body {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }
`;

export const Title = styled(Text)`
  font-family: Avenir;
  font-weight: 800;
  line-height: 24px;
  color: ${mode1ThemePrimatives.secondaryS1100};
`;

export const Message = styled(Text)`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${mode1ThemePrimatives.secondaryS1100};
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  height: fit-content;
  cursor: pointer;
`;
