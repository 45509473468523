import styled from 'styled-components';

import Text from 'shared/components/Text/Text';
import { CardMainBody } from 'shared/components/Card/Card.styles';

export const OverViewCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  ${CardMainBody} {
    width: 100%;
  }

  div {
    margin: 0;
    max-width: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 48px;
  }
`;

export const CardDescription = styled(Text)`
  ${({ theme }) => `
        color: ${theme.colors.textSecondarySubtleGrey80};
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 100;
    `}
`;
