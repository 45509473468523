import React, { useState } from 'react';
import { downloadContract } from 'api/agreement';
import { Button } from 'legacy/components/common/design-system';
import { Card, Message } from 'legacy/components/common/myeverbright';
import { AgreementType, MessageType } from 'legacy/types';
import { formatDateShort } from 'legacy/utils';
import { Grid } from './DocumentsCardStyles';

export function DocumentsCard({ agreement }: { agreement: AgreementType }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<MessageType | null>(null);

  const handleDownload = async () => {
    setMessage(null);
    setLoading(true);
    downloadContract(agreement?.id)
      .then((response) => {
        const { presigned_url: presignedUrl } = response.data;
        const link = document.createElement('a');
        link.href = presignedUrl;
        link.download = 'signed_everbright_contract.pdf';
        link.click();
        link.remove();
      })
      .catch(() => {
        setMessage({
          message: 'Something went wrong. Please try again later, or contact support.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card $fullWidth title="Documents">
      <h3>Documents</h3>
      <Grid $columns={3}>
        <p>EverBright Contract</p>

        <p>Signed {formatDateShort(agreement.contract_signed_date)}</p>

        <Button
          onClick={handleDownload}
          label="Download"
          iconRight
          styleVariant="tertiary"
          margin="0 auto 0 0"
          isLoading={loading}
        />
      </Grid>
      {message && <Message severity={message.type}>{message.message}</Message>}
    </Card>
  );
}

export default DocumentsCard;
