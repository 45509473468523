import { AccountType, UserType } from 'api/types';
import { MilestoneType } from 'modules/types/util';
import pages, { Page } from 'pages';
import { isHomeowner } from './checkUserRole';

export const getDashboardRedirectPath = (user?: UserType, account?: AccountType) => {
  let hasDashboardAccess = isHomeowner(user?.role) && Boolean(account);
  let redirectPath: `/${Page}` = `/${pages.ADMIN}`;

  const activeAccounts = user?.accounts?.filter((userAccount) => userAccount.agreements.length);

  // contract/workflow has been canceled if no agreements are on an account
  if (isHomeowner(user?.role) && !activeAccounts?.length) {
    hasDashboardAccess = false;
    redirectPath = `/${pages.STATUS}`;
  } else if (account) {
    const agreement = account.agreements[0];

    if (
      hasDashboardAccess &&
      agreement?.product_version !== 1 && // RIC 1.0 aren't a part of Early Access
      agreement?.current_milestone && // current_milestone is null for older agreements that are in service.
      agreement?.current_milestone !== MilestoneType.IN_SERVICE
    ) {
      hasDashboardAccess = false;
      redirectPath = `/${pages.STATUS}`;
    }
  }

  return { hasDashboardAccess, redirectPath };
};

export default {};
