import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyRegular } from 'legacy/components/common/design-system/Typography';

export default function SystemInstallationContent() {
  const { t } = useTranslation();
  return (
    <div>
      <BodyRegular>{t('statusAccordionCopy.systemInstallation.copy.body1')}</BodyRegular>
      <ul>
        <li>
          <BodyRegular>
            {t('statusAccordionCopy.systemInstallation.copy.body2List.item1')}
          </BodyRegular>
        </li>
        <li>
          <BodyRegular>
            {t('statusAccordionCopy.systemInstallation.copy.body2List.item2')}
          </BodyRegular>
        </li>
        <li>
          <BodyRegular>
            {t('statusAccordionCopy.systemInstallation.copy.body2List.item3')}
          </BodyRegular>
        </li>
      </ul>
      <BodyRegular $margin="0 0 16px">
        {t('statusAccordionCopy.systemInstallation.copy.body3')}
      </BodyRegular>
    </div>
  );
}
