import { MonitoringDataParams } from 'legacy/types';
import { DateRange } from './createDateRange';
import { getAggregationLevel } from './getAggregationLevel';

export const getRequestParams = (
  dateRange: DateRange | undefined | null,
): MonitoringDataParams | undefined => {
  if (!dateRange) {
    return undefined;
  }
  const params = {
    start_time: dateRange.startDate.toISOString(),
    end_time: dateRange.endDate.toISOString(),
    aggregation_level: getAggregationLevel(dateRange.zoomLevel),
  };
  return params;
};

export default getRequestParams;
