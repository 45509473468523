/**
 *
 * STYLE VARIANTS
 *
 */

import IconProps from 'shared/components/icons/types';

// INTERACTIONS
export type TDropdownInteractionsStyles = {
  // enabled
  fontColor: string;
  background: string;
  border: string;
  borderRadius: string;
  padding: string;
  dropdownItemPadding: string;

  // hover
  dropdownItemBackgroundHover: string;

  // active
  dropdownItemBackgroundActive: string;
};

// STATES
export type TDropdownStates = {
  enabled: TDropdownInteractionsStyles;
  disabled: TDropdownInteractionsStyles;
};
export type TDropdownStatesKey = keyof TDropdownStates;
export const ENABLED_DROPDOWN_STATE: TDropdownStatesKey = 'enabled';
export const DISABLED_DROPDOWN_STATE: TDropdownStatesKey = 'disabled';

// STYLE VARIANTS
export type TDropdownStyleVariants = {
  default: TDropdownStates;
  secondary: TDropdownStates;
};
export type TDropdownStyleVariantsKey = keyof TDropdownStyleVariants;
export const DEFAULT_DROPDOWN_STYLE_VARIANT: TDropdownStyleVariantsKey = 'default';
export const SECONDARY_DROPDOWN_STYLE_VARIANT: TDropdownStyleVariantsKey = 'secondary';

/**
 *
 * TDropdownes in the theme
 *
 */
export type TDropdowns = {
  variants: TDropdownStyleVariants;
};

export type TDropdownDataItem = {
  title?: string;
  value: string;
  Icon?: (props: IconProps) => JSX.Element;
  isDisabled?: boolean;
};
