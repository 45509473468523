import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomFieldProps, FormField } from '../FormField/FormField';

interface PasswordFieldProps extends CustomFieldProps {
  label?: string;
  showPlaceHolder?: boolean;
  onValidate?: (value: string) => string | boolean;
}

export function PasswordField({
  register,
  onClearInput,
  hasError,
  errorMessage,
  margin,
  fieldName = 'password',
  label: labelProp,
  onValidate,
  showPlaceholder = true,
}: PasswordFieldProps) {
  const { t } = useTranslation();

  const label = labelProp || t('loginScreen.password');
  const placeholder = labelProp || t('loginScreen.password');

  const handleValidate = (value: string): string | boolean => {
    if (onValidate) {
      return onValidate(value);
    }

    if (!value) return t('loginScreen.errors.PASSWORD_MISSING');

    return true;
  };

  return (
    <FormField
      label={label}
      type="password"
      placeholder={showPlaceholder ? placeholder : ' '}
      hasError={hasError}
      errorMessage={errorMessage}
      onClearInput={() => onClearInput(fieldName)}
      margin={margin}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...register(fieldName, { validate: (value) => handleValidate(value) })}
    />
  );
}

export default PasswordField;
