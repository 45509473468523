import { useEffect } from 'react';

declare global {
  // This interface is, in fact, used (see inContact.onload below)
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    icPatronChat: any;
  }
}

export default function InContact() {
  useEffect(() => {
    const inContact = document.createElement('script');
    inContact.src = '/incontact.20240123.js';
    inContact.defer = true;
    inContact.onload = () => {
      // InContact must be initialized once and exactly once, or an ominous
      // shadow appears on the page where the chat box ought to be for
      // every invocation.
      const isInitialized =
        document.getElementsByClassName('ie-div-position-customer-chat').length === 0;
      if (isInitialized) {
        const { icPatronChat } = window;
        // It would be better practice to extract the "special" keys from here
        // into .env but it's not totally clear which of these are actually special.
        icPatronChat.init({
          serverHost: 'https://home-c63.nice-incontact.com',
          bus_no: 4606227,
          poc: '9f67020b-a67d-400c-aabb-c5ab3a1e8bc4',
          params: ['FirstName', 'Last Name', 'first.last@company.com', '555-555-5555'],
        });
      }
    };
    document.body.appendChild(inContact);

    return () => {
      document.body.removeChild(inContact);
    };
  });
  return null;
}
