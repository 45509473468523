import React, { useEffect, useState } from 'react';
import { CaptionProps, DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { addMonths, format, subMonths } from 'date-fns';
import { CustomCaption } from './CustomCaption';
import { CustomFooter } from './CustomFooter';

import { StyledDayPicker } from './DatePickerStyles';

type Props = {
  mode?: 'single' | 'range';
  selectedDate: Date | DateRange;
  onDayClick: (date: Date) => void;
  onCancel: () => void;
  onOk: () => void;
  dateRange: { oldest: Date; newest: Date };
};

const initCurrentMonth = (mode: 'single' | 'range', selectedDate: Date | DateRange) => {
  if (mode === 'single') return (selectedDate as Date) || new Date();
  return (selectedDate as DateRange).from || new Date();
};

export function Calendar({
  mode = 'single',
  selectedDate,
  onDayClick,
  onCancel,
  onOk,
  dateRange,
}: Props) {
  const [currentMonth, setCurrentMonth] = useState(initCurrentMonth(mode, selectedDate));
  const [earliestMonth, setEarliestMonth] = useState(false);
  const [latestMonth, setLatestMonth] = useState(false);

  useEffect(() => {
    setEarliestMonth(dateRange.oldest > currentMonth);
    setLatestMonth(
      dateRange.newest < new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0),
    );
  }, [dateRange, currentMonth]);

  const handlePrevClick = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextClick = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const formatWeekdayName = (date: Date) => format(date, 'EEEEEE');

  const disableFutureDates = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date > today;
  };

  return mode === 'single' ? (
    <StyledDayPicker
      components={{
        Caption: ({ displayMonth }: CaptionProps) =>
          CustomCaption({
            header: format(displayMonth, 'MMMM yyyy'),
            onPrevClick: earliestMonth ? undefined : handlePrevClick,
            onNextClick: latestMonth ? undefined : handleNextClick,
          }),
        Footer: () => CustomFooter({ onCancelClick: onCancel, onOkClick: onOk }),
      }}
      mode="single"
      selected={selectedDate as Date}
      onDayClick={onDayClick}
      month={currentMonth}
      disabled={disableFutureDates}
      formatters={{
        formatWeekdayName,
      }}
      classNames={{
        table: 'custom-table',
        cell: `custom-cell`,
        day_selected: 'custom-day-selected',
        day_range_start: 'custom-day-range-start',
        day_range_middle: 'custom-day-range-middle',
        day_range_end: 'custom-day-range-end',
        head_cell: 'custom-head-cell',
        month: `custom-month`,
        nav_button_next: `custom-nav-next`,
        nav_button_previous: `custom-nav-prev`,
      }}
    />
  ) : (
    <StyledDayPicker
      components={{
        Caption: ({ displayMonth }: CaptionProps) =>
          CustomCaption({
            header: format(displayMonth, 'MMMM yyyy'),
            onPrevClick: earliestMonth ? undefined : handlePrevClick,
            onNextClick: latestMonth ? undefined : handleNextClick,
          }),
        Footer: () => CustomFooter({ onCancelClick: onCancel, onOkClick: onOk }),
      }}
      mode="range"
      selected={selectedDate as DateRange}
      onDayClick={onDayClick}
      month={currentMonth}
      formatters={{
        formatWeekdayName,
      }}
      classNames={{
        table: 'custom-table',
        cell: `custom-cell`,
        day_selected: 'custom-day-selected',
        day_range_start: 'custom-day-range-start',
        day_range_middle: 'custom-day-range-middle',
        day_range_end: 'custom-day-range-end',
        head_cell: 'custom-head-cell',
        month: `custom-month`,
        nav_button_next: `custom-nav-next`,
        nav_button_previous: `custom-nav-prev`,
      }}
    />
  );
}

export default Calendar;
