import axios from 'axios';
import routes from 'routes';

export type PostCommentRequestBody = {
  comment: string;
};

export function postComment(data: PostCommentRequestBody) {
  return axios.post(routes.userComment, { ...data });
}

export default {};
