import styled from 'styled-components';
import { FONT_FAMILY } from '../Text/Text';

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const StoreButton = styled.a`
  ${({ theme }) => `
        align-self: center;
        justify-self: center;
        @media (width < ${theme.breakpoints.small}) {
            display: none;
        }
    `}
`;

export const StoreButtonLarge = styled(StoreButton)`
  ${({ theme }) => `
        @media (width < ${theme.breakpoints.small}) {
            display: unset;
        }
        @media (width >= ${theme.breakpoints.small}) {
            display: none;
        }
    `}
`;

export const MenuWrapper = styled.ul`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    list-style: none;
    padding: 0px;
    font-family: ${FONT_FAMILY};
    margin-top: 0;
    li {
        margin-right: 24px;
        width: fit-content;
        a {
            display: block;
            color: ${theme.colors.textSecondaryGrey90};
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;

            &:hover {
                color: ${theme.colors.textLinkLinkSecondary2100};
            }
    
            &:active {
                color: ${theme.colors.textLinkLinkVisitedFunction4100};
            }
    
            &:focus-visible {
                color: ${theme.colors.textLinkLinkSecondary2100};
                outline: 3px solid ${theme.colors.chartChartLightbluePressedSecondary240};
                border-radius: 5%;
            }
        }
    }
`}
`;

export const FooterContent = styled.div`
  display: flex;

  ${StoreButton} {
    margin-left: 12px;
  }
`;

export const FooterWrapper = styled.div`
  ${({ theme }) => `
        display: flex;
        flex-direction: column;
        align-self: baseline;
        width: 100%;
        border-top: 1px solid ${theme.colors.backgroundAccentGreySubtleGrey10};
        sub {
            color: ${theme.colors.textSecondarySubtleGrey80};
            font-size: 12px;
            font-weight: 400;
            font-family: ${FONT_FAMILY};
        }

        @media (width < ${theme.breakpoints.small}) {
            padding: 32px 16px 23px 16px;
            margin-top: 32px;
            ${FooterContent} {
                flex-direction: column;
                ${MenuWrapper} {
                    flex-direction: column;
                    justify-content: left;
                    margin-bottom: 8px;
                    li {
                        padding-left: 16px;
                        margin-bottom: 24px;
                        a {
                            padding-left: 0;
                        }
                    }
                }
                ${ButtonsWrapper} {
                    padding-bottom: 31px;
                    width: 100%;
                    justify-content: center;
                    ${StoreButton} {
                        margin: 0px 8px;
                    }
                }
            }
            sub {
                align-self: center;
                font-weight: 100;
            }
        }

        @media (${theme.breakpoints.small} <= width < ${theme.breakpoints.medium}) {
            padding: 32px 24px 24px 34px;
            margin-top: 40px;
            ${FooterContent} {
                flex-direction: column;
                align-items: center;
                ${MenuWrapper} {
                    justify-content: space-between;
                    margin-bottom: 0px;
                    a {
                        padding-bottom: 0;
                    }
                }
                ${ButtonsWrapper} {
                    padding: 24px 0px 20px; 
                    ${StoreButton} {
                        margin: 0px 6px;
                    }
                }
            }
            sub {
                align-self: center;
            }
        }

        @media (width >= ${theme.breakpoints.medium}) {
            padding: 32px 0 23px 0;     
            justify-content: space-between;   
            margin-top: 48px;
            ${MenuWrapper} {
                margin-bottom: 24px;
                a {
                    padding-left: 0;
                }
            }
            sub {
                align-self: left;
            }    
        }
    `}
`;
