import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function VisibilityIcon({ color, isVisible }: IconProps & { isVisible: boolean }) {
  const theme = useTheme();

  return isVisible ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3338 7.63363C11.3338 9.47486 9.84155 10.9671 8.00033 10.9671C6.1591 10.9671 4.66685 9.47486 4.66685 7.63363C4.66685 5.79241 6.1591 4.30016 8.00033 4.30016C9.84155 4.30016 11.3338 5.79241 11.3338 7.63363ZM8.00033 5.13353C6.59662 5.13353 5.50022 6.22993 5.50022 7.63363C5.50022 9.0139 6.59662 10.1337 8.00033 10.1337C9.3806 10.1337 10.5004 9.0139 10.5004 7.63363C10.5004 6.22993 9.3806 5.13353 8.00033 5.13353ZM13.0162 3.8991C14.235 5.02936 15.0501 6.36014 15.4355 7.31331C15.5215 7.51905 15.5215 7.74822 15.4355 7.95396C15.0501 8.88369 14.235 10.2145 13.0162 11.3682C11.7896 12.5088 10.1046 13.4672 8.00033 13.4672C5.89607 13.4672 4.2111 12.5088 2.98501 11.3682C1.7662 10.2145 0.951585 8.88369 0.564095 7.95396C0.478635 7.74822 0.478635 7.51905 0.564095 7.31331C0.951585 6.36014 1.7662 5.02936 2.98501 3.8991C4.2111 2.75947 5.89607 1.80005 8.00033 1.80005C10.1046 1.80005 11.7896 2.75947 13.0162 3.8991ZM1.33337 7.63363C1.68052 8.467 2.43056 9.71706 3.55222 10.7588C4.67466 11.8005 6.16691 12.6339 8.00033 12.6339C9.83374 12.6339 11.326 11.8005 12.4484 10.7588C13.5709 9.71706 14.3209 8.467 14.6673 7.63363C14.3209 6.77683 13.5709 5.52677 12.4484 4.5085C11.326 3.46653 9.83374 2.63342 8.00033 2.63342C6.16691 2.63342 4.67466 3.46653 3.55222 4.5085C2.43056 5.52677 1.68052 6.77683 1.33337 7.63363Z"
        fill={color || theme.colors.iconIconSecondarySubtleGrey80}
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4749_363)">
        <path
          d="M15.8475 13.085C16.0225 13.2225 16.05 13.4525 15.915 13.6475C15.7775 13.8225 15.525 13.85 15.3525 13.715L0.152148 1.714C-0.0212341 1.577 -0.0508266 1.3255 0.0860734 1.15215C0.222948 0.978766 0.474498 0.949173 0.647748 1.08607L15.8475 13.085ZM15.1375 7.7075C14.8825 8.3225 14.43 9.1325 13.79 9.9325L13.1625 9.4375C13.7525 8.6775 14.17 7.9525 14.4 7.4C14.0675 6.5775 13.3475 5.3775 12.27 4.4C11.1925 3.39975 9.76 2.6 8 2.6C7.005 2.6 6.115 2.85525 5.335 3.25775L4.6525 2.7195C5.6 2.17 6.72 1.7775 8 1.7775C10.02 1.7775 11.6375 2.721 12.815 3.815C13.985 4.9 14.7675 6.1775 15.1375 7.0925C15.22 7.29 15.22 7.51 15.1375 7.7075ZM2.20975 4.8675L2.8375 5.3625C2.2465 6.1 1.83075 6.8475 1.5775 7.3775C1.93325 8.2 2.6525 9.4 3.73 10.4C4.8075 11.4 6.24 12.2 7.9775 12.2C8.995 12.2 9.885 11.945 10.665 11.5425L11.3475 12.08C10.3775 12.63 9.28 13 7.9775 13C5.98 13 4.3625 12.08 3.185 10.985C2.0155 9.8775 1.2335 8.6 0.861498 7.7075C0.779498 7.51 0.779498 7.29 0.861498 7.0925C1.11825 6.4775 1.57 5.6675 2.20975 4.8675ZM4.7775 7.3775C4.7775 7.2425 4.8125 7.09 4.8325 6.9375L5.605 7.5475C5.68 8.805 6.7025 9.8 7.9775 9.8C8.14 9.8 8.28 9.7875 8.4125 9.765L9.185 10.3725C8.8175 10.52 8.4175 10.6 7.9775 10.6C6.2325 10.6 4.7775 9.1675 4.7775 7.3775ZM11.2 7.3775C11.2 7.5575 11.1875 7.71 11.1675 7.8625L10.395 7.2525C10.32 5.995 9.275 4.9775 7.9775 4.9775C7.86 4.9775 7.72 5.0125 7.5875 5.035L6.815 4.4275C7.1825 4.28 7.5825 4.1775 7.9775 4.1775C9.7675 4.1775 11.2 5.6325 11.2 7.3775Z"
          fill={color || theme.colors.iconIconSecondarySubtleGrey80}
        />
      </g>
      <defs>
        <clipPath id="clip0_4749_363">
          <rect width="16" height="16" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VisibilityIcon;
