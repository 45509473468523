import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';

export const ClaimAccountFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;

  input {
    width: 100%;
  }

  .password-strength-bar {
    margin: 8px 0 24px;
    > div > div {
      height: 4px !important;
    }
  }
`;

export const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Value = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;
