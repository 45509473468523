import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router';

import useUser from 'hooks/useUser';
import pages from 'pages';
import { AdminContext } from 'contexts/AdminContext';
import { getFAQs, getTags } from 'api/faqs';
import { AxiosError } from 'axios';
import { USER_ROLES } from 'legacy/constants';
import { Container, MenuItem, Menu } from 'legacy/views/admin/AdminStyles';
import { Column } from 'legacy/components/common/myeverbright';
import { isAdmin, isMarketing, isSupport } from 'legacy/utils';

export function AdminLayout() {
  const { setTags, setFaqs } = useContext(AdminContext);
  const { userResult, refetch } = useUser();

  const user = userResult?.user;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const faqsResponse = await getFAQs();
        setFaqs(faqsResponse.data);

        if (
          userResult?.user?.role === USER_ROLES.ADMIN ||
          userResult?.user?.role === USER_ROLES.MARKETING ||
          userResult?.user?.role === USER_ROLES.SUPER_ADMIN
        ) {
          const tagsResponse = await getTags();
          setTags(tagsResponse.data);
        }
      } catch (error) {
        if ((error as AxiosError).response && (error as AxiosError).response?.status === 401) {
          refetch();
        }
      }
    };

    fetchData();
  }, [refetch, setFaqs, setTags, userResult]);

  return (
    <Container>
      <Column>
        <h1>Admin Dashboard</h1>
        {user && (
          <Column>
            <Menu>
              {isSupport(user.role) && <MenuItem to={pages.AGREEMENTS}>Agreements</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.ADMINS}>Admins</MenuItem>}
              <MenuItem to={pages.STATISTICS}>Statistics</MenuItem>
              {isMarketing(user.role) && <MenuItem to={pages.MANAGE_FAQS}>FAQs</MenuItem>}
              {isMarketing(user.role) && <MenuItem to={pages.TAGS}>Tags</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.MONITORING}>Monitoring</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.AUTH_COPY_ACK}>Auth Copy Ack</MenuItem>}
              {isAdmin(user.role) && <MenuItem to={pages.BACKFILL}>Backfill</MenuItem>}
            </Menu>
            <Outlet />
          </Column>
        )}
      </Column>
    </Container>
  );
}

export default AdminLayout;
