import React from 'react';
import { useTheme } from 'styled-components';

export function GridComponent(props: any) {
  const { x1, y1, x2, y2 } = props;
  const theme = useTheme();

  return (
    <svg>
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        stroke={theme.colors.chartYAxisStrokeGrey}
        strokeWidth="1"
        strokeDasharray="5,5"
      />
    </svg>
  );
}

export default GridComponent;
