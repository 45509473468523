import React from 'react';
import { Button, Select, TextInput } from 'legacy/components/common/design-system';
import { Card, Column, Row } from 'legacy/components/common/myeverbright';
import SystemForm, { SystemFormType } from './SystemForm';
import { ContractType, ProductType } from '../../constants';

export interface AgreementFormType {
  product_name: string | null;
  product_type: string | null;
  system: SystemFormType;
  homeowner_contract_id: string | null;
  platform_uuid: string | null;
  initial_balance: number | null;
  current_balance: number | null;
  contract_type: string | null;
  contract_signed_date: Date | string | null;
  contract_term: number | null;
  monthly_payment: number | null;
  rate_contract: number | null;
  rate_escalator: number | null;
}

type Props = {
  agreement: AgreementFormType;
  setAgreement: Function;
  handleSubmit: (e: React.SyntheticEvent) => Promise<void>;
};

function AgreementForm({ agreement, setAgreement, handleSubmit }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement({ ...agreement, [e.target.name]: e.target.value });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement({ ...agreement, [e.target.name]: Number(e.target.value) });
  };

  const handleSelectType = (type: string, value: string) => {
    setAgreement({ ...agreement, [type]: value });
  };

  return (
    <form>
      <Column>
        <Row $justifyContent="space-between">
          <Row $width="100%">
            <TextInput
              ariaLabel="Product Name"
              label="Product Name"
              name="product_name"
              isRequired
              defaultValue={agreement.product_name?.toString() || ''}
              onChange={handleChange}
            />
          </Row>
        </Row>
        <Row $justifyContent="space-between">
          <Row $width="100%">
            <Select
              label="Product Type"
              placeholder="select product type"
              value={agreement.product_type || ''}
              options={[
                {
                  value: ProductType.LOAN_BUYDOWN_PROMO,
                  title: ProductType.LOAN_BUYDOWN_PROMO,
                },
                {
                  value: ProductType.VARIABLE_PPA,
                  title: ProductType.VARIABLE_PPA,
                },
                {
                  value: ProductType.FIXED_PPA,
                  title: ProductType.FIXED_PPA,
                },
                { value: ProductType.LEASE, title: ProductType.LEASE },
              ]}
              onSelectOption={(value) => handleSelectType('product_type', value)}
            />
          </Row>
          <Row $width="100%">
            <Select
              label="Contract Type"
              placeholder="select contract type"
              value={agreement.contract_type || ''}
              options={[
                {
                  value: ContractType.LOA,
                  title: ContractType.LOA,
                },
                {
                  value: ContractType.LSE,
                  title: ContractType.LSE,
                },
                {
                  value: ContractType.PPA,
                  title: ContractType.PPA,
                },
              ]}
              onSelectOption={(value) => handleSelectType('contract_type', value)}
            />
          </Row>
        </Row>
        <Row $justifyContent="space-between">
          <Row $width="100%">
            <TextInput
              ariaLabel="Ops Workflow UUID"
              label="Ops Workflow UUID (hex string of the form <8-4-4-4-12>)"
              name="platform_uuid"
              defaultValue={agreement.platform_uuid || ''}
              isRequired
              onChange={handleChange}
            />
          </Row>
        </Row>
        <Row>
          <Column $gap="0px">
            <p>System</p>
            <Card $fullWidth>
              <SystemForm
                system={agreement.system}
                setSystem={(system: SystemFormType) => setAgreement({ ...agreement, system })}
              />
            </Card>
          </Column>
        </Row>
        <Row>
          <TextInput
            type="number"
            ariaLabel="Initial Balance"
            label="Initial Balance ( $ )"
            name="initial_balance"
            isRequired
            defaultValue={agreement.initial_balance?.toString() || ''}
            onChange={handleNumberChange}
          />
          <TextInput
            type="number"
            ariaLabel="Current Balance"
            label="Current Balance ( $ )"
            name="current_balance"
            isRequired
            defaultValue={agreement.current_balance?.toString() || ''}
            onChange={handleNumberChange}
          />
        </Row>
        <Column $width="100%">
          <p>Contract</p>
          <Card $fullWidth>
            <Column>
              <Row>
                <TextInput
                  ariaLabel="Homeowner Contract ID"
                  label="Homeowner Contract ID"
                  name="homeowner_contract_id"
                  minLength={8}
                  maxLength={8}
                  isRequired
                  defaultValue={agreement.homeowner_contract_id || ''}
                  onChange={handleChange}
                />
              </Row>
              <Row>
                <TextInput
                  type="number"
                  ariaLabel="Contract Term"
                  label="Contract Term"
                  name="contract_term"
                  defaultValue={agreement.contract_term?.toString() || ''}
                  onChange={handleNumberChange}
                />
                <TextInput
                  type="number"
                  ariaLabel="Montly Payment"
                  label="Monthly Payment ( $ )"
                  name="monthly_payment"
                  defaultValue={agreement.monthly_payment?.toString() || ''}
                  onChange={handleNumberChange}
                />
              </Row>
              <Row>
                <TextInput
                  type="number"
                  ariaLabel="Contract Rate"
                  label="Contract Rate"
                  name="rate_contract"
                  defaultValue={agreement.rate_contract?.toString() || ''}
                  onChange={handleNumberChange}
                />
                <TextInput
                  type="number"
                  ariaLabel="Escalator Rate"
                  label="Escalator Rate"
                  name="rate_escalator"
                  defaultValue={agreement.rate_escalator?.toString() || ''}
                  onChange={handleNumberChange}
                />
                <TextInput
                  type="date"
                  ariaLabel="Contract Signed Date"
                  label="Contract Signed Date"
                  isRequired
                  value={agreement.contract_signed_date?.toString() || ''}
                  onChange={(e) =>
                    setAgreement({
                      ...agreement,
                      contract_signed_date: e.target.value,
                    })
                  }
                />
              </Row>
            </Column>
          </Card>
        </Column>
        <Row $justifyContent="space-between">
          <Row $width="100%">
            <Button label="Submit" margin="0 0 0 auto" onClick={handleSubmit} />
          </Row>
        </Row>
      </Column>
    </form>
  );
}

export default AgreementForm;
