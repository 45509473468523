import React from 'react';
import { Column } from 'legacy/components/common/myeverbright';
import BackfillWorkflowsForm from 'legacy/components/forms/BackfillWorkflowsForm/BackfillWorkflowsForm';
import { H2 } from 'legacy/components/common/design-system/Typography';

export default function BackfillWorkflows() {
  return (
    <Column>
      <div>
        <H2>Backfill Subset of Workflows</H2>
        <BackfillWorkflowsForm />
      </div>
    </Column>
  );
}
