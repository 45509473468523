import React, { useState } from 'react';
import axios from 'axios';

import { formatDate, formatDateObj, formatMoney, parseDateOnly } from 'legacy/utils';
import { Accordion } from 'legacy/components/common/design-system';
import { AgreementType, MessageType } from 'legacy/types';
import routes from 'routes';
import InfoCard from './InfoCard';
import SystemSection from './SystemSection';
import { AdminAccordionWrapper } from './UserDetailStyles';

type Props = {
  agreement: AgreementType;
  refreshUser: () => void;
};

export default function AgreementSection({ agreement, refreshUser }: Props) {
  const [message, setMessage] = useState<MessageType | null>(null);

  const infoList = [
    { label: 'Product Name', value: agreement.product_display_name, id: 'ag1' },
    { label: 'Product Type', value: agreement.product_type, id: 'ag2' },
    { label: 'Product Version', value: agreement.product_version?.toString() || '', id: 'ag3' },
    { label: 'Contract Type', value: agreement.contract_type, id: 'ag4' },
    { label: 'Homeowner Contract ID', value: agreement.homeowner_contract_id, id: 'ag5' },
    { label: 'Install Cost', value: formatMoney(agreement.system.install_cost), id: 'ag6' },
    { label: 'Initial Balance', value: formatMoney(agreement.initial_balance), id: 'ag7' },
    { label: 'Monthly Payment', value: formatMoney(agreement.monthly_payment), id: 'ag8' },
    { label: 'Contract Term', value: `${agreement.contract_term} years`, id: 'ag9' },
    { label: 'Rate Contract', value: `${agreement.rate_contract}`, id: 'ag10' },
    { label: 'Rate Escalator', value: `${agreement.rate_escalator}`, id: 'ag11' },
    {
      label: 'Contract Signed Date',
      value: formatDate(agreement.contract_signed_date),
      id: 'ag12',
    },
    {
      label: 'Contract Start Date',
      value: agreement.contract_start_date
        ? formatDateObj(parseDateOnly(agreement.contract_start_date))
        : 'N/A',
      id: 'ag13',
    },
    {
      label: 'In-Service Date',
      value: agreement.in_service_date
        ? formatDateObj(parseDateOnly(`${agreement.in_service_date}`))
        : 'N/A',
      id: 'ag14',
    },
    { label: 'Current Milestone', value: agreement.current_milestone, id: 'ag15' },
    { label: 'Interest Rate', value: `${agreement.interest_rate}`, id: 'ag16' },
    {
      label: 'Amount Financed',
      value: formatMoney(agreement.amount_financed || undefined),
      id: 'ag17',
    },
    { label: 'Buydown Amount', value: formatMoney(agreement.buydown_amount), id: 'ag18' },
    { label: 'Platform UUID', value: agreement.platform_uuid, id: 'ag19' },
    {
      label: 'Great America Application IDs',
      value: agreement.great_america_application_ids?.toString(),
      id: 'ag20',
    },
    { label: 'Archived', value: agreement.archived.toString(), id: 'us20' },
  ];

  const handleChangeArchive = (e: React.SyntheticEvent) => {
    const archived = !agreement.archived;
    e.preventDefault();

    setMessage(null);
    axios
      .patch(routes.agreement(agreement.id), { archived })
      .then(() => {
        refreshUser();
      })
      .catch((error) => {
        setMessage({ message: error.response.data, type: 'error' });
      });
  };

  const content = (
    <InfoCard
      items={infoList}
      archived={agreement.archived}
      enableArchive
      onChangeArchived={handleChangeArchive}
      message={message}
    >
      <SystemSection system={agreement.system} />
    </InfoCard>
  );

  return (
    <AdminAccordionWrapper>
      <Accordion
        id="admin-accordion"
        isOpen
        styleVariant="secondary"
        items={[{ label: `Agreement #${agreement.homeowner_contract_id}`, content }]}
      />
    </AdminAccordionWrapper>
  );
}
