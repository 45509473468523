import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Modal, Table } from 'legacy/components/common/design-system';
import { Card, Column, Row } from 'legacy/components/common/myeverbright';

import { impersonateUser, updateUser } from 'api/user';
import { useUser } from 'hooks/useUser';
import pages from 'pages';

import { formatDate, formatDateTime, formatDescription, getActiveAccount } from 'legacy/utils';
import { removeLocalStoragePerSessionItems } from 'contexts/removeLocalStorageItems';
import { routes } from '../../../../../routes';
import { MessageType, UserType } from '../../../../types';
import { EditUser } from './EditUser';
import { ResendClaimed } from './UserSectionStyles';
import InfoCard from '../InfoCard';

type Props = {
  user: UserType;
  refreshUser: () => void;
  message: MessageType | null;
  onMessageUpdate: (message: MessageType | null) => void;
};

export default function UserSection({ user, refreshUser, message, onMessageUpdate }: Props) {
  const [openModal, setOpenModal] = useState(false);

  const { setUserResult } = useUser();
  const navigate = useNavigate();

  const infoList = [
    { label: 'Email', value: user.email, id: 'us0' },
    { label: 'Phone', value: user.phone_number || '', id: 'us1' },
    { label: 'Date Joined', value: formatDate(user.date_joined), id: 'us2' },
    { label: 'Claimed Date', value: formatDate(user.claimed_date), id: 'us3' },
    { label: 'Last Login', value: formatDate(user.last_login), id: 'us4' },
    {
      label: 'Last Password Reset Date',
      value: formatDate(user.last_password_reset_date),
      id: 'us5',
    },
    { label: 'Archived', value: user.archived.toString(), id: 'us6' },
  ];

  const handleImpersonateUser = async () => {
    try {
      const response = await impersonateUser(user.id);
      removeLocalStoragePerSessionItems();
      setUserResult({
        user: response.data.user,
        selectedAccount: getActiveAccount(response.data.user),
        impersonator: response.data.impersonator,
      });
      navigate(`/${pages.DASHBOARD}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        onMessageUpdate({ message: error.message, type: 'error' });
      } else {
        onMessageUpdate({ message: 'An unexpected error occurred', type: 'error' });
      }
    }
  };

  const handleChangeArchive = (e: React.SyntheticEvent) => {
    const archived = !user.archived;
    e.preventDefault();

    onMessageUpdate(null);

    updateUser({ user_id: user.id, body: { archived } })
      .then(() => {
        refreshUser();
      })
      .catch((error) => {
        onMessageUpdate({ message: error.response.data, type: 'error' });
      });
  };

  const resendClaimAccountEmail = async () => {
    onMessageUpdate(null);
    axios
      .post(routes.sendClaimAccountEmail, { email: user.email, is_resend: true })
      .then(() => {
        onMessageUpdate({ message: `Claim Account Email sent to ${user.email}`, type: 'success' });
      })
      .catch((error) => {
        onMessageUpdate({ message: error.response.tableData, type: 'error' });
      });
  };

  const columns = [{ title: 'Date' }, { title: 'Email Description' }, { title: 'Destination' }];

  const rows = user.sent_emails.map((sent_email) => ({
    key: `table-${sent_email.id}`,
    cells: [
      formatDateTime(sent_email.date_sent),
      formatDescription(sent_email.template_description, sent_email.email_template),
      sent_email.user_email,
    ],
  }));

  return (
    <div>
      <h2>
        {user.first_name} {user.last_name}
      </h2>
      <Card $fullWidth>
        <InfoCard
          items={infoList}
          archived={user.archived}
          enableArchive
          message={message}
          onChangeArchived={handleChangeArchive}
        />

        <Row>
          <Column $width="25%">
            <Button label="Edit User" onClick={() => setOpenModal(true)} />
          </Column>
          <Column $width="25%">
            <Button label="Impersonate" styleVariant="secondary" onClick={handleImpersonateUser} />
          </Column>
          <Column $width="25%">
            <ResendClaimed>
              {user.claimed_account ? (
                <span className="claimed">Claimed!</span>
              ) : (
                <span
                  className="link-like"
                  role="button"
                  tabIndex={0}
                  onClick={resendClaimAccountEmail}
                  onKeyDown={resendClaimAccountEmail}
                >
                  Resend Claim Email
                </span>
              )}
            </ResendClaimed>
          </Column>
        </Row>
      </Card>
      <h3>Sent Emails</h3>

      <Table columns={columns} rows={rows} />

      <Modal
        contentLabel="Edit User Info"
        title="Edit User Info"
        isFullWidth
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
      >
        <EditUser user={user} refreshUser={refreshUser} />
      </Modal>
    </div>
  );
}
