import React, { ReactNode } from 'react';
import { formatPhoneNumber } from 'shared/utils/formatPhoneNumber';
import { PHONE_NUMBERS } from 'constants/phoneNumbers';
import { Box, Column, Row } from '../../components/common/myeverbright';
import SupportIcon from '../../../static/support-icon.svg';
import { customerSupportCopy } from '../../copy';
import { Card } from '../../components/OverviewCards/Card';
import { Content, Footer, Icon, IconWrapper } from '../../components/OverviewCards/CardStyles';

function Support({ children }: { children: ReactNode }) {
  return (
    <Column>
      <h1>Support & FAQs</h1>
      <Row $collapsing>
        <Column $flex="2">{children}</Column>
        <Column $flex="1">
          <Box>
            <Card title="Customer Support" fullWidth>
              <IconWrapper>
                <Icon alt="customer support" src={SupportIcon} />
                <p>{customerSupportCopy}</p>
              </IconWrapper>
            </Card>
          </Box>
          <Box>
            <Card fullWidth>
              <>
                <h4>Other ways to contact the EverBright support team include: </h4>

                <Content>
                  <div>
                    <b>Phone</b>
                  </div>
                  <div className="list-detail">
                    <a
                      href={`tel:+1${PHONE_NUMBERS.CUSTOMER_SUPPORT}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT)}
                    </a>
                  </div>
                </Content>
                <Footer>
                  <b>Email</b>
                  <div className="list-detail">
                    <a href="mailto:support@myeverbright.com" target="_blank" rel="noreferrer">
                      support@myeverbright.com
                    </a>
                  </div>
                </Footer>
              </>
            </Card>
          </Box>
        </Column>
      </Row>
    </Column>
  );
}

export default Support;
