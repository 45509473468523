import React from 'react';
import { CheckIcon } from 'shared/components/icons';
import { AddressType } from 'api/types';
import { useTranslation } from 'react-i18next';
import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import { AddressSelector, AddressSelectorWrapper, IconSpacer } from './AddressSelect.styles';

interface AddressSelectProps {
  selectedOption: { id: string; address: AddressType };
  options: { id: string; address: AddressType }[];
  onSelectOption: (id: string) => void;
}

export function AddressSelect({ selectedOption, options, onSelectOption }: AddressSelectProps) {
  const { t } = useTranslation();

  const addressDropdownItems: TDropdownDataItem[] = options.map(({ id, address }) => {
    const addressString = t('addressSelector.address', {
      street_1: address.street_1,
      street_2: address.street_2 ? ` ${address.street_2}` : '',
      city: address.city,
    });
    return {
      title: addressString,
      value: id,
      Icon: id === selectedOption.id ? CheckIcon : () => <IconSpacer />,
    };
  });

  const handleSelectOption = (value: string) => {
    onSelectOption(value);
  };

  return (
    <AddressSelectorWrapper>
      <AddressSelector
        options={addressDropdownItems}
        value={selectedOption.id}
        onSelectOption={handleSelectOption}
      />
    </AddressSelectorWrapper>
  );
}

export default AddressSelect;
