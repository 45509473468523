import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import useUser from 'hooks/useUser';
import { isHomeowner } from 'legacy/utils';
import pages from 'pages';
import ExpiringSessionModal from 'legacy/components/ExpiringSessionModal';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';

type Props = {
  isAdminDashboard: boolean;
  redirectPath: string;
  children?: JSX.Element;
};
export function AdminGuard({ isAdminDashboard, redirectPath, children }: Props) {
  const { userResult } = useUser();
  const location = useLocation();

  if (userResult?.loading) return <LoadingLogo />;

  const hasSession = Boolean(userResult?.user);
  let isAllowed = false;

  if (!hasSession) {
    return <Navigate to={`/${pages.LOGIN}`} replace />;
  }

  if (isHomeowner(userResult?.user?.role) && !isAdminDashboard) {
    isAllowed = true;
  }

  if (!isHomeowner(userResult?.user?.role) && isAdminDashboard) {
    isAllowed = true;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ path: location.pathname }} />;
  }

  return (
    <>
      <ExpiringSessionModal />
      {children || <Outlet />}
    </>
  );
}

export default AdminGuard;
